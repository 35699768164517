import * as Y from 'yjs';
import { useMemo } from 'react';
import { useImmerYjs } from './useImmerYjs';
import {
  AuthorityParty,
  FormCode,
  FormFamilyState,
  FormInstance,
  FormSigningState,
  PropertyRootKey,
  MaterialisedPropertyData,
  META_APPEND,
  OfferDocumentDesc,
  ProspectivePurchaserGroup,
  ServeStateRecipient,
  SignedStates, SigningParty,
  TransactionMetaData
} from '@property-folders/contract/yjs-schema/property';

import {
  OfferState,
  OfferValues,
  PartySignedSummary
} from '@property-folders/contract/property/OfferContractState';
import { Predicate } from '@property-folders/common/predicate';
import { compareFormInstances } from '@property-folders/common/util/compareFormInstances';
import { FormUtil } from '@property-folders/common/util/form';
import { PurchaserSubmittedDocumentStatus } from '@property-folders/contract/yjs-schema/purchaser-portal';
import { determineCompletedTime, mapSigningPartySourceTypeToCategory } from '@property-folders/common/yjs-schema/property/form';
export type ContractRootKeySummary = OfferValues & ContractRootKeySigningStates & { lineageRootKey: string, linkingFormId: string, label?: string, archived?: boolean, form1Served?: number };
import { useYDocObserve } from './useYDocObserve';

// Hiding offered contracts, declined and widthdrawn are both states of offered contracts, so we remove them too
export const hideStatesFilter = (c: ContractRootKeySummary) => ![OfferState.Declined, OfferState.Withdrawn, OfferState.Submitted].includes(c.state);

// This hook was considered temporary before, but now submitted offers end up in an alternative root
// key, just like everything else.
export function useAlternateRootKeyContracts(props: {ydoc: Y.Doc}): ContractRootKeySummary[] {
  const { ydoc } = props;
  const { bindState } = useImmerYjs<TransactionMetaData>(ydoc, PropertyRootKey.Meta.toString());
  const { data: rootKeyList } = bindState<string[]>(m=>m?.sublineageRoots);
  const { data: offerMgmtPurchasers } = bindState<ProspectivePurchaserGroup[]>(m=>m?.offerManagement?.prospectivePurchasers??[]);
  const { data: meta } = bindState<TransactionMetaData>(m => m);
  const form1Recipients = meta?.formStates?.[FormCode.Form1]?.recipients||[];

  const lineageKeyToPurchaserPartyMap = new Map<string, ProspectivePurchaserGroup>();
  for (const party of (offerMgmtPurchasers??[])) {
    for (const offer of party.submittedOffers??[]) {
      if (!offer.id) continue;
      lineageKeyToPurchaserPartyMap.set(offer.id, party);
    }
  }

  //bind to every lineage to listen for changes
  const buster = useYDocObserve(ydoc,
    (rootKeyList || []).flatMap(rootKey => [rootKey, rootKey+META_APPEND]),
    [
      /^purchasers\..*/i,
      /^vendors\..*/i,
      /^formStates\.RSC\.instances\.\[.+\]\.signing/i
    ]
  );

  const calculateSummaries = (fnRootKeyList: string[])=>{
    if (!fnRootKeyList) {
      return [];
    }

    return fnRootKeyList
      .map((rootKey)=>{
        const data = ydoc.getMap(rootKey).toJSON() as MaterialisedPropertyData;
        const meta = ydoc.getMap(rootKey+META_APPEND).toJSON() as TransactionMetaData;

        return summariseContractRootKey(rootKey, data, meta, lineageKeyToPurchaserPartyMap, form1Recipients);
      })
      .filter(Predicate.isTruthy);
  };

  // To finish this off properly, we need to listen to ydoc changes to retrigger a calculation.
  // However, given we are going to get the backend to do this, that may be a waste of time, this
  // should at least allow real data to be shown in the mean time.

  return useMemo(()=>{
    if (!rootKeyList) return [];
    return calculateSummaries(rootKeyList);
  }, [rootKeyList?.join(';'), buster]);
}

type ContractRootKeySigningStates = {
  state: OfferState,
  stateTime: Date | null,
  purchasers: PartySignedSummary[]
  vendors: PartySignedSummary[],
  prospectivePurchaserId: string
};

function summariseContractRootKey(
  rootKey: string,
  data: MaterialisedPropertyData,
  meta: TransactionMetaData,
  lineageKeyToPurchaserPartyMap: Map<string,ProspectivePurchaserGroup>,
  servedParties: ServeStateRecipient[]
): ContractRootKeySummary | undefined {
  const lineageState = meta?.formStates?.[FormCode.RSC_ContractOfSale];
  if (!lineageState) return undefined;

  // Subdocuments should only have one lineage, which means there should only be one form family
  // formcode here
  const lineageInstances = meta.formStates?.[FormCode.RSC_ContractOfSale]?.instances||[];
  let instances = lineageInstances;
  const latestFormId = lineageInstances[lineageInstances.length-1].id;
  if (lineageInstances.filter(i=>i.signing?.state === FormSigningState.Signed).length > 0) {
    instances = lineageInstances.filter(i=>i.signing?.state === FormSigningState.Signed);
  }
  const latestSignedInstance = [...instances].sort(compareFormInstances)[0];

  if (!latestSignedInstance) {
    return undefined;
  }

  const purchasers = latestSignedInstance?.signing?.parties?.filter(p => mapSigningPartySourceTypeToCategory(p.source.type) === 'purchaser');
  const contractServedParties = purchasers?.map(p => {
    const recipient = servedParties?.find(sp => sp.email === p.snapshot?.email || sp.id === p.id);
    return recipient?.servedByEmail || recipient?.manuallyServed ? {
      email: p.snapshot?.email,
      servedAt: recipient?.servedByEmail?.timestamp || recipient?.manuallyServed?.timestamp
    } : undefined;
  })?.filter(Boolean);

  // Should we be checking for any signed instance here? Perhaps a Variation should not change the signing state. But should a termination?

  // Making the assumption that being only one lineage, the data shouldn't change during signing.
  // Should do for now, but later we should probably check signing source. However we are not
  // interested in the signer names, we're interested in the party names, so we do need to be
  // mindful. It may be necessary to check snapshot data as a result.
  // Use party data from data rather than metadata

  const populateParty = (party: AuthorityParty): PartySignedSummary => {
    const result: PartySignedSummary = {
      name: party.fullLegalName??'Unknown'
    };
    if (latestSignedInstance.signing?.state && ![FormSigningState.None, FormSigningState.Configuring].includes(latestSignedInstance.signing?.state)) {
      const matchingSignatories = (latestSignedInstance.signing.parties??[]).filter(p=>p.source.id===party.id);
      if (matchingSignatories.length && matchingSignatories.filter(s=>!s.signedTimestamp).length === 0) {
        result.signedDate = new Date(determineCompletedTime(latestSignedInstance, true));
      }
    }
    return result;
  };

  const submittedOffersEntry = lineageKeyToPurchaserPartyMap.get(rootKey)?.submittedOffers.find(so=>so.id === rootKey);

  const { state, stateTime } = getOfferState(lineageState, latestSignedInstance, submittedOffersEntry);
  const linkingFormId = latestFormId;

  return {
    offerPrice: data.contractPrice?.purchasePrice ? Number(data.contractPrice.purchasePrice) : undefined,
    deposit: data.contractPrice?.deposit ? Number(data.contractPrice.deposit) : undefined,
    settlementDate: data.contractSettlement?.onDate == null
      ? '-'
      : data.contractSettlement?.onDate
        ? data.contractSettlement?.date
        : 'Conditional',
    conditions: {
      finance: data.contractSpecial?.financeRequired,
      salePurchaserProperty: data.contractSpecial?.purchaserSaleRequired
    },
    lineageRootKey: rootKey,
    vendors: (data.vendors??[]).map(populateParty),
    purchasers: (data.purchasers??[]).map(populateParty),
    state,
    stateTime,
    prospectivePurchaserId: lineageKeyToPurchaserPartyMap.get(rootKey)?.id??'',
    linkingFormId,
    label: lineageState.label,
    archived: lineageState.archived,
    form1Served: contractServedParties?.some(csp => csp.servedAt) ? contractServedParties?.find(csp => csp.servedAt)?.servedAt : undefined
  };
}

function getOfferState(lineageState: FormFamilyState, latestSignedInstance: FormInstance, submittedMeta: OfferDocumentDesc|undefined): { state: OfferState, stateTime: Date | null } {
  if (lineageState.terminatedTime) {
    return {
      state: OfferState.Terminated,
      stateTime: stateTimeFn(lineageState.terminatedTime)
    };
  }

  if (!latestSignedInstance.signing?.state) {
    return {
      state: OfferState.Draft,
      stateTime: null
    };
  }

  const expectedSigningPartySourceTypes = latestSignedInstance?.signing?.session?.partySourceTypeRestriction?.length
    ? new Set(latestSignedInstance.signing.session.partySourceTypeRestriction)
    : undefined;
  const allSigningParties = latestSignedInstance.signing.parties || [];
  const expectedSigningParties = expectedSigningPartySourceTypes
    ? allSigningParties.filter(party => expectedSigningPartySourceTypes.has(party.source.type))
    : allSigningParties || [];

  if (FormUtil.instanceIsSubmitted(latestSignedInstance)) {

    switch (submittedMeta?.status) {
      case PurchaserSubmittedDocumentStatus.Declined: {
        return {
          state: OfferState.Declined,
          stateTime: stateTimeFn(submittedMeta.statusAtMs)
        };
      }
      case PurchaserSubmittedDocumentStatus.Withdrawn: {
        return {
          state: OfferState.Withdrawn,
          stateTime: stateTimeFn(submittedMeta.statusAtMs)
        };
      }
    }
    return {
      state: OfferState.Submitted,
      stateTime: stateTimeFn(latestSignedInstance.signing?.session?.completedTime || derivePartyTimestamp(expectedSigningParties))
    };
  }

  if (SignedStates.has(latestSignedInstance.signing.state)) {
    return {
      state: OfferState.Signed,
      stateTime: stateTimeFn(latestSignedInstance.signing.session?.completedTime)
    };
  }

  return {
    state: OfferState.Incomplete,
    stateTime: null
  };
}

function stateTimeFn(ts?: number) {
  return ts ? new Date(ts) : null;
}

function derivePartyTimestamp(parties: SigningParty[]): number | undefined {
  const timestamps = parties.map(p => p.signedTimestamp).filter(Predicate.isTruthy);
  return timestamps.length
    ? Math.max(...timestamps)
    : undefined;
}
