import { Maybe } from '@property-folders/contract';
import { useLightweightTransaction } from '../../hooks/useTransactionField';
import React from 'react';

export interface YValueProps<T> {
  parentPath?: string,
  myPath?: string,
  bindToMetaKey?: boolean,
  ydocForceKey?: string,
  children: (value: Maybe<T>) => React.ReactElement<HTMLElement>
}

export function YValue<T = any>(props: YValueProps<T>) {
  return props.parentPath || props.myPath
    ? <YValueInternal {...props} />
    : props.children(undefined);
}

function YValueInternal<T = any>({
  parentPath,
  myPath,
  bindToMetaKey,
  ydocForceKey,
  children
}: YValueProps<T>) {
  const { value } = useLightweightTransaction({ parentPath, myPath, bindToMetaKey, ydocForceKey });

  return children(value as Maybe<T>);
}
