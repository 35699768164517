import * as Y from 'yjs';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { FormStates } from '@property-folders/contract';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { determineIfFormPassesConditions } from '@property-folders/common/util/stagedDataTransformations';
import { useContext } from 'react';
import { FileSyncContext } from '../../context/fileSyncContext';
import { handleNewForm } from '@property-folders/common/util/handleNewForm';

export function NoSigningDialog({ thisFormCode, formStates, show, onHide, ydoc, ignoreAction }: {
  thisFormCode: string;
  formStates?: FormStates;
  show: boolean;
  onHide: () => void;
  ydoc?: Y.Doc,
  ignoreAction: ()=>any
}) {
  const navigate = useNavigate();
  const formType = FormTypes[thisFormCode];
  const { dependencyPassed, failedInstances } = determineIfFormPassesConditions(thisFormCode, formType?.signingRequirements,formStates);
  const { instance: fileSync } = useContext(FileSyncContext);
  const navigateToForm = (instanceId: string, formCode: string) => {
    const niceText = FormTypes[formCode]?.label;
    navigate(`../document/${LinkBuilder.seoFriendlySlug(instanceId, niceText)}`);
  };

  const createForm = async (formCode: string) => {
    // This had better not be used to create anything expected to live in a sublineage.
    const newForm = await handleNewForm(ydoc, formCode, fileSync);
    if (!newForm) {
      return;
    }
    navigateToForm(newForm.formId, formCode);
  };

  const linkingFormCode = failedInstances[0];

  if (dependencyPassed || !linkingFormCode) { // We should have already tested this, but...
    return <Modal show={show} onHide={onHide} centered>
      <Modal.Body>
      An unexpected state has occured. Please contact reaforms support.
      </Modal.Body>
      <Modal.Footer>
        <Button variant={'primary'} onClick={onHide}>OK</Button>
      </Modal.Footer>
    </Modal>;
  }
  const failedCode = typeof linkingFormCode === 'string' ? linkingFormCode : linkingFormCode?.formCode;
  const failedMessageRef = formType.signingRequirementsMessage?.[failedCode];
  const failedTitle = FormTypes[typeof linkingFormCode === 'string' ? linkingFormCode : linkingFormCode?.formCode]?.label;
  const failedMessage = failedMessageRef
    ? failedMessageRef
    : 'The form '+failedTitle+' has not yet been signed.';
  const missingExists = typeof linkingFormCode !== 'string';

  return <Modal show={show} onHide={onHide} centered>
    <Modal.Header closeButton>
      <Modal.Title>Signing not Completed</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>{failedMessage}</p>
      <p>{missingExists
        ? 'Click View to open the '+failedTitle+'.'
        : 'Click Create to create '+failedTitle+'.'
      }</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant={'secondary'} disabled={true} title='Upload scan of requested document'>Upload</Button>
      <Button variant={'secondary'} onClick={()=>{ignoreAction();onHide();}} title='Proceed to signing anyway'>Ignore</Button>
      <Button variant={'secondary'} onClick={onHide}>Cancel</Button>
      <Button variant={'primary'} onClick={()=>{
        if (linkingFormCode && typeof linkingFormCode === 'object' && linkingFormCode.id) {
          navigateToForm(linkingFormCode.id, linkingFormCode.formCode);
          return;
        }
        if (typeof linkingFormCode === 'string') {
          createForm(linkingFormCode);
          return;
        }
      }}>{missingExists ? 'View' : 'Create'}</Button>
    </Modal.Footer>
  </Modal>;
}
