import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { BP_MINIMA } from '@property-folders/common/data-and-text/bootstrapBreakpoints';
import { SplitButtonChildren, SplitIfManyButton } from './SplitIfManyButton';

export function SplitIfBelowBreakpointButton({
  children,
  boundaryBreakpoint = 'sm',
  aboveBreakpointRenderer
}: {
  children: SplitButtonChildren,
  boundaryBreakpoint: 'sm' | 'md' | 'lg' | 'xl',
  aboveBreakpointRenderer: (children: React.ReactNode) => JSX.Element
}) {
  const atBoundary = useMediaQuery({ minWidth: BP_MINIMA[boundaryBreakpoint] });
  return atBoundary
    ? aboveBreakpointRenderer(children)
    : <SplitIfManyButton>{children}</SplitIfManyButton>;
}
