import { FallbackProps } from '@property-folders/components/telemetry/ErrorBoundary';
import { WholePageApology } from './major-crash';
import { Link } from 'react-router-dom';

export function AddPropertyApology(props: FallbackProps) {
  return <WholePageApology {...props}>
    <div>
      <h4>Error loading new property.</h4>
      <div className='text-center mt-4'>
        <div><a href='#' onClick={props.resetErrorBoundary}>Retry</a></div>
        <div><Link to={'/properties'}>Back to Properties</Link></div>
      </div>

    </div>
  </WholePageApology>;
}
