import { Button, Form, Modal } from 'react-bootstrap';
import React, { useContext, useState } from 'react';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { FormCode, TransactionMetaData } from '@property-folders/contract';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { PropertyFormYjsDal, setAutoServeRecipeints } from '@property-folders/common/yjs-schema/property/form';
import { applyMigrationsV2_1 } from '@property-folders/common/yjs-schema';
import { FormInstance, FormSigningState, MaterialisedPropertyData, PropertyRootKey, SignedStates } from '@property-folders/contract/yjs-schema/property';
import { useEntity } from '@property-folders/components/hooks/useEntity';
import { cloneDeep, keyBy, mapValues } from 'lodash';
import { SubscriptionFormCode } from '@property-folders/common/subscription-forms';
import { SubscriptionFormApi } from '@property-folders/common/client-api/subscription-form';
import { navigateToFormFactory } from '@property-folders/components/react-util/nagivate-factories';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import { createNewFormInstanceMigration } from '@property-folders/common/util/handleNewForm';

export function Form1CloneButtonAndModal({ onClose, onPostOk, formId, show }: { show: boolean, onClose: () => void, onPostOk?: () => void, formId: string | null | undefined }) {
  const navigate = useNavigate();
  const { ydoc, transactionMetaRootKey } = useContext(YjsDocContext);
  const { bindState: metaBindState } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const { data: meta } = metaBindState<TransactionMetaData>(m => m);
  const { value: transRoot } = useLightweightTransaction<MaterialisedPropertyData>({});
  const recipients = PropertyFormYjsDal.getFormFamilyServeRecipients(FormCode.Form1, meta);
  const instance = formId && meta ? PropertyFormYjsDal.getFormInstanceFromState(FormCode.Form1, formId, meta) : null;
  const localEntity = useEntity(meta?.entity?.id);
  const [reServe, setReserve] = useState<{[key:string]: boolean}>(localEntity?.signingOptions?.autoServeForm1 ? mapValues(keyBy(recipients, r=>r.id), ()=>true) : {});

  // ID is on the main root key
  const propertyIdRaw = transRoot?.id;
  const propertyId = typeof propertyIdRaw === 'string' ? propertyIdRaw : null;
  const headline = generateHeadlineFromMaterialisedData(transRoot);

  const navigateToForm = propertyId ? navigateToFormFactory(navigate, propertyId, { headline: headline }) : null;

  const handleClone = () => {
    if (!(propertyId && instance?.id && ydoc && navigateToForm)) return;
    if (!(instance.formCode === SubscriptionFormCode.SAF001V2_Form1 && instance.subscription && !instance.order)) return;
    if (!(SignedStates.has(instance.signing?.state??FormSigningState.None))) return;
    const formCode = SubscriptionFormCode.SAF001V2_Form1;
    return SubscriptionFormApi.cloneSelfPreparedForm1(propertyId, instance.id).then(async (response) => {
      const responseJson = (await response.json() as Partial<FormInstance>);
      const subscription = responseJson.subscription;
      const newFormId = v4();
      const maybeUpdate = applyMigrationsV2_1<TransactionMetaData>({
        doc: ydoc,
        docKey: PropertyRootKey.Meta,
        typeName: 'Property',
        migrations: [
          createNewFormInstanceMigration(formCode, newFormId, subscription?.documentId, {
            annexures: cloneDeep(instance.annexures)
          }, subscription?.formId),
          {
            name: 'set auto re-serve for Form 1',
            fn: draft => {
              const toAutoServe = recipients?.filter(r => !!reServe?.[r.id])?.map(r => r.id)||[];
              setAutoServeRecipeints(draft, FormCode.Form1, toAutoServe);
            }
          }
        ]
      });
      onPostOk?.();
      if (maybeUpdate) {
        navigateToForm(newFormId, formCode);
      }
    });
  };

  return <Modal show={show} size='lg' onHide={onClose}>
    <Modal.Header closeButton>
      <div className='d-flex flex-column'>
        <Modal.Title>Clone Signed Form 1</Modal.Title>
      </div>
    </Modal.Header>
    <Modal.Body>
      <div className={'px-3'}>
        <p>This will archive the existing signed Form 1 and create a new Form 1 with the existing details.</p>
        {!!recipients?.length &&
          <div>
            <div className='mb-1'>Re-serve the new document to the following recipients automatically when completed:</div>
            {recipients.map(r => <Form.Check
              id={`re-serve-${r.id}`}
              key={`re-serve-${r.id}`}
              label={r.name}
              className={'ms-3 mb-1'}
              checked={reServe?.[r.id]}
              onChange={()=>setReserve(e=> ({ ...e, [r.id]: !e?.[r.id] }))}
            >
            </Form.Check>)}
          </div>
        }
      </div>

    </Modal.Body>
    <Modal.Footer>
      <Button variant='outline-secondary' onClick={onClose}>Cancel</Button>
      <AsyncButton onClick={handleClone}>Clone</AsyncButton>
    </Modal.Footer>
  </Modal>;

}
