import { formatTimestamp, localDate } from '@property-folders/common/util/formatting';
import { CardSectionRows } from './CardSectionRows';
import { orderBy } from 'lodash';
import clsJn from '@property-folders/common/util/classNameJoin';
import { clickNoBubble } from '@property-folders/common/util/clickNoBubble';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import React from 'react';
import { FormFamilyState, FormInstance, OfferMeta } from '@property-folders/contract';
import { useNavigate } from 'react-router-dom';

export function FormInstanceInfoSnippets({
  propertyId,
  familyState,
  instance,
  offerManagement
}: {
  propertyId: string,
  familyState: FormFamilyState | undefined,
  instance: FormInstance | undefined,
  offerManagement: OfferMeta | undefined
}) {
  const servedRecipients = familyState?.recipients?.filter(r => r.servedByEmail || r.manuallyServed);
  const navigate = useNavigate();
  return <>
    {instance?.order?.job?.requestedAtMs && <div className='d-flex'>
      <div className={'d-flex align-items-center'}>Requested</div>
      <div className='ms-auto'>{localDate(instance.order.job.requestedAtMs) ?? '-'}</div>
    </div>}
    {instance?.order?.job?.preparedAtMs && !instance?.signing?.session?.completedTime && <div className='d-flex'>
      <div className={'d-flex align-items-center'}>Prepared</div>
      <div className='ms-auto'>{localDate(instance.order.job.preparedAtMs) ?? '-'}</div>
    </div>}
    {instance?.signing?.session?.completedTime && <div className='d-flex'>
      <div className={'d-flex align-items-center'}>Signed</div>
      <div className='ms-auto'>{localDate(instance.signing.session.completedTime) ?? '-'}</div>
    </div>}
    {!!servedRecipients?.length && <CardSectionRows
      className={'mt-3'}
      title={'Served'}
      rows={orderBy(servedRecipients?.map(r => instance?.archived ? r.servedInstances?.[instance.id] : r)?.filter(Boolean), r => r.timestamp, 'desc')?.map((r, idx) => {
        const purchaserId = offerManagement?.prospectivePurchasers?.find(p => p.purchaserParties?.find(pp => pp.id === p.primaryPurchaser)?.email === r.email)?.id;
        return [
          <div
            key={`${idx}:${purchaserId}`}
            className={clsJn(purchaserId && 'text-decoration-underline')}
            onClick={clickNoBubble(() => purchaserId && navigate(LinkBuilder.offerPurchaserPath({ id: propertyId }, { id: purchaserId })))}>{r.name}
          </div>,
          formatTimestamp(r?.servedByEmail?.timestamp || r?.manuallyServed?.timestamp, '', false)
        ];
      })}
      maxRows={instance?.order?.job ? 5 : 11}
    />}
  </>;
}
