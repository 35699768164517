import { Button, Card, Dropdown, ListGroup, ToggleButton } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { FormCode, SigningPartyType, TransactionMetaData } from '@property-folders/contract/property';
import { buildYDoc } from '../../form-gen-util/buildYDoc';
import {
  determineFormState,
  FormState,
  generateHeadlineFromMaterialisedData
} from '@property-folders/common/yjs-schema/property';
import { merge } from 'lodash';
import { getVendorOrPrimarySubVendor, YManager } from '@property-folders/common/offline/yManager';
import React, { memo, useContext } from 'react';
import { PropertiesSearchResultItem } from '@property-folders/contract/rest/property';
import { FormUtil } from '@property-folders/common/util/form';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { useUserPreferences } from '@property-folders/components/hooks/useUserPreferences';
import { UserUtil } from '@property-folders/common/util/user';
import { IOfflineProperty } from '@property-folders/common/offline/offlineProperties';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { bind, UpdateFn } from 'immer-yjs';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { FormCodeUnion, PropertyRootKey, META_APPEND } from '@property-folders/contract/yjs-schema/property';
import { Doc } from 'yjs';
import { Predicate } from '@property-folders/common/predicate';
import { YManagerContext } from '@property-folders/components/context/YManagerContext';
import { SubscriptionFormCode } from '@property-folders/common/subscription-forms';
import { isSubscriptionForm } from '@property-folders/common/subscription-forms/isSubscriptionForm';
import { FormListBadge } from '@property-folders/components/display/properties/FormListBadge';
import { FileSync } from '@property-folders/common/offline/fileSync';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { formDependenciesMet } from '@property-folders/common/util/form/FormDependenciesMet';
import { handleNewForm } from '@property-folders/common/util/handleNewForm';
import { buildContractDocumentAndForm } from '../../form-gen-util/buildSubDocument';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { PropertyCardFallback } from '../../display/errors/card';
import clsJn from '@property-folders/common/util/classNameJoin';
import { useEntities } from '../../hooks/useEntity';

class YDocMetaLoadTimeoutError extends Error {
  constructor(msg?: string) {
    super(msg);
    this.name = 'YDocMetaLoadTimeoutError';
    Object.setPrototypeOf(this, YDocMetaLoadTimeoutError.prototype);
  }
}

export interface IPropertyCardDataProps {
  id: string;
  headline: string;
  address: string;
  vendors: string[];
  agents: string[];
  meta?: TransactionMetaData;
  starred: boolean;
  lastAccessed?: number;
  created?: number;
  score?: number;
  sublineageMeta?: {[sublineageId: string]: TransactionMetaData}
}

export type OnPropertyArchiveSetHandler = (id: string, newArchiveState: boolean | null) => void;

export interface IPropertyCardProps extends IPropertyCardDataProps {
  onPropertyArchiveSet: OnPropertyArchiveSetHandler;
  onForceFocus: (id: string, forceMe: boolean) => void;
  forceFocus?: boolean
}

export class PropertyCardProps {
  static fromPropertiesSearchResultItem(p: PropertiesSearchResultItem, starredProperties: Set<string>): IPropertyCardDataProps {
    return {
      id: p.id,
      headline: generateHeadlineFromMaterialisedData(p.data.data) || '',
      address: p.primarySaleAddress,
      vendors: (p.vendors || []).map(getVendorOrPrimarySubVendor).map(v => v.fullLegalName),
      agents: (p.agents || []).map(a => a.name),
      meta: p.data.meta,
      starred: starredProperties.has(p.id),
      lastAccessed: p.lastAccessed,
      created: p.created,
      sublineageMeta: Object.assign({}, ...Object.entries(p.data?.alternativeRoots??{}).map(([k,v])=>({ [k]: v.meta })))
    };
  }

  static fromOfflineData(p: IOfflineProperty, starredProperties: Set<string>): IPropertyCardDataProps {
    return {
      id: p.id,
      headline: p.headline,
      address: p.address,
      vendors: p.vendors || [],
      agents: p.agents || [],
      meta: p.meta,
      starred: starredProperties.has(p.id),
      lastAccessed: p.lastAccessed,
      created: (new Date(p.meta?.createdUtc || 0)).getTime(),
      sublineageMeta: p.sublineageMeta
    };
  }

  static mergeOfflineOnlinePair(offlineData?: IPropertyCardDataProps, onlineData?: IPropertyCardDataProps): IPropertyCardDataProps | undefined {
    if (!offlineData) return onlineData;

    return {
      ...offlineData,
      // there appears to be (have been) a bug where the created date was not defined
      // fall back to the mysql date
      created: offlineData.created && offlineData.created > 0 ? offlineData.created : onlineData?.created,
      score: onlineData?.score
    };
  }

  /**
   * Note: it's up to the caller to order the results
   * Annotate online properties with what's stored offline.
   */
  static fromMergedOfflineOnlinePairs(offlineProperties: IOfflineProperty[], onlineProperties: PropertiesSearchResultItem[], starredProperties: Set<string>): IPropertyCardDataProps[] {
    const offlineMap = new Map(offlineProperties.map(offline => [offline.id, this.fromOfflineData(offline, starredProperties)]));

    return onlineProperties.map(online => this.mergeOfflineOnlinePair(
      offlineMap.get(online.id),
      this.fromPropertiesSearchResultItem(online, starredProperties)
    ))
      .filter(Predicate.isNotNull);
  }
}

export class PropertyCardDimensions {
  /**
   * Width inclusive of margins
   */
  public readonly totalWidth: number;
  public readonly width: number;

  /**
   * Height inclusive of margins
   */
  public readonly totalHeight: number;
  public readonly height: number;

  /**
   * margin around a card
   */
  public readonly margin: number;
  public readonly marginPx: string;

  /**
   * space between two cards (double the margin)
   */
  public readonly gap: number;
  public readonly gapPx: string;

  constructor(
    opts: {
      width: number,
      // keep in sync with .property-card class aspect ratio
      aspectRatio?: number,
      margin?: number,
      baseWidthIncludesMargins?: boolean
    }
  ) {
    const {
      width,
      aspectRatio,
      margin,
      baseWidthIncludesMargins
    } = merge({
      aspectRatio: 0.707,
      margin: 14,
      baseWidthIncludesMargins: false
    }, opts);

    const doubleMargin = margin * 2;
    const baseWidth = baseWidthIncludesMargins
      ? width - doubleMargin
      : width;
    this.margin = margin;
    this.marginPx = `${this.margin}px`;
    this.gap = doubleMargin;
    this.gapPx = `${this.gap}px`;
    this.width = baseWidth;
    this.height = (baseWidth / aspectRatio);
    this.totalWidth = this.width + doubleMargin;
    this.totalHeight = this.height + doubleMargin;
  }
}

export const DefaultPropertyCardDimensions = new PropertyCardDimensions({
  width: 310
});

export function latestFormInstanceExact(formCode: FormCode, meta?: TransactionMetaData) {
  if (!meta?.formStates) {
    return undefined;
  }
  const family = FormTypes[formCode].formFamily;
  const state = meta?.formStates[family];
  if (!state) {
    return undefined;
  }

  if (!state.instances?.length) return undefined;
  const instances = state.instances.filter(inst=>inst.formCode===formCode);

  let latest = instances[0];
  for (const instance of instances) {
    if (!instance.created) continue;
    if (!latest.created || instance.created > latest.created) {
      latest = instance;
    }
  }

  return latest;
}

export function latestFormInstanceInFamily(family: FormCode, meta?: TransactionMetaData) {
  if (!meta?.formStates) {
    return undefined;
  }

  const state = meta?.formStates[family];
  if (!state) {
    return undefined;
  }

  if (!state.instances?.length) return undefined;
  if (state.instances.length === 1) return state.instances[0];

  let latest = state.instances[0];
  for (const instance of state.instances) {
    if (!instance.created) continue;
    if (!latest.created || instance.created > latest.created) {
      latest = instance;
    }
  }

  return latest;
}

function getRenderableFormState(
  formCode: string,
  navigateToDocument: (formId: string, nicetext: string, isSubscriptionForm?: boolean) => void,
  meta: TransactionMetaData | undefined,
  propertyId: string,
  yManager: YManager,
  latestOfFamily = true,
  fileSync: FileSync,
) {

  const instance = (latestOfFamily ? latestFormInstanceInFamily : latestFormInstanceExact)(formCode, meta);
  const type = FormTypes[instance?.formCode ?? formCode];
  const { isVariation, isTermination } = type;
  const instanceState = instance
    ? determineFormState(instance)
    : undefined;
  const formStates = meta?.formStates || {};
  const createAllowed = FormUtil.canAddForm(formStates ?? {}, formCode) && FormTypes[formCode]?.formFamily !== FormCode.Form1;
  const requirementsMet = formDependenciesMet(type, formStates);
  const canCreate = instance ? false : (createAllowed && requirementsMet);
  const exists = !!(instance?.id);
  const configuringOrder = instanceState === FormState.ORDER_ORDERING;
  return {
    formCode: instance?.formCode ?? formCode,
    label: isVariation
      ? 'Variation'
      : isTermination
        ? 'Termination'
        : type?.label ?? 'Unknown or Defunct',
    canCreate,
    exists,
    signed: instanceState === FormState.SIGNED,
    waitingSign: instanceState === FormState.AWAITING_SIGN,
    waitingConfigure: instanceState === FormState.CONFIGURING,
    declined: instanceState === FormState.DECLINED,
    cancelled: instanceState === FormState.ORDER_CANCELLED,
    terminated: !!(formStates[FormCode.RSC_ContractOfSale]?.terminationConfirmed),
    signingProgress: instance?.signing?.parties?.length||0 > 0 ? [
      instance?.signing?.parties?.filter(party=>party.signedTimestamp).length,
      instance?.signing?.parties?.length
    ] : undefined,
    onClick: exists
      ? async () => {
        if (!instance?.id) {
          return;
        }

        navigateToDocument(
          instance.id,
          type.label,
          (!!instance.subscription || isSubscriptionForm(instance.formCode ?? formCode))
        );
      }
      : canCreate
        ? async () => {
          const { ydoc, whenSynced } = buildYDoc(propertyId, false, yManager);
          await whenSynced;
          // This only works on the main document, override instances that also need to create sublineages
          const newForm = await handleNewForm(ydoc, formCode, fileSync);

          if (!newForm) {
            throw new Error('Failed to create new form');
          }

          navigateToDocument(newForm.formId, type.label, !!type.subscription);
        }
        : undefined,
    id: instance?.id,
    wetSignedPdfNotUploaded: instance?.signing?.parties?.some(p => p.type === SigningPartyType.SignWet && p?.signedTimestamp && !p.signedPdf),
    configuringOrder,
    ordered: instanceState === FormState.ORDER_ORDERING
  };
}

async function updatedMetaOnceLoaded(ydoc: Doc, updateFn: UpdateFn<TransactionMetaData>) {
  // Property Cards refer to the summary view on the main Property List, so there's no context for
  // forms or sublineages here
  const metaMap = ydoc.getMap(PropertyRootKey.Meta);
  if (!(metaMap.toJSON() as TransactionMetaData | Record<string, never>)?.entity) {
    // Archived properties may not be stored in the local provider repo, so in this case, we need
    // to wait for it to download from the remote server. This might also be true for non-archived
    // properties that have not been open recently.
    // Keep in mind that this properties list is a summary and may not be derived from local ydocs,
    // unlike a lot of other code which relates to a currently active ydoc being edited/viewed etc.

    let boundResolve: (val: unknown)=>void | undefined;
    let boundReject: ()=>void | undefined;
    const proceedPromise = new Promise((resolve, reject) => {
      boundReject = ()=>{reject(new YDocMetaLoadTimeoutError());};
      boundResolve = resolve;
    });
    // eslint-disable-next-line prefer-const
    let timeoutHandle: ReturnType<typeof setTimeout> | undefined;
    const observerFunc: Parameters<typeof metaMap.observeDeep>[0] = (yevent, transaction)=>{
      const propMeta = transaction.doc.getMap(PropertyRootKey.Meta).toJSON() as TransactionMetaData | Record<string, never>;
      if (propMeta.entity) {
        boundResolve?.(null);
        clearTimeout(timeoutHandle);
      }
    };
    metaMap.observeDeep(observerFunc);
    timeoutHandle = setTimeout(()=>{
      metaMap.unobserveDeep(observerFunc);
      console.error('Couldn\'t load ydoc to run update over');
      boundReject?.();
    }, 5000);
    await proceedPromise;
    metaMap.unobserveDeep(observerFunc);
  }

  const updateRootMetaState = bind<TransactionMetaData>(metaMap).update;
  updateRootMetaState(updateFn);
}

function unarchiveProperty(id: string, ifFailed?: ()=>void) {
  const { ydoc, localProvider } = buildYDoc(id);
  localProvider.whenSynced.then(async ()=>{
    try {
      await updatedMetaOnceLoaded(ydoc, draft=> {
        delete draft.archived;
      });
    } catch (e) {
      if (e instanceof YDocMetaLoadTimeoutError) {
        ifFailed?.();
        return;
      }
      throw e;
    }
  });

}

function archiveProperty(id: string, agentId: number, ifFailed?: ()=>void) {
  const { ydoc, localProvider } = buildYDoc(id);
  localProvider.whenSynced.then(async ()=>{
    try {
      await updatedMetaOnceLoaded(ydoc, draft=> {
        draft.archived = {
          archivedByAgentId: agentId,
          archivedDate: (new Date()).toISOString()
        };
      });
    } catch (e) {
      if (e instanceof YDocMetaLoadTimeoutError) {
        ifFailed?.();
        return;
      }
      throw e;
    }
  });
}

export function PropertyCard(props: IPropertyCardProps) {
  const { instance: yManager } = useContext(YManagerContext);
  const agentSession = AuthApi.useGetAgentSessionInfo();
  const currentAgentId = agentSession?.data?.agentId;
  const entities = useEntities() ?? {};
  const hasMultipleEntities = Object.keys(entities).length > 0;
  const vendors = (props.vendors || []).join(', ');
  const agents = (props.agents || []).join(', ');
  const navigate = useNavigate();
  const {
    update: updateUserPrefs
  } = useUserPreferences();
  const { instance: fileSync } = useContext(FileSyncContext);
  const headline = props.headline || props.address;
  const propertyPath = `/properties/${LinkBuilder.seoFriendlySlug(props.id, headline)}`;
  const navigateToDocument = (formId: string, nicetext: string, isSubscriptionForm?: boolean) => {
    navigate(LinkBuilder.documentPath(
      { id: props.id, nicetext: headline },
      { id: formId, nicetext },
      !!isSubscriptionForm));
  };

  if (!yManager) {
    return <></>;
  }

  const sublineageDataList = Object.values(props.sublineageMeta??{});
  const firstContractSublineage = sublineageDataList.length === 1
    ? sublineageDataList[0]
    : sublineageDataList.length === 0
      ? {}
      : null;

  const formCodeRemap = (meta: TransactionMetaData | undefined, opts?: {overrideMainCreate: ()=>Promise<void>}) => (formCode: FormCodeUnion) => {
    const { overrideMainCreate } = opts??{};
    const family = FormTypes[formCode].formFamily;
    const showLatestOfFamily = Boolean(FormTypes[family]?.useLatestOnPropertyCard);
    const instances = meta?.formStates?.[family]?.instances;
    const anyVariations = !!instances?.filter(inst=>FormTypes[inst?.formCode]?.isVariation).length;
    const baseMember = getRenderableFormState(
      showLatestOfFamily ? family : formCode,
      navigateToDocument,
      meta,
      props.id,
      yManager,
      showLatestOfFamily,
      fileSync
    );
    if (overrideMainCreate && baseMember.canCreate && !baseMember.exists) {
      baseMember.onClick = overrideMainCreate;
    }

    const result = [
      baseMember
    ];
    if (anyVariations) {
      result.push(getRenderableFormState(
        formCode,
        navigateToDocument,
        meta,
        props.id,
        yManager,
        true,
        fileSync
      ));
    }

    return result;
  };

  const forms = ([
    FormCode.RSAA_SalesAgencyAgreement,
    SubscriptionFormCode.SAF001V2_Form1
  ])
    .map(formCodeRemap(props.meta));

  const handleCreateContractSublineage = async () => {
    const sublineageList = props.meta?.sublineageRoots ?? [];
    const { ydoc, whenSynced } = buildYDoc(props.id);
    await whenSynced;
    if (sublineageList.length === 1) {
      const meta = ydoc.getMap(sublineageList[0]+META_APPEND).toJSON() as TransactionMetaData;
      const instance = latestFormInstanceInFamily(FormCode.RSC_ContractOfSale, meta);
      if (!instance) return;
      navigate(LinkBuilder.documentPath(
        { nicetext: headline, id: props.id },
        { nicetext: FormTypes[instance?.formCode].label, id: instance?.id },
        false
      ));

      return;
    }

    buildContractDocumentAndForm(ydoc, fileSync).then(res=>{
      const { formId, formCode } = res ?? {};
      if (!formId || !ydoc || !props.id) return;
      navigate(LinkBuilder.documentPath({ id: props.id, nicetext: headline }, { id: formId, nicetext: FormTypes[formCode].label }, false));
    });
  };

  if (firstContractSublineage) {

    forms.push(formCodeRemap(firstContractSublineage,{ overrideMainCreate: handleCreateContractSublineage })(FormCode.RSC_ContractOfSale));
  }

  const formOpenClickEventGen = (form: ReturnType<typeof getRenderableFormState>) => {
    return async (e) => {
      if (!form.onClick || (form.formCode === 'SAF001V2' && !form.exists)) {
        return;
      }
      e.stopPropagation();
      e.preventDefault();
      await form.onClick();
    };
  };

  let entityDisplayName = props.meta?.entity?.name ?? '';
  if (props.meta?.entity?.id && entities[props.meta.entity.id]) {
    const entity = entities[props.meta.entity.id];
    if (entity?.profileName || entity?.name) {
      entityDisplayName = entity.profileName || entity.name;
    }
  }

  return (
    <Card className={clsJn({
      'property-card': true,
      'hoverable': true,
      'hoverable-force': props.forceFocus === true,
      'hoverable-force-off': props.forceFocus === false
    })}>
      <ListGroup variant={'flush'}>
        <ListGroup.Item className={clsJn('card-header-item-group', { 'show-entity': false })}>
          <div className='d-flex title-row'>
            <div className='flex-grow-1 clickable' onClick={() => {
              navigate(propertyPath);
            }}>
              <h5 className='property-title' title={props.headline}>{props.headline}</h5>
            </div>
            <div className='flex-grow-0 icon-container'>
              {props.meta?.archived
                ? <Icon name='archive' icoClass='archive-indicator'></Icon>
                : <ToggleButton
                  type={'checkbox'}
                  value={'starred'}
                  variant={'outline-secondary'}
                  className={clsJn({
                    'p-0': true,
                    'border-0': true,
                    'star-button': true,
                    'starred': props.starred
                  })}
                  onClick={(e) => {
                    UserUtil.toggleFavouriteProperty(props.id, props.starred, updateUserPrefs);
                  }}>
                  <Icon name='star'></Icon>
                </ToggleButton>}
            </div>
          </div>
        </ListGroup.Item>
        <ListGroup.Item className="clickable card-header-parties-group" onClick={() => {
          navigate(propertyPath);
        }}>
          <div className='key-value-row'>
            {props.vendors.length > 1
              ? <span>Vendors:</span>
              : <span>Vendor:</span>}
            <span className='long-value' title={vendors}>{vendors}</span>
          </div>
          <div className='key-value-row'>
            {props.agents.length > 1
              ? <span>Salespersons:</span>
              : <span>Salesperson:</span>}
            <span className='long-value' title={agents}>{agents}</span>
          </div>
          {hasMultipleEntities && <div className='key-value-row'>
            <span></span>
            <span className='long-value text-secondary' title={entityDisplayName}>{entityDisplayName}</span>
          </div>}
        </ListGroup.Item>
      </ListGroup>
      <Card.Body
        className='clickable d-flex flex-column'
        onClick={() => {
          navigate(propertyPath);
        }}
      >
        <ListGroup variant={'flush'} className='no-divider-group'>
          {forms.map((formFam, idx) => {
            return (<ListGroup.Item key={idx} className={formFam.length === 1 ? 'key-value-row' : ''}
              onClick={formFam.length === 1 ? formOpenClickEventGen(formFam[0]) : undefined}>
              {formFam.map((form, index) => {
                const link = <span>
                  {index ? <span className='me-1'>└</span> : undefined}
                  <a className={clsJn({
                    'form-link': true,
                    'form-can-create': form.canCreate,
                    'form-exists': form.exists
                  })}>
                    {form.label}
                  </a>
                </span>;
                const badge = <FormListBadge form={form} />;

                return formFam.length === 1
                  ? <React.Fragment key={index}>
                    {link}
                    {badge}
                  </React.Fragment>
                  : <div key={index} className='key-value-row' onClick={formOpenClickEventGen(form)}>
                    {link}
                    {badge}
                  </div>;
              })}
            </ListGroup.Item>);
          })}
          <ListGroup.Item
            key={forms.length}
            className='key-value-row'
            onClick={e => {
              e.stopPropagation();
              const sublineageList = props.meta?.sublineageRoots ?? [];
              if (sublineageList.length > 1) {
                const navPath = LinkBuilder.contractManagementPath({ nicetext: headline, id: props.id });
                navigate(navPath);
                return;
              }

            }}
          >
            {(props.meta?.sublineageRoots?.length ?? 0) > 1 && <span><a className={clsJn({
              'form-link': true,
              'form-can-create': false,
              'form-exists': true
            })}>Contract Management</a>
            </span>/* Sublineages can contain letters of offer, which isn't strictly a contract. However, having letters of offer implies you'll be using the contract method flow. For now this is fine.*/}

          </ListGroup.Item>

        </ListGroup>
      </Card.Body>
      <Card.Footer className='d-flex w-100'>
        <div className='ms-auto d-flex'>
          <Button variant={'outline-secondary'} className={'position-relative rounded-0'} title='View/Edit this property'>
            <Link to={propertyPath} className={'nav-link'}>Open</Link>
          </Button>
          <Dropdown onToggle={(nextShow) => props.onForceFocus(props.id, nextShow)}>
            <Dropdown.Toggle
              variant={'outline-secondary'}
              className='ms-2 no-dropdown-button'
            >...</Dropdown.Toggle>
            <Dropdown.Menu>
              {props.meta?.archived
                ? <Dropdown.Item
                  disabled={currentAgentId==null}
                  onClick={()=>{
                    if (currentAgentId!=null) unarchiveProperty(props.id, ()=>props.onPropertyArchiveSet(props.id, null));
                    props.onPropertyArchiveSet(props.id, false);
                  }}
                >Unarchive</Dropdown.Item>
                : <Dropdown.Item
                  disabled={currentAgentId==null}
                  onClick={()=>{
                    if (currentAgentId!=null) archiveProperty(props.id, currentAgentId, ()=>props.onPropertyArchiveSet(props.id, null));
                    props.onPropertyArchiveSet(props.id, true);
                  }}
                >Archive</Dropdown.Item>
              }
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Card.Footer>
    </Card>
  );
}

function BoundaryPropertyCard (props: IPropertyCardProps) {
  return <ErrorBoundary FallbackComponent={PropertyCardFallback}>
    <PropertyCard {...props} />
  </ErrorBoundary>;
}

export const MemoPropertyCard = memo(BoundaryPropertyCard);
