import { useContext, useState } from 'react';
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap';
import { PropertyFormYjsDal } from '@property-folders/common/yjs-schema/property/form';
import { SigningPartyDeclineType } from '@property-folders/contract';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '../../display/errors/modals';

const maxLength = 500;

interface DeclineToSignModalProps {
  initialReason?: string;
  onClose: () => void;
  onSubmit: () => void;
  formPath: string,
  partyId: string,
  formCode: string,
  formId: string,
  signingSessionId: string,
  declineType: SigningPartyDeclineType
}

export function DeclineToSignModal(props: DeclineToSignModalProps) {
  return <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} show={true} onClose={props.onClose} />}>
    <DeclineToSignModalUnwrapped {...props} />
  </ErrorBoundary>;
}

function DeclineToSignModalUnwrapped(props: DeclineToSignModalProps) {
  const { ydoc, transactionMetaRootKey, transactionRootKey } = useContext(YjsDocContext);
  const [reason, setReason] = useState(props.initialReason);
  const remainingChars = maxLength - (reason?.length || 0);
  const reasonValid = reason && reason.length <= maxLength;
  const formDal = ydoc ? new PropertyFormYjsDal(ydoc, transactionRootKey, transactionMetaRootKey) : undefined;

  const send = () => {
    if (!(reason && formDal)) {
      return;
    }

    formDal.setPartyDeclined(
      props.formCode,
      props.formId,
      props.signingSessionId,
      props.partyId,
      {
        type: props.declineType,
        reason
      });
    props.onSubmit();
  };

  return <Modal show={true} onClose={props.onClose}>
    <Modal.Header>Decline to sign</Modal.Header>
    <Modal.Body>
      <p>Please specify a reason</p>
      <FloatingLabel label={'Reason'}>
        <Form.Control
          as={'textarea'}
          rows={6}
          value={reason}
          onChange={e => setReason(e.target.value.slice(0, maxLength))}
          isInvalid={!reasonValid}
        />
        <p>Remaining characters: {remainingChars}</p>
      </FloatingLabel>
    </Modal.Body>

    <Modal.Footer>
      <div className={'d-flex flex-column w-100'}>
        <div className={'d-flex flex-row justify-content-end align-items-center gap-3'}>
          <Button variant={'outline-secondary'} onClick={props.onClose}>Cancel</Button>
          <Button variant={'secondary'} disabled={!reasonValid} onClick={send}>Decline</Button>
        </div>
      </div>
    </Modal.Footer>
  </Modal>;
}
