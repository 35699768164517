import { ButtonProps, Dropdown, Spinner } from 'react-bootstrap';
import { DropdownItemProps } from 'react-bootstrap/DropdownItem';
import React, { useCallback, useState } from 'react';

export function AsyncDropdownItem(props: ButtonProps & DropdownItemProps) {
  const [processing, setProcessing] = useState(false);
  const onClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    if (!props.onClick) return;

    setProcessing(true);
    Promise.resolve(props.onClick(e))
      .finally(() => setProcessing(false));
  }, [props.onClick]);

  return <Dropdown.Item {...props} onClick={onClick} disabled={props.disabled || processing}>
    {processing && <Spinner animation="border" size="sm"/>}
    {props.children}
  </Dropdown.Item>;
}
