import { useEffect, useState } from 'react';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import detectIncognito from 'detectincognitojs';

// keep the reference outside the function, we only want a single instance of the client running at any given point
let _rum: AwsRum | null = null;

export function useRum(): AwsRum | null {
  const [awsRum, setAwsRum] = useState<AwsRum | null>(_rum);

  useEffect(() => {
    if (awsRum) {
      return;
    }

    if (_rum) {
      setAwsRum(_rum);
      return;
    }

    try {
      const identityPoolArn: string = (import.meta as any).env.VITE_RUM_POOL_ARN;
      if (!identityPoolArn) return;
      const identityPoolId = identityPoolArn.startsWith('arn:aws:cognito-identity:')
        ? identityPoolArn.substring(identityPoolArn.indexOf('/') + 1)
        : identityPoolArn;

      const config: AwsRumConfig = {
        sessionSampleRate: (import.meta as any).env.VITE_RUM_SAMPLE_RATE,
        guestRoleArn: (import.meta as any).env.VITE_RUM_ROLE_ARN,
        identityPoolId,
        endpoint: `https://${(import.meta as any).env.VITE_FRIENDLY_DOMAIN}`,
        telemetries: ['errors', 'performance', 'http'],
        allowCookies: true,
        enableXRay: true,
        enableRumClient: false
      };

      const APPLICATION_ID: string = (import.meta as any).env.VITE_RUM_MONITOR_ID;
      const APPLICATION_VERSION: string = '1.0.0';
      const APPLICATION_REGION: string = 'ap-southeast-2';

      if (!APPLICATION_ID) {
        console.warn('No application ID', APPLICATION_ID);
        return;
      }

      _rum = new AwsRum(
        APPLICATION_ID,
        APPLICATION_VERSION,
        APPLICATION_REGION,
        config
      );

      setAwsRum(_rum);

      (window as any)._awsRum = _rum;

      detectIncognito().then(val => {
        _rum?.addSessionAttributes({
          incognitoSession: val.isPrivate
        });
      });
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
      console.error('Error initialising Rum', error);
      console.error(error);
    }
  }, []);

  return awsRum;
}
