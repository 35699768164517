import { useContext, useState } from 'react';
import { useTransactionField } from '../../hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { agencyExclusiveOptions, commencementAgreementDateBoolOpts } from '@property-folders/common/data-and-text/constants';
import '../Form.scss';
import { CollectionEditor } from './CollectionEditor';
import { WrField } from './CommonComponentWrappers';
import { NarrowAgentInput } from './NarrowAgentInput';
import { FormContext } from '../../context/FormContext';
import { ExtraFormCode } from '@property-folders/contract';
import { ShowGuidanceNotesButton } from '../guidance/ShowGuidanceNotesButton';
import clsJn from '@property-folders/common/util/classNameJoin';
import { LineageContext } from '../../hooks/useVariation';
import { buildSigningTimelines } from '@property-folders/common/util/dataExtract';
import { isoLocalDateFormatter } from '@property-folders/common/util/formatting';

export const AgencyTermInput = ({  ...restProps }: TransactionConsumerProps): JSX.Element => {
  const { value: transRoot } = useTransactionField({ parentPath: restProps.parentPath, myPath: '' });
  const { formName } = useContext(FormContext);
  const { snapshotHistory } = useContext(LineageContext);
  const subsequentMode = formName === ExtraFormCode.CRSSA_SalesAgencyAgreementSubsequent && !!snapshotHistory;
  const [ showAdvice, setShowAdvice ] = useState<boolean|string>(false);

  const latestExpiry = subsequentMode && buildSigningTimelines(snapshotHistory).latestExpiry;
  let latestExpiryString = '';
  if (latestExpiry) {
    latestExpiry.setHours(0,0,0,0);
    latestExpiryString = isoLocalDateFormatter(latestExpiry);
  }

  return (
    <div className="d-flex w-100 gapped-row">
      <div className='w-100'>

        <div className="d-flex w-100 flex-wrap mt-2">
          <div style={{ minWidth: '250px' }}>
            <WrField.Control
              setDefaultValue='90 days'
              name='agencyDuration'
              myPath='agency.duration'
              label="Agreement duration"
              placeholder='## days'
              guidanceNoteId='agencyDuration'
            />
          </div>

        </div>
        <div className={clsJn(!subsequentMode && 'd-flex flex-wrap w-100')}>
          {subsequentMode && <div className='lead mt-3'>Start of Agreement<ShowGuidanceNotesButton noteId='startOfAgreement'/><sup className='fs-6' style={{ color: 'red' }}>*</sup></div>}
          {!subsequentMode && <div className='mt-2'>
            <WrField.CheckRadio
              label='Start of Agreement'
              radioType='checkbox'
              name='agencyStart'
              myPath='agency.start'
              options={commencementAgreementDateBoolOpts}
              valueType='boolean'
              titleGuidanceNoteKey='startOfAgreement'
            />
          </div>}
          {transRoot?.agency?.start === false && <div style={{ minWidth: '150px', maxWidth: '250px' }}  className='mt-2'>
            <WrField.Control
              type='date'
              name='agencyStartOther'
              myPath='agency.startOther'
              label="Start Date"
              dateFromOther={latestExpiryString}
              useCanonical={true}
            />
          </div>}
        </div>

        <div className='mt-3'>
          <WrField.CheckRadio
            label='This Agreement is a:'
            radioType='checkbox'
            options={agencyExclusiveOptions}
            guidanceNotesMap={{ 'true': 'soleAgency', 'false': 'generalAgency' }}
            valueType='boolean'
            inline={false}
            name='agencyExclusive'
            myPath='agencyExclusive'
          />
        </div>
        {typeof transRoot?.agencyExclusive === 'boolean' && !transRoot?.agencyExclusive && <div className='mt-2'>
          <CollectionEditor
            title='Other general agencies if known'
            level={3}
            myPath="otherAgents"
            autoAddFirst={false}
            allowAdd={true}
            compact={false}
            childProps={{ noPersonAuto: true }}
            childItemRenderer={NarrowAgentInput}
            itemNoun='Agent'
            restorationFieldDisplay='company'
            addTooltip='Add another known general agent'
          />
        </div>}
      </div>
    </div>
  );
};
