import { ChangeEvent, useCallback, useRef, useState } from 'react';
import { PropertySearchApi } from '@property-folders/common/client-api/propertySearchApi';
import { ISuburbSearchSuggestion, ISuburbSearchSuggestions, IPropertySearchSuggestions } from '@property-folders/contract/rest/address-search';
import { WrField } from './CommonComponentWrappers';

interface Props {
  myPath: string;
  parentPath: string;
  onChange?: (e: ChangeEvent<any>) => void;
}

export function Hundred({ myPath, parentPath, onChange }: Props) {
  const [isSearching, setIsSearching] = useState(false);
  const [suggestions, setSuggestions] = useState([] as string[]);
  const activeSearch = useRef<{abort: ()=>void, response: Promise<IPropertySearchSuggestions | ISuburbSearchSuggestions | undefined>}>();

  const search = useCallback((query: any) => {
    activeSearch.current?.abort();
    if (!query || query.trim() === '') {
      setSuggestions([]);
      return;
    }

    activeSearch.current = PropertySearchApi.getHundredSuggestions(query, true);
    activeSearch.current.response.then((suggestions: any) => {
      setSuggestions(suggestions?.Predictions.slice(0, 10).map((s: ISuburbSearchSuggestion) => ({
        label: `${s.Name.toLowerCase().replace(/\b\w/g, s => s.toUpperCase())}`,
        ...s
      })) ?? []);
    }).catch(e => {
      console.warn('Error getting hundred suggestions', e);
    }).finally(()=>{
      setIsSearching(false);
    });
  }, []);

  return <WrField.AsyncAutoComplete
    label='Hundred'
    name='Hundred'
    parentPath={parentPath}
    myPath={myPath}
    options={suggestions || []}
    isLoading={isSearching}
    onSearch={search}
    onChange={e => { onChange?.(e); }}
  />;
}
