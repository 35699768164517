import { Card, Col, Row } from 'react-bootstrap';
import { HumanTimestampText } from '../HumanTimestamp';
import clsJn from '@property-folders/common/util/classNameJoin';

interface Props {
  name: string;
  position: string;
  status: 'Pending';
  reviewTime: Date;
  selected: boolean;
  className?: string;
  onClick?: () => void;
}

export function ExternalReviewParty({
  name,
  position,
  status,
  reviewTime,
  selected,
  className,
  onClick
}: Props) {
  return <Card
    style={{
      background: selected ? '#edf5ff': '#ffffff',
      cursor: 'pointer',
      border: 0
    }}
    className={clsJn(className, 'p-3')}
    onClick={onClick}
  >
    <Row>
      <Col>
        <h4>{name}</h4>
      </Col>
      <Col className='text-end'>
        <span className='badge' style={{ backgroundColor: '#e8ecef', color: 'black' }}>{status}</span>
      </Col>
    </Row>
    <Row>
      <Col>
        <p className='m-0 p-0'>{position}</p>
      </Col>
      <Col className='text-end'>
        <HumanTimestampText timestampMs={reviewTime.getTime()} />
      </Col>
    </Row>
  </Card>;
}
