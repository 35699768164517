import { Button, Modal } from 'react-bootstrap';

export function WarnBeforeUpdateDialog({ show, onHide, onProceedAnyway, title, body }: {
  title: string;
  body: JSX.Element | string
  show: boolean;
  onHide: () => void;
  onProceedAnyway?: ()=>void
}) {

  return <Modal show={show} onHide={onHide} centered>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {body}
    </Modal.Body>
    <Modal.Footer>
      <Button variant={'secondary'} onClick={onHide}>Cancel</Button>
      <Button variant={'primary'} onClick={()=>{
        onProceedAnyway?.();
        onHide();
      }}>Continue</Button>
    </Modal.Footer>
  </Modal>;
}
