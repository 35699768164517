import { Accordion, Alert, Button, DropdownButton, FloatingLabel, Form, Modal } from 'react-bootstrap';
import {
  EditPartyState,
  editPartyStateReducer,
  getInitialEditPartyState
} from '@property-folders/components/dragged-components/signing/editSigningSessionParty/EditPartyState';
import {
  AgencySalesperson,
  SignerProxyType,
  SigningInitiator,
  SigningParty,
  SigningPartySourceType,
  SigningPartyType
} from '@property-folders/contract';
import { useImmerReducer } from 'use-immer';
import { buildFilteredSigningPartyTypeOptions } from '@property-folders/components/dragged-components/signing/Common';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { buildCustomiseDropdownItems, CustomiseItemWrapper } from '@property-folders/components/dragged-components/signing/PartyConfigurationCard';
import { useOnline } from '@property-folders/components/hooks/useOnline';
import { useCallback, useState } from 'react';
import { CustomiseVerificationStandalone } from '@property-folders/components/dragged-components/signing/CustomiseVerification';
import { CustomiseMessageStandalone } from '@property-folders/components/dragged-components/signing/CustomiseMessage';
import { EntitySettingsSigningOptions } from '@property-folders/contract/yjs-schema/entity-settings';
import { useCurrentFormSnapshot } from '../../../hooks/useVariation';
import clsJn from '@property-folders/common/util/classNameJoin';
import { Predicate } from '@property-folders/common/predicate';
import { canonicalisers } from '@property-folders/common/util/formatting';

export function EditSigningSessionPartyModal({
  formCode,
  formId,
  signingSessionId,
  partyId,
  paths,
  party,
  initiator,
  salespersons,
  onCancel,
  onSave,
  signingOptions,
  forceShowEmailField,
  disallowPaper
}: {
  formCode: string,
  formId: string,
  signingSessionId: string,
  partyId: string,
  paths: { party: string, fields: string },
  party: SigningParty,
  initiator?: SigningInitiator
  salespersons?: AgencySalesperson[],
  signingOptions: EntitySettingsSigningOptions
  forceShowEmailField?: boolean
  onCancel: () => void,
  onSave: (opts: { state: Pick<EditPartyState, 'party'>, emailChanged: boolean, phoneChanged: boolean, voidSigning?: boolean }) => void,
  disallowPaper?: boolean
}) {
  const [state, dispatch] = useImmerReducer(editPartyStateReducer, getInitialEditPartyState(party, formCode));
  const online = useOnline();
  const [ accordionActiveKey, setAccordionActiveKey ] = useState<string | string[] | null | undefined>(undefined);

  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();

  const { propertyData } = useCurrentFormSnapshot();

  const filteredOptions = buildFilteredSigningPartyTypeOptions({
    sessionInfo,
    signingParty: party,
    partyData: party?.snapshot?.linkedSalespersonId
      // we can just use the party snapshot data here, it should have been sourced from the main data at some point anyway
      ? { linkedSalespersonId: party.snapshot.linkedSalespersonId, name: party.snapshot.name }
      : undefined,
    salespersons,
    initiator,
    partyType: party.source.type,
    formCode,
    dataModel: propertyData ?? undefined,
    showOnlyType: state.party.proxyAuthority ?? SignerProxyType.Self,
    signingOptions,
    disallowPaper
  });

  const { items: dropdownItems, allow } = buildCustomiseDropdownItems({
    isOnline: online,
    signingParty: { type: state.party.type, source: state.party.source },
    verificationConfig: state.party.verification,
    messageConfig: state.party.message,
    updateVerificationConfig: config => {
      setAccordionActiveKey('3');
      dispatch({ type: 'set-verification', value: config });
    },
    updateMessageConfig: config => {
      setAccordionActiveKey('4');
      dispatch({ type: 'set-message', value: config });
    }
  });

  const showVoidButton = state.form.showPhoneWarning || state.form.showEmailWarning;
  const saveCallback = useCallback((voidSigning: boolean) => {
    onSave({
      state,
      emailChanged: state.form.showEmailWarning,
      phoneChanged: state.form.showPhoneWarning,
      voidSigning
    });
  }, [state]);

  const isSalesperson = party.source.type === SigningPartySourceType.Salesperson;
  const signingTypeNonComposite = parseInt(state.party.typeHostComposite?.split('_')?.[0]??'-1');
  const allowVerificationDelete = !(
    (signingOptions?.requireIdHosted && signingTypeNonComposite === SigningPartyType.SignInPerson)
    || (signingOptions?.requireIdRemote && signingTypeNonComposite === SigningPartyType.SignOnline)
  );
  const compositeSplit = state.party.typeHostComposite?.split('_');
  const typeHostSplit = compositeSplit?.[0];

  // Normalising value will ensure it most likely matches an option
  const maskedValue = `${compositeSplit?.[0]}_${compositeSplit?.[1]??''}`;

  const proxyMode = Predicate.proxyNotSelf(state.party.proxyAuthority);
  const currentPhone = (proxyMode ? state.party.proxyPhone : state.party.snapshot.phone) || '';
  return <Modal show={true} size={'lg'}>
    <Modal.Header>
      <h3>Edit {party.snapshot?.name || 'Party'}</h3>
    </Modal.Header>
    <Modal.Body className={'d-flex flex-column gap-3'}>
      <Form.Group>
        <FloatingLabel label={'Signing Method'}>
          <Form.Select
            value={maskedValue}
            onChange={e => dispatch({ type: 'set-signing-method', value: e.target.value })}
          >
            {filteredOptions.map(({ name, label, groupheading }) => (<option key={name} value={name} disabled={groupheading} className={clsJn(groupheading && 'fw-bold text-dark')}>
              {label}
            </option>))}
          </Form.Select>
        </FloatingLabel>
        {state.form.invalidSigningType && <Alert variant={'secondary'} className={'mt-3'}>Counterpart signing is not permitted for this document. All parties must be configured to sign either digitally (on screen, or via link), or on paper. To update this you will need to void signing for all parties.</Alert>}
      </Form.Group>
      <Form.Group>
        {(typeHostSplit === SigningPartyType.SignOnline.toString() || forceShowEmailField) &&
          <FloatingLabel label={'Email'}>
            <Form.Control
              value={(proxyMode ? state.party.proxyEmail : state.party.snapshot.email) || ''}
              onChange={e => dispatch({ type: 'set-snapshot-email', value: e.target.value })}
              isInvalid={state.validationParts.requiresEmail && !state.validationParts.email}
              disabled={isSalesperson}
            />
            {state.form.showEmailWarning && <Alert variant={'secondary'} className={'mt-3'}>The email address forms part of the content of the document being signed. To update the value in the document you will need to void signing for all parties.</Alert>}
          </FloatingLabel>}
        {typeHostSplit === SigningPartyType.SignOnlineSms.toString() &&
          <FloatingLabel label={'Phone'}>
            <Form.Control
              value={canonicalisers.phone(currentPhone).display}
              onChange={e => dispatch({ type: 'set-snapshot-phone', value: e.target.value })}
              isInvalid={state.validationParts.requiresPhone && !state.validationParts.phone}
              disabled={isSalesperson}
            />
            {state.form.showPhoneWarning && <Alert variant={'secondary'} className={'mt-3'}>The phone number forms part of the content of the document being signed. To update the value in the document you will need to void signing for all parties.</Alert>}
          </FloatingLabel>}
      </Form.Group>
      {!!dropdownItems.length &&
        <DropdownButton size={'lg'} variant={'link'} title={'Advanced Options'}>
          {dropdownItems}
        </DropdownButton>}
      <Accordion activeKey={accordionActiveKey} onSelect={key => setAccordionActiveKey(key)} alwaysOpen>

        {!!state.party.verification && allow.verification &&
          <CustomiseItemWrapper
            eventKey={'3'}
            title='Two-Factor Authentication'
            icon={'badge'}
            canDelete={allowVerificationDelete}
            onDeleteClick={() => dispatch({ type: 'set-verification', userInitiatedDelete: true })}>
            <CustomiseVerificationStandalone
              config={state.party.verification}
              phone={currentPhone}
              phoneValid={state.validationParts.phone}
              onConfigChange={value => dispatch({ type: 'set-verification', value })}
              onPhoneChange={value => dispatch({ type: 'set-snapshot-phone', value })}
              phoneDisabled={isSalesperson}
            />
            {state.form.showPhoneWarning && <Alert variant={'secondary'} className={'mt-3'}>The phone number forms part of the content of the document being signed. To update the value in the document you will need to void signing for all parties.</Alert>}
          </CustomiseItemWrapper>}
        {!!state.party.message && allow.message &&
          <CustomiseItemWrapper
            eventKey={'4'}
            title={'Personal Message'}
            description={'Shown in the email and personal signing page for this party'}
            icon={'chat_bubble'}
            canDelete={true}
            onDeleteClick={() => dispatch({ type: 'set-message' })}>
            <CustomiseMessageStandalone
              config={state.party.message}
              onConfigChange={value => dispatch({ type: 'set-message', value })} />
          </CustomiseItemWrapper>}
      </Accordion>
    </Modal.Body>
    <Modal.Footer>
      <Button variant={'outline-secondary'} onClick={onCancel}>Cancel</Button>
      {showVoidButton &&
        <Button disabled={!state.isValid} variant={'outline-danger'} onClick={() => saveCallback(true)}>Void</Button>}
      <Button disabled={!state.isValid} onClick={() => saveCallback(false)}>Apply</Button>
    </Modal.Footer>
  </Modal>;
}
