import { PendingRemoteSigningSessionPage, RemoteSigningSessionDataField } from '@property-folders/contract';
import { renderText } from '@property-folders/common/util/image';
import { formatTimestamp } from '@property-folders/common/util/formatting';
import { asPercentage } from './common';
import { Image } from 'react-bootstrap';
import React from 'react';

export function DataField({ page: { width: pageWidth, height: pageHeight }, field, timeZone }: { page: PendingRemoteSigningSessionPage, field: RemoteSigningSessionDataField, timeZone: string }) {
  const location = field.position;
  const asImage = field.type === 'ts-party' && field.value && field.value > 0
    ? renderText({
      text: formatTimestamp(field.value, timeZone, false),
      font: {
        name: 'Helvetica',
        size: 30
      },
      // this is just to adjust how freaking massive the text would appear otherwise
      padding: {
        left: 4,
        top: 40,
        right: 340,
        bottom: 10
      }
    })
    : undefined;

  return <div
    style={{
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'end',
      left: asPercentage(location.x, pageWidth),
      bottom: asPercentage(location.y, pageHeight),
      width: asPercentage(location.width, pageWidth),
      height: asPercentage(location.height, pageHeight)
    }}>
    <Image src={asImage} className={'w-100'}/>
  </div>;
}
