import Select from 'react-select';
import { FormCode } from '@property-folders/contract';
import clsJn from '@property-folders/common/util/classNameJoin';
import { Form } from 'react-bootstrap';
import { AjaxPhp, ListSubscriptionFormsResponse } from '@property-folders/common/util/ajaxPhp';
import { FormsApi } from '@property-folders/common/client-api/formsApi';
import { useEffect, useMemo, useState } from 'react';
import { ListFormsForAgent } from '@property-folders/contract/rest/forms';

interface CommonProps<IsMulti extends boolean> {
  subscriptionForms?: boolean;

  // default true
  propertyForms?: boolean;

  className?: string;

  variant?: 'light' | 'dark'

  bootstrapSelect?: boolean;

  includeAllFormsOption?: boolean;

  entityUuid?: string;

  label?: string | JSX.Element;

  filter?: ((selectOption: SelectOption) => boolean) | string[];

  withClauses?: boolean;

  allFormsLabel?: string;

  renderStructured?: boolean;
}

interface MultiProps extends CommonProps<true> {
  multiSelect: true;

  value?: string[];

  onChange: (newForms: string[]) => void;
}

interface SingleProps extends CommonProps<false> {
  multiSelect: false,

  value?: string;

  onChange: (newForms: string | undefined) => void;
}

type SelectOption = {
  value: string | 'AllForms';
  label: string;
  indent?: number;
  subLabel?: string;
};
type GroupedOption = { label: string; options: SelectOption[]; };

function Label({ label }: { label: string | JSX.Element }) {
  return typeof label === 'string'
    ? <label>{label}</label>
    : label;
}

function SelectForm<IsMulti extends boolean>(props: MultiProps | SingleProps) {
  const {
    subscriptionForms = false,
    propertyForms = true,
    multiSelect,
    className,
    onChange,
    value,
    variant = 'light',
    bootstrapSelect = false,
    includeAllFormsOption = false,
    entityUuid,
    label,
    renderStructured
  } = props;

  const [val, setVal] = useState<SelectOption | SelectOption[] | undefined>();
  const [options, setOptions] = useState<GroupedOption[] | SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getValue = (options: GroupedOption[] | SelectOption[]): SelectOption | SelectOption[] | undefined => {
    const optionsFlat = options.map(o => 'options' in o ? o.options : o).flat();
    if (typeof value === 'string') {
      return optionsFlat.find(opt => opt.value === value);
    } else if (Array.isArray(value)) {
      return value.map(v => (
        optionsFlat.find(o => o.value === v) ?? { value: v, label: v })
      );
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    setVal(getValue(options));
  }, [value, isLoading]);

  useEffect(() => {
    const allFormsLabel = props.allFormsLabel ?? 'All Forms';
    const newOptions: GroupedOption[] = includeAllFormsOption
      ? [
        { label: allFormsLabel, options: [{ value: 'AllForms', label: allFormsLabel }] }
      ]
      : [];

    if (propertyForms) {
      newOptions.push({
        label: 'Property Folders',
        options: [
          { value: FormCode.RSAA_SalesAgencyAgreement, label: 'Residential Sales Agency Agreement', subLabel: 'Property Folders' },
          { value: FormCode.RSC_ContractOfSale, label: 'Residential Sales Contract', subLabel: 'Property Folders' }
        ]
      });
    }

    const propsFilter = props.filter;
    const filter = typeof propsFilter !== 'undefined'
      ? Array.isArray(propsFilter)
        ? (option: SelectOption) => propsFilter.includes(option.value)
        : propsFilter
      : () => true;

    if (subscriptionForms) {
      setIsLoading(true);
      const promise: Promise<ListSubscriptionFormsResponse | ListFormsForAgent | undefined> = entityUuid
        ? AjaxPhp.listSubscriptionForms(entityUuid, props.withClauses)
        : FormsApi.listFormsForAgent();
      promise.then(r => {
        const groups: { [key: string]: GroupedOption } = {};
        if (r && Array.isArray(r.results)) {
          for (const result of r.results) {
            const fullProductName = result.fullProductName;
            if (!groups[fullProductName]) {
              groups[fullProductName] = {
                label: fullProductName,
                options: []
              };
            }

            const module = 'module' in result && result.module
              ? {
                path: result.module.chain.map(x => x.moduleName).join(' > '),
                moduleId: result.module.moduleId
              }
              : undefined;

            if (module && renderStructured && !groups[fullProductName].options.find(x => x.label === module.path)) {
              groups[fullProductName].options.push({
                value: `module.${module.moduleId}`,
                label: module.path,
                subLabel: fullProductName,
                indent: 0
              });
            }

            groups[fullProductName].options.push({
              value: result.code,
              label: module?.path ? result.name : `${result.name} (${result.stateName})`,
              indent: 1,
              subLabel: module?.path ? `${result.stateName} > ${module.path}` : undefined
            });
          }
        }

        for (const o of Object.keys(groups)) {
          groups[o].options = groups[o].options
            .filter(filter);

          if (groups[o].options.length > 0) {
            newOptions.push(groups[o]);
          }
        }

        if (renderStructured) {
          setOptions(newOptions.flatMap(g => g.options));
        } else {
          setOptions(newOptions);
        }
        setVal(getValue(newOptions));
      }).finally(() => {
        setIsLoading(false);
      });
    } else {
      setOptions(newOptions);
      setVal(getValue(newOptions));
      setIsLoading(false);
    }
  }, [subscriptionForms, entityUuid, propertyForms, props.filter]);

  const { totalOptions, firstOption } = useMemo(() => {
    let totalOptions = 0;
    let firstOption = null;
    for (const group of options) {
      if ('options' in group) {
        totalOptions += group.options.length ?? 0;
        if (firstOption === null && group.options.length > 0) {
          firstOption = group.options[0];
        }
      } else {
        totalOptions += 1;
      }
    }

    return { totalOptions, firstOption };
  }, [options]);

  if (totalOptions <= 1) {
    if (firstOption) {
      // doing this more succinctly freaked typescript out, so we're stuck with this
      if (multiSelect) {
        onChange([firstOption.value]);
      } else {
        onChange(firstOption.value);
      }
    }

    return <></>;
  }

  if (bootstrapSelect) {
    return <>
      {label && <Label label={label} />}
      <Form.Select
        size='lg'
        className={variant === 'dark' ? 'border-secondary' : ''}
        onChange={e => {
          const val = e.target?.value ? e.target.value : undefined;
          if (multiSelect) {
            onChange(val ? [val] : []);
          } else {
            onChange(val);
          }
        }}
        defaultValue={value}
      >
        {options.map(o => {
          if ('options' in o) {
            return (
              <optgroup key={o.label} label={o.label}>
                {o.options.map(opt => <option key={opt.value + opt.label} value={opt.value}>{opt.label}</option>)}
              </optgroup>
            );
          } else {
            return <option key={o.value + o.label} value={o.value}>{o.label}</option>;
          }
        })}
      </Form.Select>
    </>;
  }

  return <>
    {label && <Label label={label} />}
    <Select
      className={clsJn('select-form', className)}
      isMulti={multiSelect}
      options={options}
      isClearable={false}
      value={isLoading ? { value: '', label: 'Loading...' } : val}
      isLoading={isLoading}
      onChange={newValue => {
        if (newValue == null) {
          return;
        }

        if (multiSelect) {
          // @ts-ignore
          onChange((newValue ?? []).map(v => v.value));
        } else {
          // @ts-ignore
          onChange(newValue ? newValue.value : undefined);
        }
      }}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          background: 'var(--bs-body-bg)',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: `rgb(var(--bs-${variant}-rgb))`,
          borderRadius: 0,
          height: '100%',
          fontSize: '1.25rem'
        })
      }}
      formatOptionLabel={(option: SelectOption, meta) => {
        if (renderStructured && meta.context === 'menu') {
          const subLabel = 'subLabel' in option && option.subLabel ? option.subLabel : undefined;
          const marginLeft = option.indent
            ? `${option.indent}rem`
            : undefined;
          return <div className={clsJn('d-flex flex-column')} style={{ marginLeft }}>
            <span className=''>{option.label}</span>
            <small>{subLabel}</small>
          </div>;
        }

        return option.label;
      }}
      formatGroupLabel={(option) => {
        if (renderStructured) return '';

        return option.label;
      }}
    />
  </>;
}

export function SingleSelectForm(props: Omit<SingleProps, 'multiSelect'>) {
  return <SelectForm {...props} multiSelect={false} />;
}

export function MultiSelectForm(props: Omit<MultiProps, 'multiSelect'>) {
  return <SelectForm {...props} multiSelect={true} />;
}
