import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import { COMMAND_PRIORITY_CRITICAL, PASTE_COMMAND } from 'lexical';
import { $convertFromMarkdownString } from '@lexical/markdown';

// not perfect, but should be good enough for our purposes
const MsPastedContentRegex = /.*<html.*xmlns:o="urn:schemas-microsoft-com:office:office".*/;
const StartsWithDigitRegex = /^\d+/;
const StartsWithAlphaRegex = /^\([a-z]\)/;

function iterateOverNodes(
  nodes: NodeListOf<ChildNode>,
  state: {
    formatting?: string;
    list?: {
      level: number,
    } & ({ type: 'margin', currentMargin: number; } | { type: 'mso-list' })
  } = {}
) {
  const newMd: string[] = [];
  const targetEndListLevel = state.list?.level ?? 1;

  for (const node of nodes) {
    let isListNode = false;

    if (node.nodeType === Node.COMMENT_NODE) {
      continue;
    }

    const textContent = (node.textContent ?? '').trim();
    if (node.nodeType === Node.ELEMENT_NODE) {
      const attributes = (node as Element).attributes;
      if (attributes) {
        const style = attributes.getNamedItem('style');

        if (style && style.value.includes('mso-list')) {
          const values = style.value.split(';');
          const list = values.find(v => v.startsWith('mso-list'))?.split(':').map(v => v.trim().toLowerCase()) ?? [];
          if (!list[1] || (list[1] && list[1] === 'ignore')) {
            continue;
          }

          if (state.list) {
            if (state.list.type === 'mso-list') {
              // level starts at 1
              // l0 level1 1fo1
              const listDetails = list[1].split(' ');
              const level = Number.parseInt(listDetails[1].replace('level', ''));

              if (level > state.list.level) {
                state.list.level++;
              } else if (level < state.list.level) {
                state.list.level--;
              }
            }
          } else {
            state.list = {
              level: 1,
              type: 'mso-list'
            };
          }
          isListNode = true;
        } else if (style && style.value.includes('margin-left') && style.value.includes('text-indent')) {
          const values = style.value.split(';');
          const marginLeft = values.find(v => v.startsWith('margin-left'))?.split(':').map(v => v.trim());
          const textIndent = values.find(v => v.startsWith('text-indent'))?.split(':').map(v => v.trim());

          if (
            marginLeft?.length == 2
            && textIndent?.length == 2
            && (StartsWithDigitRegex.test(textContent)) || StartsWithAlphaRegex.test(textContent)
          ) {
            isListNode = true;
            const currentMargin = Number.parseFloat((marginLeft as string[])[1]);
            if (state.list) {
              if (state.list.type === 'margin') {
                if (currentMargin > state.list.currentMargin) {
                  state.list.level++;
                  state.list.currentMargin = currentMargin;
                } else if (currentMargin < state.list.currentMargin) {
                  state.list.level--;
                  state.list.currentMargin = currentMargin;
                }
              }
            } else {
              state.list = {
                level: 1,
                type: 'margin',
                currentMargin
              };
            }
          }
        }
      }
    }

    const listIndent = isListNode
      ? ' '.repeat(((state.list?.level ?? 1) - 1) * 4) + '1. '
      : '';
    let formatting = '';
    let needsNewLine = false;

    switch (node.nodeName.toLowerCase()) {
      case 'b':
      case 'strong':
        formatting += '**';
        break;
      case 'i':
      case 'em':
        formatting += '*';
        break;
      case 'u':
        formatting += '++';
        break;
    }

    if (['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div', 'br'].includes(node.nodeName.toLowerCase())) {
      needsNewLine = true;
    }

    const formattingSpace = formatting.length > 0 ? '' : '';

    if (node.hasChildNodes()) {
      const childNodeMd = iterateOverNodes(node.childNodes, state);
      if (childNodeMd.length > 0) {
        if (isListNode) {
          newMd.push(listIndent, formatting, childNodeMd, formatting, formattingSpace);
        } else {
          newMd.push(formatting, childNodeMd, formatting, formattingSpace);
        }
      }
    } else {
      if (node.textContent && textContent.length > 0) {
        if (isListNode) {
          newMd.push(listIndent, formatting, node.textContent, formatting, formattingSpace);
        } else {
          // const looksLikeListButIsnt = !isListNode && LooksLikeListRegex.test(textContent);
          const textToInsert = node.textContent.replaceAll('\n', ' ');
          newMd.push(formatting, textToInsert, formatting, formattingSpace);
        }
      }
    }

    if (needsNewLine) {
      newMd.push('\n');
    }

    if (state.list?.level && state.list.level > targetEndListLevel) {
      state.list.level = targetEndListLevel;
    }
  }

  if (state.list?.level && state.list.level > targetEndListLevel) {
    state.list.level = targetEndListLevel;
  }

  return newMd
    .join('');
}

export function LexicalMsWordPasteInterceptorPlugin({ transformers }: { transformers: any[] }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor) {
      return;
    }

    return editor.registerCommand<ClipboardEvent>(
      PASTE_COMMAND,
      event => {

        if (!event.clipboardData?.types.includes('text/html')) {
          return false;
        }

        const htmlText = event.clipboardData?.getData('text/html');
        console.log(htmlText);

        /**
         * we only handle a few things
         *   bold
         *   italic
         *   underline
         *   lists
         */
        if (MsPastedContentRegex.test(htmlText)) {
          event.preventDefault();
          const domParser = new DOMParser();
          const html = domParser.parseFromString(htmlText, 'text/html');
          const newMarkdown = iterateOverNodes(html.body.childNodes);
          console.log(newMarkdown);
          $convertFromMarkdownString(newMarkdown, transformers);
          return true;
        }

        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );

  }, [editor]);

  return null;
}
