import { AwsRum } from 'aws-rum-web';
import { Predicate } from '@property-folders/common/predicate';

const consoleError = window.console.error;

export function useConsoleRumShim(props: {rum: AwsRum | null}) {
  const { rum } = props;

  if (!rum) {
    return;
  }

  console.error = (...args: any[]) => {
    if (args) {
      const filteredArgs = args.filter(Predicate.isNotNull);
      if (filteredArgs.length > 0) {
        rum.recordError(filteredArgs.length === 1 ? filteredArgs[0] : args);
      }
    }

    consoleError(...args);
  };
}
