import {
  BaseItem,
  InfiniteScrollListColumn,
  InfiniteScrollListProps,
  LazyInfiniteTableList
} from '../dragged-components/LazyInfiniteTableList';

export type TableListColumns<T> = InfiniteScrollListColumn<T>;

export function TableList<T extends BaseItem>(props: Omit<InfiniteScrollListProps<T>, 'hasNextPage'>) {
  return <LazyInfiniteTableList hasNextPage={false} disableScroll={true} {...props} />;
}
