import React, { forwardRef } from 'react';
import { FieldProps, useFieldVisibilityAid } from './common';
import { asPercentage } from '@property-folders/signing/src/components/field/common';

export const RadioField = forwardRef<HTMLDivElement, FieldProps>((props, ref) => {
  const {
    id,
    location,
    page: {
      width: pageWidth,
      height: pageHeight
    },
    text,
    fillField,
    clearField,
    custom
  } = props;

  const { className } = useFieldVisibilityAid(Boolean(fillField) && !text);
  const readOnly = !fillField;

  return <div
    ref={ref}
    style={{
      position: 'absolute',
      left: asPercentage(location.x, pageWidth),
      bottom: asPercentage(location.y, pageHeight),
      width: asPercentage(location.width, pageWidth),
      height: asPercentage(location.height, pageHeight)
    }}
    className={className}
  >
    <input
      type='radio'
      id={id}
      name={custom?.radio?.group}
      style={{ height: '100%', width: '100%', aspectRatio: 1 }}
      data-read-only={readOnly}
      tabIndex={readOnly ? -1 : undefined}
      onChange={fillField && (() => {
        fillField();
      })}
      onClick={clearField && ((e) => {
        if (e.currentTarget.value === 'on') {
          clearField();
        }
      })}
      checked={text === 'on'}
    />
  </div>;
});
