import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItemProps } from 'react-bootstrap';
import clsJn from '@property-folders/common/util/classNameJoin';
import './BreadCrumbs.scss';
import { BreadCrumbsProps } from '@property-folders/common/types/BreadCrumbTypes';

export const BreadCrumbs = ({ segments = [], className }: BreadCrumbsProps) => {
  const parts = [];
  for (const segi in segments) {
    const smg = segments[segi];
    const inclusions: BreadcrumbItemProps & {to?: string} = {};
    if (parseInt(segi)+1 === segments.length) {
      inclusions.active = true;
    } else {
      parts.push(<li key={`${smg.label}-${smg.href}`} className={clsJn('breadcrumb-item', inclusions.active && 'active')}>
        {smg.href ? <Link to={smg.href}>{smg.label}</Link> : smg.label}
      </li>);
    }
  }

  return <Breadcrumb className={clsJn('h-100', className)}>{parts}</Breadcrumb>;
};
