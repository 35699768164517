import { Predicate } from '@property-folders/common/predicate';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { canonicalisers } from '@property-folders/common/util/formatting';
import { FormCode, OfferDocumentDesc, OfferMeta, PurchaserProcessAction } from '@property-folders/contract';
import { OfferType } from '@property-folders/contract/property/OfferContractState';
import { PurchaserSubmittedDocumentStatus } from '@property-folders/contract/yjs-schema/purchaser-portal';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function offerSorter (offerA: OfferDocumentDesc, offerB: OfferDocumentDesc) {
  let diff = (offerB?.offerSummary?.offerPrice??0) - (offerA?.offerSummary?.offerPrice??0);
  if (diff !== 0) return diff;
  // Having a condition of being dependant on the sale of another property sounds a lot more fraught and headache inducing than simply
  // finance
  diff = (offerB?.offerSummary?.conditions?.salePurchaserProperty ? 0 : 1) + (offerA?.offerSummary?.conditions?.salePurchaserProperty ? 0 : -1);
  if (diff !== 0) return diff;
  return (offerB?.offerSummary?.conditions?.finance ? 0 : 1) + (offerA?.offerSummary?.conditions?.finance ? 0 : -1);
}

export function SidebarMetrics({ offerData, headline, propertyId }: {offerData: OfferMeta | undefined, propertyId: string, headline: string}) {

  const offers = offerData?.prospectivePurchasers.flatMap(pur=>pur.submittedOffers);
  const offerRefs = (offers??[])
    .filter(offer=>[PurchaserSubmittedDocumentStatus.Accepted, PurchaserSubmittedDocumentStatus.Submitted].includes(offer.status))
    .filter(offer=>Predicate.isNotNullish(offer.offerSummary.offerPrice))
    .sort(offerSorter);
  const withdrawnCount = (offers??[]).filter(offer=>offer.status===PurchaserSubmittedDocumentStatus.Withdrawn).length;
  const bestOffer = offerRefs[0];

  const bestOfferFormId = bestOffer?.metadata.formStates?.[bestOffer.type === OfferType.LetterOfOffer ? FormCode.OfferToPurchase : FormCode.RSC_ContractOfSale].instances?.[0]?.id;
  const bestOfferLineageId = bestOffer?.id;
  const bestPurchaser = bestOffer && offerData?.prospectivePurchasers.find(pp=>pp.id === bestOffer?.purchaserId)?.purchaserParties?.[0].fullLegalName;
  const linkTarget = bestOffer && bestOffer.type === OfferType.LetterOfOffer
    ? bestOfferLineageId && propertyId && LinkBuilder.LetterOfOfferPreviewPath({ id: propertyId, nicetext: headline }, { id: bestOfferLineageId, name: bestPurchaser })
    : bestOfferFormId && propertyId && LinkBuilder.documentPath({ id: propertyId, nicetext: headline }, { id: bestOfferFormId, nicetext: bestPurchaser }, false);

  const form1Views = offerData?.prospectivePurchasers.filter(offer=>offer.meta?.form1.some(a=>a.action === PurchaserProcessAction.viewed)).length;
  const templateContractViews = offerData?.prospectivePurchasers.filter(offer=>offer.meta?.exampleContract.some(a=>a.action === PurchaserProcessAction.viewed)).length;
  const offersInDraft = (offerData?.draftOffersIncrementArray??[]).reduce((acc,cv)=>acc+cv,0);
  return <Card className="p-3 m-2">
    <div className='fw-bold'>METRICS</div>
    <div className='summary-card-body'>
      <Container>
        <Row>
          <Col xs={7}>{linkTarget ? <Link
            to={linkTarget}
          >
            Best offer
          </Link> : 'Best offer'}</Col>
          <Col xs={5}>{bestOffer ? canonicalisers.aud(bestOffer?.offerSummary?.offerPrice).display : '-'}</Col>
        </Row>

        <Row>
          <Col xs={7}><Link to={'offer-management'}>Offers received</Link></Col>
          <Col xs={5}>{offers?.length ?? 0}{withdrawnCount ? <small className='ms-1'>({withdrawnCount} withdrawn)</small> : ''}</Col>
        </Row>

        <Row>
          <Col xs={7}>Offers in draft</Col>
          <Col xs={5}>{offersInDraft}</Col>
        </Row>

        <Row>
          <Col xs={7}>Form 1 views</Col>
          <Col xs={5}>{form1Views ?? 0}</Col>
        </Row>

        <Row>
          <Col xs={7}>Template Contract views</Col>
          <Col xs={5}>{templateContractViews??0}</Col>
        </Row>
      </Container>
    </div>
  </Card>;
}