import { boolYesNoOpts, rentalPeriodOpts, tenancyTypeOpts } from '@property-folders/common/data-and-text/constants';
import { WrField } from './CommonComponentWrappers';
import { EditorListChildProps } from './CollectionEditor';
import { TenantAgreement } from '@property-folders/contract';
import { CollectionRemoveButton } from './CollectionRemoveButton';
import { useTransactionField } from '../../hooks/useTransactionField';

export function TenancyInputInst({ saleContractMode = false, parentPath, myPath, myIndex, hideDelete, removable }: {saleContractMode?: boolean} & EditorListChildProps) {
  const { value: tenant, fullPath, handleRemove } = useTransactionField<TenantAgreement>({ parentPath, myPath });
  const removeButton = handleRemove && <CollectionRemoveButton removable={removable} onRemove={handleRemove} />;
  return <div className='w-100 mt-3'>
    <div className={'d-flex flex-row'}>
      {(typeof myIndex === 'number' || myIndex) && <div className='fs-5'>Tenancy {(typeof myIndex === 'string' ? parseInt(myIndex) : myIndex)+1}</div>}
      {(!hideDelete) && <div className='d-flex align-items-center delete-div ms-auto'>
        {removeButton}
      </div>}
    </div>
    <div className="d-flex w-100 flex-wrap">

      <WrField.CheckRadio
        label='Agreement type'
        radioType='checkbox'
        options={tenancyTypeOpts}
        inline={true}
        name='type'
        parentPath={fullPath}
        myPath='type'
      />

    </div>
    <div>
      <div className="d-flex w-100 flex-wrap gapped-row">
        <div className='flex-grow-1'>
          <WrField.Control name='tenantName' label={'Tenant(s)'} parentPath={fullPath} myPath='tenantName'
            placeholder={'Tenant\'s Name'}/>
        </div>
      </div>
      {!saleContractMode && <div className="d-flex w-100 flex-wrap gapped-row">
        <div className='flex-grow-1'>
          <WrField.Control name='manageAgent' label={'Managing agent'} parentPath={fullPath} myPath='manageAgent'
            placeholder='Details of managing agent'/>
        </div>
      </div>}
      <div className="d-flex w-100 flex-wrap gapped-row">
        <div className='flex-grow-1'>
          <WrField.Control name='start' type='date' useCanonical={true} label={'Commencement of tenancy'}
            parentPath={fullPath} myPath='start' placeholder='Start date'/>
        </div>

        <div className='flex-grow-1'>
          <WrField.Control name='end' type='date' dateFromToday={true} useCanonical={true} label={'End of tenancy'}
            parentPath={fullPath} myPath='end' placeholder='End date'/>
        </div>
      </div>
      <div className="d-flex w-100 flex-wrap gapped-row">
        <div className='flex-grow-0' style={{ width: '150px' }}>
          <WrField.Control name='rentalValue' label={'Rent amount'} parentPath={fullPath} myPath='rentalValue'
            placeholder='$###'/>
        </div>
        <div className='flex-grow-0 d-flex align-items-center' style={{ width: '200px' }}>
          <div className='mx-3'>per</div>
          <div className='flex-grow-1'>
            <WrField.Select
              label='Period'
              options={rentalPeriodOpts}
              name='period'
              parentPath={fullPath}
              myPath='period'
            />
          </div>
        </div>
      </div>
      <div className="d-flex w-100 flex-wrap gapped-row mt-3">
        <div className='d-flex flex-wrap flex-grow-0'>
          <div className='flex-grow-1'>
            <WrField.CheckRadio
              label='Bond with Consumer and Business Services'
              radioType='checkbox'
              options={boolYesNoOpts}
              valueType='boolean'
              inline={true}
              name='bondEnable'
              parentPath={fullPath}
              myPath='bondEnable'
            />
          </div>
          {tenant?.bondEnable && <div className='flex-grow-0 ms-3' style={{ width: '200px' }}>
            <WrField.Control name='bondAmt' label={'Bond amount'} parentPath={fullPath} myPath='bondAmt'
              placeholder='$#,###'/>
          </div>}
        </div>
      </div>
      {!saleContractMode && <div className="d-flex w-100 flex-wrap mt-2">
        <div className='flex-grow-1'>
          <WrField.CheckRadio
            label='Vendor to advise tenants upon sale?'
            radioType='checkbox'
            options={boolYesNoOpts}
            valueType='boolean'
            inline={true}
            name='adviseTenant'
            parentPath={fullPath}
            myPath='adviseTenant'
          />
        </div>
        <div className='flex-grow-1'>
          <WrField.CheckRadio
            label='Sale subject to existing tenancy continuing?'
            radioType='checkbox'
            options={boolYesNoOpts}
            valueType='boolean'
            inline={true}
            name='saleContTenant'
            parentPath={fullPath}
            myPath='saleContTenant'
          />
        </div>
      </div>}
    </div>
  </div>;
}