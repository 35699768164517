import { CustomFieldConfiguration, CustomFieldType } from '@property-folders/contract';

export enum DraggableType {
  Create = 'create',
  Existing = 'existing'
}

export interface ExistingDetails {
  mode: DraggableType.Existing
}

export type ExistingCustomFieldDetails = ExistingDetails & CustomFieldConfiguration;
export type CreateCustomFieldDetails = {
  mode: DraggableType.Create,
  type: CustomFieldType,
  partyId?: string,
  width: number,
  height: number
};
export type CustomFieldDetails =
  | ExistingCustomFieldDetails
  | CreateCustomFieldDetails;
