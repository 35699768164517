import { CreateCustomFieldDetails } from './types';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { CustomField, CustomFieldPartyInfo } from './CustomField';
import { MaterialisedPropertyData, Maybe } from '@property-folders/contract';
import { XYCoord } from 'react-dnd';

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  cursor: 'crosshair'
};

function getItemStyles(
  currentOffset?: XYCoord
): CSSProperties {
  if (!currentOffset) {
    return {
      display: 'none'
    };
  }

  const { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
    opacity: '0.6'
  };
}

export function PlonkLayer({
  plonkable,
  parties,
  property,
  contentScale
}: {
  plonkable?: CreateCustomFieldDetails,
  parties: CustomFieldPartyInfo[],
  property: MaterialisedPropertyData,
  contentScale: number
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<Maybe<XYCoord>>(undefined);

  useEffect(() => {
    if (!plonkable) return;

    const handler = (e: MouseEvent) => {
      setPosition({ x: e.x, y: e.y });
    };
    window.addEventListener('mousemove', handler);
    return () => {
      setPosition(undefined);
      window.removeEventListener('mousemove', handler);
    };
  }, [Boolean(plonkable)]);

  if (!plonkable) {
    return <></>;
  }

  return <div ref={ref} className='plonk-layer' style={layerStyles}>
    <div style={getItemStyles(position)}>
      <CustomField
        details={plonkable}
        parties={parties}
        dragging={true}
        contentScale={contentScale}
        property={property}
      />
    </div>
  </div>;
}
