import { FormInstance, FormSigningState, SigningPartyType } from '@property-folders/contract';
import { SigningProgressBar } from './SigningProgressBar';
import React from 'react';
import { determineFormState, FormState } from '@property-folders/common/yjs-schema/property';
import { missingWetPdfWarning } from '../display/properties/MissingWetPdfWarning';

export function FormInstanceSigningProgressBar({ instance } : {instance: FormInstance}) {
  if (!instance) return <></>;

  if (instance?.external) {
    return <SigningProgressBar
      completion={0}
      text='External'
      variant='success' />;
  }

  switch (determineFormState(instance)) {
    case FormState.ORDER_ORDERING:
      return <SigningProgressBar
        completion={0}
        text='Ordering in progress'
        variant='success' />;
    case FormState.ORDER_RETURNED:
      return <SigningProgressBar
        completion={0}
        text='Returned'
        variant='success' />;
    case FormState.ORDER_CANCELLED:
      return <SigningProgressBar
        completion={100}
        text='Cancelled'
        variant='danger' />;
    case FormState.ORDER_PREPARING:
      return <SigningProgressBar
        completion={0}
        text='Preparation in progress'
        variant='success' />;
    case FormState.DRAFT:
      return <SigningProgressBar
        completion={0}
        text='Draft'
        variant='success'
      />;
    case FormState.SIGNED: {
      const isMissingPdf = instance?.signing?.parties?.some(p => p.type === SigningPartyType.SignWet && !p.signedPdf);
      return <SigningProgressBar
        completion={100}
        text="Signed"
        title={isMissingPdf ? missingWetPdfWarning : ''}
        variant={isMissingPdf ? 'warning' : 'success'}
      />;
    }
    case FormState.DECLINED:
      return <SigningProgressBar
        completion={100}
        text='Declined'
        variant='danger'
      />;
    case FormState.CONFIGURING:
    case FormState.AWAITING_SIGN: {
      const signerCount = instance?.signing?.parties?.length || 1;
      const signersSignedCount = instance?.signing?.parties?.filter(party => party.signedTimestamp).length || 0;
      return <SigningProgressBar
        completion={instance.signing?.state === FormSigningState.Configuring ? 0 : signersSignedCount / signerCount}
        text={instance.signing?.state === FormSigningState.Configuring ? 'Configuring Signing' : `Signing ${signersSignedCount} of ${signerCount}`}
        variant="success"
      />;
    }
    default:
      return <></>;
  }
}
