import React, { forwardRef, useEffect, useRef } from 'react';
import { FieldProps, useFieldVisibilityAid } from './common';
import { asPercentage } from '@property-folders/signing/src/components/field/common';
import { useMergeRefs } from '../../../hooks/useMergeRefs';

export const CheckField = forwardRef<HTMLDivElement, FieldProps>((props, ref) => {
  const {
    id,
    location,
    page: {
      width: pageWidth,
      height: pageHeight
    },
    text,
    fillField,
    observer
  } = props;

  const localRef = useRef<HTMLDivElement>();
  const mergedRef = useMergeRefs(ref, localRef);

  const { className, setTouched } = useFieldVisibilityAid(Boolean(fillField) && !text);
  const readOnly = !fillField;

  useEffect(() => {
    if (!observer) return;
    if (!localRef.current) return;

    const el = localRef.current;
    observer.observe(el);
    return () => {
      observer.unobserve(el);
    };
  }, [observer, localRef.current]);

  return <div
    ref={mergedRef}
    data-field-id={id}
    style={{
      position: 'absolute',
      left: asPercentage(location.x, pageWidth),
      bottom: asPercentage(location.y, pageHeight),
      width: asPercentage(location.width + 1, pageWidth),
      height: asPercentage(location.height + 1, pageHeight)
    }}
    className={className}
  >
    <input
      type='checkbox'
      id={id}
      name={id}
      style={{ height: '100%', width: '100%', aspectRatio: 1 }}
      data-read-only={readOnly}
      tabIndex={readOnly ? -1 : undefined}
      onChange={fillField && (e => {
        setTouched(e.currentTarget.checked);
        fillField(e.currentTarget.checked ? 'on' : 'off');
      })}
      checked={text === 'on'}
    />
  </div>;
});
