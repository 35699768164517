import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';

export function DisabledPlugin(props: {disabled?: boolean}) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor) {
      return;
    }

    const isEditable = !props.disabled;

    if (isEditable !== editor.isEditable()) {
      editor.setEditable(isEditable);
    }
  }, [editor, props.disabled]);

  return null;
}
