import { Button, Card, Dropdown, Spinner, Table } from 'react-bootstrap';
import React, { ReactElement, useEffect, useRef, useCallback, useState, useMemo, Fragment } from 'react';
import { useSize } from '../hooks/useSize';
import { Icon } from './Icon';
import { BP_MINIMA } from '@property-folders/common/data-and-text/bootstrapBreakpoints';
import { useMediaQuery } from 'react-responsive';
import { clsJn as classNames } from '@property-folders/common/util/classNameJoin';
import './InfiniteTableList.scss';
import clsJn from '@property-folders/common/util/classNameJoin';
import { Predicate } from '@property-folders/common/predicate';
import { orderBy } from 'lodash';
import { useVirtualizer } from '@tanstack/react-virtual';
import { mapMutateIfDelete, mapMutateIfSet } from '@property-folders/common/util';

export interface BaseItem {
  id: string | number;
  rowClass?: string
}

export interface ChildItemProps<T> {
  forceFocus: boolean | undefined;
  // onForceFocus: () => void;
  item: T
}

interface ArbitraryDropdownToggleProps {
  onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  children: React.ReactNode;
}
export const ArbitraryDropdownToggle = React.forwardRef<HTMLAnchorElement, ArbitraryDropdownToggleProps>(({ children, onClick }, ref) => (
  <a
    href="#"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

type ColumnText<T> =
  | keyof T
  | ((rowData: T) => string | ReactElement | null)
  | ReactElement;
type HoverText<T> =
  | keyof T
  | ((rowData: T) => string);

export interface InfiniteScrollListColumn<T> {
  label?: string | null
  headerCellStyle?: Record<string, any>
  rowMajor?: ColumnText<T>;
  rowMinor?: ColumnText<T> | ((rowData: T) => string | ReactElement | undefined);
  hoverText?: HoverText<T>;
  onClick?: (event: React.SyntheticEvent, rowData: T) => void;
  onClickEnabled?: boolean | ((rowData: T) => boolean);
  sort?: {
    key: string
    defaultSort?: string
    sortFn?: (rowData: T) => number
  }
  hideIfEmptyInCardView?: boolean
}

export type RowActionResult =
  | void
  | boolean
  | string;

export interface InfiniteScrollRowAction<T> {
  label: string;
  action: (rowData: T) => RowActionResult | Promise<RowActionResult>;
  if?: (rowData: T) => boolean;
  disabled?: (rowData: T) => boolean;
  dividerAbove?: boolean;
}

export interface InfiniteScrollListProps<T extends BaseItem> {
  storageKey: string;
  hasNextPage: boolean | undefined;
  isFetching?: boolean;
  isFetchingNextPage?: boolean;
  fetchNextPage?: () => void;
  items: T[];
  columns: InfiniteScrollListColumn<T>[],
  mobileColumns?: InfiniteScrollListColumn<T>[]
  rowActions?: InfiniteScrollRowAction<T>[]
  rowClick?: (rowData: T) => void
  rowClickEnabled?: ((rowData: T) => boolean) | boolean;
  cardStyleWidth?: keyof typeof BP_MINIMA
  containerClass?: string
  headerClass?: string
  rowHeight?: `${number}px` | 'unset'
  hover?: boolean;
  disableScroll?: boolean;
}

type SortType = {
  key: string,
  dir: string
};

export function LazyInfiniteTableList<T extends BaseItem>(props: InfiniteScrollListProps<T>) {
  const {
    storageKey,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    items,
    columns: defaultColumns,
    mobileColumns,
    rowActions,
    rowClick,
    rowClickEnabled,
    cardStyleWidth,
    containerClass,
    headerClass,
    hover = true,
    disableScroll
  } = props;

  let lsDefault: SortType | undefined;
  try {
    lsDefault = JSON.parse(localStorage.getItem(`LazyInfiniteTableList.${storageKey}`) || '');
  } catch {
    lsDefault = undefined;
  }

  const useListNotCards = useMediaQuery({ minWidth: cardStyleWidth ?? BP_MINIMA.sm });
  const columns = !useListNotCards && Array.isArray(mobileColumns)
    ? mobileColumns
    : defaultColumns;
  const dSort = columns.find(c => c.sort?.defaultSort);
  const [sort, setSort] = useState<SortType | undefined>(lsDefault ?? (dSort?.sort ? {
    key: dSort.sort.key,
    dir: dSort.sort.defaultSort || ''
  } : undefined));
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const scrollDebounceRef = useRef<number>(0);
  const size = useSize(scrollAreaRef);
  const width = size.width;

  useEffect(() => {
    sort && localStorage.setItem(`LazyInfiniteTableList.${storageKey}`, JSON.stringify(sort));
  }, [sort]);

  const isLoading = isFetching || isFetchingNextPage;
  const statusMessage = isLoading || hasNextPage
    ? undefined
    : items.length
      ? undefined
      : 'No matches found';

  useEffect(() => {
    const target = scrollAreaRef.current;
    if (!width) {
      return;
    }

    if (!hasNextPage || isFetchingNextPage || !fetchNextPage) {
      return;
    }

    if (!target) {
      return;
    }

    const top = target.scrollTop;
    const total = target.scrollHeight;
    const thisViewport = target.clientHeight;
    const below = total - top - thisViewport;

    if (!isFetchingNextPage && below < thisViewport / 2) {
      fetchNextPage();
    }
  }, [
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    width
  ]);

  const scrollCallback = useCallback((evt: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (isFetchingNextPage || !hasNextPage || scrollDebounceRef.current) {
      return;
    }
    scrollDebounceRef.current = setTimeout(() => {
      scrollDebounceRef.current = 0;
    }, 200) as unknown as number;
    const target = evt.currentTarget;
    const top = target.scrollTop;
    const total = target.scrollHeight;
    const thisViewport = target.clientHeight;
    const below = total - top - thisViewport;

    if (hasNextPage && !isFetchingNextPage && below < thisViewport / 2) {
      fetchNextPage?.();
    }
  }, [hasNextPage, fetchNextPage, isFetchingNextPage]);

  const rowHeight = props.rowHeight || (columns.filter(colDef => colDef.rowMinor).length ? '55px' : '38px');
  const sizeEstimatePx = rowHeight && rowHeight !== 'unset'
    ? parseInt(rowHeight, 10)
    : 60;

  const virtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => scrollAreaRef.current,
    estimateSize: () => sizeEstimatePx,
    overscan: useListNotCards ? 5 : 2,
    paddingStart: 0
  });
  const virtualItems = virtualizer.getVirtualItems();

  useEffect(() => {
    // clear (and re-measure) any cached item measurements when the render system switches between list/card views
    // because rows and cards have wildly different vertical sizes
    virtualizer.measure();
  }, [useListNotCards]);

  const filteredRowActions = Array.isArray(rowActions)
    ? rowActions.filter(Predicate.isNotNullish)
    : [];
  const sortByCol = columns.find(col => col.sort?.key === sort?.key);
  const sortedItems = useMemo(() => {
    return orderBy(items, sortByCol?.sort?.sortFn || sortByCol?.sort?.key, sort?.dir);
  }, [items, sortByCol?.sort?.sortFn || sortByCol?.sort?.key, sort?.dir]);

  const renderedItems = virtualItems.map(vItem => {
    const item = sortedItems[vItem.index];
    const actions = filteredRowActions.filter(fra => fra.if ? fra.if(item) : true);
    const actionDropdown = actions.filter(Predicate.isNotNullish).length && <ActionDropdown
      actions={actions}
      item={item}
    />;

    const dropdownFiltered = !!filteredRowActions.length && !actionDropdown;
    const rce = (typeof rowClickEnabled === 'boolean' ? rowClickEnabled : rowClickEnabled?.(item)) ?? true;

    return useListNotCards
      ? <tr
        key={vItem.key}
        data-index={vItem.index}
        ref={virtualizer.measureElement}
        className={item.rowClass}
      >
        {columns.map((colDefn, index) => {
          const major = typeof colDefn.rowMajor === 'function' ? colDefn.rowMajor(item) : item[colDefn.rowMajor];
          const minor = colDefn.rowMinor && typeof colDefn.rowMinor === 'function' ? colDefn.rowMinor(item) : item[colDefn.rowMinor];
          const hover = colDefn.hoverText && typeof colDefn.hoverText === 'function' ? colDefn.hoverText(item) : item[colDefn.hoverText];

          const outerClass = minor ? 'd-flex flex-column justify-content-center h-100' : 'd-flex align-items-center h-100';
          const colClickEnable = (typeof colDefn.onClickEnabled === 'boolean' ? colDefn.onClickEnabled : colDefn.onClickEnabled?.(item)) ?? true;
          const colClick = colClickEnable ? colDefn.onClick ?? (rowClick ? () => rowClick?.(item) : undefined) : undefined;
          return <td
            key={index}
            onClick={colClick ? e => colClick(e, item) : undefined}
            style={{ height: rowHeight, ...(colClick && colClickEnable && rce?{ cursor: 'pointer' }:undefined) }}
          >
            <div className={outerClass} title={hover ?? ''}>
              {major}
              {minor && <div className='small text-secondary'>{minor}</div>}
            </div>
          </td>;
        })}
        {!!actionDropdown && <td style={{ height: rowHeight }} className='action-button-cell'>
          {actionDropdown}
        </td>}
        {dropdownFiltered && <td
          onClick={rowClick? () => rowClick?.(item) : undefined}
          style={{ height: rowHeight, ...(rowClick && rce?{ cursor: 'pointer' }:undefined) }}
        >
        </td>}
      </tr>
      : <Card
        key={vItem.key}
        data-index={vItem.index}
        ref={virtualizer.measureElement}
        className='table-substitute-card border-0 shadow d-flex flex-row mb-3'
      >
        <div
          className='column-replacement-set flex-grow-1'
          onClick={() => rowClick?.(item)}
          style={rowClick ? { cursor: 'pointer' } : undefined}
        >
          {(() => {
            return columns.length === 1 && typeof columns[0].rowMajor === 'function'
              ? columns[0].rowMajor(item)
              : columns.map((colDefn, colIndex) => {
                const major = typeof colDefn.rowMajor === 'function' ? colDefn.rowMajor(item) : item[colDefn.rowMajor];
                const minor = colDefn.rowMinor && typeof colDefn.rowMinor === 'function' ? colDefn.rowMinor(item) : item[colDefn.rowMinor];

                if (colDefn.hideIfEmptyInCardView && ((Array.isArray(major) && major.length === 0) || major == null || major === '') ) {
                  return null;
                }
                return <div key={colIndex}>
                  <div className={classNames({
                    'fw-bold': colIndex === 0,
                    'd-flex flex-row align-items-center justify-content-between gap-3': true
                  })}>
                    {colIndex > 0 && colDefn.label && <span className='small fw-bold'>{colDefn.label}: </span>}
                    {major}
                  </div>
                  {minor && <div className='w-100 d-flex justify-content-between'>
                    {colIndex > 0 && colDefn.label && <div></div>}
                    <div className='small text-secondary'>{minor}</div>
                  </div>}
                </div>;
              });
          })()}
        </div>
        {!!actionDropdown && <div className='flex-grow-0'>{actionDropdown}</div>}
      </Card>;
  });

  const nextSortDir = (currentDir: string): string => {
    switch (currentDir) {
      case '':
        return 'asc';
      case 'asc':
        return 'desc';
      default:
        return '';
    }
  };

  const getSortIcon = (currentDir: string): string => {
    switch (currentDir) {
      case 'asc':
        return 'keyboard_arrow_up';
      case 'desc':
        return 'keyboard_arrow_down';
      default:
        return '';
    }
  };

  const handleHeaderClick = (col: InfiniteScrollListProps<T>['columns'][0]) => {
    if (!col.sort) return;
    setSort(prev => ({ key: col.sort.key, dir: prev?.key === col.sort.key ? nextSortDir(prev.dir) : 'asc' }));
  };

  const totalVirtualSize = virtualizer.getTotalSize();
  const virtualStart = virtualItems.at(0)?.start ?? 0;
  const virtualEnd = virtualItems.at(-1)?.end ?? 0;
  return <div
    ref={scrollAreaRef}
    className='h-100 w-100 pb-3'
    style={{ overflowY: 'auto', overflowX: 'hidden' }}
    onScroll={scrollCallback}
  >

    <div
      className={clsJn('mx-auto list-table-container', containerClass)}
      style={{
        position: 'relative'
      }}
    >
      <div className='mx-3'>
        {useListNotCards
          ? <Card className={'border-0'}>
            <Table className='border-1' hover={hover} style={{ zIndex: 0 }}>
              <thead className={clsJn('bg-light', headerClass)}>
                <tr>
                  {columns.map((col, index) =>
                    <th
                      key={index}
                      className={clsJn('py-0 bg-light border-light sticky-top', col.sort && 'cursor-pointer')}
                      style={{ ...col.headerCellStyle, verticalAlign: 'middle', top: '-1px', zIndex: 1 }}
                      onClick={() => handleHeaderClick(col)}>
                      <div className={'d-flex align-items-center'}>
                        <span className={'py-2 user-select-none'}>{col.label}</span>
                        <span style={{ width: '20px' }}
                          className={'ms-1 user-select-none'}>{sort?.key === col.sort?.key &&
                        <Icon name={getSortIcon(sort?.dir || '')} />}</span>
                      </div>
                    </th>)}
                  {Array.isArray(filteredRowActions) && !!filteredRowActions.length &&
                  <th className='bg-light sticky-top' style={{ width: '3rem', top: '-1px', zIndex: 1 }}></th>}
                </tr>
              </thead>
              <tbody>
                <tr style={{ height: `${virtualStart}px` }}></tr>
                {renderedItems}
                <tr style={{ height: `${totalVirtualSize - virtualEnd}px` }}></tr>
                {statusMessage && <tr><td colSpan={columns?.length} className='status-line'>{statusMessage}</td></tr>}
              </tbody>
            </Table>
          </Card>
          : disableScroll ?
            <div className='table-substitute-card-set'>
              {renderedItems.length
                ? <div className='d-flex flex-column'>{renderedItems}</div>
                : statusMessage && <Card className='status-card table-substitute-card border-0 shadow d-flex flex-row'>{statusMessage}</Card>}
            </div>
            : <div className='table-substitute-card-set' style={{ height: virtualizer.getTotalSize(), position: 'relative' }}>
              {renderedItems.length
                ? <div className='d-flex flex-column' style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  transform: `translateY(${virtualStart ?? 0}px)`
                }}>{renderedItems}</div>
                : statusMessage && <Card className='status-card table-substitute-card border-0 shadow d-flex flex-row'>{statusMessage}</Card>}
            </div>
        }
      </div>
    </div>
  </div>;
}

function ActionDropdown<T extends BaseItem>({
  actions,
  item
}: {
  actions: InfiniteScrollRowAction<T>[],
  item: T
}) {
  // this is a separate component so we have more control over show/hide
  const [show, setShow] = useState(false);
  const [processing, setProcessing] = useState<Map<number, boolean>>(new Map());
  const [messages, setMessages] = useState<Map<number, string>>(new Map());

  const startProcessing = (index: number) => {
    setMessages(map => mapMutateIfDelete(map, index));
    setProcessing(map => mapMutateIfSet(map, index, true));
  };
  const endProcessing = (index: number, message = '', timeoutMs = 1500) => {
    setProcessing(map => mapMutateIfDelete(map, index));
    if (message) {
      setMessages(map => mapMutateIfSet(map, index, message));
      setTimeout(() => {
        setMessages(map => mapMutateIfDelete(map, index));
        setShow(false);
      }, timeoutMs);
    } else {
      setShow(false);
    }
  };

  return <Dropdown
    style={{ color: 'unset !important' }}
    show={show}
    onToggle={(nextShow) => {
      setShow(nextShow);
    }}
    onSelect={(evtKey, evt) => {
      const arrIndex = Number.parseInt(evtKey ?? '');
      if (!isNaN(arrIndex)) {
        startProcessing(arrIndex);
        Promise.resolve(actions[arrIndex].action(item))
          .then(result => {
            switch (typeof result) {
              case 'string':
                endProcessing(arrIndex, result);
                break;
              case 'boolean':
                endProcessing(arrIndex, result ? 'Succeeded' : 'Failed');
                break;
              default:
                endProcessing(arrIndex);
                setShow(false);
                break;
            }
          });
      } else {
        setShow(false);
      }
    }}
    autoClose={'outside'}
  >
    <Dropdown.Toggle as={ArbitraryDropdownToggle} id={'moreActions' + item.id}>
      <Button
        variant="light"
        className="btn-list-action coll-del-button"
        title='More actions'
      >
        <Icon name='more_vert' />
      </Button>
    </Dropdown.Toggle>
    <Dropdown.Menu popperConfig={{ strategy: 'fixed' }} renderOnMount={true}>
      {actions
        .filter(fra => fra.if ? fra.if(item) : true)
        .map((rowAction, idx) => {
          const disabled = rowAction.disabled && rowAction.disabled(item);
          const isProcessing = Boolean(processing.get(idx));
          const actionMessage = messages.get(idx);
          return <Fragment key={idx}>
            {rowAction.dividerAbove && <Dropdown.Divider />}
            <Dropdown.Item
              eventKey={`${idx}`}
              className={clsJn({ 'text-dark': !disabled, 'd-flex flex-column': true })}
              disabled={disabled || isProcessing}
            >
              <div>
                {isProcessing && <Spinner size={'sm'} animation='border' />}{rowAction.label}
              </div>
              {actionMessage && <small><strong>{actionMessage}</strong></small>}
            </Dropdown.Item>
          </Fragment>;
        })
      }
    </Dropdown.Menu>
  </Dropdown>;
}
