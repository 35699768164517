import { Button, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { ClausesApi } from '@property-folders/common/client-api/clausesApi';
import { ClauseImportCard } from './ClauseImportCard';
import { SearchBar } from '../SearchBar';
import { Clause } from '@property-folders/common/types/Clause';
import { useMediaQuery } from 'react-responsive';
import { BP_MINIMA } from '@property-folders/common/data-and-text/bootstrapBreakpoints';

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
  onAddClause: (clauses: Clause[]) => void;
  formCode: string;
  selected?: string[];
  propertyId: string;
}

export function ClausesImportModal({ show, setShow, onAddClause, formCode, selected, propertyId }: Props) {
  const [remoteData, setRemoteData] = useState<Clause[]>([]);
  const [selectedClauses, setSelectedClauses] = useState<Clause[]>([]);
  const [visibleData, setVisibleData] = useState<Clause[]>([]);
  const notMobile = useMediaQuery({ minWidth: BP_MINIMA.lg }); // ipad landscape kinda width

  useEffect(() => {
    setSelectedClauses([]);
    if (!show) {
      return;
    }

    ClausesApi.listClausesForFormAndProperty({ propertyId, formCode, html: true, markdown: true }).then(r => {
      if (!r?.results) {
        setRemoteData([]);
        return;
      }

      const filteredResults = r.results.filter(r => !selected?.find(s => s === String(r.clauseId)));
      setRemoteData(filteredResults);
      setVisibleData(filteredResults);
    });
  }, [show, selected]);

  const toggleClause = (clause: Clause) => {
    setSelectedClauses(prevState => prevState.find(c => c.clauseId === clause.clauseId)
      ? prevState.filter(c => c.clauseId !== clause.clauseId)
      : [...prevState, clause]
    );
  };

  const onHide = () => setShow(false);

  return <Modal
    show={show}
    onHide={onHide}
    size='lg'
  >
    <Modal.Header><h4>Add Standard Additional Conditions</h4></Modal.Header>

    <Modal.Body>
      <SearchBar
        placeholder='Search for a clause'
        className='mb-3'
        onSearch={term => {
          setVisibleData(remoteData.filter(c => c.title.toLowerCase().includes(term.toLowerCase())));
        }}
      />

      <div className='content' style={{ overflowY: 'auto', maxHeight: '60vh' }}>
        {visibleData.map(c => <ClauseImportCard
          clause={c}
          checked={!!selectedClauses.find(sel => sel.clauseId === c.clauseId)}
          onChange={() => toggleClause(c)}
          showMigratable={notMobile}
          key={c.clauseId}
        />)}
      </div>
    </Modal.Body>

    <Modal.Footer className='d-flex'>
      <Button
        className='ms-auto'
        onClick={() => {
          onAddClause(selectedClauses);
          setShow(false);
        }}
      >Add</Button>
      <Button
        variant='light'
        className='ms-2'
        onClick={onHide}
      >Cancel</Button>
    </Modal.Footer>
  </Modal>;
}
