import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './ProcessEditor.scss';
import { Button, Modal } from 'react-bootstrap';
import FormCheck from 'react-bootstrap/FormCheck';
import { DocumentTypePP, PurchaserProcessStepType } from '@property-folders/contract';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '../display/errors/modals';
import { Predicate } from '@property-folders/common/predicate';

export type ProcessStep = {
  name: string
  label: string
  description?: string
  enabled?: boolean
  disableSteps?: string[]
  hidden?: boolean
  unavailable?: boolean // Warning, not function block
  stepType: PurchaserProcessStepType
  sharedDocumentType?: DocumentTypePP
  availableUnauth?: boolean
};

export const PurchaserProcessStep :{[key in PurchaserProcessStepType]?: ProcessStep } = {

  [PurchaserProcessStepType.AccessBrochure]: {
    name: PurchaserProcessStepType.AccessBrochure,
    label: 'Brochure',
    description: 'View the Property Brochure',
    sharedDocumentType: 'brochure'
  },
  [PurchaserProcessStepType.Register]: {
    name: PurchaserProcessStepType.Register,
    label: 'Register',
    description: 'Register an account in the Purchaser portal',
    hidden: true
  },
  [PurchaserProcessStepType.AccessR3]: {
    name: PurchaserProcessStepType.AccessR3,
    label: 'R3',
    description: 'Access the Prescribed Notice – Form R3 – Buyers Information Notice',
    hidden: true,
    sharedDocumentType: 'prescribed-notice'
  },
  [PurchaserProcessStepType.AccessForm1]: {
    name: PurchaserProcessStepType.AccessForm1,
    label: 'Form 1',
    description: 'Access the Form 1 – Vendor’s Statement',
    sharedDocumentType: 'form-1'
  },
  [PurchaserProcessStepType.AccessContract]: {
    name: PurchaserProcessStepType.AccessContract,
    label: 'View Contract',
    description: 'Access the Template Contract',
    sharedDocumentType: 'contract'
  },
  [PurchaserProcessStepType.SubmitLetterOfOffer]: {
    name: PurchaserProcessStepType.SubmitLetterOfOffer,
    label: 'Letter of Offer',
    description: 'Submit a Letter of Offer',
    disableSteps: [PurchaserProcessStepType.SubmitContract]
  },
  [PurchaserProcessStepType.SubmitContract]: {
    name: PurchaserProcessStepType.SubmitContract,
    label: 'Contract',
    description: 'Submit an Offer (Contract)',
    disableSteps: [PurchaserProcessStepType.AccessContract, PurchaserProcessStepType.SubmitLetterOfOffer]
  }
};

export interface ProcessEditorProps {
  enableROR3?: boolean,
  steps: ProcessStep[],
  onUpdate: (steps: ProcessStep[]) => void
  processForText?: string,
  forProperty?: boolean
}

export function ProcessEditor(props: ProcessEditorProps) {
  const [showModal, setShowModal] = useState(false);
  const steps = props.steps.filter(step => step.enabled);
  const { processForText } = props;
  let forText = '';
  if (processForText) {
    forText = ` for ${processForText}`;
  }
  return <>
    <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} show={showModal} onClose={()=>setShowModal(false)}/>}>
      <ProcessEditorModal {...props} show={showModal} onHide={()=>setShowModal(false)} />
    </ErrorBoundary>
    <div className={'d-flex'}>
      <div className={'processList flat d-none d-sm-inline-block'}>
        {steps.map(step => <div key={step.name} className={'processText'}>{step.label}</div>)}
        <div className={'processListLink'}><Link to='#' className={'ms-2 align-self-end small'} onClick={()=>setShowModal(true)}>Change process{forText}</Link></div>
      </div>
      <div className={'d-sm-none w-100 d-flex flex-row justify-content-between align-items-center'}>
        {steps?.length
          ? steps.length === 1
            ? <span>{steps[0].label}</span>
            : steps.length === 2
              ? <span>{steps.at(0)?.label} &gt; {steps.at(-1)?.label}</span>
              : <span>{steps.at(0)?.label} &gt; ... &gt; {steps.at(-1)?.label}</span>
          : <span>No process configured</span>
        }
        <Link to='#' className={'small'} onClick={()=>setShowModal(true)}>Change process{forText}</Link>
      </div>
    </div>
  </>;
}

export function ProcessEditorModal(props: ProcessEditorProps & {show: boolean, onHide: ()=>void}) {
  const [steps, setSteps] = useState(props.steps);

  useEffect(() => {
    setSteps(props.steps);
  }, [props.steps]);

  const generateUnauthEnabler = ({ documentType, forcedOn, hideNA, enabled, selected }: {documentType?: string, forcedOn?: boolean, hideNA?: boolean, enabled: boolean, selected?: boolean}) => {
    if (hideNA || !enabled) {
      return null;
    }
    if (forcedOn) {
      return <FormCheck
        type={'checkbox'}
        disabled={true}
        checked={true}
        label={''}
        className={'mx-3'}
      />;
    }
    return <FormCheck
      type={'checkbox'}
      checked={selected}
      label={''}
      className={'mx-3 cursor-pointer'}
      onChange={(e) => {
        setSteps(p => {
          return p.map(ps => ps.sharedDocumentType === documentType
            ? { ...ps, availableUnauth: !e.target.checked }
            : ps
          );
        });
      }}
    />;
  };

  const r3Step = steps.find(s=>s.sharedDocumentType === 'prescribed-notice');

  const rows = [ props.enableROR3 && <div className='d-flex flex-row justify-content-between align-items-center mb-2'>
    <FormCheck
      type={'checkbox'}
      disabled={true}
      checked={true}
      label={PurchaserProcessStep[PurchaserProcessStepType.AccessR3]?.description}
    />
    {props.forProperty && generateUnauthEnabler({ enabled: true, forcedOn: false, documentType: PurchaserProcessStep[PurchaserProcessStepType.AccessR3]?.sharedDocumentType, selected: !r3Step?.availableUnauth })}
  </div>,
  ...(steps??[]).filter(step => !step.hidden).map(step => {
    const portalDocType = step.sharedDocumentType;
    return <div className='d-flex flex-row justify-content-between align-items-center mb-2'>
      <FormCheck
        key={step.name}
        id={step.name}
        type={'checkbox'}
        value={step.name}
        label={step.description}
        checked={step.enabled}
        onChange={(e) => {
          setSteps(p => {
            const checked = e.target.checked ? p.find(pp => pp.name === e.target.value) : undefined;
            return p.map(ps => ps.name === step.name
              ? { ...ps, enabled: e.target.checked }
              : checked?.disableSteps?.includes(ps.name) ? { ...ps, enabled: false } : ps);
          });
        }}
      />
      {props.forProperty && generateUnauthEnabler({ enabled: !!step.enabled, forcedOn: !portalDocType, documentType: portalDocType, selected: !step.availableUnauth })}
    </div>;
  })].filter(Predicate.isNotNullish);

  return <Modal show={props.show} onHide={props.onHide} dialogClassName='modal-md'>
    <Modal.Header closeButton className=''>
      <h3>{!props.forProperty && props.processForText ? `Process for ${props.processForText}` : 'Prospective Purchaser Process'}</h3>
    </Modal.Header>
    <Modal.Body>
      <p>You can configure exactly how prospective purchasers will engage with this property transaction here.{props.forProperty ? ' Changes made here will override individual purchaser process changes.' : ''}</p>
      <div className='d-flex flex-row justify-content-between align-items-center mb-2'>
        <div>{props.forProperty ? 'Prospective purchasers may:' : 'This purchaser may:'}</div>
        {props.forProperty && <div>Registration required</div>}
      </div>
      <div className={'ms-4'}>{rows}</div>
    </Modal.Body>
    <Modal.Footer className=''>
      <Button onClick={props.onHide} variant='outline-secondary'>Cancel</Button>
      <Button onClick={()=> { props.onUpdate(steps); props.onHide(); }} variant='primary'>Apply</Button>
    </Modal.Footer>
  </Modal>;
}
