import './SigningConfiguration.scss';

import React, { useContext, useEffect, useMemo } from 'react';
import { Predicate } from '@property-folders/common/predicate';
import {
  AgencySalesperson,
  FormInstanceSigning,
  MaterialisedPropertyData,
  SigningInitiator,
  SigningOrderSettingsItem,
  SigningParty,
  SigningPartySourceType,
  SigningPartyType,
  sourceTypeGroup,
  TransactionMetaData
} from '@property-folders/contract/property';
import * as Y from 'yjs';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { FormUtil } from '@property-folders/common/util/form';
import { Maybe } from '@property-folders/common/types/Utility';
import { IPartyDetailPaths } from './Common';
import {
  generatePartyConfigurationCardStyle,
  MemoPartyConfigurationCard
} from '@property-folders/components/dragged-components/signing/PartyConfigurationCard';
import { WizardStepPage } from '../Wizard/WizardStepPage';
import { Alert, Card, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { EntitySettingsSigningOptions } from '@property-folders/contract/yjs-schema/entity-settings';
import { getPathParentAndIndex, getValueByPath } from '@property-folders/common/util/pathHandling';
import { FormContext } from '@property-folders/components/context/FormContext';
import {
  FormCodeUnion,
  FormInstance,
  PropertyRootKey
} from '@property-folders/contract/yjs-schema/property';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { PartyGroup } from '../../hooks/useSigningNavProps';
import { fullPathJoin, useLightweightTransaction, useTransactionField } from '../../hooks/useTransactionField';
import clsJn from '@property-folders/common/util/classNameJoin';
import { Icon } from '../Icon';
import { uuidv4 } from 'lib0/random';
import { WrField } from '../form/CommonComponentWrappers';
import { materialisePropertyData } from '@property-folders/common/yjs-schema/property';
import {
  FormTypes,
  getPartyDetailPaths,
  mapSigningPartySourceTypeToCategory,
  partyCategoryToLabelStrings
} from '@property-folders/common/yjs-schema/property/form';
import { YjsDocContext } from '../../context/YjsDocContext';
import { BelongingEntityMeta, EntitySettingsExtendedType } from '@property-folders/common/redux-reducers/entityMeta';
import { useEntity } from '../../hooks/useEntity';
import { byMapperFn } from '@property-folders/common/util/sortComparison';
import { YValue } from '../form/YValue';

export function GroupedPartiesRow(props: {
  title: string,
  items?: SigningPartyForCardItem[],
  initiator?: SigningInitiator
  salespersons?: AgencySalesperson[]
  signingOptions?: EntitySettingsSigningOptions
  disabled?: {name?: boolean, email?: boolean, phone?: boolean}
  noUndefinedSalesperson?: boolean
  partiallySignedConfiguration?: boolean,
  showColours?: boolean
}) {
  if (!props.items?.length) {
    return <></>;
  }
  const items = props.items;
  const sublineages = new Set(props.items.map(item => item.signingParty.source.sublineageId).filter(x => !!x));
  const multipleSublineages = sublineages.size > 1;

  return <Row>
    <div className='grouped-party-list'>
      {multipleSublineages && <Alert variant='warning'>
        Purchasers from multiple different contracts are present. If this is intentional then please disregard.
      </Alert>}
      {items.map(item => (
        <MemoPartyConfigurationCard
          removedData={item.removedData}
          key={item.id}
          initiator={props.initiator}
          salespersons={props.salespersons}
          paths={item.paths}
          id={item.signingParty.id}
          signingOptions={props.signingOptions}
          disabled={props.disabled}
          noUndefinedSalesperson={props.noUndefinedSalesperson}
          partiallySignedConfiguration={props.partiallySignedConfiguration}
          noStyle={!props.showColours}
        />
      ))}
    </div>
  </Row>;
}

export function ReorderableGroupedPartiesRow(props: {
  title: string,
  items?: SigningPartyForCardItem[],
  initiator?: SigningInitiator
  salespersons?: AgencySalesperson[]
  signingOptions?: EntitySettingsSigningOptions
  disabled?: {name?: boolean, email?: boolean, phone?: boolean}
  noUndefinedSalesperson?: boolean
  partiallySignedConfiguration?: boolean,
  showColours?: boolean,
  /**
   * path should index to a specific signing order setting
   */
  signingOrderSettingsPath: string,
  formId: string,
  formCode: FormCodeUnion
}) {
  const groupId = useMemo(() => uuidv4(), []);
  const { ydoc, transactionMetaRootKey } = useContext(YjsDocContext);
  const { binder: metaBinder } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const { value: signingOrderSettings } = useLightweightTransaction<SigningOrderSettingsItem>({
    myPath: props.signingOrderSettingsPath,
    bindToMetaKey: true
  });

  if (!props.items?.length) {
    return <></>;
  }
  const items = props.items;
  const sublineages = new Set(props.items.map(item => item.signingParty.source.sublineageId).filter(x => !!x));
  const multipleSublineages = sublineages.size > 1;

  return <div className='grouped-party-list'>
    {multipleSublineages && <Alert variant='warning'>
        Purchasers from multiple different contracts are present. If this is intentional then please disregard.
    </Alert>}
    <DragDropContext
      onDragEnd={result => {
        console.log(result);
        if (!result.destination) return;
        if (!metaBinder) return;
        const toIndex = result.destination.index;
        const fromIndex = result.source.index;

        const orderedPartyIds = items.map(item => item.signingParty.id);
        orderedPartyIds.splice(toIndex, 0, ...orderedPartyIds.splice(fromIndex, 1));
        metaBinder.update(draft => {
          const instance = FormUtil.getFormState(props.formCode, props.formId, draft);
          if (!instance?.signing?.parties?.length) return;
          for (const party of instance.signing.parties) {
            const idx = orderedPartyIds.indexOf(party.id);
            if (idx < 0) continue;

            if (party.signingOrderSettings) {
              party.signingOrderSettings.order = idx;
            } else {
              party.signingOrderSettings = {
                order: idx,
                auto: false
              };
            }
          }
        });
      }}
    >
      <Droppable droppableId={`party-individual-droppable-${groupId}`}>
        {(dropProvided, dropSnapshot) => (
          <div
            ref={dropProvided.innerRef}
            {...dropProvided.droppableProps}
            className={clsJn({
              'mx-0 d-flex flex-column gap-3': true,
              'droppable': dropSnapshot.isDraggingOver
            })}
          >
            {items.map((item, index) => {
              return (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                >
                  {(dragProvided, dragSnapshot) => {
                    return <div
                      ref={dragProvided.innerRef}
                      {...dragProvided.draggableProps}
                      className={clsJn({
                        'bg-white': true,
                        'shadow': dragSnapshot.isDragging
                      })}
                      style={{
                        ...dragProvided.draggableProps.style,
                        ...generatePartyConfigurationCardStyle(props.showColours && item?.signingParty?.colour
                          ? item.signingParty.colour
                          : undefined),
                        display: 'grid',
                        gridTemplateColumns: '24px auto',
                        gridTemplateRows: 'auto'
                      }}
                    >
                      <div {...dragProvided.dragHandleProps} tabIndex={-1}
                        className='d-flex flex-column justify-content-center'
                        style={{
                          height: '100%'
                        }}>
                        <Icon
                          pack='material-symbols'
                          name='drag_indicator'
                          style={{ color: '#555' }}
                        />
                      </div>
                      <MemoPartyConfigurationCard
                        removedData={item.removedData}
                        key={item.id}
                        initiator={props.initiator}
                        salespersons={props.salespersons}
                        paths={item.paths}
                        id={item.signingParty.id}
                        signingOptions={props.signingOptions}
                        disabled={props.disabled}
                        noUndefinedSalesperson={props.noUndefinedSalesperson}
                        partiallySignedConfiguration={props.partiallySignedConfiguration}
                        noStyle={true}
                      />
                    </div>;
                  }}
                </Draggable>
              );
            })}
            {dropProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  </div>;
}

export type SigningPartyForCardItem = {
  id: string,
  removedData?: {
    name: any,
    email: any,
    phone: any,
    partyType: any,
    authority: any,
    linkedSalespersonId: any,
  },
  signingParty: SigningParty,
  paths: IPartyDetailPaths,
  partyTypeGroup?: SigningPartySourceType
};
export type SigningPartyForCardFunc = (signingParty: SigningParty) => undefined | SigningPartyForCardItem;

export function signingPartyForCard({
  metaPath,
  data,
  previousData
}: {
  metaPath: string | undefined,
  data: MaterialisedPropertyData | undefined,
  previousData?: MaterialisedPropertyData
}): SigningPartyForCardFunc {
  return (signingParty: SigningParty) => {

    if (!data) return undefined;

    const isRemoving = signingParty?.source?.isRemoving && previousData;
    const paths = getPartyDetailPaths(signingParty, signingParty?.source?.isRemoving && previousData ? previousData : data, metaPath || '');
    if (!paths) {
      return undefined;
    }
    let removedData;
    if (isRemoving) {
      const orignalPaths = getPartyDetailPaths(signingParty, signingParty?.source?.isRemoving && previousData ? previousData : data, metaPath || '', true);
      if (orignalPaths) {
        const partyObj = getValueByPath(orignalPaths.data.base, previousData);
        const getNamedData = (key: keyof typeof paths.data) => getValueByPath(paths.data[key], partyObj, true);
        removedData = {
          name: getNamedData('name'),
          email: getNamedData('email'),
          phone: getNamedData('phone'),
          partyType: getNamedData('partyType'),
          authority: getNamedData('authority'),
          linkedSalespersonId: getValueByPath('linkedSalespersonId', partyObj, true)
        };
      }
    }
    return {
      id: signingParty.id,
      removedData: removedData,
      signingParty,
      paths,
      partyTypeGroup: sourceTypeGroup.get(signingParty.source.type)
    };
  };
}

export function SigningPartyConfiguration({
  formCode,
  formId,
  ydoc,
  yRootKey = PropertyRootKey.Data,
  yMetaRootKey = PropertyRootKey.Meta,
  previousData,
  partyGroups,
  signingOrderVersion,
  instancePath,
  showColours
}: {
  formCode: FormCodeUnion;
  formId: string;
  ydoc: Maybe<Y.Doc>;
  yRootKey: string,
  yMetaRootKey: string,
  previousData?: MaterialisedPropertyData,
  // some callers set the name for wizardy reasons
  name?: string,
  partyGroups: PartyGroup[] | undefined,
  signingOrderVersion: number,
  instancePath: string,
  showColours?: boolean
}) {

  const {
    bindState: metaBindState
  } = useImmerYjs<TransactionMetaData>(ydoc, yMetaRootKey);
  const {
    bindState: dataBindState
  } = useImmerYjs<MaterialisedPropertyData>(ydoc, yRootKey);
  const {
    bindState: rootDataBindState
  } = useImmerYjs<MaterialisedPropertyData>(ydoc, PropertyRootKey.Data);
  const { data: meta, update: updateMeta } = metaBindState<TransactionMetaData>(m => m);
  const { data } = dataBindState<MaterialisedPropertyData>(s => s);
  const { data: rootData } = rootDataBindState<MaterialisedPropertyData>(s => s);
  const { value: signing, fullPath: signingPath } = useLightweightTransaction<FormInstanceSigning>({
    parentPath: instancePath,
    myPath: 'signing',
    bindToMetaKey: true
  });

  const localEntity = useEntity(meta?.entity?.id);

  useEffect(()=>{
    // Hopefully we don't need this. Transition should capture this. But for any in flight sessions
    // at time of deployment, we can't mislead them
    if (localEntity && updateMeta && signing && signing?.instanceAutoForm1 == null) {
      updateMeta?.(draft => {
        const { parent, indexer } = getPathParentAndIndex(instancePath, draft, true);
        const fi = parent[indexer] as FormInstance;
        if (!fi.signing) {
          return;
        }
        fi.signing.instanceAutoForm1 = !!localEntity.signingOptions?.autoServeForm1;
      });
    }
  }, [signing, signing?.instanceAutoForm1, !!localEntity, !!updateMeta]);

  const needMainDataAgents = (yRootKey !== PropertyRootKey.Data && !data?.agent);

  const metaPath = FormUtil.getFormPath(formCode, formId);
  const entityIdKey = (meta?.entity?.id ?? -1).toString();
  const { signingOptions: settingsSigningOptions } = useSelector<{ entityMeta?: BelongingEntityMeta }, EntitySettingsExtendedType | undefined>(state =>
    state?.entityMeta?.[entityIdKey]) ?? {};
  const { authRepMode } = useContext(FormContext);
  const signingOptions = useMemo(() => {
    if (FormTypes[formCode]?.disableWetSigning) {
      return {
        ...settingsSigningOptions,
        allowPaper: false
      };
    }
    return settingsSigningOptions;
  }, [settingsSigningOptions]);

  const signingPartyForCardMapFn: SigningPartyForCardFunc = (party: SigningParty) => {
    if (party.source.sublineageId) {
      return signingPartyForCard({
        metaPath,
        data: ydoc
          ? materialisePropertyData(ydoc, party.source.sublineageId)
          : undefined,
        previousData: undefined
      })(party);
    }

    return signingPartyForCard({ metaPath, data, previousData })(party);
  };

  const initiator = signing?.sessionInitiator;
  // Don't pass both in. In situations where it is an Authorised Representative managed signing experience,
  // We don't want to show "On Joe Salesperson's Screen"
  // (Joe Salesperson belongs to the requesting Agency, not the filler).
  const salespersons = ((authRepMode
    ? data?.authRep
    : (needMainDataAgents ? rootData : data)?.agent
  ) || []).map(agency => agency.salesp ?? []).flat();
  const showPartyColours = showColours != null ? showColours : !!FormTypes[formCode]?.isCustomisable;

  switch (signingOrderVersion) {
    case 2:
      return <RenderForSeparateParties
        signingPath={signingPath}
        signingPartyForCardMapFn={signingPartyForCardMapFn}
        salespersons={salespersons}
        showColours={showPartyColours}
        initiator={initiator}
        signingOptions={signingOptions}
        formCode={formCode}
        formId={formId}
      />;
    default:
      return <RenderForPartyGroups
        partyGroups={partyGroups || []}
        signingPath={signingPath}
        signingPartyForCardMapFn={signingPartyForCardMapFn}
        salespersons={salespersons}
        showColours={showPartyColours}
        initiator={initiator}
        signingOptions={signingOptions}
        formCode={formCode}
        formId={formId}
      />;
  }
}

function RenderForPartyGroups({
  signingPath,
  partyGroups,
  signingPartyForCardMapFn,
  salespersons,
  initiator,
  showColours,
  signingOptions,
  formCode,
  formId
}: {
  partyGroups: PartyGroup[],
  signingPath: string,
  signingPartyForCardMapFn: SigningPartyForCardFunc,
  salespersons: AgencySalesperson[],
  initiator: SigningInitiator | undefined,
  showColours: boolean,
  signingOptions: EntitySettingsSigningOptions | undefined,
  formCode: FormCodeUnion,
  formId: string
}) {
  const { value: signing } = useLightweightTransaction<FormInstanceSigning>({
    parentPath: signingPath,
    bindToMetaKey: true
  });
  const { value: signingOrderSettings, handleUpdate: updateSigningOrderSettings, fullPath: updateSigningOrderSettingsPath } = useTransactionField<SigningOrderSettingsItem[]>({
    parentPath: signingPath,
    myPath: 'signingOrderSettings',
    bindToMetaKey: true
  });

  if (signing?.useSigningOrder && signing?.parties?.some(p => p.type === SigningPartyType.SignOnline || p.type === SigningPartyType.SignOnlineSms)) {
    return <>
      <DragDropContext
        onDragEnd={result => {
          if (!result.destination) return;
          const toIndex = result.destination.index;
          const fromIndex = result.source.index;
          const order = partyGroups.map(pg => pg.type);
          // (splice-remove) the item from the from index, and then (splice-insert) that removed item at the to index
          order.splice(toIndex, 0, ...order.splice(fromIndex, 1));
          if (signingOrderSettings) {
            const clone = structuredClone(signingOrderSettings);
            order.forEach((o, index) => {
              const match = clone.find(s => s.type === o);
              if (match) {
                match.order = index;
              } else {
                clone.push({
                  id: uuidv4(),
                  type: o,
                  order: index,
                  auto: false
                });
              }
            });
            updateSigningOrderSettings(clone, true);
          } else {
            updateSigningOrderSettings(order.map((o, index) => ({
              id: uuidv4(),
              type: o,
              order: index,
              auto: false
            })), true);
          }
        }}
      >
        <Droppable droppableId='party-type-droppable'>
          {(dropProvided, dropSnapshot) => (
            <div
              ref={dropProvided.innerRef}
              {...dropProvided.droppableProps}
              className={clsJn({
                'mx-0 mx-sm-2 mx-md-4 party-type-droppable': true,
                'droppable': dropSnapshot.isDraggingOver
              })}
            >
              {partyGroups.map((pg, index, allGroups) => {
                const previousPartyGroup = index !== 0 ? partyGroups[index - 1] : undefined;
                const afterXText = previousPartyGroup
                  ? previousPartyGroup.parties.length === 1
                    ? `Automatic after ${previousPartyGroup.labels.singular}`
                    : `Automatic after ${previousPartyGroup.labels.plural}`
                  : '';
                const signsText = previousPartyGroup
                  ? previousPartyGroup.parties.length === 1
                    ? 'signs'
                    : 'sign'
                  : '';
                const settingsId = signingOrderSettings?.find(s => s.type === pg.type)?.id;
                const autoPath = settingsId ? `${updateSigningOrderSettingsPath}.[${settingsId}].auto` : undefined;
                const items = pg.parties.map(party => signingPartyForCardMapFn(party)).filter(Predicate.isNotNull);

                return (
                  <Draggable
                    key={pg.key}
                    draggableId={pg.key}
                    index={index}>
                    {(dragProvided, dragSnapshot) => {
                      return (
                        <Card
                          ref={dragProvided.innerRef}
                          {...dragProvided.draggableProps}
                          className='shadow'
                          style={{
                            ...dragProvided.draggableProps.style,
                            borderTop: '2px solid var(--clr-reaforms-orange)'
                          }}
                        >
                          <Card.Header className='d-flex flex-row align-items-center justify-content-between' {...dragProvided.dragHandleProps} tabIndex={-1}>
                            <div className='d-flex flex-row align-items-center gap-1'>
                              <div>
                                <Icon pack='material-symbols' name='drag_indicator'
                                  style={{ color: '#555', marginLeft: '-0.75rem' }}/>
                              </div>
                              <span className='fs-3 fw-semibold'>{pg.label}</span>
                            </div>
                            {previousPartyGroup && autoPath && pg.parties.some(party => party.type === SigningPartyType.SignOnlineSms || party.type === SigningPartyType.SignOnline)
                              ? <div className='d-flex flex-row align-items-center'>
                                <WrField.BoolCheck
                                  name={autoPath}
                                  myPath={autoPath}
                                  bindToMetaKey={true}
                                  label={afterXText}
                                  inline={false}
                                />
                                <span className='d-none d-sm-inline' style={{ marginBottom: '0.125rem' }}>&nbsp;{signsText}</span>
                              </div>
                              : <></>}
                          </Card.Header>
                          <Card.Body className='p-3'>
                            {pg.type === 'other' && settingsId && updateSigningOrderSettingsPath
                              ? <ReorderableGroupedPartiesRow
                                key={pg.key}
                                initiator={initiator}
                                salespersons={salespersons}
                                title={pg.label}
                                items={items}
                                signingOptions={signingOptions}
                                showColours={showColours}
                                signingOrderSettingsPath={`${updateSigningOrderSettingsPath}.[${settingsId}]`}
                                formCode={formCode}
                                formId={formId}
                              />
                              : <GroupedPartiesRow
                                key={pg.key}
                                initiator={initiator}
                                salespersons={salespersons}
                                title={pg.label}
                                items={items}
                                signingOptions={signingOptions}
                                showColours={showColours}
                              />}

                          </Card.Body>
                        </Card>
                      );
                    }}
                  </Draggable>
                );
              })}
              {dropProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>;
  }

  return <>
    {partyGroups.map((pg, index) => (<WizardStepPage
      key={index}
      name={pg.label}
      label={pg.label}
      icon={pg.icon}
    >
      <GroupedPartiesRow
        key={pg.key}
        initiator={initiator}
        salespersons={salespersons}
        title={pg.label}
        items={pg.parties.map(signingPartyForCardMapFn).filter(Predicate.isNotNull)}
        signingOptions={signingOptions}
        showColours={showColours}
      />
    </WizardStepPage>))}
  </>;
}

function RenderForSeparateParties({
  signingPath,
  signingPartyForCardMapFn,
  salespersons,
  initiator,
  showColours,
  signingOptions,
  formCode,
  formId
}: {
  signingPath: string,
  signingPartyForCardMapFn: SigningPartyForCardFunc,
  salespersons: AgencySalesperson[],
  initiator: SigningInitiator | undefined,
  showColours: boolean,
  signingOptions: EntitySettingsSigningOptions | undefined,
  formCode: FormCodeUnion,
  formId: string
}) {
  const { value: signing } = useLightweightTransaction<FormInstanceSigning>({
    parentPath: signingPath,
    bindToMetaKey: true
  });
  const { ydoc, transactionMetaRootKey } = useContext(YjsDocContext);
  const { binder: metaBinder } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);

  const { parties, hasOnlineType } = useMemo(() => {
    const partyNameMap = new Map<string, { path: string, sublineageId?: string }>();
    const result = new Array<{
      party: SigningParty,
      label: string,
      item: SigningPartyForCardItem,
      previousName?: { path: string, sublineageId?: string }
    }>();
    let hasOnlineType = false;
    [...signing?.parties || []]
      .sort(byMapperFn(p => p.signingOrderSettings?.order ?? 99999))
      .forEach((party, index, list) => {
        hasOnlineType = hasOnlineType || party.type === SigningPartyType.SignOnline || party.type === SigningPartyType.SignOnlineSms;

        const type = party.source.originalType
          ? party.source.originalType.replace(/(\w)(\d)/, '$1 $2')
          : mapSigningPartySourceTypeToCategory(party.source.type) || 'other';

        const label = partyCategoryToLabelStrings(
          type,
          Boolean(party.source.isAuthRep)
        ).singular;
        const item = signingPartyForCardMapFn(party);
        if (!item) return;

        partyNameMap.set(party.id, {
          path: fullPathJoin({ parentPath: item.paths.data.base, myPath: item.paths.data.name }),
          sublineageId: party.source.sublineageId
        });
        const previousPartyId = index === 0
          ? undefined
          : list.at(index - 1)?.id;
        result.push({
          party,
          label,
          item,
          previousName: previousPartyId
            ? partyNameMap.get(previousPartyId)
            : undefined
        });
      });
    return {
      parties: result,
      hasOnlineType
    };
  }, [signing]);

  if (!parties.length) {
    return <></>;
  }

  if (signing?.useSigningOrder && hasOnlineType) {
    return <DragDropContext
      onDragEnd={result => {
        if (!result.destination) return;
        if (!metaBinder) return;
        const toIndex = result.destination.index;
        const fromIndex = result.source.index;
        const orderedPartyIds = parties.map(({ party: { id } }) => id);
        if (!orderedPartyIds.length) return;
        orderedPartyIds.splice(toIndex, 0, ...orderedPartyIds.splice(fromIndex, 1));
        metaBinder.update(draft => {
          const instance = FormUtil.getFormState(formCode, formId, draft);
          if (!instance?.signing?.parties?.length) return;
          for (const party of instance.signing.parties) {
            const idx = orderedPartyIds.indexOf(party.id);
            if (idx < 0) continue;

            if (party.signingOrderSettings) {
              party.signingOrderSettings.order = idx;
            } else {
              party.signingOrderSettings = {
                order: idx,
                auto: false
              };
            }
          }
        });
      }}
    >
      <Droppable droppableId='party-droppable'>
        {(dropProvided, dropSnapshot) => (
          <div
            ref={dropProvided.innerRef}
            {...dropProvided.droppableProps}
            className={clsJn({
              'mx-0 mx-sm-2 mx-md-4 d-flex flex-column gap-3': true,
              'droppable': dropSnapshot.isDraggingOver
            })}
            style={{
              marginBottom: '1.4rem'
            }}
          >
            {parties.map(({ party, label, item, previousName }, index) => {
              return (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                >
                  {(dragProvided, dragSnapshot) => {
                    return <Card
                      ref={dragProvided.innerRef}
                      {...dragProvided.draggableProps}
                      className={clsJn({
                        'bg-white': true,
                        'shadow': dragSnapshot.isDragging
                      })}
                      style={{
                        ...dragProvided.draggableProps.style,
                        borderTop: '2px solid var(--clr-reaforms-orange)'
                      }}
                    >
                      <Card.Header className='d-flex flex-row align-items-center justify-content-between' {...dragProvided.dragHandleProps} tabIndex={-1}>
                        <div className='d-flex flex-row align-items-center gap-1'>
                          <div>
                            <Icon pack='material-symbols' name='drag_indicator'
                              style={{ color: '#555', marginLeft: '-0.75rem' }}/>
                          </div>
                          <span className='fs-3 fw-semibold'>{label}</span>
                          {item?.paths?.data?.name && <YValue<string>
                            parentPath={item.paths.data.base}
                            myPath={item.paths.data.name}
                            ydocForceKey={party.source.sublineageId}
                            bindToMetaKey={false}
                          >
                            {value => value
                              ? <span className='fs-3 fw-semibold'>- {value}</span>
                              :<></>}
                          </YValue>}
                        </div>
                        {item.paths.meta.base && index > 0 && [SigningPartyType.SignOnline, SigningPartyType.SignOnlineSms].includes(party.type) && <div>
                          <YValue<string>
                            parentPath={previousName?.path}
                            ydocForceKey={previousName?.sublineageId}
                            bindToMetaKey={false}
                          >{previousPartyName => <WrField.BoolCheck
                              name={'auto'}
                              label={`Automatic after ${previousPartyName || 'previous party'} signs`}
                              parentPath={item.paths.meta.base}
                              myPath={'signingOrderSettings.auto'}
                              bindToMetaKey={true}
                              inline={false}
                            />}</YValue>
                        </div>}
                      </Card.Header>
                      <Card.Body>
                        <MemoPartyConfigurationCard
                          removedData={item.removedData}
                          key={item.id}
                          initiator={initiator}
                          salespersons={salespersons}
                          paths={item.paths}
                          id={item.signingParty.id}
                          signingOptions={signingOptions}
                          noStyle={!showColours}
                        />
                      </Card.Body>
                    </Card>;
                  }}
                </Draggable>
              );
            })}
            {dropProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>;
  }

  return <>
    {parties.map(({ party }, index) => {
      const label = partyCategoryToLabelStrings(
        mapSigningPartySourceTypeToCategory(party.source.type) || 'other',
        false
      ).singular;
      const item = signingPartyForCardMapFn(party);
      if (!item) return <></>;
      return (<WizardStepPage
        key={index}
        name={label}
        label={label}
        icon={''}
      >
        <MemoPartyConfigurationCard
          removedData={item.removedData}
          key={item.id}
          initiator={initiator}
          salespersons={salespersons}
          paths={item.paths}
          id={item.signingParty.id}
          signingOptions={signingOptions}
          noStyle={!showColours}
        />
      </WizardStepPage>);
    })}
  </>;
}
