import 'react-bootstrap-typeahead/css/Typeahead.scss';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import { AsyncButton } from '../dragged-components/AsyncButton';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';

export interface Props {
  setShow: (show: boolean) => void;
  show: boolean;
  documentId: number | undefined;
  documentName: string | undefined;
  navigateToDocument: (documentId: number|string) => void|Promise<void>;
}

export function CloneSubscriptionDocumentModal(props: Props) {
  const { show, setShow, documentId, navigateToDocument, documentName } = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [newName, setNewName] = useState(`Copy of ${documentName}`);

  if (!documentId) {
    return <></>;
  }

  return <Modal
    show={show}
    onHide={() => setShow(false)}
    size='lg'
  >
    <Modal.Header><h3>Clone Document</h3></Modal.Header>

    <Modal.Body>
      {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
      <Form.FloatingLabel label='New Name'>
        <Form.Control value={newName} onChange={ev => { setNewName(ev.target.value); } } />
      </Form.FloatingLabel>
    </Modal.Body>

    <Modal.Footer>
      <Button variant='outline-secondary' onClick={() => setShow(false)}>Cancel</Button>

      <AsyncButton
        onClick={async () => {
          try {
            setErrorMessage('');

            const r = await AjaxPhp.cloneSubsciptionDocument({
              documentId,
              name: newName
            });

            if (!r?.documentid) {
              return;
            }

            await navigateToDocument(r.documentid);
            setShow(false);
          } catch (e) {
            console.error(e);
            setErrorMessage('An error occurred, please try again.');
          }
        }}
      >Clone</AsyncButton>
    </Modal.Footer>
  </Modal>;
}
