import { useContext, useEffect, useState } from 'react';
import { UserPreferencesMain, UserPreferencesRootKey } from '@property-folders/contract/yjs-schema/user-preferences';
import { Maybe } from '@property-folders/common/types/Utility';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { IndexeddbPersistence } from 'y-indexeddb';
import { YManagerContext } from '@property-folders/components/context/YManagerContext';
import { MaybeUpdateFn } from '@property-folders/common/types/MaybeUpdateFn';
import { AuthApi } from '@property-folders/common/client-api/auth';

interface Result {
  data: Maybe<UserPreferencesMain>,
  update: MaybeUpdateFn<UserPreferencesMain>
}

async function readyWait(
  localProvider: IndexeddbPersistence,
  done: () => void
) {
  if (localProvider.synced) {
    return done();
  }
  await localProvider.whenSynced;
  done();
}

export function useUserPreferences() {
  // this gets returned, but we want to set it after we're satisfied the document is ready to go
  // (i.e. after it's synced with local storage at least)
  const [result, setResult] = useState<Result>({ data: undefined, update: undefined });
  const { instance: yManagerInstance } = useContext(YManagerContext);

  const entry = yManagerInstance?.getUserPrefs();
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const agentId = (sessionInfo && 'agentId' in sessionInfo)
    ? sessionInfo.agentId
    : undefined;

  const {
    bindState
  } = useImmerYjs<UserPreferencesMain>(entry?.doc, UserPreferencesRootKey.Main);
  const {
    data,
    update
  } = bindState<UserPreferencesMain>(s => s);

  useEffect(() => {
    if (!(entry?.localProvider && entry?.doc && data && update)) {
      return;
    }

    readyWait(entry.localProvider, () => {
      if (update) {
        update(s => {
          if (!s.agentId && agentId) {
            s.agentId = agentId;
          }
        });
      }
      setResult({
        data,
        update
      });
    });
  }, [entry?.localProvider, entry?.doc, data, update]);

  return result;
}
