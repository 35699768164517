import 'react-bootstrap-typeahead/css/Typeahead.scss';
import { Alert, Button, Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { AsyncButton } from '../dragged-components/AsyncButton';
import { BaseAjaxResponse, LegacyApi } from '@property-folders/common/client-api/legacyApi';
import Select from 'react-select';

export interface AssignAgentModalProps {
  setShow: (show: boolean) => void;
  show: boolean;
  documentId: number | undefined;
  refetch?: () => Promise<unknown>;
}

interface AjaxResponseAgent {
  AgentID: number;
  AgentName: string;
}
interface GetAgentsResponse extends BaseAjaxResponse {
  agents: AjaxResponseAgent[];
  selected: AjaxResponseAgent[];
}

export function AssignAgentModal(props: AssignAgentModalProps) {
  const { show, setShow, documentId, refetch } = props;
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<AjaxResponseAgent[]>([]);
  const [selected, setSelected] = useState<AjaxResponseAgent[]>([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!documentId) return;

    setLoading(true);
    setErrorMessage('');
    const ac = new AbortController();

    LegacyApi.ajax<GetAgentsResponse>('getagents', {
      DocumentID: documentId.toString()
    }, ac.signal)
      .then(data => {
        if (ac.signal.aborted) return;
        setOptions(data.agents);
        setSelected(data.selected);
      })
      .catch(err => {
        console.error(err);
        setErrorMessage('Could not load list of assigned agents');
      })
      .finally(() => setLoading(false));

    return () => ac.abort();
  }, [documentId]);

  if (!documentId) {
    return <></>;
  }

  return <Modal
    show={show}
    onHide={() => setShow(false)}
    size='lg'
  >
    <Modal.Header><h3>Assign Agents</h3></Modal.Header>

    <Modal.Body>
      {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
      <Select
        isMulti={true}
        options={options}
        value={selected}
        isClearable={true}
        isLoading={loading}
        isDisabled={loading}
        getOptionValue={item => item.AgentID.toString()}
        getOptionLabel={item => item.AgentName}
        onChange={items => {
          setSelected([...items]);
        }}
        styles={{
          control: baseStyles => ({
            ...baseStyles,
            background: 'rgb(244, 245, 250)',
            border: '1px solid rgb(134, 142, 150)',
            borderRadius: 0,
            height: '100%'
          })
        }}
      />
    </Modal.Body>

    <Modal.Footer>
      <Button variant='outline-secondary' onClick={() => setShow(false)}>Cancel</Button>

      <AsyncButton
        onClick={async () => {
          await LegacyApi.setDocumentAssignments(
            documentId,
            selected.map(a => a.AgentID)
          );

          if (refetch) {
            await refetch();
          }

          setShow(false);
        }}
      >Apply</AsyncButton>
    </Modal.Footer>
  </Modal>;
}
