import React, { useContext } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { FormUtil, generateInitiator } from '@property-folders/common/util/form';
import { WrField } from '@property-folders/components/dragged-components/form/CommonComponentWrappers';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import {
  FormCodeUnion,
  MaterialisedPropertyData,
  SigningInitiator,
  TransactionMetaData
} from '@property-folders/contract';
import { PropertyFormYjsDal } from '@property-folders/common/yjs-schema/property/form';
import {
  emailSubject,
  generateHeadlineFromMaterialisedData,
  getDocumentName
} from '@property-folders/common/yjs-schema/property';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { useSelector } from 'react-redux';
import { reaformsOrange } from '@property-folders/common/visual';
import './SigningPartyGeneralConfiguration.scss';
import { Predicate } from '@property-folders/common/predicate';
import { EntitySettingsEntity } from '@property-folders/contract/yjs-schema/entity-settings';
import { useEntity } from '@property-folders/components/hooks/useEntity';
import { companyTradingWithFallback } from '@property-folders/common/util/formatting';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import '../../display/EmailPreview.scss';

export function SigningGeneralConfiguration({
  formCode,
  formId,
  entityLogoLoadedUri,
  overrideInitiator,
  nextButton
}: {
  formCode: string;
  formId: string;
  entityLogoLoadedUri?: string;
  overrideInitiator?: SigningInitiator;
  nextButton: JSX.Element
}) {

  const { ydoc, transactionMetaRootKey } = useContext(YjsDocContext);
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const { bindState: bindMetaState } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const {
    data: metaData
  } = bindMetaState(meta => meta);
  const localEntity = useEntity(metaData?.entity?.id);
  const metaPath = FormUtil.getFormPath(formCode, formId);
  const { value: transRoot } = useLightweightTransaction<MaterialisedPropertyData|undefined>({ myPath: '' });

  const instance = metaData && PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, metaData);
  const documentName = getDocumentName(formCode as FormCodeUnion, instance);
  const aDocumentName = (['a','e','i','o','u'].includes(documentName.slice(0,1).toLowerCase()) ? 'an' : 'a') + ` ${documentName}`;
  const addressSummary = generateHeadlineFromMaterialisedData(transRoot);
  const subjectPlaceholder = emailSubject(documentName, addressSummary);

  const entityId = metaData?.entity?.id;
  const { brand } = useSelector(state=> state?.entityMeta?.[(entityId??-1)?.toString()] as EntitySettingsEntity | undefined) ?? {};

  const initiator = overrideInitiator ?? instance?.signing?.sessionInitiator ?? generateInitiator(metaData, sessionInfo, localEntity);

  // Convert plain text into formatted paragraphs, which the emailer does too, albeit with different
  // code because it is not react.
  const genMessage = instance?.signing?.general?.message
    ?.split(/\n\n+/).filter(Predicate.isTruthy).map(para => {
      return <p>{para.split(/\n/).filter(Predicate.isTruthy).map((line, idx, { length }) => idx < length -1
        ? <>{line}<br /></>
        : line
      )}</p>;
    });

  const entityName = companyTradingWithFallback(localEntity?.name, localEntity?.tradeName);

  return <div className={'container signing-configuration'}>

    <Row>
      <Col lg={12}>
        <WrField.Text
          parentPath={metaPath}
          myPath={'signing.general.subject'}
          name={'signing.general.subject'}
          bindToMetaKey={true}
          placeholder={subjectPlaceholder}
          label={'Email Subject'}
          maxLength={100}
          showLengthRemaining={true}
        />
      </Col>
    </Row>
    <div className='mb-4 email-preview-container d-flex justify-content-center'>
      <div className='centerline'>
        <div className='whitebox'>
          <div><Image className='email-preview-logo' src={entityLogoLoadedUri} /></div>
          <div className='brand-box' style={{ backgroundColor: brand?.content?.backgroundColour || reaformsOrange, color: brand?.content?.foregroundColour || 'white' }}>
            <p className='mt-2'>{initiator.name} from {entityName} has sent you {aDocumentName} for {addressSummary} to review and sign electronically.</p>
            <div className='d-flex justify-content-center'><div className='fake-button' style={{ backgroundColor: brand?.button?.backgroundColour || 'white', color: brand?.button?.foregroundColour || reaformsOrange }}>REVIEW AND SIGN</div></div>
          </div>
          <div>
            {genMessage && <p>{genMessage}</p>}

            <p>Should you have any questions in relation to the document or the electronic signing process, please contact:</p>
            <p>
              <b>{initiator.name}</b>
              <br />
              <a href='#'>{initiator.email}</a>
            </p>

          </div>
        </div>
      </div>
    </div>

    <Row>
      <Col lg={12} className={'mb-2'}>The general message entered below will be sent to recipients who have not been provided with a Personal Message.</Col>
    </Row>

    <Row>
      <Col lg={12}>
        <WrField.TextArea
          parentPath={metaPath}
          myPath={'signing.general.message'}
          name={'signing.general.message'}
          bindToMetaKey={true}
          label={'Email Message'}
          maxLength={1000}
          showLengthRemaining={true}
          rows={6}
          minHeight={'105px'}
        />
      </Col>
    </Row>
    <Row>
      <Col className={'d-flex flex-row-reverse'}>
        {nextButton}
      </Col>
    </Row>
  </div>;
}
