import { Button, Modal } from 'react-bootstrap';
import FormCheck from 'react-bootstrap/FormCheck';
import { useState } from 'react';
import { PropertyAddress } from '@property-folders/common/client-api/LssaCommon';
import { PropertySearchApi } from '@property-folders/common/client-api/propertySearchApi';
import { v4 } from 'uuid';

export interface Props {
  locations: PropertyAddress[];
  onSelection: (PropertyAddress: PropertyAddress[], ignoredAddresses: PropertyAddress[]) => any;
  visible: boolean;
  setVisible: (v: boolean) => void;
}

export function MultipleLocationsDetectedModal({ locations, onSelection, visible, setVisible }: Props) {
  const [selectedLocations, setSelectedLocations] = useState<PropertyAddress[]>(locations);

  return <Modal show={visible} onHide={() => { setVisible(false);} } centered={true}>
    <Modal.Header>
      <h4>Multiple Locations Detected</h4>
    </Modal.Header>
    <Modal.Body>
      <p>We have identified multiple locations that match the address entered. This may be because an easement affects this and neighbouring properties.</p>
      <p>Please indicate whether these addresses should be part of the property transaction:</p>

      {locations.map(l => {
        const address = PropertySearchApi.stringifyPropertyAddressImmutable(l);
        const id = v4();
        return <FormCheck
          key={id}
          id={id}
          name='multiple-locations-detected'
        >
          <FormCheck.Input
            type='checkbox'
            checked={selectedLocations.indexOf(l) !== -1}
            value={id}
            onChange={e => {
              setSelectedLocations(sl => e.target.checked ? [...new Set([...sl, l])] : sl.filter(t => t !== l));
            }}
          />
          <FormCheck.Label className='d-flex w-100'>
            <span>{address}</span>
          </FormCheck.Label>
        </FormCheck>;})}
      <div className='d-flex justify-content-end mt-3'>
        <Button onClick={() => { setVisible(false); onSelection(selectedLocations, locations.filter(l => !selectedLocations.includes(l))); }}>Confirm</Button>
      </div>
    </Modal.Body>
  </Modal>;
}
