import { defaultSettlementDays } from '@property-folders/common/data-and-text/constants';
import { Predicate } from '@property-folders/common/predicate';
import { MaterialisedPropertyData } from '@property-folders/contract';
import { UpdateFn } from 'immer-yjs';
import { useEffect } from 'react';

export function useSetAuctionSettlement(transRoot?: MaterialisedPropertyData, updateDraft?: ((userFunc: UpdateFn<MaterialisedPropertyData>) => void) | undefined) {
  // Effect to set settlement date to the agreed settlement period after an auction with a set date
  useEffect(()=>{
    const settlementDays = transRoot?.contractSettlement?.onContractDays || defaultSettlementDays;
    if (transRoot?.contractSettlement?._auctionSettlementTimeTriggered) return;
    if (
      Predicate.isNullish(transRoot?.contractSettlement?.date)
    && transRoot?.sale?.saleMethod === 'auction'
    && transRoot?.auction?.propDate
    && settlementDays
    && settlementDays > 0
    ) {
      updateDraft?.(draft => {
        if (!draft.contractSettlement) draft.contractSettlement = {};
        const d = new Date(draft.auction?.propDate);
        d.setDate(d.getDate()+settlementDays);
        // toISOString may return in UTC, which could result in odd date alignment
        const tzDateString = `${d.getFullYear()}-${(d.getMonth()+1).toString().padStart(2,'0')}-${(d.getDate()).toString().padStart(2,'0')}`;
        draft.contractSettlement.date = tzDateString;
        draft.contractSettlement.onDate = true;
        delete draft.contractSettlement.onContract;
        draft.contractSettlement._auctionSettlementTimeTriggered = true;
      });
      transRoot?.contractPrice?.depositPayAt;
    }
  }, [transRoot?.contractSettlement?.onContractDays, transRoot?.sale?.saleMethod, transRoot?.auction?.propDate, transRoot?.contractSettlement?.date]);
}