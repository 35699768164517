import { useTransactionField } from '../../hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { WrField } from './CommonComponentWrappers';
import '../Form.scss';

import { CollectionRemoveButton } from './CollectionRemoveButton';
import { portionOptionsExpanded } from '@property-folders/common/util/pdfgen/constants';
import { SaleSubTitle, SaleTitle, TitleInclusionState } from '@property-folders/contract';
import { wholePortionsOnly } from './NarrowTitle';
import { getLinkedTitles } from '@property-folders/common/yjs-schema/property/validation/expected-evaluator';

type SalesRepInputProps = TransactionConsumerProps & {
  removable?: boolean,
  hideDelete?: boolean
  allTitles?: SaleTitle[]
};

export const NarrowPlanInput = ({ removable = true, hideDelete = false, ...restProps }: SalesRepInputProps): JSX.Element => {
  const { value, fullPath, handleRemove } = useTransactionField<SaleSubTitle>(restProps);
  const parentTitle = (restProps.allTitles ?? []).find(t => (t.subTitles ?? []).find(st => value && st.id === value.id));
  const linkedTitles = getLinkedTitles(parentTitle, restProps.allTitles, true);
  const collection = linkedTitles.flatMap(t => t.subTitles);

  const options: Partial<typeof portionOptionsExpanded> = { ...portionOptionsExpanded };
  if (collection?.length === 1) {
    delete options[TitleInclusionState.none];
  }
  if (value.portionType !== TitleInclusionState.none && collection?.filter(item=>item.portionType !== TitleInclusionState.none).length === 1) {
    delete options[TitleInclusionState.none];
  }

  const closeButton = handleRemove && <CollectionRemoveButton removable={removable} onRemove={handleRemove} />;

  return (
    <div className="d-flex w-100">
      <div className='w-100'>
        <div className="d-flex w-100 flex-wrap align-items-center">
          {wholePortionsOnly
            ? null
            : <WrField.Select
              name="portionType"
              options={options}
              valueType='int'
              parentPath={fullPath}
              myPath='portionType'
              canClear={false}
            />}
          <div className='ps-3'>
            {wholePortionsOnly ? options[value.portionType] : null} {value?.lot} {value?.lotid} on {value?.plan} {value?.planid}
          </div>

        </div>
      </div>
      {(!hideDelete) && <div className='d-flex align-items-center delete-div idk'>
        {closeButton}
      </div>}
    </div>
  );
};
