import React, { useEffect, useMemo, useRef } from 'react';
import { useDrag } from 'react-dnd';
import type { SourceType } from 'dnd-core';
import { getEmptyImage } from 'react-dnd-html5-backend';

export interface DraggablePositionedProvided {
  innerRef: (element: HTMLElement | null) => any;
  isDragging: boolean;
}

type OtherAttrs = Record<string, string | number>;
export function DraggablePositioned<TObjectType>({
  children,
  type,
  item,
  hideDefaultPreview = true,
  otherAttrs
}: {
  type: SourceType,
  item: TObjectType,
  children(provided: DraggablePositionedProvided): React.ReactElement<HTMLElement>,
  hideDefaultPreview?: boolean,
  otherAttrs?: OtherAttrs
}) {
  const ref = useRef<Element>();
  const [{ isDragging }, dragRef, preview] = useDrag<TObjectType, unknown, {
    isDragging: boolean;
    otherAttrs?: OtherAttrs
  }>(() => ({
    type,
    item: { ...otherAttrs, ...item },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      otherAttrs
    })
  }), [item, JSON.stringify(otherAttrs)]);

  useEffect(() => {
    if (hideDefaultPreview) {
      preview(getEmptyImage());
    } else {
      preview(null);
    }
  }, [hideDefaultPreview]);

  const provided = useMemo<DraggablePositionedProvided>(() => {
    return {
      innerRef: (elem: HTMLElement | null) => {
        ref.current = elem ? elem : undefined;
        dragRef(elem);
      },
      isDragging
    };
  }, [isDragging]);

  return children(provided);
}

