import { Inclusions as InclusionsType } from '@property-folders/contract';
import { useTransactionField } from '@property-folders/components/hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import {
  boolYesNoOpts,
  chattelsOptionsSaa,
  OPT_CCC
} from '@property-folders/common/data-and-text/constants';
import { CheckMultiSelect } from '../CheckMultiSelect';
import { WrField } from '../CommonComponentWrappers';
import { WizardDisplayContext } from '@property-folders/components/context/WizardContexts';
import { useContext } from 'react';
import { composeErrorPathClassName } from '@property-folders/common/util/formatting';
import clsJn from '@property-folders/common/util/classNameJoin';
import { TooltipWhenRequired } from '@property-folders/components/dragged-components/TooltipWhenDisabled';

const requiredTipText = 'Specify included chattels. Select None Applicable if there are none';

interface Props {
  inclusions: typeof chattelsOptionsSaa;
  purchaserMode?: boolean;
  contractMode?: boolean
}

export const Inclusions = (props: TransactionConsumerProps & Props) => {
  const { value: inclusions, fullPath, required } = useTransactionField<InclusionsType>(props);
  const { showFocusErrors, focusErrList } = useContext(WizardDisplayContext);
  const showInclusionsError = showFocusErrors && focusErrList.includes(composeErrorPathClassName([...fullPath.split('.'), 'simple'], ''));
  const errorFocusTarget = composeErrorPathClassName([...fullPath.split('.'), 'simple'], '');
  return <div className={clsJn('subsection scrollspy-target', errorFocusTarget)} data-focus-path="subsection-inclusions">
    <TooltipWhenRequired title={requiredTipText??''} required={required}>
      <div className='fs-4 mb-3'>Inclusions{required ? <sup className='fs-5' style={{ color: 'red' }}> *</sup> : null}</div>
    </TooltipWhenRequired>
    {showInclusionsError && <div className='d-block invalid-feedback mb-3'>{requiredTipText}</div>}
    <CheckMultiSelect
      options={props.inclusions}
      parentPath={fullPath}
      myPath='simple'
      allowOthers={true}
      otherAddText='Add Inclusion'
      otherTitle='Other Inclusions'
    />
    {!props.contractMode && !props.purchaserMode && <div className='d-flex'>
      <div className='mt-2 me-2'>
        <WrField.CheckRadio
          radioType='checkbox'
          label='Are there any consumer credit chattels?'
          name='cccEnable'
          myPath='cccEnable'
          parentPath={fullPath}
          valueType='boolean'
          options={boolYesNoOpts}
          titleGuidanceNoteKey='consumerCreditChattels'
        />

      </div>

      {inclusions?.cccEnable &&<div className='mt-2 flex-grow-1' style={{ width: '300px' }} >
        <div className='flex-grow-1'><WrField.Control label={'Details of '+OPT_CCC} parentPath={fullPath} myPath='cccDetail' name='cccDetail'/></div>

      </div>}
    </div>}

  </div>;
};
