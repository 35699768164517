import { useLightweightTransaction } from '../../hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import {
  boolApplicableOpts,
  boolKnownOpts,
  chattelsOptionsSaa,
  quadStateYesNoUnknownOpts
} from '@property-folders/common/data-and-text/constants';
import '../Form.scss';
import { WrField } from './CommonComponentWrappers';
import { Exclusions } from './SubSections/Exclusions';
import { Inclusions } from './SubSections/Inclusions';
import { PoolCompliance } from './SubSections/PoolCompliance';
import { TenancySubsection } from './TenancySubsection';
import { MaterialisedPropertyData } from '@property-folders/contract';
import { ShowGuidanceNotesButton } from '../guidance/ShowGuidanceNotesButton';
import { Predicate } from '@property-folders/common/predicate';
import { vendorWorksFieldPlaceholder } from '@property-folders/common/util/formatting/constants';
import { QuadStateDualBoolCheckboxes } from '../../display/form/QuadStateRadioCheckboxes';
import { GstWithholding } from '../../display/form/GstWithholding';
import { ContractSettlement } from './SaleContract/ContractSettlement';

export const ParticularsInput = (props: TransactionConsumerProps): JSX.Element => {
  const { value: transRoot, fullPath } = useLightweightTransaction<MaterialisedPropertyData>(props);

  return (
    <div className="d-flex w-100 gapped-row">
      <div className='w-100'>
        <div className="d-flex w-100 flex-wrap subsection scrollspy-target"
          data-focus-path="subsection-settlement-period">
          <ContractSettlement myPath='contractSettlement' saaMode={'true'} />
        </div>
        <Inclusions parentPath={fullPath} myPath='chattels' inclusions={chattelsOptionsSaa}/>
        <Exclusions parentPath={fullPath} myPath='exclusions'/>

        <div className='subsection scrollspy-target' data-focus-path="subsection-tenancy">
          <TenancySubsection/>
        </div>
        <div className='subsection scrollspy-target' data-focus-path="subsection-encroach">
          <div className='fs-4 d-flex align-items-center'>Encroachments and fences not on
            boundaries <ShowGuidanceNotesButton noteId='knownEncroachments'/></div>
          <div className="w-100 mt-2">
            <WrField.CheckRadio
              radioType='checkbox'
              options={boolKnownOpts}
              valueType='boolean'
              inline={true}
              name='encroachEnable'
              myPath='encroachEnable'
            />
          </div>
          {transRoot?.encroachEnable && <div className='w-100 mt-2'>
            <WrField.Control textArea={{ rows: 3 }} name='encroach' myPath='encroach' label='Known encroachments' maxLength={1000}/>
          </div>}
        </div>
        <div className='subsection scrollspy-target' data-focus-path="subsection-noti-works">
          <div className='fs-4 d-flex align-items-center'>
            Alterations and improvements erected without consent
            <ShowGuidanceNotesButton noteId='alterationsWithoutConsent'/>
          </div>
          <div className="w-100 mt-2">
            <WrField.CheckRadio
              radioType='checkbox'
              options={boolKnownOpts}
              valueType='boolean'
              inline={true}
              name='notiWorksEnable'
              myPath='notiWorksEnable'
            />
          </div>
          {transRoot?.notiWorksEnable && <div className='w-100 mt-2'>
            <WrField.Control textArea={{ rows: 3 }} name='notiWorks' myPath='notiWorks' label='Alterations' maxLength={1000}/>
          </div>}
        </div>

        <div className='subsection scrollspy-target' data-focus-path="subsection-vendor-works">
          <div className='fs-4 mb-3'>Vendor’s Works</div>

          <div className="w-100 mt-2">
            <WrField.CheckRadio
              radioType='checkbox'
              options={boolApplicableOpts}
              valueType='boolean'
              inline={true}
              name='vendorWorksEnable'
              myPath='vendorWorksEnable'
            />
          </div>
          {transRoot?.vendorWorksEnable && <div className='w-100 mt-2'>
            <WrField.Control textArea={{ rows: 3 }} name='vendorWorks' myPath='vendorWorks' label={vendorWorksFieldPlaceholder} maxLength={1000} />
          </div>}

        </div>

        <GstWithholding />

        <div className='subsection scrollspy-target' data-focus-path="subsection-gst-rego">
          <div className='fs-4 d-flex align-items-center'>
            GST Conditions of Sale <ShowGuidanceNotesButton noteId='gstReg'/>
          </div>
          <div className='d-flex w-100 mt-2'>
            <QuadStateDualBoolCheckboxes
              label='Is the Vendor registered, or required to be registered, for GST?'
              options={quadStateYesNoUnknownOpts}
              yesNoBoolRelPath='vendorGst'
              unknownBoolRelPath='saaGstUnknowns.vendorGst'
              inline={true}
            />
          </div>

          {Predicate.boolFalse(transRoot?.vendorGst) && <div className='d-flex w-100 mt-2'>GST does not apply.</div>}
          {(transRoot?.vendorGst
            || transRoot?.saaGstUnknowns?.vendorGst
          )  && <>
            <div className='d-flex w-100 mt-2'>
              <QuadStateDualBoolCheckboxes
                label='Is the sale the supply of an existing ‘residential premises’, which is input taxed?'
                titleGuidanceNoteKey='inputTaxedPremises'
                options={quadStateYesNoUnknownOpts}
                yesNoBoolRelPath='contractPrice.inputTaxed'
                unknownBoolRelPath='saaGstUnknowns.inputTaxed'
                inline={true}
              />
            </div>
            {transRoot?.contractPrice?.inputTaxed && <div className='d-flex w-100 mt-2'>GST does not apply.</div>}
            {(Predicate.boolFalse(transRoot?.contractPrice?.inputTaxed) || transRoot?.saaGstUnknowns?.inputTaxed) && <div className='d-flex w-100 mt-2'>
              The Sale may be considered a taxable supply.
              The parties will need to determine if the margin scheme is to apply or if a relevant GST exemption applies
              (e.g. the supply of a going concern, or farmland supplied for a farming business).
              The Vendor should obtain the advice of an accountant or tax advisor.
            </div>}
            {!transRoot?.contractPrice?.inputTaxed && transRoot?.vendorGst !== false && (transRoot?.saaGstUnknowns?.inputTaxed || transRoot.saaGstUnknowns?.vendorGst)  && <div className='d-flex w-100 mt-2'>The Vendor advises it is seeking instructions in relation to the applicable GST treatment on the Sale.</div>}
          </>}
          <div className='d-flex w-100 mt-2'>The Agent is not qualified to advise on the appropriate GST treatment. The
            Vendor and Purchaser must obtain their own independent professional advice in relation to the appropriate
            GST treatment.
          </div>
        </div>

        <PoolCompliance saaMode={true}/>
      </div>
    </div>
  );
};
