import { useCallback, useMemo, useState } from 'react';
import { Button, Col, FloatingLabel, Form, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { SigningStateEntryMode } from '../SigningState';
import { SelectSelfDrawnSignature } from './SelectSelfDrawnSignature';
import { SelectSystemGeneratedSignature } from './SelectSystemGeneratedSignature';
import { SelectUploadedSignature } from '@property-folders/components/dragged-components/signing/adoptSignature/SelectUploadedSignature';
import './AdoptSignatureModal.scss';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '../../../display/errors/modals';

interface SignatureState {
  fullName: string;
  initials: string;
  initialsImage?: string;
  signatureImage?: string;
}

export enum AdoptSignatureMode {
  Both,
  Signature,
  Initials
}

export interface AdoptSignatureModalProps {
  initialState?: Partial<SignatureState>;
  onSave: (newState: SignatureState) => void;
  onCancel: () => void;
  mode: SigningStateEntryMode
}

function AdoptHeader({ mode }: {mode: SigningStateEntryMode}) {
  switch (mode) {
    case SigningStateEntryMode.AdoptSignature:
      return <Modal.Header><h3 className={'m-0'}>Adopt your signature</h3></Modal.Header>;
    case SigningStateEntryMode.AdoptInitials:
      return <Modal.Header><h3 className={'m-0'}>Adopt your initials</h3></Modal.Header>;
  }
}

type ImagePair =
  | undefined
  | { signature?: string, initials?: string };

export function AdoptSignatureModal(props: AdoptSignatureModalProps) {
  return <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} onClose={props.onCancel} />}>
    <AdoptSignatureModalUnwrapped {...props} />
  </ErrorBoundary>;
}

function AdoptSignatureModalUnwrapped(props: AdoptSignatureModalProps) {
  const [initials, setInitials] = useState(props.initialState?.initials);
  const [fullName, setFullName] = useState(props.initialState?.fullName);
  const [selectedTab, setSelectedTab] = useState('0');
  const [generated, setGenerated] = useState<ImagePair>(undefined);
  const onGeneratedChanged = useCallback((signatureImage?: string, initialsImage?: string) => {
    setGenerated({ signature: signatureImage, initials: initialsImage });
  }, []);
  const [drawn, setDrawn] = useState<ImagePair>(undefined);
  const onDrawnChanged = useCallback((signatureImage?: string, initialsImage?: string) => {
    setDrawn({ signature: signatureImage, initials: initialsImage });
  }, []);
  const [uploaded, setUploaded] = useState<ImagePair>(undefined);
  const onUploadedChanged = useCallback((signatureImage?: string, initialsImage?: string) => {
    setUploaded({ signature: signatureImage, initials: initialsImage });
  }, []);

  const valid = useMemo(() => {
    if (!initials) return false;
    if (!fullName) return false;

    switch (selectedTab) {
      case '0':
        return !!generated?.signature || !!generated?.initials;
      case '1':
        return !!drawn?.signature || !!drawn?.initials;
      case '2':
        return !!uploaded?.signature || !!uploaded?.initials;
      default:
        return false;
    }
  }, [initials, fullName, selectedTab, generated, drawn, uploaded]);

  const onAdoptClick = () => {
    const emitOnSave = (initials?: string, fullName?: string, images?: ImagePair) => {
      if (!(initials && fullName)) return;
      if (!(images?.signature || images?.initials)) return;

      props.onSave({
        fullName,
        initials,
        signatureImage: images.signature,
        initialsImage: images.initials
      });
    };

    switch (selectedTab) {
      case '0':
        return emitOnSave(initials, fullName, generated);
      case '1':
        return emitOnSave(initials, fullName, drawn);
      case '2':
        return emitOnSave(initials, fullName, uploaded);
      default: break;
    }
  };

  const { mode } = props;

  return <Modal id='adopt-signature-modal' show={true} onClose={props.onCancel} size={'lg'}>
    <AdoptHeader mode={mode} />
    <Modal.Body>
      <p>Confirm your name, initials, and image.</p>
      <Row>
        <Col>
          <Form.Group>
            <FloatingLabel label={'Full Name'}>
              <Form.Control required isInvalid={!fullName} type={'text'} value={fullName} onChange={e => setFullName(e.target.value)} />
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <FloatingLabel label={'Initials'}>
              <Form.Control required isInvalid={!initials} type={'text'} value={initials} onChange={e => setInitials(e.target.value)} />
            </FloatingLabel>
            <Form.Control.Feedback type={'invalid'}>Initials are required.</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <br />
      <Tabs
        onSelect={key => setSelectedTab(key || '0')}
        activeKey={selectedTab}
        transition={false}
        justify
      >
        <Tab eventKey={'0'} title={'Select Style'}>
          <SelectSystemGeneratedSignature
            fullName={fullName}
            initials={initials}
            mode={mode}
            onImagesChanged={onGeneratedChanged} />
        </Tab>
        <Tab eventKey={'1'} title={'Draw'}>
          <SelectSelfDrawnSignature
            mode={mode}
            onImagesChanged={onDrawnChanged}
            isVisible={selectedTab === '1'}/>
        </Tab>
        <Tab eventKey={'2'} title={'Upload'}>
          <SelectUploadedSignature
            mode={mode}
            onImagesChanged={onUploadedChanged}
            isVisible={selectedTab === '2'}
          />
        </Tab>
      </Tabs>
    </Modal.Body>
    <Modal.Footer className={'position-sticky bottom-0'} style={{ backgroundColor: 'inherit' }}>
      <Button variant={'secondary'} onClick={props.onCancel}>Cancel</Button>
      <Button disabled={!valid} onClick={onAdoptClick}>Adopt & Sign</Button>
    </Modal.Footer>
  </Modal>;
}
