import { CustomFieldDetails, DraggableType } from './types';
import { customFieldMetas, defaultCheckmark } from '@property-folders/contract/property/meta';
import React from 'react';
import { CustomFieldConfiguration, CustomFieldType } from '@property-folders/contract';
import { SupportedFontFamily, TextDimensionEstimator } from '@property-folders/common/util/pdf';

export function getStyles(details: CustomFieldDetails, scale: number) {
  const meta = customFieldMetas[details.type];
  const generated: React.CSSProperties = {
    height: (meta.signingSessionFieldType || meta.formField) ? undefined : '100%',
    paddingTop: '0.1em',
    whiteSpace: 'pre'
  };
  if (details.type === CustomFieldType.checkmark) {
    generated.fontFamily = 'DejaVu Sans';
  }

  if (details.mode === DraggableType.Existing) {
    if (details.type === CustomFieldType.remoteText) {
      if (details.multiline) {
        generated.height = '100%';
        generated.textWrap = 'wrap';
      }
    }

    for (const attr of meta.attributes) {
      switch (attr.name) {
        case 'fontSize':
          generated.fontSize = formatNumericAttribute(attr.name, details, value => `${scale * value}px`);
          if (meta.resize === 'auto') {
            generated.lineHeight = 1;
          }
          break;
        case 'lineHeight':
          generated.lineHeight = formatNumericAttribute(attr.name, details, value => `${scale * value}px`);
          break;
        case 'fontFamily':
          generated.fontFamily = getStringAttribute(attr.name, details);
          break;
      }
    }
  }
  return generated;
}

export function formatNumericAttribute<TFormatted>(name: string, field: CustomFieldConfiguration, formatter: (value: number) => TFormatted | undefined): TFormatted | undefined {
  if (!(name in field)) return undefined;

  const value = (field as any)[name];
  if (value == null) return undefined;

  if (typeof value !== 'number') return undefined;

  return formatter(value);
}

export function getStringAttribute(name: string, field: CustomFieldConfiguration): string | undefined {
  if (!(name in field)) return undefined;

  const value = (field as any)[name];
  if (value == null) return undefined;

  if (typeof value !== 'string') return undefined;

  return value;
}

export function autoResize(field: CustomFieldConfiguration, {
  force,
  estimator
}: {
  force?: boolean,
  estimator: TextDimensionEstimator
}) {
  const auto = customFieldMetas[field.type].resize === 'auto';
  if (!(force || auto)) return;
  field.position = estimatePosition(field, { estimator });
}

export function estimatePosition(field: CustomFieldConfiguration, { estimator }: { estimator: TextDimensionEstimator }) {
  switch (field.type) {
    case CustomFieldType.checkmark:
      return {
        ...field.position,
        ...estimator.estimateTextDimensions(field.checkmark, SupportedFontFamily.DejaVuSansCheckmarks, field.fontSize, { nopad: true })
      };
    // maybe others later
    case CustomFieldType.remoteRadio:
    case CustomFieldType.remoteCheck:
      if (field.label.trim()) {
        return {
          ...field.position,
          ...estimator.estimateTextDimensions(`${defaultCheckmark}   ${field.label}`.trim(), field.fontFamily, field.fontSize)
        };
      } else {
        const { height } = estimator.estimateTextDimensions(`${defaultCheckmark}`, field.fontFamily, field.fontSize);
        // noinspection JSSuspiciousNameCombination we want it to be square
        return {
          ...field.position,
          ...{
            height,
            width: height
          }
        };
      }
    case CustomFieldType.remoteText:
      if (field.multiline) {
        // until we support number-of-lines specified, we can't really estimate the dimensions.
        // currently text box sizes are still manually dragged
        return field.position;
      } else {
        return {
          ...field.position,
          ...estimator.estimateTextDimensions('Remote Text Field With Dangly', field.fontFamily, field.fontSize, { hFudge: 1.1 })
        };
      }
    default:
      return field.position;
  }
}

export function afterAttrChange({
  field,
  name,
  estimator,
  all
}: {
  field: CustomFieldConfiguration,
  name: string,
  estimator: TextDimensionEstimator,
  all: CustomFieldConfiguration[]
}) {
  console.log('afterAttrChange', field);
  const meta = customFieldMetas[field.type];
  const attr = meta.attributes.find(a => a.name === name);
  if (!attr?.afterEdit) return;

  switch (attr.afterEdit) {
    case 'calc-text-height':
      // recalculate height of text field, e.g. for single line text fields
      if (field.type === CustomFieldType.remoteText && !field.multiline) {
        field.position = {
          ...field.position,
          height: estimator.estimateTextDimensions(
            'Dangly',
            field.fontFamily,
            field.fontSize,
            { hFudge: 1.1 }
          ).height
        };
      }
      break;
    case 'turn-off-group-members': {
      const groupId = getGroup(field);
      if (!groupId) break;

      for (const other of all) {
        if (other === field || other.id === field.id) continue;
        if (getGroup(other) === groupId && 'on' in other) {
          delete other.on;
        }
      }
      break;
    }
  }
}

function getGroup(field: CustomFieldConfiguration): string | undefined {
  return 'group' in field
    ?field.group
    : undefined;
}
