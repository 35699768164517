import { useEffect, useMemo, useState } from 'react';
import { Predicate } from '@property-folders/common/predicate';
import { FormCode, FormInstance, InstanceHistory, MaterialisedPropertyData, TransactionMetaData } from '@property-folders/contract';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { buildSigningTimelines } from '@property-folders/common/util/dataExtract';
import { formCompleted } from '@property-folders/common/util/form/formCompleted';
import { loadSnapshot } from './useVariation';
import { Y } from '@syncedstore/core';

export function useLineageFormless({ formFamily, requireAllHistory = false, ydoc, transactionMetaRootKey }: {
  formFamily: FormCode,
  requireAllHistory?: boolean,
  ydoc: Y.Doc | undefined
  transactionMetaRootKey: string
} ) {
  const { bindState, status } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);

  const { data: formInstances } = bindState<FormInstance[]>(state => {
    return state?.formStates?.[formFamily]?.instances || [];
  });

  const [latestSnapshotData, setLatestSnapshotData] = useState<MaterialisedPropertyData|null>(null);
  const [snapshotHistory, setSnapshotHistory] = useState<InstanceHistory|null>(null);
  const [snapshotLoadError, setSnapshotLoadError] = useState<string|null>(null);

  const signedInstances = useMemo(()=>{
    if (!formInstances) {
      return;
    }
    const sortedSignedInstances = formInstances?.filter(formCompleted);

    return (sortedSignedInstances.sort((instanceA, instanceB) => {
      // ascending sort
      return (instanceA.signing?.session?.completedTime||0) - (instanceB.signing?.session?.completedTime||0);
    }) as typeof formInstances);

  }, [formInstances?.length]);

  const latestInstance = useMemo(()=>{
    if (!Array.isArray(signedInstances)) {
      return;
    }

    return signedInstances[signedInstances.length-1];
  }, [signedInstances?.length]);

  useEffect(()=>{
    setLatestSnapshotData(null);
    setSnapshotLoadError(null);
    setSnapshotHistory(null);
    if (!latestInstance) {
      setSnapshotLoadError('No Latest Instance found to load for snapshot!');
      return;
    }

    const latestSnapshotId = latestInstance?.signing?.session?.associatedFiles?.propertyDataSnapshot?.id;
    if (!latestSnapshotId) {
      setSnapshotLoadError('Latest instance has no associated Snapshot!');
      return;
    }

    Promise.all(
      (signedInstances??[])
        .filter(instance=>requireAllHistory || instance.id === latestInstance?.id)
        .map(loadSnapshot)
    )
      .then(objectList => {
        const instHist = {
          instanceList: signedInstances??[],
          data: Object.assign({}, ...objectList.map(r=>r.success).filter(Predicate.isNotNullish))
        };
        const timelines = buildSigningTimelines(instHist);
        setSnapshotHistory({ ...instHist, signingTimelines: timelines.instanceSets, latestExpiry: timelines.latestExpiry || undefined });
        const latestInstanceFileId = (signedInstances?.find(inst=>inst.id === latestInstance.id)||{}).signing?.session?.associatedFiles?.propertyDataSnapshot?.id||'';
        const latestError = objectList.find(o=>o.id === latestInstance.id)?.error;
        if (latestError) {
          setSnapshotLoadError(latestError);
        } else {
          setLatestSnapshotData(instHist.data[latestInstanceFileId]??null);
        }
      });

  }, [latestInstance, signedInstances?.length, requireAllHistory]);

  return useMemo(()=>({
    snapshotData: latestSnapshotData ?? undefined,
    snapshotHistory: snapshotHistory ?? undefined,
    snapshotLoadError: latestSnapshotData ? null : snapshotLoadError
  }), [snapshotLoadError, latestSnapshotData]);
}
