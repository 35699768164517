import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { CollectionEditor } from './form/CollectionEditor';
import { useYdocBinder } from '../hooks/useYdocBinder';
import { ProspectivePurchaserParty } from '@property-folders/contract';
import { MaterialisedPropertyData, PartyType, SigningAuthorityType } from '@property-folders/contract/yjs-schema/property';
import { PathType } from '@property-folders/contract/yjs-schema/model';
import { ButtonSelectList } from './ButtonSelectList';
import { PartyAuthorityInput } from './form/PartyAuthorityInput';
import { purchaserTypeOptions } from '@property-folders/common/data-and-text/constants';
import './ConstrainedHeightDialog.scss';
import { SearchBar } from './SearchBar';
import { getPathParentAndIndex, getValueByPath, normalisePathToStr } from '@property-folders/common/util/pathHandling';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '../display/errors/modals';

interface PurchaserCollectionModalProps {
  prospectivePurchasers?: ProspectivePurchaserParty[][]
  showImportModal: boolean,
  onHideImportModal: ()=>void,
  parentPath?: string
  myPath?: PathType,
  primaryPurchaserIdPath?: PathType
}

export function PurchaserCollection (props: PurchaserCollectionModalProps & {
  setHeaderActions?: ()=>void
}) {
  const myPath = props.myPath ?? ['purchasers'];
  return <>
    <CollectionEditor
      itemNoun='Purchaser'
      restorationFieldDisplay='fullLegalName'
      myPath={normalisePathToStr(myPath)}
      autoAddFirst={true}
      allowAdd={true}
      primaryIdAbsPath={['primaryPurchaser']}
      setPrimaryIdToFirst={true}
      makePrimaryFirstElement={true}
      setHeaderActions={props.setHeaderActions}
      childItemRenderer={PartyAuthorityInput}
      childProps={{
        typeOptions: purchaserTypeOptions,
        partyLabel: 'Purchaser',
        hideOnTitleField: true,
        enableSameAsPrimaryPurchaser: true
      }}
      addTooltip='Add an additional joint Purchaser.'
    />
    <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} show={props.showImportModal} onClose={props.onHideImportModal} />}>
      <PurchaserImportModal {...props} myPath={myPath} />
    </ErrorBoundary>
  </>;
}

function PurchaserImportModal({ onHideImportModal, showImportModal, myPath = ['purchasers'], parentPath, primaryPurchaserIdPath, prospectivePurchasers }: PurchaserCollectionModalProps) {
  const [filterTerm, setFilterTerm] = useState('');

  const { updateDraft: updatePurchasersParent } = useYdocBinder<MaterialisedPropertyData>({ path: parentPath??[] });
  const [selectedOption, setSelectedOption] = useState<number|null>(null);

  function handleAddPurchaser() {
    if (selectedOption==null) return;
    const purchaserGroup = prospectivePurchasers?.[selectedOption];
    if (!purchaserGroup || !Array.isArray(purchaserGroup) || purchaserGroup.length === 0) {
      return;
    }
    updatePurchasersParent?.((draft)=> {
      const { parent, indexer } = getPathParentAndIndex(myPath, draft);
      if (!Array.isArray(parent?.[indexer])) {
        parent[indexer] = [];
      }
      const purchasers = getValueByPath(myPath, draft);

      purchasers.splice(0,purchasers.length);
      purchasers.push(...purchaserGroup.map(purchaser=>(purchaser.fullParty ?? {
        id: purchaser.id,
        partyType: PartyType.Individual,
        authority: SigningAuthorityType.self,
        fullLegalName: purchaser.fullLegalName,
        email1: purchaser.email,
        phone1: purchaser.phone,
        addressSingleLine: purchaser.address,
        addressSingleLine_parts: purchaser.address_parts
      })));
      const { parent: primaryParent, indexer: primaryIndexer } = getPathParentAndIndex(primaryPurchaserIdPath, draft);
      if (purchasers[0]?.id) primaryParent[primaryIndexer] = purchasers[0].id;
    });
    handleClose();
  }
  function handleClose() {
    setSelectedOption(null);
    onHideImportModal();
    setFilterTerm('');
  }

  return <Modal
    show={showImportModal}
    onHide={handleClose}
    dialogClassName='constrained-height-dialog full-width'
  >
    <Modal.Header className='flex-grow-0' closeButton><Modal.Title>Import existing Prospective Purchaser</Modal.Title></Modal.Header>
    <Modal.Body className='overflow-hidden flex-grow-1 d-flex flex-column'>
      <div className='flex-grow-0'>
        <SearchBar onSearch={setFilterTerm} placeholder='Search Prospective Purchaser' />
      </div>
      <div className='flex-grow-1 overflow-auto padding-x'>
        <ButtonSelectList<ProspectivePurchaserParty[]>
          onOptionClick={(_opt, idx)=>setSelectedOption(idx)}
          options={prospectivePurchasers??[]}
          subtitleKey='email'
          titleKey='fullLegalName'
          selectedOption={selectedOption}
          filterTerm={filterTerm}
        />
      </div>
    </Modal.Body>
    <Modal.Footer className='flex-grow-0'>
      <Button variant='light' onClick={handleClose}>Cancel</Button>
      <Button variant='primary' onClick={handleAddPurchaser} disabled={selectedOption==null}>Add</Button>
    </Modal.Footer>
  </Modal>;
}
