import { useTransactionField } from '../../hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { WrField } from './CommonComponentWrappers';
import '../Form.scss';
import { CollectionRemoveButton } from './CollectionRemoveButton';
import { AddressSelector } from './AddressSelector';

type CompareSaleProps = TransactionConsumerProps & {
  removable?: boolean,
  hideDelete?: boolean
  gnafCentre?: string;
  autoFocus?: boolean
};

export const CompareSaleInput = ({ removable = true, hideDelete = false, gnafCentre, autoFocus, ...restProps }: CompareSaleProps): JSX.Element => {
  const { fullPath, handleRemove } = useTransactionField(restProps);
  const closeButton = handleRemove && <CollectionRemoveButton removable={removable} onRemove={handleRemove} />;

  return (
    <div className="d-flex w-100 mt-3">
      <div className="d-flex w-100 flex-wrap">
        <div className="d-flex w-100 flex-wrap">
          <div className='flex-grow-1 mt-2'>
            <AddressSelector
              parentPath={fullPath}
              autoFocus={autoFocus}
              myPath='addrLine'
              label='Address'
              gnafCentre={gnafCentre}
            />
          </div>
        </div>
        <div className="d-flex w-100 flex-wrap">
          <div style={{ flexGrow: 1 }} className='mt-2'>
            <WrField.Control parentPath={fullPath} myPath='soldPrc' name='soldPrc' label='Sold price' placeholder="$9,999,999" />
          </div>
          <div style={{ flexGrow: 1 }} className='mt-2'>
            <WrField.Control type='date' useCanonical={true} parentPath={fullPath} myPath='soldDate' name='soldDate' label='Sold date'/>
          </div>
        </div>
      </div>
      {(!hideDelete) && <div className='d-flex align-items-center delete-div ms-1'>
        {closeButton}
      </div>}
    </div>
  );
};
