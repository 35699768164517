import { Button, Modal, FormControl } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { AsyncButton } from '../dragged-components/AsyncButton';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';

export interface AssignAgentModalProps {
  setShow: (show: boolean) => void;
  show: boolean;
  documentId: number | undefined;
  name: string | undefined;
  refetch?: () => Promise<unknown>;
}

export function RenameFormModal(props: AssignAgentModalProps) {
  const { show, setShow, documentId, name, refetch } = props;
  const [nameVal, setNameVal] = useState<string>(name ?? '');

  useEffect(() => {
    setNameVal(name ?? '');
  }, [documentId, name, show]);

  if (!documentId) {
    return <></>;
  }

  return <Modal
    show={show}
    onHide={() => setShow(false)}
  >
    <Modal.Header>Rename Form</Modal.Header>

    <Modal.Body>
      <FormControl value={nameVal} onChange={ev => setNameVal(ev.target.value)} />
    </Modal.Body>

    <Modal.Footer>
      <Button onClick={() => setShow(false)}>Cancel</Button>

      <AsyncButton
        onClick={async () => {
          await AjaxPhp.renameDocument({ id: documentId, newName: nameVal });

          if (refetch) {
            await refetch();
          }

          setShow(false);
        }}
      >Rename</AsyncButton>
    </Modal.Footer>
  </Modal>;
}
