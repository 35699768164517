import { useLightweightTransaction } from '../../hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { agencyAssocAuthOptions } from '@property-folders/common/data-and-text/constants';
import '../Form.scss';
import { WrField } from './CommonComponentWrappers';
import { useMemo } from 'react';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import { MaterialisedPropertyData } from '@property-folders/contract';
import { v4 } from 'uuid';
import { BelongingEntityMeta, REDUCER_NAME as entityMetaKey } from '@property-folders/common/redux-reducers/entityMeta';
import { useSelector } from 'react-redux';
import { uniqBy } from 'lodash';
import { Predicate } from '@property-folders/common/predicate';
import { EntitySettingsSalesperson } from '@property-folders/contract/yjs-schema/entity-settings';
import { useCurrentEntity } from '../../hooks/useEntity';

function ensureBasicAssocAgentStructure (draftRoot: MaterialisedPropertyData) {
  if (!Array.isArray(draftRoot.assocAgents)) {
    draftRoot.assocAgents = [];
  }

  if (draftRoot.assocAgents.length > 1) return;

  if (draftRoot.assocAgents.length === 0) {
    draftRoot.assocAgents.push({
      id: v4(),
      salesp: [{ id: v4() }]
    });
    return;
  }

  if (!Array.isArray(draftRoot.assocAgents[0].salesp)) {
    draftRoot.assocAgents[0].salesp = [];
  }
  if (!Array.isArray(draftRoot.assocAgents[0].salesp.length === 0)) {
    draftRoot.assocAgents[0].salesp.push({ id: v4() });
  }
}

export const AssociatedAgentsInput = ({ noReauth = false, lockedFields = [], parentPath, setHeaderActions }: TransactionConsumerProps & {noReauth?: boolean, lockedFields?: string[], setHeaderActions?: ()=>void}): JSX.Element => {
  const { value: transRoot } = useLightweightTransaction<MaterialisedPropertyData>({ parentPath });
  const currentEntity = useCurrentEntity();
  const memberEntities = useSelector((state: any) => state?.[entityMetaKey] as BelongingEntityMeta | undefined);
  const salespeople = useMemo(()=>{
    const sp = Object.values(memberEntities??{})
      .flatMap(me=>me?.salespeople)
      .filter(Predicate.isNotNullish)
      .filter(sp=>sp.isAssocAgent);
    return uniqBy(sp, sp=>sp?.id);

  }, [memberEntities]);

  const { updateDraft } = useYdocBinder<MaterialisedPropertyData>({ path: parentPath??[] });

  // So we don't really support multiple conjunction salespeople, seems like we already removed the
  // bits and pieces for it, but the structure used to.

  // So to simplify automatic insertion of data, we now just assume there's a single agent and
  // agency.

  // However, in order to get our error fields scrolling into view, we do need the ID, as these
  // are used in generation of the path to search
  const agentPathId = transRoot?.assocAgents?.[0]?.id ?? '0';
  const salesPathId = transRoot?.assocAgents?.[0]?.salesp?.[0]?.id ?? '0';

  return (
    <div className='w-100'>
      {!noReauth && <div className="d-flex w-100 flex-wrap gapped-row">
        <div className='flex-grow-1'>
          <WrField.CheckRadio
            label='Is the Agent authorised to appoint other licensed agents to assist the Agent in the sale process and marketing?'
            radioType='checkbox'
            options={agencyAssocAuthOptions}
            valueType='boolean'
            inline={false}
            name='authAssoc'
            myPath='authAssoc'
          />
        </div>
      </div>}
      {typeof transRoot?.authAssoc === 'boolean' && transRoot?.authAssoc && <div className='mt-2'>
        <div>
          <WrField.AutoComplete
            label='Salesperson'
            autocompleteLabelKey='name'
            parentPath={parentPath}
            myPath={`assocAgents.[${agentPathId}].salesp.[${salesPathId}].name`}
            name='assocSalespName'
            options={salespeople}
            disabled={lockedFields.includes('salesName')}
            onClear={()=>{
              updateDraft(troot => {
                delete troot.assocAgents[0].salesp[0].name;
                delete troot.assocAgents[0].salesp[0].linkedSalespersonId;
                delete troot.assocAgents[0].company;
                delete troot.assocAgents[0].abn;
                delete troot.assocAgents[0].rla;
              });
            }}
            onSuggestSelect={(option)=>{
              const singleOpt = option[0] as EntitySettingsSalesperson;
              if (!singleOpt) return;
              updateDraft(troot => {
                ensureBasicAssocAgentStructure(troot);
                if (!troot.assocAgents?.[0]?.salesp?.[0]?.id) return;
                troot.assocAgents[0].salesp[0].name = singleOpt.name;
                troot.assocAgents[0].salesp[0].linkedSalespersonId = singleOpt.id;
                troot.assocAgents[0].company = singleOpt.assocCompanyName ?? currentEntity?.tradeName;
                troot.assocAgents[0].abn = singleOpt.assocAbn;
                troot.assocAgents[0].rla = singleOpt.assocRla;
              });
            }}
          />
        </div>
        <div className='mt-2'>
          <WrField.Control
            label='Agency name'
            parentPath={parentPath}
            myPath={`assocAgents.[${agentPathId}].company`}
            name='assocCompanyName'
            disabled={lockedFields.includes('agencyName')}
          />
        </div>

        <div className="d-flex w-100 flex-wrap">
          <div className='flex-grow-1 mt-2' style={{ minWidth: '200px' }}>
            <WrField.Control
              label='Agency RLA'
              parentPath={parentPath}
              myPath={`assocAgents.[${agentPathId}].rla`}
              name='assocCompanyRla'
              disabled={lockedFields.includes('agencyRla')}
            />
          </div>
          <div className='flex-grow-1 mt-2' style={{ minWidth: '200px' }}>
            <WrField.Control
              label='Agency ABN'
              parentPath={parentPath}
              myPath={`assocAgents.[${agentPathId}].abn`}
              name='assocCompanyAbn'
              disabled={lockedFields.includes('agencyAbn')}
            />
          </div>
        </div>
      </div>}
    </div>
  );
};
