import { useNavigate } from 'react-router-dom';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { MouseEvent } from 'react';
import { useLightweightTransaction } from '../hooks/useTransactionField';
import { OfferMeta } from '@property-folders/contract';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { CardSectionRows } from './CardSectionRows';
import { sortBy } from 'lodash';

export const OfferManagementCardBody = ({ propertyId, headline }: {
  propertyId: string,
  headline: string
}) => {
  const { value: offerManagement } = useLightweightTransaction<OfferMeta>({ ydocForceKey: PropertyRootKey.Meta, myPath: 'offerManagement' });
  const navigate = useNavigate();
  const doNavigate = (e: MouseEvent<HTMLDivElement>, url: string) => {
    navigate(url);
    e.stopPropagation();
    e.preventDefault();
  };

  const propertyUrl = `/properties/${LinkBuilder.seoFriendlySlug(propertyId, headline)}`;
  const offers = sortBy(
    offerManagement?.prospectivePurchasers?.filter(pp=>pp.submittedOffers.length),
    r=>r.purchaserParties?.[0]?.fullLegalName
  );
  const rows = offers?.map(o => ([
    <div onClick={(e) => doNavigate(e,`${propertyUrl}/offer-management/${o.id}`)} className={'font-underline'}>
      {o.purchaserParties?.find(p => p.id === o.primaryPurchaser)?.fullLegalName}
    </div>,
    o.submittedOffers?.length || 0
  ]));

  return (
    <div className='instance-body pseudoform-body d-flex flex-column' style={{ flex: '1 1 auto' }}>
      <CardSectionRows rows={rows} maxRows={14} />
    </div>
  );
};
