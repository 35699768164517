import { useEffect } from 'react';
import { useRum } from './useRum';

export interface Props {
  sessionMeta?: {
    agentUuid: string;
    [key: string]: string | boolean | number;
  };
  forceCollection?: boolean;
}

export function useRumAgentSession(props: Props) {
  const { sessionMeta, forceCollection } = props;
  const rum = useRum();

  useEffect(() => {
    if (!rum || !sessionMeta) {
      return;
    }

    rum.addSessionAttributes(sessionMeta);
  }, [sessionMeta, rum]);

  useEffect(() => {
    if (!rum) {
      return;
    }

    if (forceCollection) {
      rum.enable();
    }
  }, [forceCollection, rum]);
}
