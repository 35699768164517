import './EmailPreview.scss';
import { BrandStyle } from '@property-folders/services/lib/email';
import { Image } from 'react-bootstrap';
import { reaformsOrange } from '@property-folders/common/visual';
import React from 'react';
import { Predicate } from '@property-folders/common/predicate';
import { AgentAvatar, AgentAvatarProps } from './AgentAvatar';
import { AuthApi } from '@property-folders/common/client-api/auth';

interface Sender {
  name?: string,
  avatar?: AgentAvatarProps,
}

const defaultSender: Sender = {
  name: 'reaforms Document Service',
  avatar: {
    initials: 'RD',
    agent: { name: 'reaforms Document Service', id: 0 },
    style: {
      color: '#ffffff',
      backgroundColor: '#4f6bed'
    }
  }
};

export function EmailPreview({
  brand,
  brandedContent,
  contact,
  subject,
  message,
  sender = defaultSender
}:{
  brand: Omit<BrandStyle, 'agencyContact' | 'logoAltText'>,
  brandedContent: {
    text: string,
    button?: string
  }
  contact?: {
    name: string;
    email?: string;
    phone?: string;
    message?: string;
  },
  subject?: string,
  sender?: {
    name?: string,
    avatar?: AgentAvatarProps,
  },
  message?: string
}) {
  const genMessage = message
    ?.split(/\n\n+/).filter(Predicate.isTruthy).map((para, i) => {
      return <p key={i}>{para.split(/\n/).filter(Predicate.isTruthy).map((line, idx, { length }) => idx < length -1
        ? <>{line}<br /></>
        : line
      )}</p>;
    });
  const avatarProps = {
    agent: { name: sender?.name || '', id: 0 },
    fontSize: '1.5rem',
    ...sender?.avatar
  };

  return <div className='email-preview-container d-flex justify-content-center'>
    <div className='centerline'>
      <div className='whitebox'>
        {subject && sender?.name && <>
          <h4>{subject}</h4>
          <div className='d-flex flex-row align-items-center gap-2'>
            <AgentAvatar {...avatarProps} />
            <div>
              <h6>{sender?.name}</h6>
              <h6>To: Document Recipient</h6>
            </div>
          </div>
          <hr />
        </>}
        <div><Image className='email-preview-logo' src={brand.logoUrl}/></div>
        <div className='brand-box' style={{
          backgroundColor: brand?.content?.backgroundColour || reaformsOrange,
          color: brand?.content?.foregroundColour || 'white'
        }}>
          <p className='mt-2'>{brandedContent.text}</p>
          {brandedContent.button && <div className='d-flex justify-content-center'>
            <div className='fake-button' style={{
              backgroundColor: brand?.button?.backgroundColour || 'white',
              color: brand?.button?.foregroundColour || reaformsOrange
            }}>{brandedContent.button}
            </div>
          </div>}
        </div>
        <div>
          {genMessage && <>{genMessage}</>}

          {contact && <>
            {contact.message
              ? <p></p>
              : <p>Should you have any questions in relation to the document or the electronic signing process, please
                contact:</p>}
            <p>
              <b>{contact.name}</b>
              {contact.email && <><br /><a href='#'>{contact.email}</a></>}
              {contact.phone && <><br /><a href='#'>{contact.phone}</a></>}
            </p>
          </>}
        </div>
      </div>
    </div>
  </div>;
}

export function useAgentSessionPreviewEmailContact() {
  const { data } = AuthApi.useGetAgentSessionInfo();

  return {
    name: data?.name || '[Contact Name]',
    email: data?.email
  };
}

export function generateBrandText(person: string, entity: string, aDocumentName: string, addressSummary: string, action: string) {
  return `${person} from ${entity} has sent you ${aDocumentName} for ${addressSummary} to ${action}`;
}
