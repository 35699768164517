import { useLightweightTransaction, useTransactionField } from '../../hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { WrField } from './CommonComponentWrappers';
import '../Form.scss';
import { CollectionRemoveButton } from './CollectionRemoveButton';
import { useSelector } from 'react-redux';
import { BelongingEntityMeta, REDUCER_NAME as entityMetaKey } from '@property-folders/common/redux-reducers/entityMeta';
import { EntitySettingsSalesperson } from '@property-folders/contract/yjs-schema/entity-settings';
import { canonicalisers } from '@property-folders/common/util/formatting';
import { bindSetOrUnsetIfUndefined } from '@property-folders/common/util/immerTools';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import { generateParentPath } from '@property-folders/common/util/pathHandling';
import { AgencySalesperson, Agent } from '@property-folders/contract';

type SalesRepInputProps = TransactionConsumerProps & {
  removable?: boolean;
  hideDelete?: boolean;
  linkedEntityId?: number;
  primaryAgentMode?: boolean;
  noSuggest?: boolean
};

export const NarrowSalesAgentInput = ({ noSuggest, removable = true, hideDelete = false, linkedEntityId, primaryAgentMode = false, ...restProps }: SalesRepInputProps): JSX.Element => {
  if (noSuggest == null && primaryAgentMode) {
    noSuggest = false;
  } else if (noSuggest == null) {
    noSuggest = true;
  }
  const memberEntities = useSelector((state: any) => state?.[entityMetaKey] as BelongingEntityMeta | undefined);
  const { fullPath, handleRemove, value: selfAgent } = useTransactionField<AgencySalesperson>(restProps);
  const salespContainerPath = generateParentPath(fullPath);
  const { value: siblingsAndSelf } = useLightweightTransaction<AgencySalesperson[]>({ myPath: salespContainerPath });
  const agentPath = generateParentPath(salespContainerPath);
  const { updateDraft: updateAgency } = useYdocBinder<Agent>({ path: agentPath });
  const idsToHide = (siblingsAndSelf??[]).map(s=>s.linkedSalespersonId).filter(s=>s !== selfAgent.linkedSalespersonId);
  const { updateDraft } = useYdocBinder({ path: fullPath });
  const { value: linkedSalesp, handleRemove: removeLinkedSalesp } = useTransactionField({ parentPath: fullPath, myPath: 'linkedSalespersonId' });

  const options = ((memberEntities||{})[linkedEntityId?.toString() || '']?.salespeople||[])
    .filter(s => s.isSalesperson && !idsToHide.includes(s.id))
    .map(person=>{
      const label = person.name;
      return {
        label,
        ...person
      };
    }).sort(({ label: a }, { label: b }) => {
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });

  function suggestionSelected(selection: ({label: string} & EntitySettingsSalesperson)[]) {
    const suggestion = selection?.[0];
    if (!suggestion) {
      return;
    }
    if (!suggestion?.id) console.warn('Suggested salesperson has no id');
    updateDraft?.(draft=>{
      const setSalespField = bindSetOrUnsetIfUndefined(draft);
      setSalespField('name', suggestion?.name);
      setSalespField('email', canonicalisers.email(suggestion?.email).canonical);
      setSalespField('phone', canonicalisers.phone(suggestion?.phone).canonical);
      setSalespField('linkedSalespersonId', suggestion?.id);
    });

    //override agency form1 defaults if they are set in salesperson
    if (suggestion?.form1) {
      updateAgency?.(draft => {
        if (!draft.form1) draft.form1 = {};
        if (suggestion.form1?.serviceFaxOrEmail) draft.form1.serviceFaxOrEmail = suggestion.form1?.serviceFaxOrEmail;
        if (suggestion.form1?.serviceAddress) draft.form1.serviceAddress = suggestion.form1?.serviceAddress;
        if (suggestion.form1?.serviceAddress) draft.form1.serviceAddressIsRla = suggestion.form1?.serviceAddressIsRla;
      });
    }
  }

  function generateDetailRow (salesp: {email: string, phone: string}) {
    return <div className='d-flex flex-grow-1 flex-wrap justify-content-between combo-field-subtext'>
      <div>Email: {canonicalisers.email(salesp?.email).display}</div>
      <div>Phone: {canonicalisers.phone(salesp?.phone).display}</div>
    </div>;
  }

  function linkedIcon(text: string) {
    return linkedSalesp
      ? <>{text} <i className='bi bi-link-45deg' /></>
      : text;
  }

  function onUnbouncedFieldChange() {
    removeLinkedSalesp();
  }

  const closeButton = handleRemove && <CollectionRemoveButton removable={removable} onRemove={handleRemove} />;

  return (
    <div className="d-flex w-100 gapped-row">
      <div className='w-100'>
        <div className="d-flex w-100 flex-wrap gapped-row">
          <div className='flex-grow-1'>
            {noSuggest
              ? <WrField.Control
                label={primaryAgentMode ? 'Salesperson' : linkedIcon('Salesperson name')}
                name='name'
                parentPath={fullPath}
                myPath='name'
                placeholder='Name including middle names'
              />
              : <WrField.AutoComplete
                label={primaryAgentMode ? 'Salesperson' : linkedIcon('Salesperson name')}
                onChange={onUnbouncedFieldChange}
                options={options}
                optionRender={primaryAgentMode
                  ? undefined
                  : (option, idx) => {
                    const salesp = option as EntitySettingsSalesperson;
                    return <div className='d-flex flex-column'>
                      <div className='fw-bold'>{salesp.label}</div>
                      {generateDetailRow(salesp)}
                    </div>;
                  }
                }
                onSuggestSelect={suggestionSelected}
                name='name'
                parentPath={fullPath}
                myPath='name'
                placeholder='Name including middle names'
                selectOnly={primaryAgentMode}
                canClear={false}
              />
            }
          </div>
        </div>
        {!primaryAgentMode && <div className="d-flex w-100 flex-wrap gapped-row">
          <div style={{ flexGrow: 2 }}>
            <WrField.Control onChange={onUnbouncedFieldChange} label={linkedIcon('Salesperson email')} name='email' parentPath={fullPath} myPath='email' placeholder='person@example.com'/>
          </div>
          <div style={{ flexGrow: 1 }}>
            <WrField.Control onChange={onUnbouncedFieldChange} label={linkedIcon('Salesperson phone')} name='phone' parentPath={fullPath} myPath='phone' placeholder='04## ### ###'/>
          </div>
        </div>}
      </div>
      {(!hideDelete) && <div className='d-flex align-items-center delete-div ms-1'>
        {closeButton}
      </div>}
    </div>
  );
};
