import { CollectionEditor } from './CollectionEditor';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { MemodClauseEditor } from './ClauseEditor';

export function ClausesSection(props: TransactionConsumerProps & { setHeaderActions?: ()=>void }) {
  return <div className='subsection scrollspy-target' data-focus-path="subsection-clauses">
    <CollectionEditor
      childItemRenderer={MemodClauseEditor}
      parentPath={props.parentPath}
      myPath={props.myPath}
      endAddButtonText='Add New'
      itemNoun='Clause'
      restorationFieldDisplay='title'
      allowDragAndDrop={true}
      setHeaderActions={props.setHeaderActions}
      className='rich-text-collection'
    />
  </div>;
}
