import { Button, Modal, FormLabel, FormText } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';
import { AsyncButton } from '../dragged-components/AsyncButton';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FoldersApi } from '@property-folders/common/client-api/foldersApi';

import type { ListFoldersForAgent } from '@property-folders/contract/rest/folders';

export interface AssignAgentModalProps {
  documentId?: number;
  documentName?: string;
  folderName?: string;
  show: boolean;
  setShow: (show: boolean) => void;
  refetch: () => Promise<unknown>;
}

export function MoveDocumentToFolderModal(props: AssignAgentModalProps) {
  const { documentId, documentName, folderName, show, setShow, refetch } = props;
  const [options, setOptions] = useState<ListFoldersForAgent['results']>([]);
  const [folder, setFolder] = useState<ListFoldersForAgent['results'][number] | null>(null);

  if (typeof documentId !== 'number') {
    return <></>;
  }

  useEffect(() => {
    (async () => {
      setOptions((await FoldersApi.listFoldersForAgent({
        limit: 100,
        pageNumber: 1,
        searchTerm: ''
      })).results ?? []);
    })();
  }, [show]);

  return <Modal show={show} onHide={() => setShow(false)}>
    <Modal.Header>Move document to folder</Modal.Header>

    <Modal.Body>
      {options && (<>
        <FormLabel htmlFor='move-document-to-folder' className='pt-3 mb-2'>Which folder should the document be moved to?</FormLabel>
        <Typeahead
          labelKey='folderName'
          filterBy={() => true}
          id='move-document-to-folder'
          placeholder='Select a folder'
          className='searchbar-dropdown'
          options={options}
          multiple={false}
          clearButton
          onChange={(selections) => {
            // Typeahead has 💩 types, so use our own from `options`.
            const selected: typeof options[number] | undefined = (selections as typeof options)[0];
            setFolder(selected === undefined ? null : selected);
          }}
        />
      </>)}

      {folder && <div className='mt-2'><FormText>Move <strong>{documentName}</strong> from <strong>{folderName}</strong> to <strong>{folder.folderName}</strong></FormText></div>}
    </Modal.Body>

    <Modal.Footer>
      <Button onClick={() => setShow(false)}>Cancel</Button>

      <AsyncButton
        disabled={typeof documentId !== 'number' || !folder}
        onClick={async () => {
          if (typeof documentId !== 'number' || !folder) {
            return;
          }

          await AjaxPhp.moveFolder({ documentId, folderId: folder.id });
          refetch && await refetch();
          setShow(false);
        }}
      >
        Move
      </AsyncButton>
    </Modal.Footer>
  </Modal>;
}
