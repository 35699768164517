import { FormDescriptorParty } from '@property-folders/common/yjs-schema/property';
import { useMemo, CSSProperties } from 'react';
import { useLightweightTransaction } from '../../../hooks/useTransactionField';
import { MaterialisedPropertyData, SigningParty } from '@property-folders/contract';
import { FormUtil } from '@property-folders/common/util/form';
import { useEntities } from '@property-folders/components/hooks/useEntity';

export function InjectedSignatureSpace({
  party
}: {
  party?: FormDescriptorParty
}) {
  const spaceStyle: CSSProperties = {
    borderBottom: '1px solid black',
    height: '40px'
  };
  const boldStyle: CSSProperties = {
    fontWeight: 'bold'
  };
  const { value: property } = useLightweightTransaction<MaterialisedPropertyData>({ 'myPath': '' });
  const memberEntities = useEntities();
  const parties = useMemo(() => {
    if (!property) return [];
    if (!party) return [];
    if (!memberEntities) return [];

    const signingPartySources = (() => {
      switch (party.type) {
        case 'agent':
          return FormUtil.getSalespersonSigningSources(property);
        case 'vendor':
          return FormUtil.getPartySigningSources(property, 'vendors', party);
        case 'purchaser':
          return FormUtil.getPartySigningSources(property, 'purchasers', party);
        default:
          return [];
      }
    })();
    const signingParties: SigningParty[] = [];
    FormUtil.addMissingSigningParties(signingParties, signingPartySources, { memberEntities });

    return [...FormUtil.getSigningSessionSignatureSnapshots(signingParties, property, { memberEntities }).values()];
  }, [property, party, memberEntities]);

  return <div className="w-100 d-flex flex-column">
    {parties.map((party, index) => {
      return <div key={index} style={{ display: 'grid', gridTemplateColumns: '11fr 3fr', gap: '0 1rem' }}>
        <div style={spaceStyle}></div>
        <div style={spaceStyle}></div>
        <div>
          {party.filledSigningPhrase
            ? <div className={'d-flex flex-column'}><span
              style={boldStyle}>{party.name}</span><span>{party.filledSigningPhrase}</span></div>
            : <span style={boldStyle}>{party.name}</span>}
        </div>
        <div style={boldStyle}>Date</div>
      </div>;
    })}
  </div>;
}
