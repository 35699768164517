import { FileSync } from '@property-folders/common/offline/fileSync';
import { YFormUtil } from '@property-folders/common/util/yform';
import { MaterialisedPropertyData } from '@property-folders/contract';
import { buildContractDocumentAndForm } from './buildSubDocument';
import { applyMigrationsV2_1 } from '@property-folders/common/yjs-schema';

import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { Y } from '@syncedstore/core';
import { NavigateFunction } from 'react-router-dom';
import { FormCode, META_APPEND, TransactionMetaData } from '@property-folders/contract/yjs-schema/property';
import { materialisePropertyMetadata } from '@property-folders/common/yjs-schema/property';

export const handleCreateContractFromOfferFactory = ({ fileSync, headline, navigate, propertyId, ydoc }: {
    ydoc: Y.Doc,
    fileSync: FileSync,
    navigate: NavigateFunction,
    propertyId: string,
    headline: string
  }) => (offerData: MaterialisedPropertyData) => {
  buildContractDocumentAndForm(ydoc, fileSync).then(res=>{
    if (!res.formId) {
      console.error('Failed to create new form');
      return;
    }
    const { dataRootKey: newRootKey, formFamilyCode } = YFormUtil.getFormLocationFromId(res.formId, ydoc)??{};
    if (!newRootKey) {
      console.error('Failed to retrieve new root key');
      return;
    }

    const baseMeta = materialisePropertyMetadata(ydoc);
    const originalFamilyState = baseMeta?.formStates?.[formFamilyCode??FormCode.RSC_ContractOfSale];
    const originalInstance = originalFamilyState?.instances?.[0];
    const baseClausesId = originalFamilyState?.clauseChildId;

    const newMeta = materialisePropertyMetadata(ydoc, newRootKey+META_APPEND);
    const newClausesId = newMeta.formStates?.[formFamilyCode??FormCode.RSC_ContractOfSale]?.clauseChildId;

    applyMigrationsV2_1<MaterialisedPropertyData | TransactionMetaData>({
      typeName: 'Property',
      doc: ydoc,
      docKey: newRootKey,
      migrations: [{
        name: 'Apply offer data',
        fn: (draft: MaterialisedPropertyData) => {
          draft.contractPrice = offerData.contractPrice;
          draft.contractSpecial = offerData.contractSpecial;
          draft.contractSettlement = offerData.contractSettlement;
          draft.purchasers = offerData.purchasers;
          draft.primaryPurchaser = offerData.primaryPurchaser;
          draft.contractOther = offerData.contractOther;
        }
      }, {
        name: 'Copy Annexures',
        docKey: newRootKey+META_APPEND,
        fn: (draft: TransactionMetaData) => {
          if (!formFamilyCode || !res.formId) return false;
          const instance = draft.formStates?.[formFamilyCode].instances?.find(i=>i.id===res.formId);
          // We're relying on the base data having only the Template Contract, which at the time
          // this is written, should still be true.
          if (!instance || !originalInstance) return false;
          if (!Array.isArray(instance.annexures)) instance.annexures = [];
          // Because this is a sublineage, the file references should still be sane, with respect to
          // permissions etc. Further, as there are no variations, the annexure list should be self
          // contained. As such we do not copy the files here
          instance.annexures.splice(0,instance.annexures.length, ...(originalInstance.annexures??[]));
        }
      }, {
        name: 'Reassign clauses',
        fn: (draft: MaterialisedPropertyData) => {
          // buildContractDocumentAndForm should have done a structured clone, so this data should
          // be here

          // We should be able to rely on the data already copied in, as unlike the clone of offer
          // contract, the data is not being cloned from another contract, but rather from the
          // main data model/template contract
          const clauseData = draft.clausesByFamily?.find(cf=>cf.id === baseClausesId);
          if (clauseData && newClausesId) {
            clauseData.id = newClausesId;
          } else return false;
        }
      }]
    });
    navigate(`/properties/${LinkBuilder.seoFriendlySlug(propertyId, headline)}/document/${LinkBuilder.seoFriendlySlug(res.formId, FormTypes[res.formCode].label)}`);
  });
};