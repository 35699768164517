import { Button, Modal, FormControl } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';
import { AsyncButton } from '../dragged-components/AsyncButton';

export interface AssignAgentModalProps {
  folderId?: number;
  folderName?: string;
  show: boolean;
  setShow: (show: boolean) => void;
  refetch: () => Promise<unknown>;
}

export function RenameFolderModal(props: AssignAgentModalProps) {
  const { folderId, folderName, show, setShow, refetch } = props;
  const [newFolderName, setNewFolderName] = useState<string>(folderName ?? '');

  useEffect(() => {
    setNewFolderName(folderName ?? '');
  }, [folderId, folderName, show]);

  if (typeof folderId !== 'number') {
    return <></>;
  }

  return <Modal
    show={show}
    onHide={() => setShow(false)}
  >
    <Modal.Header>Rename Folder</Modal.Header>

    <Modal.Body>
      <FormControl autoFocus value={newFolderName} onChange={event => setNewFolderName(event.target.value)} placeholder='Please enter a new name for the folder' />
    </Modal.Body>

    <Modal.Footer>
      <Button onClick={() => setShow(false)}>Cancel</Button>

      <AsyncButton
        onClick={async () => {
          await AjaxPhp.renameFolder({ folderId, newName: newFolderName });
          refetch && await refetch();
          setShow(false);
        }}
      >
        Rename
      </AsyncButton>
    </Modal.Footer>
  </Modal>;
}
