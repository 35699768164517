import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { Button } from 'react-bootstrap';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import clsJn from '@property-folders/common/util/classNameJoin';
import { Icon } from '../../dragged-components/Icon';

export interface Props {
  propertyId?: string;
  show: boolean;
  headline?: string;
  className?: string;
}

export function UpdatesPendingBadge({ propertyId, headline, show, className }: Props) {
  const navigate = useNavigate();

  if (!propertyId || !show) {
    return <></>;
  }

  return <Button
    variant='outline-secondary'
    onClick={() => navigate(LinkBuilder.externalReviewPath({ id: propertyId, nicetext: headline }))}
    className={clsJn('d-flex align-items-center py-1', className)}
  >
    <Icon name='menu_open' style={{ transform: 'rotateY(180deg)' }} />
    <p className='m-0 p-0 ps-2'>Updates pending</p>
  </Button>;
}
