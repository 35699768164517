import { useLightweightTransaction } from '../../hooks/useTransactionField';
import { boolYesNoOpts } from '@property-folders/common/data-and-text/constants';
import { WrField } from './CommonComponentWrappers';
import { ShowGuidanceNotesButton } from '../guidance/ShowGuidanceNotesButton';
import { LegacyTenancy } from '@property-folders/contract/property';
import { CollectionEditor } from './CollectionEditor';
import { TenancyInputInst } from './TenancyInputInst';
import { useState } from 'react';
import { HeaderAction } from 'dragged-components/Wizard/WizardStepPage';
import { Button } from 'react-bootstrap';

export function TenancySubsection({ saleContractMode = false }: {saleContractMode?: boolean}) {
  const { value: tenant, fullPath } = useLightweightTransaction<LegacyTenancy>({ myPath: 'saaTenant' });
  const { tenantEnable } = tenant ?? {};
  const [actionButtons, setActionButtons] = useState<{[name: string]: HeaderAction}>({});

  return <div>
    <div className='d-flex justify-content-between'>
      <div className='fs-4 d-flex align-items-center mb-3'>Tenancies <ShowGuidanceNotesButton noteId='tenancy'/></div>
      <div>{Object.values(actionButtons).map(ha => <Button onClick={ha.onClick} variant='light'>{ha.label || 'Add'}</Button>)}</div>
    </div>
    <div className="d-flex w-100 flex-wrap">

      <WrField.CheckRadio
        label='Is there an existing tenancy agreement?'
        radioType='checkbox'
        options={boolYesNoOpts}
        valueType='boolean'
        inline={true}
        name='tenantEnable'
        parentPath={fullPath}
        myPath='tenantEnable'
      />

    </div>

    {tenantEnable && <CollectionEditor
      childItemRenderer={TenancyInputInst}
      autoAddFirst={true}
      childProps={{ saleContractMode }}
      myPath={'tenantsCollect'}
      allowAdd={true}
      setHeaderActions={setActionButtons}
    />}
  </div>;
}
