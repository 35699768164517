import React, { useState } from 'react';
import { Button, Container, Image as BImage } from 'react-bootstrap';
import { SigningStateEntryMode } from '../SigningState';
import '@property-folders/common/react/uncompiled-lib/react-dropzone-uploader/styles.css';
import Dropzone, { IFileWithMeta, StatusValue } from '@property-folders/common/react/uncompiled-lib/react-dropzone-uploader/Dropzone';
import useProcessSignature from '@property-folders/common/util/image/process-signature';

interface SelectUploadedSignatureProps {
  fullName?: string;
  initials?: string;
  onImagesChanged: (signatureImage: string | undefined, initialsImage: string | undefined) => void;
  mode: SigningStateEntryMode;
  isVisible: boolean;
}

export function SelectUploadedSignature(props: SelectUploadedSignatureProps) {
  const [ signatureImage, setSignatureImage ] = useState<string|undefined>();
  const [ initialsImage, setInitialsImage ] = useState<string|undefined>();
  const { mode } = props;
  const { getImage } = useProcessSignature();

  const handleUpload = async (fileWithMeta: IFileWithMeta, status: StatusValue) => {
    if (status === 'done' && fileWithMeta.meta.previewUrl) {
      const imageFromCanvas = await getImage(fileWithMeta.meta.previewUrl);
      if (mode === SigningStateEntryMode.AdoptSignature) {
        setSignatureImage(imageFromCanvas);
        props.onImagesChanged(imageFromCanvas, initialsImage);
      } else {
        setInitialsImage(imageFromCanvas);
        props.onImagesChanged(signatureImage, imageFromCanvas);
      }
    }
  };

  const handleClearSignature = ({ files }: { files: IFileWithMeta[] }) => {
    if (mode === SigningStateEntryMode.AdoptSignature) {
      setSignatureImage(undefined);
      props.onImagesChanged(undefined, initialsImage);
    } else {
      setInitialsImage(undefined);
      props.onImagesChanged(signatureImage, undefined);
    }
    files.map(f => f.remove());
  };

  return (
    <Container className={'mt-3 d-flex flex-column p-0'}>
      <Dropzone
        maxFiles={1}
        multiple={false}
        canCancel={false}
        onChangeStatus={handleUpload}
        accept="image/*"
        styles={{ dropzone: { overflow: 'hidden', borderStyle: 'dashed', minHeight: '180px' }, inputLabel: { textAlign: 'center' } }}
        PreviewComponent={props =>
          <div className={'d-flex flex-row flex-grow-1'}>
            <div className={'d-flex flex-row align-items-center justify-content-center'} style={{ flex: '1 1' }}><BImage src={signatureImage} /></div>
            <div className={'d-flex flex-row align-items-center justify-content-center'} style={{ flex: '0 0 100px' }}>
              <Button variant={'link'} className={'text-dark'} onClick={() => handleClearSignature(props)}>Clear</Button>
            </div>
          </div>
        }
      />
    </Container>
  );
}
