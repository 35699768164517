import React from 'react';
import { Icon } from './Icon';

export function Chevroned({ items, chevronSize = 6 }: { items: string[], chevronSize?: 1 | 2 | 3 | 4 | 5 | 6 }) {
  if (!items.length) return <></>;

  return <>{items.map((item, index) => {
    return index === items.length - 1
      ? item
      : <React.Fragment key={index}>
        {item}<Icon name='chevron_right' icoClass={`fs-${chevronSize} ms-1 me-1`}/>
      </React.Fragment>;
  })}</>;
}
