import {
  FieldPosition, PendingRemoteSigningSessionFieldCustom,
  PendingRemoteSigningSessionPage,
  RemoteSigningSessionDataField,
  SigningSessionFieldType,
  SigningSessionSubType, TimestampPosition
} from '@property-folders/contract';
import React, { forwardRef, RefObject, useState } from 'react';
import { Page } from 'react-pdf';
import { useBreakpointValue } from '@property-folders/components/hooks/useBreakpointValue';
import { useForwardedRef } from '@property-folders/components/hooks/useForwardedRef';
import {
  Dimension
} from '@property-folders/common/signing/draw-compound-signature';
import { dimensionsToPixelsPerInch } from '@property-folders/common/util/pdfgen/measurements';
import { Timestamp } from './field/Timestamp';
import { Witness } from './field/Witness';
import { DataField } from './field/DataField';
import { Field } from './field/Field';

export interface OverlaidPdfPageField extends FieldPosition {
  id?: string;
  type: SigningSessionFieldType;
  subtype: SigningSessionSubType;
  name: string;
  signingPhrase: string;
  text?: string;
  image?: string;
  timestamp?: number;
  custom?: PendingRemoteSigningSessionFieldCustom;
  inlineTimestampPosition?: TimestampPosition
}

export interface OverlaidPdfPageTimestamp extends FieldPosition {
  id?: string;
  value?: number;
}

export interface OverlaidPdfPageWitness extends FieldPosition {
  id?: string;
  value?: string;
}

export interface OverlaidPdfPageProps {
  index: number;
  page: PendingRemoteSigningSessionPage,
  fields: OverlaidPdfPageField[];
  timestamps: OverlaidPdfPageTimestamp[];
  witnesses: OverlaidPdfPageWitness[];
  getFieldRef: (id: string) => RefObject<HTMLDivElement> | undefined;
  fillField: (id: string, text?: string) => void;
  clearField: (id: string) => void;
  scrollContainerRef: RefObject<Element>;
  timeZone: string;
  minCompositeSignatureDimensions?: Dimension;
  dataFields: RemoteSigningSessionDataField[];
  widestWidth: number,
  observer?: IntersectionObserver
}

export const OverlaidPdfPage = forwardRef<HTMLDivElement, OverlaidPdfPageProps>((props, thisPageRef) => {
  const {
    page,
    fields,
    timestamps,
    witnesses,
    index,
    minCompositeSignatureDimensions,
    dataFields,
    widestWidth
  } = props;
  const widestPageScale = page.width / widestWidth;
  const breakpointWidth = useBreakpointValue({
    base: 300,
    sm: 450,
    md: 500,
    lg: 600,
    xl: 850,
    xxl: 1000
  }, 500) * widestPageScale;
  const fs = useBreakpointValue({
    base: 6,
    sm: 9.1,
    md: 10.1,
    lg: 12,
    xl: 17,
    xxl: 20
  }, 10.1) * widestPageScale;
  const [loaded, setLoaded] = useState(false);
  const [pixelsPerInch, setPixelsPerInch] = useState(72);
  const reusableThisPageRef = useForwardedRef(thisPageRef);

  const widthRatio = page.width / breakpointWidth;
  const breakpointHeight = page.height / widthRatio;

  return (
    <div
      ref={reusableThisPageRef}
      style={{
        position: 'relative',
        width: `${breakpointWidth}px`,
        height: `${breakpointHeight}px`,
        marginInline: 'auto',
        fontSize: `${fs}px`
      }}
    >
      <Page
        // note: canvas mode is an option but it looks broken
        renderMode='canvas'
        renderTextLayer={false}
        width={breakpointWidth}
        height={breakpointHeight}
        key={`page_${index + 1}`}
        pageNumber={index + 1}
        onRenderSuccess={(page) => {
          setPixelsPerInch(dimensionsToPixelsPerInch(page.originalWidth, page.originalHeight));
          setLoaded(true);
        }}
      />
      {loaded && fields.map((field, idx) => {
        const { id } = field;
        const fieldRef = id
          ? props.getFieldRef(id)
          : undefined;
        const fillField = id
          ? (text?: string) => props.fillField(id, text)
          : undefined;
        const clearField = id
          ? () => props.clearField(id)
          : undefined;
        return <Field
          id={field.id || ''}
          key={`field${idx}`}
          ref={fieldRef}
          type={field.type}
          subtype={field.subtype}
          name={field.name}
          timestamp={field.timestamp || -1}
          timestampPosition={field.inlineTimestampPosition}
          timeZone={props.timeZone}
          page={page}
          location={field}
          minCompositeSignatureDimensions={minCompositeSignatureDimensions}
          fillField={fillField}
          clearField={clearField}
          text={field.text}
          image={field.image}
          pixelsPerInch={pixelsPerInch}
          custom={field.custom}
          observer={props.observer}
        />;
      })}
      {loaded && timestamps.map((timestamp, idx) => {
        return <Timestamp key={`field${idx}`} page={page} location={timestamp} value={timestamp.value} timeZone={props.timeZone}/>;
      })}
      {loaded && witnesses.map((witness, idx) => {
        return <Witness key={`field${idx}`} page={page} location={witness} value={witness.value}/>;
      })}
      {loaded && dataFields.map((df, idx) => {
        return <DataField key={`df${idx}`} page={page} field={df} timeZone={props.timeZone} />;
      })}
    </div>
  );
});
