import {
  PendingRemoteSigningSessionResult,
  SigningPartyDeclineType,
  SigningSessionFieldType,
  SigningSessionSubType
} from '@property-folders/contract';
import { Predicate } from '@property-folders/common/predicate';
import { UserUtil } from '@property-folders/common/util/user';
import { Dimension } from '@property-folders/common/signing/draw-compound-signature';

export enum SigningStateEntryMode {
  AdoptSignature = 1,
  AdoptInitials = 2
}

export enum CompletionStatus {
  Completing = 1,
  Complete = 2,
  Failed = 3,
  AuthoringPdf = 4
}

interface SigningStateField {
  id: string;
  type: SigningSessionFieldType;
  subtype: SigningSessionSubType;
  required?: boolean;
  filled?: boolean;
  value?: string;
  text?: string;
  timestamp?: number;
  group?: string;
}

export interface SigningImageDetail {
  data?: string;
  replace?: boolean;
}

export interface SigningStateParty {
  fullName: string;
  initials: string;
  images: {
    signature: SigningImageDetail;
    initials: SigningImageDetail;
  }
}

// for now, check/radio have the same config.
// in the future this may need to be separated to support different behaviours,
// such as checkboxes where at least N must be checked
export interface SigningGroupConfig {
  fields: string[];
  required: boolean;
}

export interface SigningGroups {
  check: Record<string, SigningGroupConfig>;
  radio: Record<string, SigningGroupConfig>;
}

export interface SigningState {
  pending?: {
    fieldId?: string;
    mode: SigningStateEntryMode;
  }
  party: SigningStateParty;
  fields: SigningStateField[];
  completionStatus?: CompletionStatus;
  declining?: SigningPartyDeclineType;
  initialDeclineReason?: string;
  moreSignaturesRequired?: boolean;
  minCompositeSignatureDimensions?: Dimension;
  groups: SigningGroups;
}

export enum SigningActionType {
  /**
   * Open dialog to adopt a signature, or signature + initials
   */
  BeginAdoptSignature,
  /**
   * Open dialog to adopt initials, or signature + initials
   */
  BeginAdoptInitials,
  CompleteAdopt,
  FillField,
  CancelFill,
  ClearField,
  SigningSessionCompleting,
  SigningSessionComplete,
  SigningSessionCompletionFailed,
  SigningSessionCompletionCleared,
  BeginDeclining,
  CancelDeclining,
  SigningSessionAuthoringPdf,
  RemoveStoredImageData,
  VisitField
}

type UnparameterisedSigningAction = {
  type:
    | SigningActionType.CancelFill
    | SigningActionType.BeginAdoptInitials
    | SigningActionType.BeginAdoptSignature
    | SigningActionType.SigningSessionCompleting
    | SigningActionType.SigningSessionAuthoringPdf
    | SigningActionType.SigningSessionCompletionFailed
    | SigningActionType.SigningSessionCompletionCleared
    | SigningActionType.CancelDeclining
    | SigningActionType.RemoveStoredImageData
};

type FillFieldSigningAction = { type: SigningActionType.FillField, id: string, text?: string };
type ClearFieldSigningAction = { type: SigningActionType.ClearField, id: string };
type CompleteAdoptSigningAction = { type: SigningActionType.CompleteAdopt, signatureImage?: string, initialsImage?: string};
type SigningSessionCompleteSigningAction = { type: SigningActionType.SigningSessionComplete, moreSignaturesRequired: boolean };
type BeginDeclining = { type: SigningActionType.BeginDeclining, declineType: SigningPartyDeclineType, initialReason?: string };
type VisitFieldSigningAction = { type: SigningActionType.VisitField, id: string };

export type SigningAction =
  | UnparameterisedSigningAction
  | FillFieldSigningAction
  | ClearFieldSigningAction
  | CompleteAdoptSigningAction
  | BeginDeclining
  | SigningSessionCompleteSigningAction
  | VisitFieldSigningAction;

export function getInitialSigningState(data: PendingRemoteSigningSessionResult): SigningState {
  const proxyMode = Predicate.proxyNotSelf(data.party.proxyAuthority);
  const fullName = (proxyMode ? data.party.proxyName : data.party.snapshot?.name) || 'Unknown';
  const initials = UserUtil.getInitials(fullName);
  const compositeSignatureDimensions = data.fields
    .filter(field => field.type === SigningSessionFieldType.Signature && field.subtype === SigningSessionSubType.RenderInfoInline)
    .map(field => field.fieldPosition)
    .filter(Predicate.isNotNull);
  const radio: Record<string, SigningGroupConfig> = {};
  const check: Record<string, SigningGroupConfig> = {};
  for (const field of data.fields) {
    if (!field.id) continue;
    switch (field.type) {
      case SigningSessionFieldType.Check: {
        const groupId = field.custom?.check?.group;
        if (groupId) {
          if (check[groupId]) {
            check[groupId].fields.push(field.id);
          } else {
            check[groupId] = {
              fields: [field.id],
              required: false
            };
          }
        }
        break;
      }
      case SigningSessionFieldType.Radio: {
        const groupId = field.custom?.radio?.group;
        if (groupId) {
          if (radio[groupId]) {
            radio[groupId].fields.push(field.id);
          } else {
            radio[groupId] = {
              fields: [field.id],
              required: true
            };
          }
        }
        break;
      }
    }
  }

  return {
    party: {
      fullName,
      initials,
      images: {
        signature: {
          data: data.images.signature
        },
        initials: {
          data: data.images.initials
        }
      }
    },
    fields: data.fields.map<SigningStateField | undefined>(field => {
      if (!field.id) return undefined;
      const group = field.type === SigningSessionFieldType.Radio
        ? field.custom?.radio?.group
        : field.type === SigningSessionFieldType.Check
          ? field.custom?.check?.group
          : undefined;
      return {
        id: field.id,
        type: field.type,
        subtype: field.subtype,
        name: field.partyInfo?.name || '',
        signingPhrase: field.partyInfo?.filledSigningPhrase || '',
        group,
        text: field.custom?.text,
        required: field.custom?.required
      };
    }).filter(Predicate.isNotNull),
    minCompositeSignatureDimensions: compositeSignatureDimensions.length
      ? {
        width: Math.min(...compositeSignatureDimensions.map(d => d.width)),
        height: Math.min(...compositeSignatureDimensions.map(d => d.height))
      }
      : undefined,
    groups: {
      radio,
      check
    }
  };
}

function applySignatureToField(draft: SigningState, field: SigningStateField | undefined) {
  if (!field) return;
  if (field.filled) return;
  if (field.type !== SigningSessionFieldType.Signature) return;
  if (!draft.party.images.signature.data) {
    draft.pending = {
      fieldId: field.id,
      mode: SigningStateEntryMode.AdoptSignature
    };
    return;
  }

  field.filled = true;
  field.value = 'SIGNED';
  field.timestamp = Date.now();
}

function applyInitialsToField(draft: SigningState, field: SigningStateField | undefined) {
  if (!field) return;
  if (field.filled) return;
  if (field.type !== SigningSessionFieldType.Initials) return;
  if (!draft.party.images.initials?.data) {
    draft.pending = {
      fieldId: field.id,
      mode: SigningStateEntryMode.AdoptInitials
    };
    return;
  }

  field.filled = true;
  field.value = 'INITIALLED';
  field.timestamp = Date.now();
}

function applyTextToField(draft: SigningState, field: SigningStateField | undefined, text: string | undefined) {
  if (!field) return;
  if (field.type !== SigningSessionFieldType.Text) return;

  // we may have to think about behaviour for clearing/user not wanting to enter data
  field.filled = true;
  field.text = text || '';
  field.timestamp = Date.now();
}

function setRadio(draft: SigningState, field: SigningStateField | undefined) {
  if (!field) return;
  if (field.type !== SigningSessionFieldType.Radio) return;

  const now = Date.now();
  const groupFields = field.group && draft.groups.radio[field.group]
    ? new Set<string>(draft.groups.radio[field.group].fields)
    : new Set<string>();
  draft.fields.forEach(f => {
    if (f.id === field.id) {
      f.filled = true;
      f.text = 'on';
      f.value = 'on';
      f.timestamp = now;
    } else if (groupFields.has(f.id)) {
      f.filled = true;
      f.text = 'off';
      f.value = 'off';
      f.timestamp = now;
    }
  });
}

function setCheckbox(draft: SigningState, field: SigningStateField | undefined, text: string | undefined) {
  if (!field) return;
  if (field.type !== SigningSessionFieldType.Check) return;

  const checked = text?.toLowerCase() === 'on';
  field.filled = true;
  field.text = checked ? 'on' : 'off';
  field.value = checked ? 'on' : 'off';
  field.timestamp = Date.now();
}

function processFillField(draft: SigningState, action: FillFieldSigningAction) {
  const field = draft.fields.filter(field => field.id === action.id)[0];
  switch (field?.type) {
    case SigningSessionFieldType.Signature:
      applySignatureToField(draft, field);
      return;
    case SigningSessionFieldType.Initials:
      applyInitialsToField(draft, field);
      break;
    case SigningSessionFieldType.Text:
      applyTextToField(draft, field, action.text);
      break;
    case SigningSessionFieldType.Radio:
      setRadio(draft, field);
      break;
    case SigningSessionFieldType.Check:
      setCheckbox(draft, field, action.text);
      break;
    default:
      return;
  }
}

function processVisitField(draft: SigningState, action: VisitFieldSigningAction) {
  const field = draft.fields.find(field => field.id === action.id);
  if (field?.filled) return;

  switch (field?.type) {
    case SigningSessionFieldType.Check: {
      const text = field.text ?? field.value ?? 'off';
      field.filled = true;
      field.text = text;
      field.value = text;
      field.timestamp = Date.now();
      break;
    }
    case SigningSessionFieldType.Text: {
      if (field.text) {
        field.filled = true;
        field.timestamp = Date.now();
        return;
      }

      if (field.required) {
        field.filled = false;
        return;
      }

      field.filled = true;
      field.text = '';
      field.value = '';
      field.timestamp = Date.now();
    }
  }
}

function processClearField(draft: SigningState, action: ClearFieldSigningAction) {
  const field = draft.fields.filter(field => field.id === action.id)[0];
  if (!field) return;

  switch (field.type) {
    case SigningSessionFieldType.Radio: {
      const groupFields = field.group && draft.groups.radio[field.group]
        ? new Set<string>(draft.groups.radio[field.group].fields)
        : new Set<string>();
      draft.fields.forEach(f => {
        if (f.id === field.id || groupFields.has(f.id)) {
          f.filled = false;
          delete f.text;
          delete f.value;
          delete f.timestamp;
        }
      });
      break;
    }
    default:
      field.filled = false;
      delete field.value;
      delete field.timestamp;
      delete field.text;
  }

}

function processCompleteAdopt(draft: SigningState, action: CompleteAdoptSigningAction) {
  if (!(action.signatureImage || action.initialsImage)) return;

  if (action.signatureImage) {
    draft.party.images.signature.data = action.signatureImage;
    draft.party.images.signature.replace = true;
  }

  if (action.initialsImage) {
    draft.party.images.initials.data = action.initialsImage;
    draft.party.images.initials.replace = true;
  }

  if (!draft.pending) return;

  applySignatureToField(draft, draft.fields.filter(field => field.id === draft.pending?.fieldId)[0]);
  applyInitialsToField(draft, draft.fields.filter(field => field.id === draft.pending?.fieldId)[0]);

  delete draft.pending;
}

export function signingStateReducer(draft: SigningState, action: SigningAction) {
  switch (action.type) {
    case SigningActionType.FillField:
      processFillField(draft, action);
      break;
    case SigningActionType.CancelFill:
      delete draft.pending;
      break;
    case SigningActionType.VisitField:
      processVisitField(draft, action);
      break;
    case SigningActionType.ClearField:
      processClearField(draft, action);
      break;
    case SigningActionType.BeginAdoptInitials:
      draft.pending = {
        mode: SigningStateEntryMode.AdoptInitials
      };
      break;
    case SigningActionType.BeginAdoptSignature:
      draft.pending = {
        mode: SigningStateEntryMode.AdoptSignature
      };
      break;
    case SigningActionType.CompleteAdopt:
      processCompleteAdopt(draft, action);
      break;
    case SigningActionType.SigningSessionCompleting:
      draft.completionStatus = CompletionStatus.Completing;
      break;
    case SigningActionType.SigningSessionComplete:
      draft.completionStatus = CompletionStatus.Complete;
      draft.moreSignaturesRequired = action.moreSignaturesRequired;
      break;
    case SigningActionType.SigningSessionAuthoringPdf:
      draft.completionStatus = CompletionStatus.AuthoringPdf;
      break;
    case SigningActionType.SigningSessionCompletionFailed:
      draft.completionStatus = CompletionStatus.Failed;
      break;
    case SigningActionType.SigningSessionCompletionCleared:
      delete draft.completionStatus;
      break;
    case SigningActionType.BeginDeclining:
      draft.declining = action.declineType;
      draft.initialDeclineReason = action.initialReason;
      break;
    case SigningActionType.CancelDeclining:
      delete draft.declining;
      delete draft.initialDeclineReason;
      break;
    case SigningActionType.RemoveStoredImageData:
      draft.party.images.initials = {};
      draft.party.images.signature = {};
      for (const field of draft.fields.filter(f => f.filled && (f.type === SigningSessionFieldType.Initials || f.type === SigningSessionFieldType.Signature))) {
        field.filled = false;
        delete field.value;
        delete field.timestamp;
      }
      break;
    default:
      break;
  }
  return draft;
}
