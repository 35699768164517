import { useEffect, useState } from 'react';
import { formatTimestamp } from '@property-folders/common/util/formatting';
import { HumanTimestampText } from './HumanTimestamp';

export function DisplayDate({ date, timezone }: {date: Date, timezone: string}) {
  const [display, setDisplay] = useState('');

  useEffect(() => {
    setDisplay(() => {
      if (date == null) {
        return '';
      }

      return formatTimestamp(date.getTime(), timezone, false);
    });
  }, [date, timezone]);

  return <>{display} (<HumanTimestampText timestampMs={date.getTime()} />)</>;
}
