import { WrField } from './CommonComponentWrappers';
import React, { useContext, useEffect, useState } from 'react';
import { useCurrentEntity } from '../../hooks/useEntity';
import { AgencyVendorMap } from '@property-folders/contract/yjs-schema/entity-settings';
import { usePrimarySalesperson } from '../../hooks/usePrimarySalesperson';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import { useLightweightTransaction } from '../../hooks/useTransactionField';
import { Form1SearchParty, FormCode, VendorAgent, VendorParty, EpfAutoOrdering, SaleTitle } from '@property-folders/contract';
import { FormContext } from '../../context/FormContext';
import { WrappedFetch } from '@property-folders/common/client-api/wrappedFetch';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { YjsDocContext } from '../../context/YjsDocContext';
import { FormatBI } from '../../display/FormatBI';
import { getContactsFromAuthorityParty } from '@property-folders/common/util/digest-authority-party-details';
import { PostRequestJobQuoteRequestBody, PostRequestJobQuoteResponse } from '@property-folders/contract/rest/property';
import { Spinner } from 'react-bootstrap';
import { canonicalisers } from '@property-folders/common/util/formatting';
import { LinkButton } from '../../display/LinkButton';
import { PropertyRootKey, MaterialisedPropertyData } from '@property-folders/contract/yjs-schema/property';

export function Form1AndSearchesSection() {

  const sharedParentPath = ['form1AndSearches'];
  const { docName: propertyId, ydoc } = useContext(YjsDocContext);
  const { formName: formCode } = useContext(FormContext);
  const isRSAA = formCode === FormCode.RSAA_SalesAgencyAgreement;

  const { updateDraft } = useYdocBinder<{whoSearches: Form1SearchParty, whoPays: VendorAgent} | undefined>({ path: sharedParentPath });
  const { value: whoSearches } = useLightweightTransaction<Form1SearchParty>({ parentPath: sharedParentPath.join('.'), myPath: 'whoSearches' });
  const { value: saleTitles } = useLightweightTransaction<SaleTitle[]>({ myPath: 'saleTitles' });
  const [instantSearchesEnabled, setInstantSearchesEnabled] = useState<null|boolean>(null);

  const [quoteApiResponse, setQuoteApiResponse] = useState<PostRequestJobQuoteResponse | undefined>(undefined);
  const [quoteError, setQuoteError] = useState(false);
  const [quoteOrdering, setQuoteOrdering] = useState(false);
  const [showQuoteBreakdown, setShowQuoteBreakdown] = useState(false);

  const { value: vendors } = useLightweightTransaction<VendorParty[]>({ myPath: 'vendors' });
  const { value: primaryVendor } = useLightweightTransaction<string>({ myPath: 'primaryVendor' });

  const vendorOuter = vendors?.find(v => v.id === primaryVendor);
  const { email: primaryVendorEmail } = getContactsFromAuthorityParty(vendorOuter, primaryVendor).filter(p=>p.isPrimary)[0]??{};
  const validDataAllowsAutomatic = (vendorOuter?.addressSingleLine || vendorOuter?.addrSameAsSale) && primaryVendorEmail;

  const entity = useCurrentEntity();
  const agent = usePrimarySalesperson();

  // default is 0, so this takes care of that quite neatly
  const whoPays = agent?.whoPaysForm1PrepFee
    ? agent.whoPaysForm1PrepFee
    : entity?.whoPaysForm1PrepFee;

  const eckermannsLabel = `Eckermann Property Forms to order searches and contact Vendor regarding Form 1 preparation, at ${whoPays === AgencyVendorMap.AGENCY ? 'Agent' : 'Vendor'}'s cost.`;
  const vendorLabel = 'Vendor to arrange their own searches and Form 1 preparation, at Vendor’s cost.';
  const agentLabel = whoPays === AgencyVendorMap.AGENCY ? 'Agent to order searches and prepare Form 1, at Agent’s cost.' : ' Agent to arrange searches and Form 1 preparation, at Vendor’s cost.';

  useEffect(() => {
    if (!( // We won't perform this effect unless:
      formCode === FormCode.RSAA_SalesAgencyAgreement // The current form is a base RSAA
      && whoSearches === 'eckermanns' // Eckermanns is performing the form 1 via epf
      && validDataAllowsAutomatic // The user has valid contact details
      && instantSearchesEnabled == null // and that we have not yet checked that instant searches are available
    )) {
      return;
    }

    const abort = new AbortController();
    const signal = abort.signal;

    WrappedFetch.json<{
      instantSearchesEnabled: boolean
    }>(LinkBuilder.restApi(`/properties/${propertyId}/job-instant-searches`), {
      method: 'GET',
      signal
    })
      .then(result => {
        if (signal.aborted) return;
        const enabled = result?.instantSearchesEnabled || false;
        setInstantSearchesEnabled(enabled);
      });
    return () => abort.abort();
  }, [propertyId, whoSearches, validDataAllowsAutomatic]);

  useEffect(()=>{
    if (whoSearches !== 'eckermanns') return;
    if (!entity?.epfAgencyId) {
      updateDraft?.(draft => {
        if (!draft) {
          return;
        }
        draft.whoSearches = 'vendor';
      });
      return;
    }
  }, [whoSearches]);

  const saleTitleEffectStr = saleTitles?.map(st=>st.valuations?.join('')??'').join('');
  useEffect(()=>{
    if (whoSearches !== 'eckermanns') return;
    // begin form 1 quote process here
    if (!validDataAllowsAutomatic) return; // No valid contact data, no quote for you

    const abort = new AbortController();
    const signal = abort.signal;
    const requestBody: PostRequestJobQuoteRequestBody = {
      source: 'epf',
      overridePropertyData: (ydoc?.getMap(PropertyRootKey.Data).toJSON() as MaterialisedPropertyData)
    };

    setQuoteError(false);
    setQuoteApiResponse(undefined);
    setQuoteOrdering(true);
    WrappedFetch.json<PostRequestJobQuoteResponse>(LinkBuilder.restApi(`/properties/${propertyId}/job-quote-requests`), {
      method: 'POST',
      body: JSON.stringify(requestBody),
      signal
    })
      .then(result => {
        if (signal.aborted) return;
        setQuoteError(false);
        setQuoteApiResponse(result);
      })
      .catch(err => {
        console.error(err);
        if (signal.aborted) return;
        setQuoteError(true);
        setQuoteApiResponse(undefined);
      })
      .finally(()=>setQuoteOrdering(false));

    // This abort does mean that when you deselect eckermanns ordering, the quote will abort.
    // Perhaps the user may want to check for an updated quote again later
    return () => {abort.abort();setQuoteOrdering(false);};
  }, [whoSearches, validDataAllowsAutomatic, saleTitleEffectStr]);

  useEffect(()=>{
    updateDraft?.(draft=>{
      if (!draft) {
        return;
      }
      if (whoSearches === 'vendor' && whoPays === AgencyVendorMap.AGENCY) {
        draft.whoSearches = 'agent';
      }
      if (whoPays && whoPays === AgencyVendorMap.AGENCY) {
        draft.whoPays = 'agent';
      } else if (whoPays && whoPays === AgencyVendorMap.VENDOR) {
        draft.whoPays = 'vendor';
      }

    });
  }, [whoPays]);

  const allOrderingOptsDisabled = whoSearches !== 'eckermanns';
  const orderingElement = whoSearches === 'eckermanns'
    ? <>
      {!validDataAllowsAutomatic && <span
        className={'anywhere-invalid-feedback'}>
      Email and postal address are required for the primary Vendor, when ordering a Form 1 from Eckermann Property Forms
      </span>}
      {validDataAllowsAutomatic && quoteOrdering &&  <div className='mb-2'
      >
        <Spinner animation='border' size='sm'></Spinner><div className='ms-2 d-inline'>Getting quote for Form 1 order</div>
      </div>}
      {validDataAllowsAutomatic && quoteError && <span
        className={'anywhere-invalid-feedback'}>
        There was an error preparing a quote
      </span>}
      {validDataAllowsAutomatic && quoteApiResponse && <div className='mb-2'>
        <span className='d-inline'>Estimated cost: {canonicalisers.aud(quoteApiResponse.total).display} (inc GST)</span>
        <span className='ms-1'><LinkButton onClick={()=>{setShowQuoteBreakdown(ps => !ps);}}>{showQuoteBreakdown ? 'Hide breakdown' : 'View breakdown'}</LinkButton></span>
        {showQuoteBreakdown && <table>
          {quoteApiResponse.fees.map(fee => {
            return <tr><td>{fee.label}</td><td className='ps-4 text-end'>{fee.total}</td></tr>;
          })}
          <tr><td className='text-end'>Total:</td><td className='ps-4 text-end'>{canonicalisers.aud(quoteApiResponse.total).display}</td></tr>
        </table>}
      </div>}
      <WrField.CheckRadio
        options={{
          [EpfAutoOrdering.WithInstant]: { order: 1, label: <FormatBI>Order Form 1 *-*with*-* Instant Searches once Sales Agency Agreement executed</FormatBI>, disabled: (allOrderingOptsDisabled || !validDataAllowsAutomatic || !instantSearchesEnabled) },
          [EpfAutoOrdering.WithoutInstant]: { order: 2, label: <FormatBI>Order Form 1 *-*without*-* Instant Searches once Sales Agency Agreement executed</FormatBI>, disabled: (allOrderingOptsDisabled || !validDataAllowsAutomatic || instantSearchesEnabled===null) },
          [EpfAutoOrdering.Later]: { order: 3, label: 'Agent to order Form 1 later', disabled: allOrderingOptsDisabled }
        }}
        radioType='radio'
        valueType='int'
        inline={false}
        parentPath={sharedParentPath.join('.')}
        myPath='epfOrderingAutomatic'
        name='epfOrderingAutomatic'
      />

    </>
    : undefined;

  return <>
    <div className='subsection scrollspy-target' data-focus-path="subsection-form1-and-searches">
      <div className='fs-4 d-flex align-items-center'>Form 1 and Searches</div>
      <div className="w-100 mt-2 d-flex">
        <WrField.CheckRadio
          name='form1AndSearches.whoSearches'
          radioType='checkbox'
          options={{
            eckermanns: { label: eckermannsLabel, disabled: !entity?.epfAgencyId },
            ...(whoPays !== AgencyVendorMap.AGENCY ? { vendor: vendorLabel } : {}),
            agent: agentLabel
          }}
          inline={false}
          parentPath={sharedParentPath.join('.')}
          myPath='whoSearches'
          sublists={orderingElement && entity?.epfAgencyId && { 1: orderingElement }}
          disabled={!isRSAA}
        />
      </div>
    </div>
  </>;
}
