import * as Y from 'yjs';
import { useOfflineAwareness } from '@property-folders/components/hooks/useAwareness';
import * as awarenessProtocol from 'y-protocols/awareness';
import { useMemo } from 'react';
import { YjsDocContextType } from '@property-folders/common/types/YjsDocContextType';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { RoomProvider } from '@y-presence/react';
import { AwarenessData } from '@property-folders/contract';

export const initialPresence: AwarenessData = {
  location: {
    path: ''
  }
};

export function BasicYjsContext({ yDoc, yDocId, children, rootKey }: React.PropsWithChildren<{
  yDoc: Y.Doc,
  yDocId: string,
  rootKey: string
}>) {
  const awareness = useOfflineAwareness(yDoc) || new awarenessProtocol.Awareness(yDoc);
  const yjsContext = useMemo<YjsDocContextType>(() => {
    return {
      ydoc: yDoc,
      awareness,
      docName: yDocId,
      transactionRootKey: rootKey,
      clearDeclareDebounce: () => { /**/
      },
      declareDebounce: () => { /**/
      }
    };
  }, [awareness]);

  return <YjsDocContext.Provider value={yjsContext}>
    <RoomProvider<AwarenessData> awareness={awareness} initialPresence={initialPresence}>
      {children}
    </RoomProvider>
  </YjsDocContext.Provider>;
}
