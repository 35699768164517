import { createContext, useEffect, useState } from 'react';
import { EnvelopeApiHooks } from '../hooks/api/EnvelopeApiHooks';
import { useLoaderData } from 'react-router-dom';

export interface PropertyBackgroundJobsState {
  envelopeChangesAvailable: boolean;
  propertyId: string;
}

export const PropertyBackgroundJobsContext = createContext<PropertyBackgroundJobsState>({
  envelopeChangesAvailable: false,
  propertyId: ''
});

export type Props = {
  children: JSX.Element;
} & ({useLoader: true} | {propertyId: string; useLoader?: false});

export function PropertyBackgroundJobs(props: Props) {
  const [state, setState] = useState<PropertyBackgroundJobsState>({ envelopeChangesAvailable: false, propertyId: '' });
  const loaderData = useLoaderData() as {transId: string, cleanup: () => void};
  const hasUpdates = EnvelopeApiHooks.useExternalUpdateWatcher(props.useLoader ? loaderData.transId : props.propertyId);

  useEffect(() => {
    setState(s => {
      const envelopeChangesAvailable = typeof hasUpdates === 'boolean' ? hasUpdates : false;
      return { ...s, envelopeChangesAvailable };
    });
  }, [hasUpdates]);

  useEffect(() => {
    setState(s => {
      s.propertyId = loaderData.transId;
      return { ...s };
    });
  }, [loaderData.transId]);

  useEffect(() => {
    const cleanup = loaderData.cleanup;
    return () => {
      cleanup();
    };
  }, []);

  return <PropertyBackgroundJobsContext.Provider value={state}>
    {props.children}
  </PropertyBackgroundJobsContext.Provider>;
}
