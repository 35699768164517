import { ExpandAllState, LineageContext, useVariation } from '../hooks/useVariation';

import {
  TransactionFormProps
} from '@property-folders/common/types/TransactionFormProps';
import { useEffect, useMemo } from 'react';

export const LineageWrapper = (
  props: {
    requireFullHistory?: boolean,
    children: JSX.Element|JSX.Element[],
    variationsMode?: boolean
  }
) => {
  const { children, requireFullHistory, variationsMode = false } = props;
  const variationData = useVariation(requireFullHistory);
  return <LineageContext.Provider value={{ variationsMode, ...variationData }}>
    {children}
  </LineageContext.Provider>;
};

/**
 * Because this utilises useForm, this must be within a FormContext
 */
export const VariationWrapper = (props: TransactionFormProps & {requireFullHistory?: boolean, InnerForm: (props: TransactionFormProps)=>JSX.Element}) => {
  const { InnerForm, requireFullHistory, ...restProps } = props;
  const variationData = useVariation(requireFullHistory);

  const expandAll = useMemo(() => new ExpandAllState(), []);
  useEffect(() => {
    return () => {
      expandAll.destroy();
    };
  }, [expandAll]);

  return <LineageContext.Provider value={{ variationsMode: true, expandAll, ...variationData }}>
    <InnerForm {...restProps} variationsMode={true} />
  </LineageContext.Provider>;
};
