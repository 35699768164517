import './SideNavPullOut.scss';
import { Nav, Offcanvas, OffcanvasBody } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import clsJn from '@property-folders/common/util/classNameJoin';
import { HtmlCharacterCodec } from '@property-folders/common/util/html-character-codec';

interface Props {
  text: string;
  items: {text: string; link: string | null; id: string|number; header?: boolean}[];
  icon: string;
  setFragment: (f: React.ReactElement) => void;
}

export function SideNavPullOut({ items, setFragment, text, icon }: Props) {
  const [pullOutVisible, setPullOutVisible] = useState(false);
  const fragmentRef = useRef<HTMLDivElement>(null);
  const navRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // bootstrap sm
    // swap to an overlay
    if (window.innerWidth < 576) {
      setFragment(
        <Offcanvas
          ref={fragmentRef}
          show={pullOutVisible}
          onHide={() => { setPullOutVisible(false); }}
        >
          <OffcanvasBody className='side-nav-pull-out-offcanvas'>
            <Nav.Item style={{ fontSize: '2rem' }} className='back'>
              <span className='nav-text'>{HtmlCharacterCodec.decode(text)}</span>
              <Icon name='times' pack='la' onClick={() => { setPullOutVisible(false); }} />
            </Nav.Item>

            {items.map(p => <Nav.Item style={{ fontSize: '2rem' }} key={p.id} className={ p.header ? 'no-hover' : '' }>
              <span className={clsJn('nav-link')}>
                {p.link
                  ? <Link
                    to={p.link}
                    className={'nav-link'}
                    onClick={() => {setPullOutVisible(false);}}
                  >
                    <span className={clsJn('nav-text', p.header ? 'fw-bold' : '')}>{HtmlCharacterCodec.decode(p.text)}</span>
                  </Link>
                  : <span className={clsJn('nav-text', p.header ? 'fw-bold' : '')}>{HtmlCharacterCodec.decode(p.text)}</span>
                }
              </span>
            </Nav.Item>)}
          </OffcanvasBody>
        </Offcanvas>
      );

      return;
    }

    if (!pullOutVisible) {
      setFragment(<></>);
      return;
    }

    if (!navRef.current) {
      return;
    }

    const buttonRect = navRef.current.getBoundingClientRect();
    const leftPosition = `${buttonRect.right}px`;
    let topPosition = buttonRect.top - 78; // 78 is the header height
    const maxWidth = `calc(100% - ${leftPosition})`;

    const itemSize = 41;
    let bottomPosition =  window.innerHeight - topPosition - 78 - (itemSize * items.length);
    if (bottomPosition < 0) {
      bottomPosition = 0;
      topPosition = window.innerHeight - bottomPosition - (itemSize * items.length) - 78;
    }

    if (topPosition < 0) {
      topPosition = 0;
    }

    setFragment(
      <div
        className='bg-white expanded-sidebar'
        ref={fragmentRef}
        style={{ left: leftPosition, top: `${topPosition}px`, bottom: `${bottomPosition}px`, maxWidth }}
      >
        {items.map(p => <Nav.Item style={{ fontSize: '2rem' }} key={p.id} className={ p.header ? 'no-hover' : ''}>
          <span className={clsJn('nav-link', p.header ? 'no-hover' : '')}>
            {p.link
              ? <Link
                to={p.link}
                className={'nav-link'}
                onClick={() => {setPullOutVisible(false);}}
              >
                <span className={clsJn('nav-text', p.header ? 'fw-bold' : '')}>{HtmlCharacterCodec.decode(p.text)}</span>
              </Link>
              : <span className={clsJn('nav-text', p.header ? 'fw-bold' : '')}>{HtmlCharacterCodec.decode(p.text)}</span>
            }
          </span>
        </Nav.Item>)}
      </div>
    );
  }, [pullOutVisible, fragmentRef]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (!fragmentRef?.current?.contains?.(event.target) && !navRef?.current?.contains?.(event.target)) {
        setPullOutVisible(false);
      }
    }

    try {
      document.addEventListener('mousedown', handleClickOutside);
      document.querySelector('iframe:not(#print-iframe)')?.contentWindow?.addEventListener('click', handleClickOutside);
    } catch (e) {
      console.error('unable to add iframe event listener', e);
    }

    return () => {
      try {
        document.removeEventListener('mousedown', handleClickOutside);
      } catch (e) {
        console.error('unable to remove mousedown event listener', e);
      }
      try {
        document.querySelector('iframe')?.contentWindow?.removeEventListener('click', handleClickOutside);
      } catch (e) {
        console.error('unable to remove iframe event listener', e);
      }
    };
  }, [fragmentRef, navRef]);

  return <Nav.Item ref={navRef}>
    <span className={'nav-link ' + (pullOutVisible ? 'selected' : '')} onClick={() => { setPullOutVisible(v => {return !v;});}}>
      {icon && icon.includes('/') && <img className='icon' src={icon} />}
      {icon && !icon.includes('/') && <Icon name={icon} pack='la' />}
      <span className='nav-text'>{text}</span>
    </span>
  </Nav.Item>;
}
