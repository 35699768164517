import { useState, useContext } from 'react';
import { Predicate } from '@property-folders/common/predicate';
import { minimumAdvertisingPrice } from '@property-folders/common/util/defaults';
import { useTransactionField } from '../../hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { advertRangeOptions } from '@property-folders/common/data-and-text/constants';
import { canonicalisers } from '@property-folders/common/util/formatting';
import '../Form.scss';
import { CollectionEditor } from './CollectionEditor';
import { WrField } from './CommonComponentWrappers';
import { CompareSaleInput } from './CompareSaleInput';
import clsJn from '@property-folders/common/util/classNameJoin';
import { composeErrorPathClassName } from '@property-folders/common/util/formatting';
import { WizardDisplayContext } from '../../context/WizardContexts';
import { ShowGuidanceNotesButton } from '../guidance/ShowGuidanceNotesButton';
import { ComparableSalesAnnexure } from '../../display/form/ComparableSalesAnnexure';
import { FormCode } from '@property-folders/contract';
import { usePropertyGnafLocation } from '../../hooks/usePropertyGnafLocation';
import { useCurrentEntity } from '../../hooks/useEntity';
import { FormUserInteractionContext } from '../../context/FormUserInteractionContext';

export const RSAASaleInput = ({  ...restProps }: TransactionConsumerProps & { setHeaderActions?: () => void }): JSX.Element => {
  const { value: transRoot } = useTransactionField({ parentPath: restProps.parentPath, myPath: '' });
  const { focusErrList } = useContext(WizardDisplayContext);
  const shouldShowFullValidationError = useContext(FormUserInteractionContext).userShouldSeeAllValidation;
  const localEntity = useCurrentEntity();

  const comparableErrorFocusTarget = composeErrorPathClassName(['compareAddrs'], 'comparable-sales-subsection');
  const comparableErrorFocusList = composeErrorPathClassName(['compareAddrs'], undefined);
  const showComparableSectionError = shouldShowFullValidationError && (
    focusErrList.includes(comparableErrorFocusTarget)
    || focusErrList.includes(comparableErrorFocusList)
  );

  // if we have a gnaf id for an address, use that for the centre of the search
  const gnafCentre = usePropertyGnafLocation();
  const saleMap = transRoot?.sale;
  const [ showAdvice, setShowAdvice ] = useState<boolean|string>(false);
  const pmap = typeof saleMap?.vendorPrc === 'number' && typeof saleMap?.agentEstPrc === 'number' && Math.max(saleMap?.vendorPrc, saleMap?.agentEstPrc);

  const rangeDefaults = minimumAdvertisingPrice(saleMap);
  const lowerDefault = Predicate.isNotNullish(rangeDefaults?.lowerDefault) ? canonicalisers.aud(rangeDefaults?.lowerDefault).display : undefined;
  const upperDefault = Predicate.isNotNullish(rangeDefaults?.upperDefault) ? canonicalisers.aud(rangeDefaults?.upperDefault).display : undefined;

  const documentUploadedForComparable = !!transRoot?.comparableSales?.annexureRef?.['family_'+FormCode.RSAA_SalesAgencyAgreement];

  return (
    <div className="d-flex w-100 gapped-row">
      <div className='w-100'>
        <div className='subsection scrollspy-target' data-focus-path="subsection-advert-price">
          <div className='fs-4 d-flex align-items-center mb-2'>Price <ShowGuidanceNotesButton noteId='agentEstPrc' /></div>
          <div className="d-flex w-100 flex-wrap">
            <div className='flex-grow-1'>
              <WrField.Control name='agentEstPrc' myPath='sale.agentEstPrc' label="Agent's Estimated Selling Price"
                placeholder="$999,999" />
            </div>

            <div className='flex-grow-1'>
              <WrField.Control name='vendorPrc' myPath='sale.vendorPrc' label="Vendor’s Selling Price"
                placeholder="$9,999,999" />
            </div>
          </div>
          <div className="d-flex w-100 flex-wrap gapped-row">
            {pmap && 'Prescribed Minimum Advertising Price: ' + canonicalisers.aud(pmap.toString()).display}
          </div>
          <div className="d-flex w-100 flex-wrap gapped-row">
            <div style={{ width: '190px' }}><WrField.Select name='advertRange' myPath='sale.advertRange'
              options={advertRangeOptions} label='Range or set price' />
            </div>
            {saleMap?.advertRange !== 'noprice' && <div className='flex-grow-1'>
              <WrField.Control
                name='advertPrc'
                myPath='sale.advertPrc'
                label={saleMap?.advertRange === 'range' ? 'Advertised lower' : 'Advertised price'}
                valuePlaceholder={lowerDefault}
              />
            </div>}
            {saleMap?.advertRange === 'range' && <div className='flex-grow-1'>
              <WrField.Control
                name='advertPrcUpper'
                myPath='sale.advertPrcUpper'
                label="Advertised upper"
                valuePlaceholder={upperDefault}
              />
            </div>}
          </div>
        </div>

        <div className={clsJn('subsection scrollspy-target', comparableErrorFocusTarget)}
          data-focus-path="subsection-comparable-sales">

          <div className='fs-4 d-flex align-items-center'>Comparable sales <ShowGuidanceNotesButton noteId='comparableSales'/><sup className='fs-5' style={{ color: 'red' }}> *</sup></div>

          {showComparableSectionError && <div className='d-block invalid-feedback mb-3'>Agent to provide Vendor with details of comparable sales and any other information to support Agent's estimated price</div>}
          <div className='mt-2 d-flex flex-wrap'>
            {!localEntity?.requireComparableSales && <div className='mt-1 me-3'>
              <WrField.BoolCheck
                label='Already provided'
                name='disableCompareAlreadyProvided'
                myPath='disableCompareAlreadyProvided'
                overrideChecked={documentUploadedForComparable?false:undefined}
                disabled={documentUploadedForComparable}
              />
            </div>}
            {!transRoot?.disableCompareAlreadyProvided && <ComparableSalesAnnexure  />}
          </div>
          {!(transRoot?.disableCompareAlreadyProvided || transRoot?.comparableSales?.annexureRef?.['family_'+FormCode.RSAA_SalesAgencyAgreement]) && <>

            <CollectionEditor
              level={2}
              autoAddNew={true}
              maximum={4}
              myPath={'compareAddrs'}
              childItemRenderer={CompareSaleInput}
              itemNoun='Previous Sale'
              restorationFieldDisplay='addrLine'
              addTooltip='Add properties which are similar enough to act as a price guide'
              childProps={{ gnafCentre }}
              requiredForAutoAddRelativePaths={['addrLine', 'soldPrc', 'soldDate']}
            />
          </>}
        </div>
      </div>
    </div>
  );
};
