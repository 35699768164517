import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap';
import { EditorMode, RichTextEditor } from '../RichTextEditor';
import { useEffect, useState } from 'react';
import { MultiSelectForm } from '../SelectForm';
import FormCheck from 'react-bootstrap/FormCheck';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { PartialClause } from '@property-folders/common/types/Clause';
import { AsyncButton } from '../AsyncButton';

interface Props {
  show: boolean
  onHide: () => void;
  onAdd: (clause: PartialClause) => void | Promise<void>;
  entityUuid: string;
  propertyFoldersEnabled: boolean;
}

export function NewClauseModal(props: Props) {
  const defaultState: PartialClause = {
    title: '',
    markdownClause: '',
    htmlClause: '',
    formCodes: [],
    formCodesRequired: [],
    allForms: true,
    editable: true
  };

  const { show, onHide, onAdd } = props;
  const [clause, setClause] = useState<PartialClause>(defaultState);
  const { newPropertyFolders } = useFeatureFlags();

  const updateClauseProperty = <T extends keyof PartialClause>(key: T, val: PartialClause[T]) => {
    setClause(c => {
      if (key === 'formCodes' && Array.isArray(val)) {
        c.formCodesRequired = c.formCodesRequired.filter(fcr => !val.includes(fcr));
      } else if (key === 'formCodesRequired' && Array.isArray(val)) {
        c.formCodes = c.formCodes.filter(fc => !val.includes(fc));
      }

      const newClause =  { ...c, [key]: val };
      validate(key, newClause);

      return newClause;
    });
  };

  useEffect(() => {
    if (props.show) {
      setClause(defaultState);
    }
  }, [props]);

  const [validations, setValidations] = useState({
    title: true,
    clause: true,
    formCodes: true
  });

  const validate = (type?: string, actualClause?: PartialClause) => {
    if (!actualClause) {
      actualClause = clause;
    }

    let newValidations = {
      title: validations.title,
      clause: validations.clause,
      formCodes: validations.formCodes,
      allForms: true
    };

    const actualValidation: {[key: string]: boolean} = {
      title: actualClause.title.length > 0,
      clause: 'markdownClause' in actualClause
        ? actualClause.markdownClause.length > 0
        : actualClause.htmlClause.length > 0,
      formCodes: actualClause.formCodes.length > 0 || actualClause.formCodesRequired.length > 0 || actualClause.allForms,
      allForms: true
    };

    if (type && actualValidation[type] != null) {
      // @ts-ignore
      newValidations[type] = actualValidation[type];
    } else {
      // @ts-ignore
      newValidations = actualValidation;
    }

    setValidations(newValidations);

    for (const validation of Object.keys(newValidations)) {
      // @ts-ignore
      if (!newValidations[validation]) {
        return false;
      }
    }

    return true;
  };

  return <Modal
    onHide={onHide}
    show={show}
    size='xl'
  >
    <Modal.Header>
      <h3>New Clause</h3>
    </Modal.Header>

    <Modal.Body>
      <Form.Group className='mb-3'>
        <div className='d-flex w-100'>
          <Form.Control
            type='text'
            value='1.'
            disabled={true}
            className='w-auto'
            style={{ maxWidth: 32 }}
            title='When clauses are imported into a document, they will be part of a numbered list. The top-level number will be the position of the clause in the list.'
          />
          <FloatingLabel label='Title' className='w-100'>
            <Form.Control
              type='text'
              placeholder='Enter the name of the clause...'
              onChange={e => updateClauseProperty('title', e.target?.value ?? '')}
              className='w-100'
            />
          </FloatingLabel>
        </div>
        {!validations.title && <p className='text-danger'>*Title must be set</p>}
      </Form.Group>

      <RichTextEditor
        namespace='new-clause-dialog'
        onUpdate={markdown => updateClauseProperty('markdownClause', markdown)}
        outputMode={EditorMode.MARKDOWN}
        contentEditableStyle={{ maxHeight: '50vh', overflow: 'auto' }}
      />
      {!validations.clause && <p className='text-danger'>*Clause text must be set</p>}

      <FormCheck
        type='checkbox'
        label='Is editable?'
        checked={clause?.editable}
        id='editable'
        onChange={e => {
          updateClauseProperty('editable', !clause?.editable);
        }}
        className='mt-2'
      />

      <FormCheck
        type='checkbox'
        label='Available in all forms'
        checked={clause?.allForms}
        id='allForms'
        onChange={e => {
          updateClauseProperty('allForms', !clause?.allForms);
        }}
        className='mt-2'
      />

      {!clause?.allForms && <>
        <MultiSelectForm
          label='Available in'
          className='mt-2'
          value={clause?.formCodes}
          onChange={newForms => updateClauseProperty('formCodes', newForms)}
          subscriptionForms={true}
          entityUuid={props.entityUuid}
          propertyForms={false}
          withClauses={true}
        />

        {newPropertyFolders && <MultiSelectForm
          label='Required in'
          className='mt-2'
          value={clause?.formCodesRequired}
          onChange={newForms => updateClauseProperty('formCodesRequired', newForms)}
          subscriptionForms={true}
          entityUuid={props.entityUuid}
          propertyForms={false}
          withClauses={true}
        />}

        {!validations.formCodes && <p className='text-danger'>*At least one form must be selected</p>}
      </>}
    </Modal.Body>
    <Modal.Footer>
      <Button
        variant='light'
        className='ms-auto'
        onClick={onHide}
      >Cancel</Button>
      <AsyncButton
        className='ms-2'
        onClick={async () => {
          if (validate()) {
            await onAdd({ ...clause });
          }
        }}
      >Add</AsyncButton>
    </Modal.Footer>
  </Modal>;
}
