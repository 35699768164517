import { Doc as YDocType } from 'yjs';
import { FormCode, META_APPEND, PropertyRootKey, MaterialisedPropertyData, TransactionMetaData } from '@property-folders/contract/yjs-schema/property';
import { buildContractDocumentAndForm } from './buildSubDocument';
import { YFormUtil } from '@property-folders/common/util/yform';
import { applyMigrationsV2_1 } from '@property-folders/common/yjs-schema';
import { NavigateFunction } from 'react-router-dom';
import { FileSync } from '@property-folders/common/offline/fileSync';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { materialisePropertyMetadata } from '@property-folders/common/yjs-schema/property';

export function cloneContractLikeFactory({ navigate, ydoc, fileSync, propertyId: transId, headline, cloneFromRootNotSource = true }: {
  headline: string,
  ydoc: YDocType,
  navigate?: NavigateFunction,
  fileSync: FileSync,
  propertyId: string,
  cloneFromRootNotSource?: boolean
}) {
  return (sourceDataRootKey: string) => {
    buildContractDocumentAndForm(ydoc, fileSync, sourceDataRootKey, sourceDataRootKey+META_APPEND, { noClauseOrAnnexureClone: true }).then(res=>{
      if (!res.formId) {
        console.error('Failed to create new form?');
        return;
      }
      const { dataRootKey: newRootKey, formFamilyCode, metaRootKey: newMetaKey } = YFormUtil.getFormLocationFromId(res.formId, ydoc)??{};
      if (!newRootKey) {
        console.error('Failed to retrieve new root key');
        return;
      }
      const currentPropertyData = ydoc.getMap(PropertyRootKey.Data).toJSON() as MaterialisedPropertyData | undefined;
      if (!currentPropertyData) {
        console.error('No property data?');
        return;
      }

      const baseMeta = materialisePropertyMetadata(ydoc);
      const originalFamilyState = baseMeta?.formStates?.[formFamilyCode??FormCode.RSC_ContractOfSale];
      const originalInstance = originalFamilyState?.instances?.[0];
      const baseClausesId = originalFamilyState?.clauseChildId;

      const newMeta = materialisePropertyMetadata(ydoc, newRootKey+META_APPEND);
      const newClauseId = newMeta.formStates?.[formFamilyCode??FormCode.RSC_ContractOfSale]?.clauseChildId;

      const currentVendors = currentPropertyData.vendors;
      const currentAddrs = currentPropertyData.saleAddrs;
      const currentTitles = currentPropertyData.saleTitles;
      const currentDivision = currentPropertyData.titleDivision;
      applyMigrationsV2_1<MaterialisedPropertyData|TransactionMetaData>({
        typeName: 'Property',
        doc: ydoc,
        docKey: newRootKey,
        migrations: [{
          name: 'Apply current Vendor to sublineage',
          fn: (draft: MaterialisedPropertyData) => {
            if (!Array.isArray(currentVendors)) {
              return false;
            }
            if (Array.isArray(draft.vendors)) {
              draft.vendors.splice(0,draft.vendors.length);
              draft.vendors.push(...currentVendors);
            } else {
              draft.vendors = currentVendors;
            }
          }
        }, {
          name: 'Apply current Property Addresses to sublineage',
          fn: (draft: MaterialisedPropertyData) => {
            if (!Array.isArray(currentAddrs)) {
              return false;
            }
            if (Array.isArray(draft.saleAddrs)) {
              draft.saleAddrs.splice(0,draft.saleAddrs.length);
              draft.saleAddrs.push(...currentAddrs);
            } else {
              draft.saleAddrs = currentAddrs;
            }
          }
        }, {
          name: 'Apply current Property Titles to sublineage',
          fn: (draft: MaterialisedPropertyData) => {
            if (!Array.isArray(currentTitles)) {
              return false;
            }
            if (Array.isArray(draft.saleTitles)) {
              draft.saleTitles.splice(0,draft.saleTitles.length);
              draft.saleTitles.push(...currentTitles);
            } else {
              draft.saleTitles = currentTitles;
            }
          }
        }, {
          name: 'Apply current Land division to sublineage',
          fn: (draft: MaterialisedPropertyData) => {
            if (!Array.isArray(currentDivision)) {
              return false;
            }
            draft.titleDivision = currentDivision;
          }
        },
        {
          name: 'Copy Annexures',
          docKey: newMetaKey,
          fn: (draft: TransactionMetaData) => {
            if (!formFamilyCode || !res.formId) return false;
            const instance = draft.formStates?.[formFamilyCode].instances?.find(i=>i.id===res.formId);
            // We're relying on the base data having only the Template Contract, which at the time
            // this is written, should still be true.
            if (!instance || !originalInstance) return false;
            if (!Array.isArray(instance.annexures)) instance.annexures = [];
            // Because this is a sublineage, the file references should still be sane, with respect to
            // permissions etc. Further, as there are no variations, the annexure list should be self
            // contained. As such we do not copy the files here
            instance.annexures.splice(0,instance.annexures.length, ...(originalInstance.annexures??[]));
          }
        }, {
          name: 'Reassign clauses',
          fn: (draft: MaterialisedPropertyData) => {
            // buildContractDocumentAndForm should have done a structured clone, so this data should
            // be here
            const clauseData = currentPropertyData.clausesByFamily?.find(cf=>cf.id === baseClausesId);
            if (clauseData && newClauseId) {
              draft.clausesByFamily = [{ ...clauseData, id: newClauseId }];
            } else {
              return false;
            }
          }
        }]
      });
      navigate?.(`/properties/${LinkBuilder.seoFriendlySlug(transId, headline)}/document/${LinkBuilder.seoFriendlySlug(res.formId, FormTypes[res.formCode].label)}`);
    });
  };
}