import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { LexicalRichTextEditor } from '../LexicalRichTextEditor';
import { CollectionRemoveButton } from './CollectionRemoveButton';
import { useTransactionField } from '../../hooks/useTransactionField';
import { Clause, ImportedClause } from '@property-folders/contract/property';
import { WrField } from './CommonComponentWrappers';
import { Expander } from '../Expander';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import { Icon } from '../Icon';
import { Button } from 'react-bootstrap';
import './ClauseEditor.scss';
import { memo, useEffect, useState } from 'react';
import { ClausesApi } from '@property-folders/common/client-api/clausesApi';
import clsJn from '@property-folders/common/util/classNameJoin';
import { EditorMode } from '@property-folders/components/dragged-components/RichTextEditor';

type Props = TransactionConsumerProps & {
  myIndex: number;
};

export function ClauseEditor(props: Props) {
  const isImported = (c: Clause): c is ImportedClause => 'importId' in c;
  const clauseIsRequired = (c: Clause) => 'required' in c && c.required;
  const clauseIsManaged = (c: Clause) => Boolean('managedBy' in c && c.managedBy);

  const {
    fullPath,
    handleRemove,
    value
  } = useTransactionField<Clause>(props);
  const { updateDraft } = useYdocBinder<Clause>({ path: fullPath });
  const [hasUpdate, setHasUpdate] = useState(false);
  const disabled = isImported(value) && (!value.canEdit || !value.editing);
  const [pendingUpdate, setPendingUpdate] = useState<null | { title: string; markdownClause: string; hash: string; }>(null);
  const startCollapsed = isImported(value) ? true : value.text?.length > 0;
  const counterStart = (props.myIndex ?? 0) + 1;

  useEffect(() => {
    if (!isImported(value) || !value.importId) {
      return;
    }

    ClausesApi.getUpdates([{
      clauseId: value.importId,
      hash: value.hash
    }])
      .then((u) => {
        if (!u) {
          return;
        }

        const { updates } = u;
        if (updates.length > 0) {
          setHasUpdate(true);
          setPendingUpdate(updates[0]);
        }
      })
      .catch(() => setHasUpdate(false));
  }, [value, isImported(value) ? value.importId : false]);

  const applyUpdate = () => {
    if (!pendingUpdate || !pendingUpdate.hash) {
      console.warn('Tried to apply non-existant update', value.id, pendingUpdate);
      return;
    }

    updateDraft?.(draft => {
      if (!isImported(draft)) {
        console.warn('Tried to apply update on custom clause');
        return;
      }

      draft.text = pendingUpdate.markdownClause;
      draft.title = pendingUpdate.title;
      draft.hash = pendingUpdate.hash;
      setHasUpdate(false);
    });
  };

  const enableEdit = () => {
    updateDraft?.(draft => {
      if (!draft) {
        return;
      }

      if (isImported(draft)) {
        draft.editing = true;
      }
    });
  };

  return <div className={clsJn('w-100 mb-3 d-flex clause-editor', clauseIsRequired(value) ? 'clause-required' : 'clause-not-required')}>
    <Expander collapsed={startCollapsed}>
      {({ ExpanderButton, Collapsible, collapsed, setCollapsed }) => {
        return (<>
          <div className='w-100'>
            <div className='d-flex'>
              <div className='w-100 d-flex'>
                <ExpanderButton />
                <div className='d-flex w-100'>
                  <div className={clsJn('form-control', { 'disabled': disabled })}
                    style={{ width: 'auto', borderRight: 0 }}>{counterStart}.
                  </div>
                  <WrField.Text
                    placeholder='Additional Condition name'
                    name='title'
                    parentPath={fullPath}
                    myPath='title'
                    disabled={disabled}
                    className='title border-left-0'
                  />
                  {disabled && value.canEdit &&
                        <Button variant='light' onClick={()=>{setCollapsed(false); enableEdit();}} className='clause-edit-button'>
                          <Icon name='edit' pack='material-symbols' />
                        </Button>}
                  {hasUpdate && <Button variant='light' onClick={applyUpdate} className='clause-edit-button'>
                    <Icon name='refresh' pack='material-symbols' />
                  </Button>}
                  {handleRemove && !clauseIsManaged(value) && !clauseIsRequired(value) && (
                    <CollectionRemoveButton removable={true} onRemove={handleRemove} />
                  )}
                </div>
              </div>
            </div>
            <Collapsible collapsed={collapsed}>
              <LexicalRichTextEditor
                parentPath={fullPath}
                myPath='text'
                disabled={disabled}
                className='editor'
                counterStart={counterStart}
                inputMode={EditorMode.MARKDOWN}
                outputMode={EditorMode.MARKDOWN}
              />
            </Collapsible>
          </div>
        </>
        );
      }}
    </Expander>
  </div>;
}

export const MemodClauseEditor = memo(ClauseEditor);
