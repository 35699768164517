import { InfinitePropertiesList } from '@property-folders/components/dragged-components/property/InfinitePropertiesList';
import { Properties } from '@property-folders/common/client-api/properties';
import { useLiveQuery } from 'dexie-react-hooks';
import {
  IPropertyCardDataProps,
  OnPropertyArchiveSetHandler,
  PropertyCardProps
} from '@property-folders/components/dragged-components/property/PropertyCard';
import { useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { db } from '@property-folders/common/offline/db';
import { byCreatedDateDesc, byManyAspects, byScoreDesc } from '@property-folders/common/util/sortComparison';

/**
 * Show archived filter is a toggle between "show only unarchived" and "show only archived"
 */
function meetsShowArchivedFilterRequirement(property: IPropertyCardDataProps, showArchived: boolean, archivalOverrides: Map<string, boolean>) {
  // This exists to remediate stale data from the onlineData retrieval, which are not live ydocs,
  // thus preventing property cards springing back when archiving them, as their local data
  // becomes unloaded and the onlineData result from before the archival asserts itself again.

  // I did consider a useEffect on onlineData to clear the local override, however I believe it is
  // still going through a local query cache, so triggers without actually retrieving data.
  // Whatever the case, when I had it, the springback action still occured.
  const isArchived = archivalOverrides.has(property.id)
    ? !!archivalOverrides.get(property.id)
    : !!property.meta?.archived;
  return showArchived
    ? isArchived
    : !isArchived;
}

/**
 * Show starred filter is a toggle between "show all" and "show only starred"
 */
function meetsShowStarredFilterRequirement(property: IPropertyCardDataProps, showStarred: boolean) {
  if (showStarred) return property.starred;

  return true;
}

function filterMergedPropertyCardProps(
  property: IPropertyCardDataProps,
  showStarred: boolean,
  archivalOverrides: Map<string, boolean>,
  showArchived: boolean
) {
  return meetsShowStarredFilterRequirement(property, showStarred) &&
    meetsShowArchivedFilterRequirement(property, showArchived, archivalOverrides);
}

export function OnlinePropertiesList(props: {
  searchTerm: string;
  showArchived: boolean;
  showStarred: boolean;
  starredProperties: Set<string>;
  archivalOverrides: Map<string, boolean>;
  onPropertyArchiveSet: OnPropertyArchiveSetHandler;
}) {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.invalidateQueries(['properties', 'search']);
  }, []);

  // since we're doing the search online, just get every offline property rather than searching there too.
  const offlineData = useLiveQuery(() => db.agentOfflineProperties.toArray());
  const {
    data: onlineData,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = Properties.useSearchInfiniteQuery({
    searchTerm: props.searchTerm,
    showArchived: props.showArchived,
    showStarred: props.showStarred,
    pageNumber: 1,
    pageSize: 10
  });

  const onlinePagesFlat = (onlineData?.pages ?? [])
    .map(i => i.items)
    .flat();

  // observing just onlineData.pages doesn't pick up changes
  // the array appears to be updated in place for individual page updates
  // noinspection com.intellij.reactbuddy.ExhaustiveDepsInspection
  const allOnlineItems = useMemo(() => {
    return onlinePagesFlat;
  }, [JSON.stringify(onlinePagesFlat)]);

  const items = useMemo(() => {
    const merged = PropertyCardProps.fromMergedOfflineOnlinePairs(
      offlineData || [],
      allOnlineItems,
      props.starredProperties
    )
      .filter(p => filterMergedPropertyCardProps(
        p,
        props.showStarred,
        props.archivalOverrides,
        props.showArchived));

    if (props.searchTerm) {
      merged.sort(byManyAspects<IPropertyCardDataProps>([byScoreDesc, byCreatedDateDesc]));
    } else {
      merged.sort(byCreatedDateDesc);
    }

    return merged;
  }, [
    allOnlineItems,
    offlineData,
    props.starredProperties,
    props.archivalOverrides,
    props.searchTerm,
    props.showStarred,
    props.showArchived
  ]);

  return <InfinitePropertiesList
    hasNextPage={hasNextPage}
    isFetching={isFetching}
    isFetchingNextPage={isFetchingNextPage}
    fetchNextPage={fetchNextPage}
    items={items}
    onPropertyArchiveSet={props.onPropertyArchiveSet}
  ></InfinitePropertiesList>;
}
