import { Card, ListGroup, Placeholder } from 'react-bootstrap';

export function EmptyPropertyCard() {
  return (
    <Card className={'property-card rounded-0'}>
      <ListGroup variant={'flush'}>
        <ListGroup.Item className={'card-header-item-group'}>
          <Placeholder as={'h5'} animation={'glow'}>
            <Placeholder xs={1}></Placeholder> <Placeholder xs={6}></Placeholder> <Placeholder xs={2}></Placeholder> <Placeholder xs={4}></Placeholder> <Placeholder xs={1}></Placeholder> <Placeholder xs={2}></Placeholder>
          </Placeholder>
        </ListGroup.Item>
        <ListGroup.Item>
          <div className={'key-value-row'}>
            <Placeholder as={'span'} animation={'glow'}>
              <Placeholder style={{ minWidth: '50px' }}></Placeholder>
            </Placeholder>
            <Placeholder as={'span'} animation={'glow'}>
              <Placeholder style={{ minWidth: '125px' }}></Placeholder>
            </Placeholder>
          </div>
          <div className={'key-value-row'}>
            <Placeholder as={'span'} animation={'glow'}>
              <Placeholder style={{ minWidth: '75px' }}></Placeholder>
            </Placeholder>
            <Placeholder as={'span'} animation={'glow'}>
              <Placeholder style={{ minWidth: '100px' }}></Placeholder>
            </Placeholder>
          </div>
        </ListGroup.Item>
      </ListGroup>
      <Card.Body>
        <ListGroup variant={'flush'}>
          <ListGroup.Item key={1}>
            <Placeholder animation={'glow'}>
              <Placeholder as={'span'} animation={'glow'}>
                <Placeholder style={{ minWidth: '40px' }}></Placeholder> <Placeholder style={{ minWidth: '50px' }}></Placeholder>
              </Placeholder>
            </Placeholder>
          </ListGroup.Item>
          <ListGroup.Item key={2}>
            <Placeholder animation={'glow'}>
              <Placeholder as={'span'} animation={'glow'}>
                <Placeholder style={{ minWidth: '20px' }}></Placeholder> <Placeholder style={{ minWidth: '10px' }}></Placeholder>
              </Placeholder>
            </Placeholder>
          </ListGroup.Item>
          <ListGroup.Item key={3}>
            <Placeholder animation={'glow'}>
              <Placeholder as={'span'} animation={'glow'}>
                <Placeholder style={{ minWidth: '30px' }}></Placeholder> <Placeholder style={{ minWidth: '10px' }}></Placeholder> <Placeholder style={{ minWidth: '20px' }}></Placeholder>
              </Placeholder>
            </Placeholder>
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>
      <Card.Footer className={'d-flex justify-content-end'}>
        <Placeholder animation={'glow'}>
          <Placeholder.Button variant={'outline-secondary'} xs={3}></Placeholder.Button>
        </Placeholder>
      </Card.Footer>
    </Card>
  );
}
