import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import FormCheck from 'react-bootstrap/FormCheck';
import { PaymentModal } from '../PaymentModal';
import {
  AgentSessionInfoResult,
  ContentType,
  FileRef,
  FormCode,
  ManifestData,
  ManifestType,
  MaterialisedPropertyData,
  PartyType,
  SaleAddress,
  SaleTitle,
  SigningAuthorityType,
  TransactionMetaData,
  UploadType,
  VendorParty
} from '@property-folders/contract';
import { CacheListResult, PropertySearchApi } from '@property-folders/common/client-api/propertySearchApi';
import { friendlyDateFormatter } from '@property-folders/common/util/formatting';
import { useLightweightTransaction, useTransactionField } from '../../hooks/useTransactionField';
import {
  API_CHARGE_PRICE_CENTS,
  ProprietorSearch,
  Searches,
  TITLE_PRICE_CENTS,
  TITLE_PRICE_STR,
  TitleSearch,
  VENDOR_PRICE_CENTS,
  VENDOR_PRICE_STR
} from '@property-folders/common/client-api/legacyApi';
import { IGetProprietorsResponse } from '@property-folders/common/client-api/IGetProprietorsResponse';
import { uuidv4 } from 'lib0/random';
import { ITitleSearchDeliveryResponse } from '@property-folders/common/client-api/ITitleSearchDeliveryResponse';
import { DateFunctions } from '@property-folders/common/util/date';
import { stringifySaleAddress } from '@property-folders/common/util/stringifySaleAddress';
import { Predicate } from '@property-folders/common/predicate';
import { VendorSearchType } from '@property-folders/common/types/VendorSearchType';
import { useIsSailisOutage } from '@property-folders/web/src/redux/useIsSailisOutage';
import { BinderFn, useImmerYjs } from '../../hooks/useImmerYjs';
import { YjsDocContext } from '../../context/YjsDocContext';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { updateAnnexureLabelsImmer } from '@property-folders/common/util/annexure';
import { FileSync } from '@property-folders/common/offline/fileSync';
import { applyMigrationsV2 } from '@property-folders/common/yjs-schema';
import * as Y from 'yjs';
import { FileSyncContext } from '../../context/fileSyncContext';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '@property-folders/components/display/errors/modals';
import { useForm } from '../../hooks/useForm';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { processPdf } from '@property-folders/common/util/pdf/pdf-process';
import { FileStorage, FileType, StorageItemSyncStatus } from '@property-folders/common/offline/fileStorage';
import { v4 } from 'uuid';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { composeStreetAddressFromParts } from '@property-folders/common/util/formatting/string-composites';
import { ModalDepthContext } from '../../context/WithinModal';

export type PurchaseOption = {
  text: string,
  price: string,
  priceCents: number,
  searchType: VendorSearchType;
  key: string;
  purchaseDate?: Date;
  addressGrouping?: string;
  addressGroupingId?: string;
  purchases: {
    text: string,
    price: string,
    priceCents: number,
    searchType: VendorSearchType;
    key: string;
    purchaseDate?: Date;
    addressGrouping?: string;
    addressGroupingId?: string;
  }[];
};
export type PurchaseOptions = PurchaseOption[];

interface DistinctAddress {
  address: string;
  purchaseOptions: PurchaseOptions;
}

export interface AddedTitle { title: string; propertyCacheId: string }

export function VendorImportModal(props: {
  show: boolean,
  onHide: () => void,
  entityId: string | number;
  setIsSearching: (searching: boolean) => void;
}) {
  const { ydoc } = useContext(YjsDocContext);
  const {
    binder: metaBinder
  } = useImmerYjs<TransactionMetaData>(ydoc, PropertyRootKey.Meta.toString());
  const { instance: fileSync } = useContext(FileSyncContext);
  const { value: property } = useLightweightTransaction<MaterialisedPropertyData>({ myPath: '' });
  const { value: saleAddrs } = useLightweightTransaction<SaleAddress[]>({ myPath: 'saleAddrs' });
  const { value: saleTitles, handleUpdate: updateSaleTitles } = useTransactionField<SaleTitle[]>({ myPath: 'saleTitles' });
  const { handleUpdate: updatePrimaryVendor } = useTransactionField<string>({ myPath: 'primaryVendor' });
  const { handleUpdate: updateVendors } = useTransactionField<VendorParty[]>({ myPath: 'vendors' });
  const { formName } = useForm();

  const [searches, setSearches] = useState<{ [key: string]: PurchaseOption }>({});
  const [purchaseOptions, setPurchaseOptions] = useState<PurchaseOptions>([]);
  const [paymentItems, setPaymentItems] = useState<Searches>();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [distinctAddresses, setDistinctAddresses] = useState<DistinctAddress[]>([]);
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();

  const findAddressIdsFromGnaf = (gnaf: string) => (saleAddrs?.filter(sa => sa.gnaf === gnaf).map(sa => sa.id) ?? []);
  const findAddressIdsByTitle = (name: string) => (saleTitles.find(t => t.title === name)?.linkedAddresses ?? []);

  const searchForVendor = async (token?: string) => {
    props.setIsSearching(true);
    props.onHide();
    const newVendors: VendorParty[] = [];
    const promises: Promise<(((IGetProprietorsResponse & { type: 'proprietor' }) | (ITitleSearchDeliveryResponse & { type: 'title', titleId: string })) & { linkedAddressId: string[] })[]>[] = [];

    const titleUpdates: { [titleId: string]: string } = {};
    for (const currentSearchKey of Object.keys(searches)) {
      const currentSearch = searches[currentSearchKey].searchType;

      if (currentSearch === 'name' || currentSearch.startsWith('cached-name')) {
        if (!Array.isArray(saleAddrs) || saleAddrs.length === 0) {
          return;
        }

        if (currentSearch === 'name') {
          promises.push(
            (PropertySearchApi.getVendorDetails(token as string, String(props.entityId ?? '')).response)
              .then(r => new Promise(a => a(r?.searches.map(s => ({
                ...s,
                linkedAddressId: findAddressIdsFromGnaf(s.meta.gnafId),
                type: 'proprietor'
              })) ?? [])))
          );
        } else {
          const id = currentSearch.substring('cached-name-'.length);
          promises.push(
            (PropertySearchApi.getCachedProprietors(id).response as Promise<IGetProprietorsResponse>)
              .then(r => new Promise(a => a([{
                ...r,
                linkedAddressId: findAddressIdsFromGnaf(r['g-naf']),
                type: 'proprietor'
              }])))
          );
        }
      } else if (currentSearch === 'address' || currentSearch.startsWith('cached-address')) {
        if (!Array.isArray(saleTitles) || saleTitles.length === 0) {
          return;
        }

        if (currentSearch === 'address') {
          promises.push(
            (PropertySearchApi.getTitle(token as string, String(props.entityId ?? '')).response)
              .then(r => new Promise(a => a(r?.searches.map(s => ({
                ...s,
                linkedAddressId: findAddressIdsByTitle(s.identifier.displayName),
                titleId: saleTitles.find(t => t.title === s.identifier.displayName)?.id ?? '',
                type: 'title'
              })) ?? [])))
          );
        } else {
          const id = currentSearch.substring('cached-address-'.length);
          promises.push(
            (PropertySearchApi.getCachedTitle(id).response as Promise<ITitleSearchDeliveryResponse>)
              .then(r => new Promise(a => a([{
                ...r,
                linkedAddressId: findAddressIdsByTitle(r.identifier.displayName) ?? [],
                titleId: saleTitles.find(t => t.title === r.identifier.displayName)?.id ?? '',
                type: 'title'
              }])))
          );
        }
      }
    }

    try {
      const res = await Promise.allSettled(promises);
      for (const promise of res) {
        if (promise.status !== 'fulfilled') {
          continue;
        }

        const response = promise.value;
        if (!response) {
          return;
        }
        for (const r of response) {
          if (r.type === 'proprietor') {
            newVendors.push(...r.proprietors.map(p => {
              const isOrganisation = !!p.acn;
              let abnOrAcn: string | undefined = undefined;
              if (isOrganisation && p.acn) {
                const acnStr = String(p.acn);

                abnOrAcn = acnStr.length <= 9
                  ? acnStr.padStart(9, '0')
                  : acnStr.padStart(11, '0');
              }

              return {
                id: uuidv4(),
                abn: abnOrAcn,
                authority: isOrganisation ? SigningAuthorityType.authRep : SigningAuthorityType.self,
                fullLegalName: isOrganisation ? p.organisationName : `${p.givenNames ?? ''} ${p.surname ?? ''}`.trim(),
                registeredOnTitle: true,
                partyType: isOrganisation ? PartyType.Corporation : PartyType.Individual,
                personName1: isOrganisation ? `${p.givenNames ?? ''} ${p.surname ?? ''}`.trim() : '',

                // whilst it's probably the case, we don't know for certain and it might look like we did if we ticked
                // this.
                addrSameAsSale: false,
                linkedAddresses: r.linkedAddressId ?? [],
                fromLssa: true
              };
            }));
          } else if (r.type === 'title') {
            for (const t of r.tenancies) {
              newVendors.push(...t.parties.map(p => {
                const addressSingleLine: string = (p.addressString ?? '');
                const addrSameAsSale = saleAddrs?.length === 1
                  && (saleAddrs[0].streetAddr.replaceAll(',', '') + ' ' + saleAddrs[0].subStateAndPost.replaceAll(',', '')).toLowerCase() === (addressSingleLine.replaceAll(',', '')).toLowerCase();

                return {
                  id: uuidv4(),
                  abn: p.acnStr,
                  authority: p.acn ? SigningAuthorityType.authRep : SigningAuthorityType.self,
                  fullLegalName: `${p.givenNames ?? ''} ${p.surname ?? ''}`.trim(),
                  registeredOnTitle: true,
                  partyType: p.acn ? PartyType.Corporation : PartyType.Individual,
                  addrSameAsSale,
                  linkedAddresses: r.linkedAddressId ?? [],
                  fromLssa: true,
                  addressSingleLine
                } as VendorParty;
              }));
            }

            if (r.titleId) {
              titleUpdates[r.titleId] = r.meta.id;
            }
          }
        }
      }

      updateVendors(Array.from(new Set(newVendors)), true);
      if (newVendors.length > 0) {
        updatePrimaryVendor(newVendors[0].id, true);
      }

      const tracked: AddedTitle[] = [];
      const updatedSaleTitles = saleTitles.map(st => {
        const propertyCacheId = st.propertyCacheId ?? titleUpdates[st.id];
        if (propertyCacheId) {
          tracked.push({ title: st.title, propertyCacheId });
        }
        return {
          ...st,
          propertyCacheId
        };
      });
      updateSaleTitles(updatedSaleTitles);

      await ingestTitles({
        titles: tracked,
        metaBinder,
        property,
        doc: ydoc,
        docKey: PropertyRootKey.Meta.toString(),
        fileSync,
        sessionInfo
      });
    } finally {
      props.setIsSearching(false);
    }
  };

  const onOrderClick = (purchases: PurchaseOptions) => {
    // remove no-ops
    purchases = purchases.filter(p => p.searchType !== 'none');

    // no purchases, just search.
    if (!purchases.find(p => !(p.searchType.startsWith('cached-')))) {
      searchForVendor();
      return;
    }

    setPaymentItems(
      purchases
        .filter(p => !p.searchType.startsWith('cached-'))
        .filter(Predicate.isNotNull)
        .map(p => (
          p.purchases.map(sp => {
            switch (sp.searchType) {
              case 'name': {
                const saleAddr = saleAddrs?.find(t => t.id === sp.key) as SaleAddress;
                const address = composeStreetAddressFromParts(saleAddr.streetAddr_parts) || `${saleAddr.streetAddr}, ${saleAddr.subStateAndPost}`;

                return {
                  address: address,
                  type: 'proprietor',
                  gnaf: saleAddr.gnaf as string,
                  customName: `${address} (${saleAddr.gnaf})`
                } as ProprietorSearch ;
              }

              default: {
                const title = saleTitles?.find(sa => sa.id === sp.key) as SaleTitle;
                let address = '';

                if (title.linkedAddresses?.length) {
                  const saleAddress = saleAddrs?.find(t => t.id === title.linkedAddresses?.at(0));
                  if (saleAddress) {
                    address = composeStreetAddressFromParts(saleAddress.streetAddr_parts) || `${saleAddress.streetAddr}, ${saleAddress.subStateAndPost}`;
                  }
                }

                if (!address) {
                  address = sp.addressGrouping || '';
                }

                return {
                  address,
                  type: 'title_pf',
                  title: title.title,
                  customName: `${address} (${title.title})`
                } as TitleSearch;
              }
            }
          })
        )).flat()
    );

    setShowPaymentModal(true);
    props.onHide();
  };

  useEffect(() => {
    if (!props.show) {
      return;
    }

    if (!saleTitles || !saleAddrs) {
      return;
    }

    let newPurchaseOptions: PurchaseOptions = [
      // 1 per address
      ...saleAddrs.filter(t => t.gnaf).map(t => ({
        text: 'Registered proprietor names',
        price: VENDOR_PRICE_STR,
        priceCents: VENDOR_PRICE_CENTS,
        searchType: 'name',
        key: t.id,
        addressGrouping: stringifySaleAddress(t),
        addressGroupingId: t.id,
        purchases: [{
          text: 'Registered proprietor names',
          price: VENDOR_PRICE_STR,
          priceCents: VENDOR_PRICE_CENTS,
          searchType: 'name',
          key: t.id,
          addressGrouping: stringifySaleAddress(t),
          addressGroupingId: t.id
        }]
      }))
    ];

    const filteredTitles: PurchaseOptions = saleTitles.filter(t => t.fromLssa && t.linkedAddresses?.length).map(t => ({
      text: 'Registered proprietor names, addresses and title details report',
      price: TITLE_PRICE_STR,
      priceCents: TITLE_PRICE_CENTS,
      searchType: 'address',
      key: t.id,
      addressGrouping: stringifySaleAddress(saleAddrs.find(sa => sa.id === t.linkedAddresses?.at(0)) as SaleAddress),
      addressGroupingId: saleAddrs.find(sa => sa.id === t.linkedAddresses?.at(0))?.id,
      purchases: []
    }));

    newPurchaseOptions.push(...saleAddrs.map(addr => {
      const matchingTitles = filteredTitles.filter(t => t.addressGroupingId === addr.id);
      const total = (TITLE_PRICE_CENTS + API_CHARGE_PRICE_CENTS) * matchingTitles.length;

      return matchingTitles.length > 0
        ? {
          text: 'Registered proprietor names, addresses and title details report',
          price: (total / 100).toFixed(2),
          priceCents: total,
          searchType: 'address',
          key: matchingTitles[0].key,
          addressGrouping: matchingTitles[0].addressGrouping,
          addressGroupingId: matchingTitles[0].addressGroupingId,
          purchases: matchingTitles
        }
        : undefined;
    }).filter(Predicate.isNotNull));

    const promises = [];
    for (const address of saleAddrs) {
      if (!address.gnaf) {
        continue;
      }

      promises.push(
        PropertySearchApi.listCachedProprietors(address.gnaf).response
          .then(r => new Promise(a => a({
            result: r,
            type: 'cached-name',
            addressGrouping: stringifySaleAddress(address),
            addressGroupingId: address.id
          })))
      );
    }
    for (const title of saleTitles) {
      if (!title.fromLssa) {
        continue;
      }

      promises.push(
        PropertySearchApi.listCachedTitles(title.title).response
          .then(r => new Promise(a => a({
            result: r,
            type: 'cached-address',
            addressGrouping: stringifySaleAddress(saleAddrs.find(sa => sa.id === title.linkedAddresses?.at(0)) as SaleAddress),
            addressGroupingId: saleAddrs.find(sa => sa.id === title.linkedAddresses?.at(0))?.id
          })))
      );
    }

    Promise.allSettled(promises).then(res => {
      for (let i = 0; i < res.length; i++) {
        const r = res[i];
        if (r.status === 'rejected') {
          continue;
        }

        const value: { type: 'cached-name' | 'cached-address', result: CacheListResult, addressGrouping: string, addressGroupingId: string } = r.value as any;
        const type = value.type;
        const text = type === 'cached-name' ? 'names' : 'names and addresses';
        for (const v of value.result.Results ?? []) {
          newPurchaseOptions.push({
            text: `Previously purchased ${text} on ${friendlyDateFormatter(new Date(v.PurchaseDate))}`,
            price: '0.00',
            priceCents: 0,
            searchType: `${type}-${v.Id}`,
            key: v.Id,
            purchaseDate: new Date(v.PurchaseDate),
            addressGrouping: value.addressGrouping,
            addressGroupingId: value.addressGroupingId,
            purchases: []
          });
        }
      }
    }).catch(e => {
      console.error(e);
    }).finally(() => {
      const namesToFilter = newPurchaseOptions.filter(npo => npo.searchType.startsWith('cached-name') && (npo.purchaseDate ?? Date.now()) > DateFunctions.Yesterday());
      if (namesToFilter.length > 0) {
        newPurchaseOptions = newPurchaseOptions.filter(npo => (
          npo.searchType !== 'name'
          || !namesToFilter.find(n => n.addressGrouping === npo.addressGrouping)
        ));
      }

      const addressesToFilter = newPurchaseOptions.filter(npo => npo.searchType.startsWith('cached-address') && (npo.purchaseDate ?? Date.now()) > DateFunctions.Yesterday());
      if (addressesToFilter.length > 0) {
        newPurchaseOptions = newPurchaseOptions.filter(npo => (
          npo.searchType !== 'address'
          || !addressesToFilter.find(n => n.addressGrouping === npo.addressGrouping)
        ));
      }

      setPurchaseOptions(newPurchaseOptions);
    });

  }, [props.show]);

  useEffect(() => {
    const tmpDistinctAddresses: DistinctAddress[] = [...new Set(purchaseOptions.map(po => po.addressGrouping))].map(da => ({
      address: da as string,
      purchaseOptions: purchaseOptions.filter(po => po.addressGrouping === da) as PurchaseOptions
    }));

    if (tmpDistinctAddresses.length > 1) {
      for (const da of tmpDistinctAddresses) {
        da.purchaseOptions.push({
          key: `none-${da.address}`,
          addressGrouping: da.address,
          searchType: 'none',
          price: '0.00',
          priceCents: 0,
          text: 'None',
          purchases: []
        });
      }
    }

    setSearches(original => {
      const copy = { ...original };

      for (const da of tmpDistinctAddresses) {
        if (!copy[da.address]) {
          // priority for checked search is: previously purchased address, previously purchased names, names
          copy[da.address] = (da.purchaseOptions.find(s => s.searchType.startsWith('cached-address')) ?? da.purchaseOptions.find(s => s.searchType.startsWith('cached-name-')) ?? da.purchaseOptions.find(s => s.searchType === 'name')) as PurchaseOption;
        }
      }

      for (const address of Object.keys(copy)) {
        if (!tmpDistinctAddresses.find(da => da.address === address)) {
          delete copy[address];
        }
      }

      return copy;
    });
    setDistinctAddresses(tmpDistinctAddresses);
  }, [purchaseOptions]);

  const totalPrice = Object.keys(searches ?? {}).reduce((ac, cur) => {
    if (!searches[cur]) {
      return ac;
    }

    return ac + Number(searches[cur].price);
  }, 0).toFixed(2);

  const { modalClassName, backdropClassName } = useContext(ModalDepthContext);

  return <>
    <Modal
      show={props.show}
      centered={true}
      onHide={props.onHide}
      className={modalClassName}
      backdropClassName={backdropClassName}
    >
      <Modal.Header><h4>Order records held by Land Services SA</h4></Modal.Header>

      <Modal.Body>
        {distinctAddresses.map((da, index) => <>
          {distinctAddresses.length > 1 && <h5 key={da.address} className={index !== 0 ? 'mt-3' : ''}>{da.address}</h5>}
          {da.purchaseOptions.map(po => <FormCheck
            key={po.key}
            id={po.key}
            name='order-type'
          >
            <FormCheck.Input
              type='radio'
              checked={searches[da.address]?.searchType === po.searchType}
              value={po.searchType}
              onClick={e => {
                setSearches(cur => ({
                  ...cur,
                  [da.address]: po
                }));
              }}
            />
            <FormCheck.Label className='d-flex w-100'>
              <span>{po.text}</span>
              <span className='ms-auto'>${po.price}</span>
            </FormCheck.Label>
          </FormCheck>)}
        </>)}
        <div className="d-flex justify-content-end mt-3">
          <span>Sub Total: ${totalPrice}</span>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <Button
            variant="light"
            onClick={props.onHide}
            className='me-2'
          >Cancel</Button>
          <Button disabled={useIsSailisOutage()} onClick={() => {
            onOrderClick(Object.keys(searches).map(s => searches[s]));
          }}>Order</Button>
        </div>
      </Modal.Body>
    </Modal>

    <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} show={!!showPaymentModal} onClose={()=>setShowPaymentModal(false)} /> }>
      <PaymentModal
        show={showPaymentModal}
        setShow={setShowPaymentModal}
        onPayment={(token) => {
          searchForVendor(token);
        }}
        entityId={props.entityId}
        searches={paymentItems}
        formUrl={formName === 'newTrans' && property?.id
          ? `${window.location.origin}/properties/${LinkBuilder.seoFriendlySlug(property.id, generateHeadlineFromMaterialisedData(property))}`
          : undefined
        } // default to window.location.href
      />
    </ErrorBoundary>
  </>;
}

/**
 * Download titles as uploaded documents, and also add them to the rsaa if relevant
 */
async function ingestTitles({
  doc,
  docKey,
  fileSync,
  metaBinder,
  property,
  titles,
  sessionInfo
}: {
  doc?: Y.Doc,
  docKey: string,
  fileSync?: FileSync,
  metaBinder: BinderFn<TransactionMetaData>,
  property?: MaterialisedPropertyData,
  titles: AddedTitle[],
  sessionInfo?: AgentSessionInfoResult
}) {
  if (!titles.length) return;
  if (!metaBinder) return;
  if (!doc) return;
  if (!property) return;
  if (!fileSync) return;

  const uploadedDocs = metaBinder.get()?.formStates?.[FormCode.UploadedDocument]?.instances || [];
  const titlesToAdd = titles
    .filter(title => !uploadedDocs.some(d => d.upload && d.upload.linkedTitle === title.title));
  const downloaded = new Array<AddedTitle & { file: FileRef }>();
  const manifestData: ManifestData = {
    manifestType: ManifestType.None
  };

  for (const title of titlesToAdd) {
    const response = await fetch(PropertySearchApi.getPdfFromCache(title.propertyCacheId));
    const pdf = await processPdf(await response.arrayBuffer());
    if (!pdf?.pdf || pdf?.isEncrypted) {
      console.error('could not add title', title.title, 'pdf failed to download or was encrypted', pdf);
      continue;
    }

    const fileId = v4();
    await FileStorage.write(
      fileId,
      FileType.PropertyFile,
      ContentType.Pdf,
      new Blob([pdf.pdf.buffer], { type: ContentType.Pdf }),
      StorageItemSyncStatus.PendingUpload,
      {
        propertyFile: {
          propertyId: property.id,
          formId: fileId,
          formCode: FormCode.UploadedDocument
        }
      },
      { store: fileSync.store, ydoc: doc },
      manifestData,
      undefined
    );
    downloaded.push({ ...title, file: { id: fileId, contentType: ContentType.Pdf } });
  }

  if (downloaded.length) {
    applyMigrationsV2<TransactionMetaData>({
      doc,
      docKey,
      typeName: 'Property',
      migrations: [{
        name: 'Add titles as uploaded docs',
        fn: draft => {
          if (!draft.formStates) {
            draft.formStates = {};
          }
          if (!draft.formStates[FormCode.UploadedDocument]) {
            draft.formStates[FormCode.UploadedDocument] = {};
          }
          if (!draft.formStates[FormCode.UploadedDocument].instances) {
            draft.formStates[FormCode.UploadedDocument].instances = [];
          }

          const uploadedDocs = draft.formStates[FormCode.UploadedDocument].instances;
          for (const candidate of downloaded) {
            if (uploadedDocs.some(instance => instance.upload?.linkedTitle === candidate.title)) continue;

            console.log('adding', candidate.title, 'uploaded doc');
            uploadedDocs.push({
              id: candidate.file.id,
              formCode: FormCode.UploadedDocument,
              created: Date.now(),
              modified: Date.now(),
              upload: {
                id: candidate.file.id,
                contentType: candidate.file.contentType,
                linkedTitle: candidate.title,
                type: UploadType.TitleDetails,
                name: `Land Services SA - Title Details Report - ${candidate.title}`,
                uploader: sessionInfo?.agentUuid ? {
                  id: sessionInfo.agentUuid,
                  linkedSalespersonId: sessionInfo.agentId,
                  name: sessionInfo?.name,
                  email: sessionInfo?.email
                } : undefined
              }
            });
          }

          const rsaa = draft.formStates[FormCode.RSAA_SalesAgencyAgreement]?.instances?.find(i => i.formCode === FormCode.RSAA_SalesAgencyAgreement);
          if (!rsaa) return;

          if (!rsaa.annexures) {
            rsaa.annexures = [];
          }
          const annexures = rsaa.annexures;

          for (const title of titles) {
            const uploaded = uploadedDocs.find(ud => ud.upload?.linkedTitle === title.title);
            if (!uploaded?.upload) continue;

            const existing = annexures.find(a => 'linkedTitle' in a && a.linkedTitle === title.title);
            if (existing) continue;

            console.log('adding rsaa annexure...');
            annexures.push({
              id: uploaded.upload.id,
              contentType: uploaded.upload.contentType,
              noEditRemove: true,
              name: `Land Services SA - Title Details Report - ${title.title}`,
              linkedTitle: title.title,
              preserveFile: true,
              coversheetText: '',
              uploadType: UploadType.TitleDetails,
              linkedFormId: rsaa.id
            });
          }

          updateAnnexureLabelsImmer(annexures);
        }
      }]
    });
  }

  FileSync.triggerSync(fileSync);
}
