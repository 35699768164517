import { VendorParty, VendorPartyJoint, jointTypes } from '@property-folders/contract';
import { useLightweightTransaction } from '../../hooks/useTransactionField';
import { CollectionEditor } from './CollectionEditor';
import { PartyAuthorityInput } from './PartyAuthorityInput';

export interface VendorCollectionProps {
  autoAddFirst?: boolean
  useTitle?: boolean
  childProps?: {[key:string]: any}
  triggerImport?: any
  collectionMaskingText?: string
}

export function VendorCollection({ autoAddFirst, useTitle = true, childProps, collectionMaskingText, setHeaderActions }: VendorCollectionProps & { setHeaderActions?: ()=>void } ) {
  const { value: vendors } = useLightweightTransaction<(VendorParty|VendorPartyJoint)[]>({ myPath: 'vendors' });
  const hrEnable = vendors?.some(v=>jointTypes.includes(v.partyType));

  return <>
    <CollectionEditor
      title={useTitle ? (((vendors?.length ?? 0) > 1) ? 'Vendors' : 'Vendor') : undefined}
      myPath='vendors'
      primaryIdAbsPath={['primaryVendor']}
      setPrimaryIdToFirst={true}
      autoAddFirst={autoAddFirst ?? false}
      childItemRenderer={PartyAuthorityInput}
      itemNoun='Vendor'
      restorationFieldDisplay='fullLegalName'
      addTooltip='Add additional Vendors. You should add any Vendor, or their representative, if they are named on the Title of the Property being sold.'
      childProps={{ enableSameAsSaleAddr: true, ...childProps }}
      collectionMaskingText={collectionMaskingText}
      setHeaderActions={setHeaderActions}
      hrEnable={hrEnable}
      emptyListErrorMessage='At least one vendor is required for signing'
    />
  </>;
}
