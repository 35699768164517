import { useContext } from 'react';
import { fullPathJoin, useTransactionField } from '@property-folders/components/hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { exclusionOptions } from '@property-folders/common/data-and-text/constants';
import { CheckMultiSelect } from '../CheckMultiSelect';
import { WizardDisplayContext } from '@property-folders/components/context/WizardContexts';
import { composeErrorPathClassName } from '@property-folders/common/util/formatting';
import clsJn from '@property-folders/common/util/classNameJoin';
import { TooltipWhenRequired } from '@property-folders/components/dragged-components/TooltipWhenDisabled';

const requiredTipText = 'Specify excluded chattels. Select None Applicable if there are none';

export const Exclusions = (props: TransactionConsumerProps) => {
  const { required } = useTransactionField(props);
  const fullPath = fullPathJoin(props);
  const { showFocusErrors, focusErrList } = useContext(WizardDisplayContext);
  const errorFocusTarget = composeErrorPathClassName([...fullPath.split('.'), 'simple'], '');
  const showExclusionsError = showFocusErrors && focusErrList.includes(errorFocusTarget);

  return <div className={clsJn('subsection scrollspy-target', errorFocusTarget)} data-focus-path="subsection-exclusions">
    <TooltipWhenRequired title={requiredTipText??''} required={required}>
      <div className='fs-4 mb-3'>Exclusions{required ? <sup className='fs-5' style={{ color: 'red' }}> *</sup> : null}</div>
    </TooltipWhenRequired>
    {showExclusionsError && <div className='d-block invalid-feedback mb-3'>{requiredTipText}</div>}
    <CheckMultiSelect
      options={exclusionOptions}
      myPath='simple'
      parentPath={fullPath}
      allowOthers={true}
      otherAddText='Add Exclusion'
      otherTitle='Other Exclusions'
      tileWidth={300}
    />
  </div>;
};
