import { generateStandardFieldWrapping } from './CommonComponentWrappers';
import { useTransactionField } from '../../hooks/useTransactionField';
import { Form } from 'react-bootstrap';
import clsJn from '@property-folders/common/util/classNameJoin';

interface Props {
  parentPath: string;
  myPath: string;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  duplicate?: boolean;
  autoFocus?: boolean;
}

export function Title({ parentPath, myPath, onChange, onBlur, onFocus, duplicate, autoFocus }: Props) {
  const {
    value, handleUpdate, valid, errorKeys, required, justUpdated, handleAwarenessBlur, handleAwarenessFocus, fullPath, hasVaried
  } = useTransactionField({ parentPath, myPath });

  return generateStandardFieldWrapping('' +
    'Title',
  errorKeys,
  required,
  fullPath,
  <Form.Control
    className={clsJn(value && (valid && !duplicate ? '' : 'is-invalid'), hasVaried && 'varied-control')}
    style={justUpdated ? { backgroundColor: 'lightgrey' } : {}}
    name='title'
    type='text'
    placeholder='CT ####/###'
    value={value ?? ''}
    autoFocus={autoFocus}
    onChange={e => {
      // We are overriding paste behaviour if it involves an email address within angle brackets
      const nativeEvt = e.nativeEvent as InputEvent;
      const isDropInsert = nativeEvt.inputType === 'insertFromDrop';
      let val = isDropInsert ? nativeEvt.data : e.target?.value;
      // We are taking the 'data', meaning in a paste, even if you insert into the middle of
      // something, the preparser will only get what was pasted

      if (val !== null) {
        val = val.toUpperCase();

        if (val.length === 1) {
          const valIsNumber = !isNaN(Number(val));
          if (
            !['C', 'T', 'L', 'R'].includes(val)
              && !valIsNumber
          ) {
            return;
          } else if (valIsNumber) {
            val = `CT ${val}`;
          } else if (val !== 'C') {
            val = `C${val}`;
          }
        }

        if (val.length >= 2 && val[2] !== ' ') {
          if (val.length === 2 && value.length >= 2) {
            val = val.substring(0, 1);
          } else {
            val = val.substring(0, 2) + ' ' + val.substring(2);
          }
        }

        if (val.length >= 7 && val[7] !== '/') {
          // backspacing - remove / and previous number
          if (val.length === 7 && value.length >= 7) {
            val = val.substring(0, 6);
          } else {
            val = val.substring(0, 7) + '/' + val.substring(7);
          }
        }

      }
      handleUpdate(val, isDropInsert, nativeEvt.data ? 'insert' : undefined);
      onChange?.(e);
    }}
    onBlur={e => {
      handleAwarenessBlur(e);
      handleUpdate(e.target?.value, true);
      onBlur?.(e);
    }}
    onFocus={e => {
      handleAwarenessFocus(e);
      onFocus?.(e);
    }}
  />,
  undefined,
  fullPath
  );
}
