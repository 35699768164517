import { WizardStepPage, WizardStepPageProps } from '@property-folders/components/dragged-components/Wizard/WizardStepPage';
import { ContractSettlement } from '@property-folders/components/dragged-components/form/SaleContract/ContractSettlement';

export function ContractSettlementWizardStepPage(props: Partial<WizardStepPageProps>) {
  return <WizardStepPage
    name='contractSettlement'
    label="Settlement"
    icon="schedule"
    pdfFocus='subsection-contract-settlement'
    {...props}
  >
    <ContractSettlement myPath='contractSettlement' />
  </WizardStepPage>;
}
