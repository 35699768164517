import { useContext, useEffect, useState } from 'react';
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap';
import { CompletionStatus } from './SigningState';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { SigningParty, SigningPartySourcePurchasers, SigningPartySourceVendors } from '@property-folders/contract';
import { testEmail, testPhone } from '@property-folders/common/yjs-schema/property/validation/process-validation';
import { PropertyFormYjsDal } from '@property-folders/common/yjs-schema/property/form';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { Predicate } from '@property-folders/common/predicate';

function getHeader(status: CompletionStatus, dotCount: number) {
  switch (status) {
    case CompletionStatus.Completing:
      return `Uploading Signing Data${'.'.repeat(dotCount)}`;
    case CompletionStatus.AuthoringPdf:
      return `Uploading Signing Data${'.'.repeat(dotCount)}`;
    case CompletionStatus.Complete:
      return 'Signing Complete!';
    case CompletionStatus.Failed:
      return 'Upload Failed!';
  }
}

enum DistributionEmailOrPhoneState {
  Unknown,
  PreExisting,
  CanBeSet
}

export function SigningCompletionModal({
  status,
  clearStatus,
  requiresMoreSignatures,
  onContinue,
  partyPath,
  partyId,
  formCode,
  formId,
  signingSessionId
}: {
  status: CompletionStatus,
  clearStatus: () => void,
  requiresMoreSignatures?: boolean,
  onContinue: () => void,
  partyPath: string,
  partyId: string,
  formCode: string,
  formId: string,
  signingSessionId: string,
}) {
  const { ydoc, transactionRootKey, transactionMetaRootKey } = useContext(YjsDocContext);
  const {
    value: signingParty
  } = useLightweightTransaction<SigningParty>({
    parentPath: partyPath,
    bindToMetaKey: true
  });
  const formDal = ydoc ? new PropertyFormYjsDal(ydoc, transactionRootKey, transactionMetaRootKey) : undefined;
  const [partyEmailAddress, setPartyEmailAddress] = useState('');
  const [partyPhone, setPartyPhone] = useState('');
  const emailAddressValid = !partyEmailAddress || testEmail(partyEmailAddress);
  const phoneValid = !partyPhone || testPhone(partyPhone);
  const [distributionEmailState, setDistributionEmailState] = useState(DistributionEmailOrPhoneState.Unknown);
  useEffect(() => {
    if (!signingParty) {
      return;
    }

    // We are aiming to get some comms to the party, not the proxy
    const commsIsSet = signingParty.snapshot?.email || signingParty.snapshot?.phone;

    setDistributionEmailState(commsIsSet
      ? DistributionEmailOrPhoneState.PreExisting
      : DistributionEmailOrPhoneState.CanBeSet);
  }, [!!signingParty]);
  const [closed, setClosed] = useState(false);
  const [dotCount, setDotCount] = useState<number>(0);

  const isCompleting = status === CompletionStatus.Completing;

  useEffect(() => {
    if (!isCompleting) {
      return;
    }
    const timer = setTimeout(() => {
      setDotCount((dotCount + 1) % 4);
    }, 1000);

    return () => clearTimeout(timer);
  }, [isCompleting, dotCount]);

  const complete = () => {
    if (!formDal) {
      return;
    }
    if (distributionEmailState === DistributionEmailOrPhoneState.CanBeSet && (partyEmailAddress || partyPhone) && emailAddressValid && phoneValid) {
      formDal.setPartyEmailAndPhone(formCode, formId, signingSessionId, partyId, partyEmailAddress, partyPhone);
    }
    onContinue();
    setClosed(true);
  };

  return (
    <Modal show={!closed} onClose={() => { /**/ }}>
      <Modal.Header><h3>{getHeader(status, dotCount)}</h3></Modal.Header>
      <Modal.Body>
        {status === CompletionStatus.Completing &&
          <div className={'d-flex flex-column g-4'}>
            <p>Please wait a moment.</p>
            <p>Completing your signing session.</p>
          </div>
        }
        {status === CompletionStatus.AuthoringPdf &&
          <div className={'d-flex flex-column g-4'}>
            <p>Please wait a moment.</p>
            <p>Authoring final document.</p>
          </div>
        }
        {status === CompletionStatus.Complete &&
          <div className={'d-flex flex-column g-4'}>
            {distributionEmailState === DistributionEmailOrPhoneState.PreExisting &&
              <p>A link to view the completed document will be sent to your email address at {signingParty?.snapshot?.email}</p>}
            {distributionEmailState === DistributionEmailOrPhoneState.CanBeSet && partyPath &&
              <>
                <p>If you provide an email address or phone number, a copy of the completed document will be sent to {Predicate.proxyNotSelf(signingParty?.proxyAuthority) ? `the ${SigningPartySourcePurchasers.includes(signingParty.source.type) ? 'Purchaser' : SigningPartySourceVendors.includes(signingParty.source.type) ? 'Vendor' : 'party'} you represent` : 'you'}.</p>
                <FloatingLabel label={'Email'}>
                  <Form.Control
                    value={partyEmailAddress}
                    onChange={e => setPartyEmailAddress(e.target.value)}
                    isInvalid={!emailAddressValid}
                  />
                  <Form.Control.Feedback type={'invalid'}>Must be valid email address.</Form.Control.Feedback>
                </FloatingLabel>
                <FloatingLabel label={'Phone'} className='mt-2'>
                  <Form.Control
                    value={partyPhone}
                    onChange={e => setPartyPhone(e.target.value)}
                    isInvalid={!phoneValid}
                  />
                  <Form.Control.Feedback type={'invalid'}>Must be valid phone number.</Form.Control.Feedback>
                </FloatingLabel>
              </>}
            {/*
              If we conditionally show this based on whether an email address was provided,
              then we have the unfortunate situation of pushing down the continue button when the user clicks on it (change of focus).
              Very annoying! so just show a more general message always.
            */}
            {requiresMoreSignatures &&
              <p>This document requires one or more signatures from other parties. If you have provided an email address, we will notify you once all signatures are received.</p>}
          </div>
        }
        {status === CompletionStatus.Failed &&
          <p>Signing data upload failed! Please try again.</p>
        }
      </Modal.Body>
      <Modal.Footer>
        {status === CompletionStatus.Failed && <Button variant={'primary'} className={'mr-3'} onClick={() => clearStatus()}>Close</Button>}
        {status === CompletionStatus.Complete && <Button variant={'primary'} className={'mr-3'} disabled={distributionEmailState === DistributionEmailOrPhoneState.CanBeSet && !emailAddressValid && !phoneValid} onClick={complete}>Continue</Button>}
      </Modal.Footer>
    </Modal>
  );
}
