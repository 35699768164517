import { HumanizedValueToken } from '@property-folders/contract/rest/property';
import React from 'react';
import _ from 'lodash';
import { rawTextToHtmlParagraphs } from '@property-folders/common/util/formatting';

function IdentifyingText({ text }: {text?: string}) {
  return text
    ? <span className={'ps-2 identifying-text'}>{text}</span>
    : <></>;
}

export function Desc({ desc }: {desc?: HumanizedValueToken}) {
  return (() => {
    switch (desc?.type) {
      case 'list':
        return <div className={'d-flex flex-column w-100 audit-list'}>
          <IdentifyingText text={desc.identifyingText} />
          <ul>
            {desc.items.map((item, idx) => (
              <li key={idx}><Desc desc={item} /></li>
            ))}
          </ul>
        </div>;
      case 'group':
        return <div className={'d-flex flex-column w-100 audit-group'}>
          <IdentifyingText text={desc.identifyingText} />
          {desc.items.map((item, idx) => (
            <Desc key={idx} desc={item} />
          ))}
        </div>;
      case 'text':
        return <span className={'p-2 audit-text'}>{desc.text}</span>;
      case 'value':
        return (() => {
          switch (desc.mode) {
            case 'del':
              return <del className={'ps-2 w-100 wrap-text audit-value'}>{rawTextToHtmlParagraphs(desc.text)}</del>;
            case 'ins':
              return <ins className={'ps-2 w-100 wrap-text audit-value'}>{rawTextToHtmlParagraphs(desc.text)}</ins>;
            default:
              return <span className={'ps-2 w-100 wrap-text audit-value'}>{rawTextToHtmlParagraphs(desc.text)}</span>;
          }
        })();
      case 'change':
        if (desc.from.length && desc.to.length) {
          return <div className={'d-flex flex-column w-100 audit-change-from-to'}>
            <div className={'d-flex flex-row w-100 audit-change-from'}>
              {desc.from.map((item, idx) => (
                <Desc key={idx} desc={item}/>
              ))}
            </div>
            <div className={'d-flex flex-row w-100 audit-change-to'}>
              {desc.to.map((item, idx) => (
                <Desc key={idx} desc={item}/>
              ))}
            </div>
          </div>;
        }

        if (desc.from.length) {
          return <div className={'d-flex flex-row audit-change-from'}>
            {desc.from.map((item, idx) => (
              <Desc key={idx} desc={item}/>
            ))}
          </div>;
        }

        return <div className={'d-flex flex-row audit-change-to'}>
          {desc.to.map((item, idx) => (
            <Desc key={idx} desc={item}/>
          ))}
        </div>;
      default:
        return <></>;
    }
  })();
}

export function AuditChange({ change: { path, desc } }: { change: {path: string, desc?: HumanizedValueToken | undefined} }) {
  return <div className={'d-flex flex-column w-100 audit-change'}>
    <span>{path}</span>
    <Desc desc={desc}/>
  </div>;
}

export const MemoAuditChange = React.memo(AuditChange, _.isEqual);
