import { YManager } from '@property-folders/common/offline/yManager';
import { YDocContentType } from '@property-folders/contract';
import { getKeyFromPropertyId } from '@property-folders/common/yjs-schema/property-stats';

export const buildYDoc = (docName: string, local?: boolean, yManagerInstance?: YManager) => {
  const preferLocal = local || false;
  const yManager = yManagerInstance || YManager.instance();
  const entry = yManager.get(docName, YDocContentType.Property, {
    preferLocal
  });
  const stats = preferLocal
    ? undefined
    : yManager.get(getKeyFromPropertyId(docName), YDocContentType.PropertyStats, {
      preferLocal
    });

  return {
    ydoc: entry.doc,
    ydocStats: stats?.doc,
    localProvider: entry.localProvider,
    localProviderStats: entry?.localProvider,
    docName,
    whenSynced: (async () => {
      await entry.localProvider.whenSynced;
      await yManager.waitForSync(entry.doc);
    })()
  };
};
