import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormCodeUnion } from '@property-folders/contract';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';

export function FormWarningDialog({
  onContinue,
  onCancel,
  actionName,
  title,
  message,
  formCode
}: {
  onContinue: () => void,
  onCancel: () => void,
  actionName: string,
  title: string,
  message: string,
  formCode: FormCodeUnion
}) {
  const label = FormTypes[formCode]?.wizardTitle || FormTypes[formCode]?.label || 'Document';
  return <Modal size='lg' show={true} onHide={onCancel}>
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>{message}</p>
      <p>However, you may still click Continue to {actionName} the {label}</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant='outline-secondary' onClick={onCancel}>Cancel</Button>
      <Button variant='primary' onClick={onContinue}>Continue</Button>
    </Modal.Footer>
  </Modal>;
}

export interface ShowWarnParams {
  continueFn: () => void,
  title: string,
  message: string,
  actionName: string,
  formCode: FormCodeUnion
}

export function setupWarnIntercept(baseFunc?: () => void, intercept?: (inner: () => void) => void) {
  if (!baseFunc) return undefined;
  if (intercept) return () => intercept(baseFunc);
  return baseFunc;
}
