import { Col, Container, Form, Row } from 'react-bootstrap';
import { useTransactionField } from '../../hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { EditorListChildProps } from './CollectionEditor';
import { CollectionRemoveButton } from './CollectionRemoveButton';
import { WrField } from './CommonComponentWrappers';
import { MarketingTemplateItem } from '@property-folders/contract';
import { PathType } from '@property-folders/contract/yjs-schema/model';
import { TextClickCheck } from './TextClickCheck';
import { normalisePathToStr } from '@property-folders/common/util/pathHandling';
import { canonicalisers } from '@property-folders/common/util/formatting';
import clsJn from '@property-folders/common/util/classNameJoin';

export const FEE_COLS = [
  { xs: 8, sm: 9, md: 10 },
  { xs: 4, sm: 3, md: 2 }
];

export function FeeLineItem({ removable = true, hideDelete = false, ...restProps }: EditorListChildProps & TransactionConsumerProps)  {
  const { fullPath, handleRemove } = useTransactionField(restProps);

  const closeButton = handleRemove && <CollectionRemoveButton removable={removable} onRemove={handleRemove} />;

  return (
    <div className="d-flex w-100">
      <Container className='px-0'>
        <Row className='mx-0'>
          <Col className='px-0 mt-2 d-flex align-items-center' {...FEE_COLS[0]}>
            <TextClickCheck disabled={true} className={'invisible form-check'} inline={true} onSelected={()=>undefined}/>
            <div className='flex-grow-1'><WrField.Control parentPath={fullPath} myPath='itemDesc' name='itemDesc' deleteParentItemEmpty={true}/></div>
          </Col>
          <Col className='px-0 mt-2' {...FEE_COLS[1]}>
            <WrField.Control parentPath={fullPath} myPath='itemCost' name='itemCost'  deleteParentItemEmpty={true} textEnd/>
          </Col>
        </Row>
      </Container>
      {(!hideDelete) && <div className='d-flex align-items-center delete-div ms-1'>
        {closeButton}
      </div>}
    </div>
  );
}

export function FixedLineItem({
  costPath, unsetIfUnchecked, description,itemCost,enableForce,enableInvert,enablePath,enableSelectOnly,strikePriceIfDisabled: strikeIfDisabled = false
}: {
  description: string,
  itemCost?: number,
  costPath?: PathType,
  strikePriceIfDisabled?: boolean,
  enablePath?: PathType,
  enableInvert?: boolean,
  enableSelectOnly?: boolean
  enableForce?: boolean,
  unsetIfUnchecked?: boolean
}) {
  const closeButton = <CollectionRemoveButton removable={false} onRemove={()=>undefined} />;
  const { value: enableField } = useTransactionField({ myPath: enablePath });
  // XOR
  const enable = !(enableForce != null ? enableForce : enableField) !== !enableInvert;
  const showCheckbox = enableForce != null || enablePath;
  const multiLine = typeof description !== 'string' || description.includes('\n');

  return (
    <div className="d-flex w-100">
      <Container className='px-0'>
        <Row className='mx-0'>
          <Col className='px-0 mt-2 d-flex align-items-center' {...FEE_COLS[0]}>
            {
              showCheckbox && enablePath && !enableForce
                ? <WrField.BoolCheck inverter={enableInvert} unsetIfUnchecked={unsetIfUnchecked} noToggleOff={enableSelectOnly} className={'me-0'} name='enable' myPath={normalisePathToStr(enablePath)} />
                : showCheckbox && enableForce
                  ? <TextClickCheck disabled={true} checked={enableForce} inline={true} onSelected={()=>undefined}/>
                  : <div></div>
            }

            {
              multiLine
                ? <div className='disabled-form-control-replication'>{description}</div>
                : <Form.Control disabled={true} value={description} />
            }
          </Col>
          <Col className='px-0 mt-2 d-flex flex-column' {...FEE_COLS[1]}>
            {costPath
              ? <WrField.Control myPath={normalisePathToStr(costPath)} name='itemCost' deleteParentItemEmpty={true} textEnd/>
              : <Form.Control className={clsJn({ 'flex-grow-1': multiLine, 'text-end': true, 'text-decoration-line-through': strikeIfDisabled && !enable }) } disabled={true} value={itemCost ? canonicalisers.audWithNegative(itemCost).display : 'N/A'}></Form.Control>
            }
          </Col>
        </Row>
      </Container>
      <div className='d-flex align-items-center delete-div ms-1'>
        {closeButton}
      </div>
    </div>
  );
}

export function MarketingTemplateLineItem({ ...restProps }: EditorListChildProps & TransactionConsumerProps)  {
  const { fullPath, handleRemove, value: item } = useTransactionField<MarketingTemplateItem>(restProps);
  const closeButton = handleRemove && <CollectionRemoveButton removable={!item?.readonly && !restProps.isNewRow} onRemove={handleRemove} />;

  return (
    <div className="d-flex w-100">
      <Container className='px-0'>
        <Row className='mx-0'>
          <Col className='px-0 mt-2 d-flex align-items-center' {...FEE_COLS[0]}>
            <WrField.BoolCheck className={'me-0 me-2'} name='enable' parentPath={fullPath} myPath='enable' disabled={item?.readonly} />
            <WrField.Control parentPath={fullPath} containerClassName={'w-100'} className={'w-100'} myPath='itemDesc' name='itemDesc' disabled={item?.readonly}/>
          </Col>
          <Col className='px-0 mt-2' {...FEE_COLS[1]}>
            <WrField.Control parentPath={fullPath} myPath='itemCost' name='itemCost' textEnd disabled={item?.readonly}/>
          </Col>
        </Row>
      </Container>
      <div className='d-flex align-items-center delete-div ms-1'>{closeButton}</div>
    </div>
  );
}

export function LineItem({ columns, removable = true, hideDelete = false, isHeader = false, ...restProps }: EditorListChildProps & TransactionConsumerProps & {columns: any[]})  {
  const { fullPath, handleRemove } = useTransactionField(restProps);
  const closeButton = handleRemove && <CollectionRemoveButton removable={removable} onRemove={handleRemove} />;

  return (
    <div className="d-flex w-100">
      <Container className='px-0'>
        <Row className='mx-0'>
          {columns.map(i =>
            <Col className='px-0 mt-2 d-flex align-items-end' >
              {isHeader
                ? <div className={'fw-bold p-1'}>{i.header}</div>
                : <WrField.Control parentPath={fullPath} myPath={i.path} name={i.path} {...i} deleteParentItemEmpty={true}/>}
            </Col>)}
        </Row>
      </Container>
      {(!hideDelete) && !isHeader && <div className='d-flex align-items-center delete-div ms-1'>
        {closeButton}
      </div>}
      {isHeader && <div className='flex-grow-0 invisible'><CollectionRemoveButton onRemove={console.error} removable={false}/></div>}
    </div>
  );
}

export function BenefitLineItem({ columns, removable = true, hideDelete = false, isHeader = false, ...restProps }: EditorListChildProps & TransactionConsumerProps & {columns: any[]})  {
  const { value, fullPath, handleRemove } = useTransactionField(restProps);
  const deleteButton = handleRemove && <CollectionRemoveButton removable={removable} onRemove={handleRemove} />;

  return (
    <div className={clsJn('w-100 align-items-start', isHeader ? 'd-none d-sm-flex' : 'd-flex')}>
      <Container className='px-0'>
        <Row className='mx-0'>
          <div className='d-sm-none mt-4 px-0 d-flex flex-row justify-content-between align-items-center'>
            <span className='fs-5'>Benefit</span>
            {deleteButton}
          </div>
          {columns.map(i =>
            <Col xs={12} sm={3} className={clsJn('px-0 mt-2 d-flex align-items-end')} >
              {isHeader
                ? <div className={'fw-bold p-1'}>{i.header}</div>
                : i.warnText && typeof i.warn === 'function' ?
                  <div className={'d-flex flex-column w-100'}>
                    <WrField.Control
                      parentPath={fullPath}
                      myPath={i.path}
                      name={i.path}
                      type={'textarea'}
                      textArea={{ rows: 5 }}
                      containerClassName={'w-100'}
                      {...i}
                      deleteParentItemEmpty={true}
                    />
                    {typeof value === 'object' && i.warn(value[i.path]) && <p className='d-block d-sm-none text-danger'>
                      {i.warnText}
                    </p>}
                  </div>
                  : <WrField.Control
                    parentPath={fullPath}
                    myPath={i.path}
                    name={i.path}
                    type={'textarea'}
                    textArea={{ rows: 5 }}
                    containerClassName={'w-100'}
                    {...i}
                    deleteParentItemEmpty={true}
                  />}
            </Col>)}
        </Row>
      </Container>
      {(!hideDelete) && !isHeader && <div className='d-none d-sm-flex align-items-center delete-div ms-1'>
        {deleteButton}
      </div>}
      {isHeader && <div className='flex-grow-0 invisible'><CollectionRemoveButton onRemove={console.error} removable={false}/></div>}
    </div>
  );
}
