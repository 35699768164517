import { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import './ButtonSelectList.scss';
import clsJn from '@property-folders/common/util/classNameJoin';
import { Predicate } from '@property-folders/common/predicate';
import _ from 'lodash';

export function ButtonSelectList<T extends {[key: string]: any}[]>(
  {
    options, titleKey, subtitleKey, onOptionClick, selectedOption, filterTerm
  }: {
    options: T[],
    titleKey: string,
    subtitleKey: string,
    onOptionClick: (option: T, index: number)=>void,
    selectedOption: number|null,
    filterTerm: string
  }) {
  const orderedOptions = useMemo(()=>{
    return _.sortBy((options??[]).map((ppg, index)=>({ ppg, index })), ({ ppg }) => ppg[0]?.[titleKey]);
  }, options);
  return <div className='button-select-list'>
    {orderedOptions.map(({ ppg: pp, index })=>{
      // We need a stable index, so filter after we get the index
      if (filterTerm) {
        const r=pp.map(p=>
          ([p[titleKey], p[subtitleKey]].filter(Predicate.isNotNullish))
        ).flat().flat().join(' ');
        if (!r.includes(filterTerm)) {
          return null;
        }
      }
      return <div className='btn-wrap'>
        <Button
          key={pp?.[0]?.id}
          className={clsJn('w-100 d-flex flex-row overflow-hidden', index===selectedOption&&'selected')}
          variant='outline-light'
          onClick={()=>onOptionClick(pp, index)}>
          {pp.map(p=>{
            return <div className='party-segment' key={p?.id}>
              <div className='title'>{p[titleKey]}</div>
              <div className='subtitle'>{p[subtitleKey]}</div>
            </div>;
          })}

        </Button>
      </div>
      ;

    }).filter(Predicate.isNotNullish)}

  </div>;
}