import { useRum } from './useRum';
import { createBrowserRouter } from 'react-router-dom';
import { useEffect } from 'react';

type Router = ReturnType<typeof createBrowserRouter>;

export interface Props {
  router: Router,
}

export function RumPageSubscriber(props: Props) {
  const { router } = props;
  const rum = useRum();
  useEffect(() => {
    if (!router || !rum) {
      return;
    }

    router.subscribe(state => {
      const path = state.matches.map(t => t.route.path).join('/');
      const pageView = {
        pageId: path,
        pageAttributes: state.matches
          .map(t => t.params)
          .reduce((acc, x) => {
            return {
              ...acc,
              ...x
            };
          }, {})
      };

      rum.recordPageView(pageView);
    });
  }, [router, rum]);

  return <></>;
}
