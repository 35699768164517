import { useEffect, useState } from 'react';
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap';
import { useImmerReducer } from 'use-immer';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { uuidv4 } from 'lib0/random';
import { getPhoneSuffix } from '@property-folders/common/util/formatting/functions/getPhoneSuffix';

enum Status {
  /**
   * Initial Dialog
   */
  Initial,
  /**
   * Client is making API call to send a code to the phone number
   */
  RequestingCode,
  /**
   * Code is sent, pending user input
   */
  EnterCode,
  /**
   * User entered code, client making API call to verify
   */
  SubmittingCode,
  /**
   * Unrecoverable error?
   */
  Error,
  /**
   * Too many attempts, prevent further abuse
   */
  Lockout
}

interface VerificationState {
  status: Status,
  lastStatus?: Status,
  lastVerificationFailed?: boolean,
  requestCodeDisabledUntilMs?: number,
  noMoreCodes?: boolean
  unexpectedError?: boolean
}

type Action =
  | { type: 'back-to-initial' }
  | { type: 'request-code' }
  | { type: 'enter-code', retryAfterS?: number, noMoreCodes?: boolean, verificationFailure?: boolean }
  | { type: 'submit-code', code: string }
  | { type: 'error' }
  | { type: 'lockout' };

function formatTimeSegment(segment: number) {
  return segment.toString().padStart(2, '0');
}

/**
 * Get the formatted time component of a date.
 * Format: HH:MM AM/PM
 */
function formatTime(date?: number | Date) {
  if (!date) return '';
  const asDate = new Date(date);
  const hours = asDate.getHours();
  const minutes = asDate.getMinutes();
  const amPm = hours >= 12 ? 'PM' : 'AM';
  const modHours = hours % 12;
  return `${formatTimeSegment(modHours ? modHours : 12)}:${formatTimeSegment(minutes)} ${amPm}`;
}

/**
 * Contains sms verification process.
 * If verification is successful, then server-side should write something to audit trail.
 * Therefore, if the user does something hacky to bypass this screen, the absence of a verification entry is telling.
 * Also, the server-side should report back a verification id, so we can write it to the signed fields.
 * Later on, audit processing should pick this up and record-match
 */
export function SigningProcessSmsVerification({
  phone,
  formCode,
  formId,
  signingSessionId,
  partyId,
  propertyId,
  onSuccess,
  onFailure
}: {
  phone: string,
  formCode: string,
  formId: string,
  signingSessionId: string,
  partyId: string,
  propertyId: string,
  onSuccess: (secret?: string) => void,
  onFailure: () => void
}) {
  const [state, dispatch] = useImmerReducer<VerificationState, Action>((draft, action) => {
    draft.lastStatus = draft.status;
    draft.unexpectedError = false;
    switch (action.type) {
      case 'request-code':
        draft.status = Status.RequestingCode;
        fetch(LinkBuilder.restApi(`/properties/${propertyId}/sms-verification/request`), {
          method: 'POST',
          body: JSON.stringify({
            deduplicationId: uuidv4(),
            formCode,
            formId,
            partyId,
            signingSessionId
          })
        })
          .then(response => {
            if (response.ok) {
              return dispatch({ type: 'enter-code', retryAfterS: 60 });
            }

            dispatch({ type: 'error' });
          })
          .catch(err => {
            console.error(err);
            dispatch({ type: 'error' });
          });
        break;
      case 'enter-code':
        if (action.retryAfterS) {
          draft.requestCodeDisabledUntilMs = Date.now() + (1000 * action.retryAfterS);
        }
        draft.status = Status.EnterCode;
        draft.lastVerificationFailed = !!action.verificationFailure;
        break;
      case 'submit-code':
        draft.status = Status.SubmittingCode;
        fetch(LinkBuilder.restApi(`/properties/${propertyId}/sms-verification`), {
          method: 'POST',
          body: JSON.stringify({
            formCode,
            formId,
            partyId,
            signingSessionId,
            smsCode: action.code
          })
        })
          .then(async response => {
            if (response.ok) {
              const body = (await response.json()) as { secret?: string };
              onSuccess(body.secret);
            }

            switch (response.status) {
              case 400:
                return dispatch({ type: 'enter-code', verificationFailure: true });
              case 403:
                return dispatch({ type: 'lockout' });
              default:
                return dispatch({ type: 'error' });
            }
          })
          .catch(err => {
            console.error(err);
            dispatch({ type: 'error' });
          });
        break;
      case 'error':
        draft.status = Status.EnterCode;
        draft.unexpectedError = true;
        break;
      case 'lockout':
        draft.status = Status.Lockout;
        break;
    }
  }, {
    status: Status.Initial,
    lastVerificationFailed: false
  });

  const [enteredCode, setEnteredCode] = useState('');
  const [requestDisabled, setRequestDisabled] = useState(false);
  useEffect(() => {
    setRequestDisabled(true);
    if (!state.requestCodeDisabledUntilMs) {
      setRequestDisabled(false);
      return;
    }
    const delay = state.requestCodeDisabledUntilMs - Date.now();
    if (delay <= 0) {
      setRequestDisabled(false);
      return;
    }
    const timer = setTimeout(() => {
      setRequestDisabled(false);
    }, state.requestCodeDisabledUntilMs - Date.now());

    return () => clearTimeout(timer);
  }, [state.requestCodeDisabledUntilMs]);

  const buttonsDisabled = state.status === Status.SubmittingCode || state.status === Status.RequestingCode;
  const disabledUntilTimeText = formatTime(state.requestCodeDisabledUntilMs);

  // * request send code (phone, name) => ok (code stays server-side)
  // * validate code (code) => ok (write to audit)
  const phoneSuffix = getPhoneSuffix(phone);
  return <Modal.Dialog className={'bg-white mt-5 p-3'} style={{ maxWidth: '600px' }}>
    <Modal.Header className={'mb-3'}>
      <h5>This document requires a verification code</h5>
    </Modal.Header>
    <Modal.Body className={'mb-3'}>
      {(state.status === Status.Initial || state.lastStatus === Status.Initial) && state.status !== Status.Lockout &&
        <p>Press <b>Send Code</b> to send a verification code to the number ending in <b>{phoneSuffix}</b></p>}
      {state.status === Status.Lockout && <p>Access is forbidden.</p>}
      {state.status === Status.EnterCode && <>
        <p>A code has been sent to the phone number ending in <b>{phoneSuffix}</b></p>
        <form onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          dispatch({ type: 'submit-code', code: enteredCode });
        }}>
          <Form.Group>
            <FloatingLabel label={'Enter verification code'}>
              <Form.Control
                required
                isInvalid={state.lastVerificationFailed}
                pattern={'\\d{6}'}
                type={'text'}
                inputMode={'numeric'}
                autoComplete={'one-time-code'}
                value={enteredCode}
                onChange={e => setEnteredCode(e.target.value)}></Form.Control>
              <Form.Control.Feedback type={'invalid'}>Incorrect verification code</Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </form>
      </>}
    </Modal.Body>
    <Modal.Footer>
      {state.status !== Status.Lockout && <div className={'d-flex flex-column align-items-end'}>
        <div className={'d-flex flex-row gap-3 justify-content-end'}>
          {state.status !== Status.Initial &&
            <Button
              disabled={buttonsDisabled}
              variant={'primary'}
              onClick={() => dispatch({ type: 'submit-code', code: enteredCode })}
            >Submit</Button>}
          <Button
            disabled={buttonsDisabled || requestDisabled}
            variant={state.status === Status.Initial ? 'primary' : 'outline-secondary'}
            onClick={() => dispatch({ type: 'request-code' })}
          >{state.status === Status.Initial ? 'Send Code' : 'Resend code'}</Button>
        </div>
        {requestDisabled && <small className={'text-muted'}>Didn't receive a code? Send again at {disabledUntilTimeText}.</small>}
        {state.unexpectedError && <p>An unexpected error occurred, please try again.</p>}
      </div>}
      {state.status === Status.Lockout && <div className={'d-flex flex-column align-items-end'}>
        <Button disabled={buttonsDisabled || requestDisabled}  variant={'outline-secondary'} onClick={() => onFailure()}>Ok</Button>
      </div>}
    </Modal.Footer>
  </Modal.Dialog>;
}
