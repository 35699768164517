import { useContext, useMemo } from 'react';
import { Badge, Button } from 'react-bootstrap';
import { InstanceCardBase } from './InstanceCardBase';
import { SigningProgressBar } from './SigningProgressBar';
import { OfferState, OfferContractSummary } from '@property-folders/contract/property/OfferContractState';
import { SplitIfManyButton } from './SplitIfManyButton';
import { Link, useNavigate } from 'react-router-dom';
import { CardSectionRows } from './CardSectionRows';
import { byMapperFn } from '@property-folders/common/util/sortComparison';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { YjsDocContext } from '../context/YjsDocContext';
import { ContractRootKeySummary } from '../hooks/useAlternateRootKeyContracts';
import { FormCardRenderOpts } from '@property-folders/common/yjs-schema/property/form';

function ContractProgressBar ({
  contracts
}: {
  contracts: OfferContractSummary[]
}) {
  const signedContracts = contracts.filter(c=>c.state===OfferState.Signed);
  if (signedContracts.length === 0) {
    return <></>;
  }
  if (signedContracts.length === 1) {
    return <SigningProgressBar
      completion={1}
      variant='success'
      text='Signed'
    />;
  }
  if (signedContracts.length > 1) {
    return <SigningProgressBar
      completion={1}
      variant='warning'
      text='Multiple Contracts'
    />;
  }
  return <SigningProgressBar
    completion={1}
    variant='danger'
    text='Unknown'
  />;
}

const bsSuccess = 'var(--bs-success)';
const bsDanger = 'var(--bs-danger)';
const bsInfo = 'var(--bs-info)';
const bsInProgress = 'var(--bs-progress-bg)';

const statusGen = (text: string, colour: string, textColour?: string) => (
  <div style={{
    textAlign: 'right',
    paddingLeft: '0.25rem',
    paddingRight: '0.25rem',
    width: '6rem',
    margin: '1px'
  }}
  >{text}</div>
);

const ContractStateOrder = [
  OfferState.Signed,
  OfferState.Incomplete,
  OfferState.Draft,
  OfferState.Submitted,
  OfferState.Terminated,
  OfferState.Declined,
  OfferState.Withdrawn,
  OfferState.Unknown
];
const ContractStateOrderMap = new Map(ContractStateOrder.map((v,i)=>[v,i]));
const mapper = byMapperFn<OfferState>((c)=>ContractStateOrderMap.get(c), 'asc');
export const offerStateMapAndNameSorter = ({ state: sA, purchasers: pA }: ContractRootKeySummary,{ state: sB, purchasers: pB }: ContractRootKeySummary)=>{
  if (sA !== sB) {
    return mapper(sA, sB);
  }
  if (pA[0] === pB[0]) return 0;
  if (pB[0] === undefined) return 1;
  if (pA[0] === undefined) return -1;
  return pA[0].name === pB[0].name
    ? 0
    : pA[0].name > pB[0].name
      ? 1
      : -1;
};

export function ContractMgmtOverviewCard ({
  contracts,
  onContractCreate,
  onContractFromOfferCreate,
  renderOpts,
  notActionable,
  onForceFocus,
  forceFocus
}: {
  contracts: ContractRootKeySummary[],
  onContractCreate: ()=>void,
  onContractFromOfferCreate: ()=>void,
  renderOpts: FormCardRenderOpts | undefined,
  notActionable: boolean,
  onForceFocus: (forceMe: boolean) => void,
  forceFocus?: boolean
}): JSX.Element {
  const navigate = useNavigate();
  const { docName } = useContext(YjsDocContext);

  const purchaserRows = useMemo(()=>{
    const prospectives = new Map<string, {ordinal: number, contract: ContractRootKeySummary }>();
    const uniquedContracts: typeof contracts = [];
    for (const contract of contracts) {
      if (contract.archived) continue;
      let ppid = contract.prospectivePurchaserId;
      if (!ppid) {
        ppid = (contract.purchasers?.[0]?.name??'')+contract.label;
      }
      if (!ppid) continue;
      const existing = prospectives.get(ppid);
      const ordinal = ContractStateOrderMap.get(contract.state)??ContractStateOrder.length;
      if (existing && ordinal > existing.ordinal) continue;
      prospectives.set(ppid, { ordinal, contract });
    }
    uniquedContracts.push(...[...prospectives.values()].map(v=>v.contract));
    return uniquedContracts.sort(offerStateMapAndNameSorter)
      .map(c=>{
        let stateElement = statusGen('Unknown', bsDanger, 'white');
        switch (c.state) {
          case OfferState.Draft:
            stateElement = statusGen('Draft', bsInProgress);
            break;
          case OfferState.Incomplete:
            stateElement = statusGen('Signing', bsInProgress);
            break;
          case OfferState.Signed:
            stateElement = statusGen('Signed', bsSuccess);
            break;
          case OfferState.Submitted:
            stateElement = statusGen('Offered', bsInfo);
            break;
          case OfferState.Terminated:
            stateElement = statusGen('Terminated', bsDanger, 'white');
            break;
          case OfferState.Unknown:
          default:
            statusGen('Unknown', bsDanger, 'white');
        }
        const location = LinkBuilder.documentPath({ id: docName }, { id: c.linkingFormId }, false);
        return [
          <Link
            style={{ color: '#000', textDecoration: 'underline' }}
            onClick={e=>{e.stopPropagation();navigate(location);}}
            to={location}>
            {(c.purchasers?.[0]?.name||'Unknown') === 'Unknown'
              ? c.label ? <Badge className={'fw-normal'} text="dark" bg="light">{c.label}</Badge> : 'Unknown'
              : c.purchasers?.[0]?.name??'Unknown'}
          </Link>,
          stateElement
        ];
      });

  }, [contracts.map(a=> (a.purchasers?.[0]?.name??'')+a.label+a.archived).join('')+contracts.map(a=>`${a.state}`).join('')]);

  return <InstanceCardBase
    title="Contract Management"
    isProcess={renderOpts?.processNotForm}
    clickable={!notActionable}
    noHover={notActionable}
    onOpen={()=>navigate('contracts')}
    iconBefore={renderOpts?.iconBefore}
    iconAfter={renderOpts?.iconAfter}
    footerButtons={<>
      <SplitIfManyButton onForceFocus={onForceFocus}>
        {!contracts.length && <Button variant='outline-secondary' onClick={e=>{e.stopPropagation(); onContractCreate?.();}}>Create Contract</Button>}
        <Button variant='outline-secondary' onClick={e=>{e.stopPropagation(); navigate('contracts');}}>View</Button>
        {!!contracts.length && <Button variant='outline-secondary' onClick={e=>{e.stopPropagation(); onContractCreate?.();}}>Create Contract</Button>}
        <Button variant='outline-secondary' onClick={e=>{e.stopPropagation(); onContractFromOfferCreate?.();}}>Create Contract from Offer</Button>
      </SplitIfManyButton>
    </>}
    forceFocus={forceFocus}
  >
    {!!contracts.length && <ContractProgressBar contracts={contracts} />}
    {!!contracts.length && <div className='section-area'>
      <CardSectionRows
        rows={purchaserRows}
        maxRows={14}
      />
    </div>}
  </InstanceCardBase>;
}
