import './TextField.scss';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { FieldProps } from './common';
import { PendingRemoteSigningSessionFieldCustom } from '@property-folders/contract';
import { asPercentage } from '@property-folders/signing/src/components/field/common';
import { useMergeRefs } from '../../../hooks/useMergeRefs';

export const TextField = forwardRef<HTMLDivElement, FieldProps>((props, ref) => {
  const {
    id,
    location,
    page: {
      width: pageWidth,
      height: pageHeight
    },
    text,
    fillField,
    clearField,
    custom,
    observer
  } = props;

  const localRef = useRef<HTMLDivElement>();
  const mergedRef = useMergeRefs(ref, localRef);

  useEffect(() => {
    if (!observer) return;
    if (!localRef.current) return;
    if (custom?.required) return;

    const el = localRef.current;
    observer.observe(el);
    return () => {
      observer.unobserve(el);
    };
  }, [observer, localRef.current]);

  return <div
    ref={mergedRef}
    data-field-id={id}
    style={{
      position: 'absolute',
      left: asPercentage(location.x, pageWidth),
      bottom: asPercentage(location.y, pageHeight),
      width: asPercentage(location.width, pageWidth),
      height: asPercentage(location.height, pageHeight),
      ...(!fillField ? {
        overflow: 'hidden'
      } : {})
    }}>
    {fillField
      ? <FillableText
        value={text || ''}
        onChange={newText => {
          const trimmed = newText.trim();
          if (!trimmed && clearField) {
            clearField();
          } else {
            fillField(trimmed);
          }
        }}
        style={getStyle(custom)}
        multiline={custom?.multiline}
      />
      : <div
        style={custom?.multiline ? {} : {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textWrap: 'nowrap',
          overflow: 'hidden'
        }}>
        <p style={{ ...getStyle(custom), margin: 0 }} title={text}>
          {(text || '').split(/[\r\n]+/g).map((line, index) => index
            ? <>{line}<br/></>
            : line
          )}
        </p>
      </div>}
  </div>;
});

function FillableText({ value, onChange, style, multiline }: {
    value: string,
    onChange: (text: string) => void,
    style: React.CSSProperties,
    multiline?: boolean
  }) {
  const [text, setText] = useState(value);
  const [newText, setNewText] = useState(text);

  useEffect(() => {
    if (text === newText) return;

    const handle = setTimeout(() => {
      onChange(newText);
    }, 200);

    return () => {
      clearTimeout(handle);
    };
  }, [text, newText]);

  return <div
    className='text-field'
    style={{
      height: '100%',
      width: '100%',
      position: 'relative',
      ...(multiline ? {} : {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      })
    }}
  >
    {multiline
      ? <textarea
        style={{
          ...style,
          width: '100%',
          resize: 'none',
          background: 'transparent',
          overflow: 'hidden',
          textWrap: 'wrap',
          outline: 0,
          border: 0,
          height: '100%'
        }}
        placeholder='Enter text'
        onChange={e => {
          const innerText = e.currentTarget.value;
          const processed =  innerText;
          setNewText(processed.trim() ? processed : '');
        }}
        onCopy={e => {
          const selection = document.getSelection();
          if (!selection) return;
          const data = selection.toString();
          e.clipboardData.setData('text/plain', data);
          e.preventDefault();
        }}
        value={newText}
      />
      : <input
        type='text'
        style={{
          ...style,
          width: '100%',
          resize: 'none',
          background: 'transparent',
          overflow: 'hidden',
          textWrap: 'nowrap',
          outline: 0,
          border: 0
        }}
        placeholder='Enter text'
        onChange={e => {
          const innerText = e.currentTarget.value;
          const processed = innerText.replaceAll(/[\r\n]+/g, ' ');
          setNewText(processed.trim() ? processed : '');
        }}
        onCopy={e => {
          const selection = document.getSelection();
          if (!selection) return;
          const data = selection.toString();
          e.clipboardData.setData('text/plain', data);
          e.preventDefault();
        }}
        onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
        value={newText}
      />}
  </div>;
}

function getStyle(custom: PendingRemoteSigningSessionFieldCustom | undefined): React.CSSProperties {
  const result: React.CSSProperties = {};
  if (custom?.fontColour) result.color = custom.fontColour;
  // if (custom?.bg && custom.bgColour) result.backgroundColor = custom.bgColour;
  if (custom?.fontFamily) result.fontFamily = custom.fontFamily;
  if (custom?.fontSize) {
    // expectation is that a parent element has defined how large a 12pt font size should appear
    // at a particular breakpoint width, so we can scale appropriately down here.
    // if we keep everything relative to a 12 point font it should work out.
    result.fontSize = `${custom.fontSize / 12}em`;
  }
  if (custom?.lineHeight) {
    result.lineHeight = `${custom.lineHeight / 12}em`;
  }
  return result;
}
