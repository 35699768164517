import { Annexure, FileRef, Maybe, UploadType, uploadTypeOpts } from '@property-folders/contract';
import { FormUtil } from '@property-folders/common/util/form';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useLightweightTransaction, useTransactionField } from '../../hooks/useTransactionField';
import { LineageContext } from '../../hooks/useVariation';
import './ProposedLotAnnexure.scss';

import { preservedAnnexureOrderHistoryList } from '@property-folders/common/util/form/annexure';
import { createAnnexureFromUploadedDocument, deleteAnnexure } from '@property-folders/common/util/annexure';
import { FormContext } from '../../context/FormContext';
import { UploadedDocumentsSelector } from '../UploadedDocumentsSelector';
import { useSelector } from 'react-redux';
import { ValidationReducerState } from '@property-folders/common/redux-reducers/validation';
import { YjsDocContext } from '../../context/YjsDocContext';
import { composeErrorPathClassName } from '@property-folders/common/util/formatting';
import { WizardDisplayContext } from '../../context/WizardContexts';
import { FormUserInteractionContext } from '../../context/FormUserInteractionContext';
import { AnnexuresSurgeon } from '@property-folders/common/util/annexures-surgeon';

export function ProposedLotAnnexure() {
  const { docName, transactionRootKey } = useContext(YjsDocContext);
  const { formId, formName: formCode } = useContext(FormContext);
  const { snapshotHistory, variationsMode, loadState: lineageLoadState } = useContext(LineageContext);
  const { showFocusErrors } = useContext(WizardDisplayContext);
  const { userShouldSeeAllValidation } = useContext(FormUserInteractionContext);

  const enableAnnexures = Boolean(formId && formCode);
  const formParentPath = FormUtil.getFormPath(formCode, formId) || '';

  const {
    value: plan,
    handleUpdate: updatePlan,
    handleRemove: removePlan,
    fullPath: planPath,
    transactionRootKey: planKey,
    readOnly
  } = useTransactionField<Maybe<FileRef>>({ parentPath: 'titleDivision', myPath: 'plan' });
  const { fullPath: planFocusPath } = useTransactionField({ parentPath: planPath, myPath: 'id' });
  const watchPaths = new Set([
    composeErrorPathClassName(planPath, undefined),
    composeErrorPathClassName(planPath, 'id'),
    composeErrorPathClassName(`${planPath}.id`, undefined)
  ]);
  const errorFound = useSelector((state: { validation: ValidationReducerState }) =>
    (state?.validation?.focusErrList?.[docName ?? '']?.[transactionRootKey ?? '']?.[formCode] || [])
      .some(x => watchPaths.has(x))
  );
  const { value: annexuresForm, fullPath: annexuresPath } = useLightweightTransaction<Annexure[]>({ parentPath: formParentPath, myPath: 'annexures', bindToMetaKey: true });
  const { updateDraft: updateAnnexures } = useYdocBinder<Annexure[]>({ path: annexuresPath, bindToMetaKey: true });
  const { annexures, usingPrevious, orderList } = useMemo(()=>{
    return preservedAnnexureOrderHistoryList(annexuresForm || [], snapshotHistory);
  }, [annexuresForm, snapshotHistory]);

  const selectedRefs = useMemo(() => {
    return plan ? [plan] : [];
  }, [plan?.id]);

  const matchedAnnexure = plan
    ? annexures.find(x => x.id === plan.id)
    : undefined;
  useEffect(() => {
    if (readOnly) return;
    if (!enableAnnexures) return;
    if (matchedAnnexure) return;
    if (!plan) return;
    // do not act hastily.
    if (variationsMode && lineageLoadState !== 'done') return;
    createAnnexureFromUploadedDocument({
      file: plan,
      name: uploadTypeOpts[UploadType.PropertyPlan],
      uploadType: UploadType.PropertyPlan,
      updateAnnexures,
      managed: true,
      binding: {
        path: planPath,
        root: planKey
      },
      orderList: orderList ?? undefined,
      uploadTypeUnique: true
    });
  }, [variationsMode, lineageLoadState, Boolean(matchedAnnexure), Boolean(plan), enableAnnexures, readOnly]);

  // ensure at most 1 plan, and that it is the expected plan
  const planAnnexures = annexures.filter(a => a.data.uploadType === UploadType.PropertyPlan);
  const hasUnexpectedAnnexures = Boolean(planAnnexures.filter(a => a.id !== plan?.id).length);
  const hasDuplicateAnnexures = Boolean(annexures.filter(a => a.id === plan?.id).length > 1);
  useEffect(() => {
    if (!enableAnnexures) return;
    // do not act hastily.
    if (variationsMode && lineageLoadState !== 'done') return;
    if (hasUnexpectedAnnexures || hasDuplicateAnnexures) {
      updateAnnexures?.(draft => {
        const surgeon = new AnnexuresSurgeon(draft, orderList || []);
        surgeon.removeAllOfType({ keepId: plan?.id, type: UploadType.PropertyPlan });
        if (plan?.id && hasDuplicateAnnexures) {
          surgeon.dedupe({ id: plan.id });
        }

        surgeon.updateLabels();
      });
    }
  }, [
    enableAnnexures,
    hasUnexpectedAnnexures,
    hasDuplicateAnnexures
  ]);

  const handleSelectionChange = useCallback((refs: FileRef[]) => {
    const ref = refs.at(0);
    if (ref) {
      // add/change annexure
      if (enableAnnexures) {
        createAnnexureFromUploadedDocument({
          file: ref,
          name: uploadTypeOpts[UploadType.PropertyPlan],
          uploadType: UploadType.PropertyPlan,
          updateAnnexures,
          replace: plan,
          managed: true,
          binding: {
            path: planPath,
            root: planKey
          },
          orderList: orderList ?? undefined,
          uploadTypeUnique: true
        });
      }
      updatePlan(ref, true);
    } else if (plan) {
      if (enableAnnexures) {
        // remove annexure
        const annexure = annexures.find(x => x.id === plan.id);
        if (annexure) {
          deleteAnnexure(annexure.data, usingPrevious, annexures, updateAnnexures);
        }
      }
      removePlan();
    }
  }, [enableAnnexures, updatePlan, removePlan, updateAnnexures, plan, annexures]);

  return <>
    <UploadedDocumentsSelector
      selectedFileRefs={selectedRefs}
      onSelect={handleSelectionChange}
      multi={false}
      uploadTypeFilter={UploadType.PropertyPlan}
      label={'Select or upload a property plan'}
      overlayLabel={'Drop property plan here'}
      error={errorFound && showFocusErrors && userShouldSeeAllValidation ? 'A \'Property Plan\' must be attached to clarify the Legal Land Description, when selling anything other than the whole of a title.' : undefined}
      inputClassName={composeErrorPathClassName(planFocusPath, undefined)}
      disabled={readOnly}
    />
  </>;
}
