import './CustomFieldsAttributesEditor.scss';
import {
  CustomFieldMeta,
  CustomFieldMetaAttribute,
  CustomFieldMetaAttributeBooleanTypeInfo,
  CustomFieldMetaAttributeColourTypeInfo,
  CustomFieldMetaAttributeNumberTypeInfo,
  CustomFieldMetaAttributeOptionsTypeInfo,
  CustomFieldMetaAttributeStringTypeInfo
} from '@property-folders/contract/property/meta';
import { CustomFieldConfiguration, FormCodeUnion, Maybe, TransactionMetaData } from '@property-folders/contract';
import { useContext, useEffect, useState } from 'react';
import { FormContext } from '../../../context/FormContext';
import { YjsDocContext } from '../../../context/YjsDocContext';
import { FormUtil } from '@property-folders/common/util/form';
import clsJn from '@property-folders/common/util/classNameJoin';
import { BinderFn, useImmerYjs } from '../../../hooks/useImmerYjs';
import { SketchPicker } from 'react-color';
import { userColours } from '@property-folders/common/util/UserColours';
import { NumberUtil } from '@property-folders/common/util/number';
import React from 'react';
import { TextDimensionEstimator } from '@property-folders/common/util/pdf';
import { afterAttrChange, autoResize } from './common';

function preProcessNewValue(attr: CustomFieldMetaAttribute, raw: any): { newValue: any, shouldSet: boolean } {
  const { typeInfo } = attr;
  switch (typeInfo.type) {
    case 'number': {
      const asNumber = Number(raw);
      if (asNumber === undefined || isNaN(asNumber) || !isFinite(asNumber)) {
        return { newValue: undefined, shouldSet: false };
      }
      return { newValue: NumberUtil.clamp(asNumber, typeInfo.min, typeInfo.max), shouldSet: true };
    }
    default: {
      return { newValue: raw, shouldSet: true };
    }
  }
}

export function CustomFieldAttributesEditor({ meta, field, estimator }: {
  meta: CustomFieldMeta,
  field: CustomFieldConfiguration,
  estimator: TextDimensionEstimator
}) {
  const { ydoc, transactionMetaRootKey } = useContext(YjsDocContext);
  const { formName: formCode, formId } = useContext(FormContext);

  const { binder } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const setValue = (attr: CustomFieldMetaAttribute, raw: any) => {
    setCustomAttribute({
      binder,
      attr,
      raw,
      field,
      estimator,
      formCode: formCode as FormCodeUnion,
      formId
    });
  };

  return <table
    className='custom-field-attributes-editor'
    style={{ maxWidth: '100%' }}
    onKeyDown={e => e.stopPropagation()}
  >
    <thead>
      <tr>
        <th>Name</th>
        <th>Value</th>
      </tr>
    </thead>
    <tbody>
      {meta.attributes.map(attr => {
        if (attr.hidden) {
          return <React.Fragment key={attr.name} />;
        }
        if (!attr.editable) {
          return <ReadOnlyAttributeRow
            key={attr.name}
            title={attr.title}
            value={(field as any)[attr.name]}
          />;
        }

        switch (attr.typeInfo.type) {
          case 'options':
            return <EditableOptionsAttributeRow
              key={attr.name}
              title={attr.title}
              type={attr.typeInfo}
              value={(field as any)[attr.name] as string}
              onChange={newValue => setValue(attr, newValue)}
            />;
          case 'string':
            return <EditableStringAttributeRow
              key={attr.name}
              title={attr.title}
              type={attr.typeInfo}
              value={(field as any)[attr.name] as string}
              onChange={newValue => setValue(attr, newValue)}
            />;
          case 'number':
            return <EditableNumberAttributeRow
              key={attr.name}
              title={attr.title}
              type={attr.typeInfo}
              value={(field as any)[attr.name] as number}
              onChange={newValue => setValue(attr, newValue)}
            />;
          case 'boolean':
            return <EditableBooleanAttributeRow
              key={attr.name}
              title={attr.title}
              type={attr.typeInfo}
              value={(field as any)[attr.name] as Maybe<boolean>}
              onChange={newValue => setValue(attr, newValue)}
            />;
          case 'colour':
            return <EditableColourAttributeRow
              key={attr.name}
              title={attr.title}
              type={attr.typeInfo}
              value={(field as any)[attr.name] as Maybe<string>}
              onChange={newValue => setValue(attr, newValue)}
            />;
        }
      })}
    </tbody>
  </table>;
}

function ReadOnlyAttributeRow({ title, value: raw }: { title: string, value: unknown }) {
  const value = typeof raw === 'string' || typeof raw === 'number'
    ? raw
    : '';
  return (<tr>
    <td>{title}</td>
    <td data-mode='readonly' className={clsJn(typeof raw === 'number' ? 'text-end' : 'text-start')}>{value}</td>
  </tr>);
}

function EditableStringAttributeRow({ title, type, value, onChange }: {
  title: string,
  type: CustomFieldMetaAttributeStringTypeInfo,
  value: string,
  onChange: (value: string) => void
}) {
  const [editing, setEditing] = useState(false);
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

  const onComplete = () => {
    setEditing(false);
  };

  useEffect(() => {
    if (!inputRef) return;
    const keyHandler = (e: KeyboardEvent) => {
      if (['Enter', 'Escape'].includes(e.key)) {
        return onComplete();
      }
    };
    const focusHandler = () => {
      onComplete();
    };
    inputRef.addEventListener('keyup', keyHandler);
    inputRef.addEventListener('focusout', focusHandler);
    return () => {
      inputRef.removeEventListener('keyup', keyHandler);
      inputRef.removeEventListener('focusout', focusHandler);
    };
  }, [inputRef]);

  return <tr>
    <td>{title}</td>
    <td data-mode='editable' className='text-start' onClick={() => setEditing(true)}>
      {editing
        ? <input
          ref={x => {
            setInputRef(x);
          }}
          // type='number'
          className='w-100 text-end'
          autoFocus={true}
          value={value}
          onChange={e => {
            onChange(e.target.value);
          }}
        />
        : <>{value}</>}
    </td>
  </tr>;
}

function EditableNumberAttributeRow({ title, type, value, onChange }: {
  title: string,
  type: CustomFieldMetaAttributeNumberTypeInfo,
  value: number,
  onChange: (value: number) => void
}) {
  const [editing, setEditing] = useState(false);
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

  const onComplete = () => {
    setEditing(false);
  };

  useEffect(() => {
    if (!inputRef) return;
    const keyHandler = (e: KeyboardEvent) => {
      if (['Enter', 'Escape'].includes(e.key)) {
        return onComplete();
      }
    };
    const focusHandler = () => {
      onComplete();
    };
    inputRef.addEventListener('keyup', keyHandler);
    inputRef.addEventListener('focusout', focusHandler);
    return () => {
      inputRef.removeEventListener('keyup', keyHandler);
      inputRef.removeEventListener('focusout', focusHandler);
    };
  }, [inputRef]);

  return <tr>
    <td title={title}>{title}</td>
    <td data-mode='editable' className='text-end' onClick={() => setEditing(true)}>
      {editing
        ? <input
          ref={x => {
            setInputRef(x);
          }}
          type='number'
          className='w-100 text-end'
          autoFocus={true}
          min={type.min}
          max={type.max}
          value={value}
          onChange={e => {
            onChange(e.target.valueAsNumber);
          }}
        />
        : <>{value}</>
      }
    </td>
  </tr>;
}

function EditableOptionsAttributeRow({ title, type, value, onChange }: {
  title: string,
  type: CustomFieldMetaAttributeOptionsTypeInfo,
  value: string,
  onChange: (value: string) => void
}) {
  return <tr>
    <td title={title}>{title}</td>
    <td data-mode='editable' className='text-end'>
      <select
        className='w-100'
        value={value}
        onChange={e => onChange(e.target.value)}>
        {type.options.map(o => (<option key={o} value={o}>{o}</option>))}
      </select>
    </td>
  </tr>;
}

function EditableBooleanAttributeRow({ title, type, value, onChange }: {
  title: string,
  type: CustomFieldMetaAttributeBooleanTypeInfo,
  value: boolean | undefined,
  onChange: (value: boolean) => void
}) {
  return <tr>
    <td title={title}>{title}</td>
    <td data-mode='editable' className='text-end'>
      <input
        type='checkbox'
        checked={!!value}
        onChange={e => onChange(e.target.checked)}
      />
    </td>
  </tr>;
}

function EditableColourAttributeRow({ title, type, value, onChange }: {
  title: string,
  type: CustomFieldMetaAttributeColourTypeInfo,
  value?: string,
  onChange: (value?: string) => void
}) {
  const [showPicker, setShowPicker] = useState<DOMRect | undefined>(undefined);
  return <tr>
    <td title={title}>{title}</td>
    <td data-mode='editable' className='text-end'>
      <div style={{
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer'
      }} onClick={e => {
        const rect = (e.target as HTMLElement).getBoundingClientRect();
        setShowPicker(rect);
      }}>
        <div style={{
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: value || type.defaultValue
        }}></div>
      </div>
      {showPicker && <div style={{
        position: 'fixed',
        bottom: `${window.innerHeight - showPicker.bottom}px`,
        left: `${showPicker.left}px`,
        zIndex: '2'
      }}>
        <div style={{
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }} onClick={() => setShowPicker(undefined)}/>
        <SketchPicker
          color={value}
          onChange={color => onChange(color.hex)}
          presetColors={['#ffffff', '#000000', ...userColours]}
          disableAlpha={true}
        />
      </div>}
    </td>
  </tr>;
}

export function setCustomAttribute({
  binder,
  attr,
  raw,
  field,
  formCode,
  formId,
  estimator
}: {
  binder: BinderFn<TransactionMetaData>,
  attr: CustomFieldMetaAttribute,
  raw: any,
  field: CustomFieldConfiguration,
  formCode: FormCodeUnion,
  formId: string,
  estimator: TextDimensionEstimator
}) {
  if (!binder?.update) return;
  const attrName = attr.name;
  const { newValue, shouldSet } = preProcessNewValue(attr, raw);
  if (!shouldSet) return;
  binder.update(draft => {
    const signing = FormUtil.getSigning(formCode, formId, draft);
    if (!signing) return;

    const match = signing.customFields?.find(f => f.id === field.id);
    if (!match) return;

    (match as any)[attrName] = newValue;
    if (signing.customFieldDefaults) {
      signing.customFieldDefaults[attrName] = newValue;
    } else {
      signing.customFieldDefaults = {
        [attrName]: newValue
      };
    }

    autoResize(match, { estimator });
    afterAttrChange({
      field: match,
      name: attrName,
      estimator,
      all: signing.customFields || []
    });
  });
}
