import { UserUtil } from '@property-folders/common/util/user';
import React from 'react';
import { Placeholder } from 'react-bootstrap';

export interface AgentAvatarProps {
  agent: { name: string; agentId: number } | { name: string; id: number },
  fontSize?: string;
  initials?: string;
  style?: React.CSSProperties;
}

export function AgentAvatar({
  agent,
  fontSize,
  initials: initialsOverride,
  style: styleOverride
}: AgentAvatarProps) {
  if (!agent) {
    return <Placeholder as={'span'} animation={'glow'}>
      <Placeholder className={'user-initials'}></Placeholder>
    </Placeholder>;
  }

  if (!fontSize) {
    fontSize = '1.25em';
  }

  const { name } = agent;
  const agentId = 'agentId' in agent ? agent.agentId : agent.id;
  const initials = initialsOverride || UserUtil.getInitials(name);
  const style = styleOverride || UserUtil.getThumbnailStyle(name, agentId);
  const size = `calc(${fontSize} * 2.25)`;

  return <div
    title={name}
    className='agent-avatar'
    style={{
      display: 'inline-flex',
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      height: size,
      width: size,
      minWidth: size,
      position: 'relative',
      ...style
    }}
  >
    <span
      className='initials'
      style={{
        lineHeight: fontSize,
        fontSize
      }}
    >
      {initials}
    </span>
  </div>;
}
