import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { NavigateFunction } from 'react-router-dom';

export const navigateToFormFactory = (navigate: NavigateFunction, propertyId: string, { headline }: {headline: string}) => (instanceId: string, formCode: string) => {
  if (!propertyId) return;

  const formType = FormTypes[formCode];
  navigate(LinkBuilder.documentPath(
    {
      id: propertyId,
      nicetext: headline
    },
    {
      id: instanceId,
      nicetext: formType.label
    },
    !!formType.subscription));
};