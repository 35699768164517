import { useUserPreferences } from '@property-folders/components/hooks/useUserPreferences';
import { TextClickCheck } from '../form/TextClickCheck';
import {
  EpfAutoOrdering,
  Form1SearchParty,
  FormCode,
  FormCodeUnion, FormInstance,
  SigningParty,
  VendorParty
} from '@property-folders/contract';
import { useLightweightTransaction } from '../../hooks/useTransactionField';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import React, { useMemo } from 'react';
import { WizardStepPage } from '../Wizard/WizardStepPage';
import { CustomisePartiesSection } from './CustomisePartiesSection';
import { getContactsFromAuthorityParty } from '@property-folders/common/util/digest-authority-party-details';
import { WrField } from '../form/CommonComponentWrappers';
import { mergePaths, normalisePathToStr } from '@property-folders/common/util/pathHandling';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import {
  getSigningOrderVersion,
  setSigningOrderVersion, SigningOrderVersion
} from '@property-folders/common/util/form';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import { FloatingLabel, Form } from 'react-bootstrap';
import { ShowGuidanceNotesButton } from '../guidance/ShowGuidanceNotesButton';

export function SigningOptions({ instancePath, formCode, hasRemoteSigning }: {
  instancePath: string,
  formCode: FormCodeUnion,
  hasRemoteSigning: boolean
}) {
  const { update: updateUserPrefs, data: userPrefs } = useUserPreferences();
  const { value: form1Preparer } = useLightweightTransaction<Form1SearchParty>({ myPath: 'form1AndSearches.whoSearches' });
  const { value: vendors } = useLightweightTransaction<VendorParty[]>({ myPath: 'vendors' });
  const { value: parties } = useLightweightTransaction<SigningParty[]|undefined>({ parentPath: instancePath, myPath: 'signing.parties', bindToMetaKey: true });
  const { value: primaryVendor } = useLightweightTransaction<string>({ myPath: 'primaryVendor' });
  const { value: epfOrderingAutomatic } = useLightweightTransaction<EpfAutoOrdering>({
    parentPath: 'form1AndSearches',
    myPath: 'epfOrderingAutomatic'
  });
  const { pfRemoteCompletion } = useFeatureFlags();
  const orderAutomatic = [EpfAutoOrdering.WithInstant, EpfAutoOrdering.WithoutInstant].includes(epfOrderingAutomatic ?? EpfAutoOrdering.Later);

  const multiplePartyTypes = (FormTypes[formCode]?.parties?.length || 0) > 1;
  const customisable = !!(FormTypes[formCode]?.isCustomisable);

  const vendorOuter = vendors?.find(v => v.id === primaryVendor);
  const { email: primaryVendorEmail } = getContactsFromAuthorityParty(vendorOuter, primaryVendor).filter(p=>p.isPrimary)[0] ?? {};
  const validDataAllowsAutomatic = (vendorOuter?.addressSingleLine || vendorOuter?.addrSameAsSale) && primaryVendorEmail;
  const items = [];
  hasRemoteSigning && items.push(<TextClickCheck
    key='notify_remote'
    title={'You will receive an email notification if someone signs remotely, or on another reaforms user\'s screen'}
    label='Notify me when a party signs remotely'
    checked={!!userPrefs?.lastNotifyOnRemoteSign}
    onSelected={() => {
      updateUserPrefs?.(draft => {
        draft.lastNotifyOnRemoteSign = !draft.lastNotifyOnRemoteSign;
      });
    }}
  />);

  formCode === FormCode.RSAA_SalesAgencyAgreement && form1Preparer === 'eckermanns' && items.push(<React.Fragment
    key='auto_form1'>
    {!validDataAllowsAutomatic
      ? <div>A Form 1 will <strong>not</strong> be ordered automatically, as the primary Vendor is missing contact details required for EPF</div>
      : !orderAutomatic
        ? <div>A Form 1 will <strong>not</strong> be ordered automatically</div>
        : epfOrderingAutomatic === EpfAutoOrdering.WithInstant
          ? <div>A Form 1 will be ordered automatically <strong>with</strong> instant searches</div>
          : <div>A Form 1 will be ordered automatically <strong>without</strong> instant searches</div>
    }
  </React.Fragment>);

  formCode === FormCode.RSC_ContractOfSale && items.push(<WrField.BoolCheck
    key='auto-form-1'
    title='Purchasers will be served the Form 1 automatically by email if an email is provided'
    label='Serve Form 1 automatically'
    bindToMetaKey={true}
    parentPath={normalisePathToStr(mergePaths(instancePath, 'signing'))}
    name={`${instancePath}.signing.instanceAutoForm1`}
    myPath='instanceAutoForm1'
  />);
  // We don't check signing types here, we'll distribute to anyone who has an email or SMS registered
  if ((parties?.length??0) > 0) {
    items.push(<div className='d-flex flex-row'><WrField.BoolCheck
      name='delayFullAutoDistribute'
      label={'Automatically distribute the fully signed agreement to the parties once completed'}
      parentPath={instancePath}
      myPath='signing.delayFullAutoDistribute'
      bindToMetaKey={true}
      inverter={true}
      nullishIsFalse={true}
      overrideChecked={(parties?.length??0) <= 1 ? true : undefined}
      disabled={(parties?.length??0) <= 1}
    />
    <span style={{ marginLeft: '-0.5rem' }}><ShowGuidanceNotesButton noteId={'distributionDelay'} /></span>
    </div>);
  }

  hasRemoteSigning && (multiplePartyTypes || customisable) && items.push(<SelectSigningOrder
    key='set_signing_order_2'
    parentPath={instancePath}
    myPath={'signing'}
    showRemoteCompletion={Boolean(pfRemoteCompletion && customisable)}
  />);

  FormTypes[formCode]?.isCustomisable && items.push(<CustomisePartiesSection key='customise_parties'/>);

  return items?.length ?
    <WizardStepPage
      key='notification-config'
      name='notification-config'
      label='Signing Options'
      icon='checkbox'
    >
      {items}
    </WizardStepPage> : <></>;
}

enum SigningOrderOption {
  None = 'none',
  Grouped = 'grouped',
  Individually = 'individually'
}

function SelectSigningOrder({
  parentPath,
  myPath,
  showRemoteCompletion
}: {
  parentPath: string,
  myPath: string,
  showRemoteCompletion: boolean
}) {
  const { value: form, fullPath: path } = useLightweightTransaction<FormInstance>({ parentPath, bindToMetaKey: true });
  const { updateDraft } = useYdocBinder<FormInstance>({ path: parentPath, bindToMetaKey: true });
  const options = useMemo(() => {
    return [
      {
        name: SigningOrderOption.None,
        label: 'Invite all parties at once',
        title: 'All parties can sign at the same time'
      },
      {
        name: SigningOrderOption.Grouped,
        label: 'Invite parties in groups',
        title: 'Drag groups to choose the order they sign'
      },
      {
        name: SigningOrderOption.Individually,
        label: showRemoteCompletion
          ? 'Invite parties individually (required for remote completion)'
          : 'Invite parties individually',
        title: 'Drag parties to choose the order they sign'
      }
    ];
  }, [showRemoteCompletion]);
  const selected = useMemo(() => {
    if (!form?.signing?.useSigningOrder) return SigningOrderOption.None;
    switch (getSigningOrderVersion(form?.signing)) {
      case SigningOrderVersion.Flat:
        return SigningOrderOption.Individually;
      case SigningOrderVersion.Grouped:
      default:
        return SigningOrderOption.Grouped;
    }
  }, [form?.signing?.useSigningOrder, form?.signing?.signingOrderVersion]);
  const onSelect = (option: SigningOrderOption) => {
    updateDraft?.(draft => {
      switch (option) {
        case SigningOrderOption.None:
          setSigningOrderVersion(draft, false, SigningOrderVersion.Grouped);
          break;
        case SigningOrderOption.Grouped:
          setSigningOrderVersion(draft, true, SigningOrderVersion.Grouped);
          break;
        case SigningOrderOption.Individually:
          setSigningOrderVersion(draft, true, SigningOrderVersion.Flat);
          break;
      }
    });
  };

  return <FloatingLabel label={'Invitation Order'} className='mt-3'>
    <Form.Select
      style={{ maxWidth: '410px' }}
      onChange={e => onSelect(e.target.value as SigningOrderOption)}
      title={options.find(opt => opt.name === selected)?.title}
    >
      {options.map(opt => <option
        key={opt.name}
        value={opt.name}
        selected={opt.name === selected}
        title={opt.title}
      >{opt.label}</option>)}
    </Form.Select>
  </FloatingLabel>;
}
