import React, { useCallback, useContext, useState } from 'react';
import { LineageContext } from '../../hooks/useVariation';
import { WizardDisplayContext } from '../../context/WizardContexts';
import { AsyncButton } from '../AsyncButton';

export function SigningConfigurationNextButton({
  prepareForSigning,
  label = 'Submit for Signing',
  waitingLabel
}: {
  prepareForSigning: () => Promise<void> | void,
  label?: string
  waitingLabel?: string
}) {
  const [waiting, setWaiting] = useState(false);
  const { snapshotData } = useContext(LineageContext);
  const { focusErrList } = useContext(WizardDisplayContext);
  const onClick = useCallback(() => {
    setWaiting(true);
    return Promise.resolve(prepareForSigning())
      .catch(console.error)
      .finally(() => setWaiting(false));
  }, [snapshotData]);

  return <div className="d-flex flex-column gap-1 align-items-end">
    <AsyncButton
      key="button"
      disabled={waiting || focusErrList.length !== 0}
      onClick={onClick}
    >
      {waitingLabel && waiting ? waitingLabel : label}

    </AsyncButton>
  </div>;
}
