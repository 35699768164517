import { difference } from 'lodash';
import { ReactNode, useMemo, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTransactionField } from '../../hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import clsJn from '@property-folders/common/util/classNameJoin';
import { CollectionRemoveButton } from './CollectionRemoveButton';
import { TextClickCheck } from './TextClickCheck';
import { getHierarchyNode } from '@property-folders/common/util/pathHandling';

export type CheckMultiSelectProps = TransactionConsumerProps & {
  options: (string|{name:string, label:string})[]
  allowOthers?: boolean
  otherTitle?: string
  otherAddText?: string
  beforeOthersNodes?: ReactNode | ReactNode[]
  noColumns?: boolean
  tileWidth?: number
};

/* */
export function CheckMultiSelect({ options, allowOthers, otherTitle, otherAddText, beforeOthersNodes, noColumns, tileWidth = 220,...restProps }: CheckMultiSelectProps) {
  const { value: selectedList, handleInsert, removeItem, handleClear, handleRemove, handleUpdate, handleAwarenessBlur, handleAwarenessFocus, hasVaried, variationContext, fullPath, readOnly } = useTransactionField(restProps);
  const { changeSet: variationChangeSet } = variationContext;
  const varied = useMemo(()=>{
    const fullPathSegs = fullPath.split('.');
    return [
      ...Object.keys(getHierarchyNode(fullPathSegs, variationChangeSet?.added)??{}),
      ...Object.keys(getHierarchyNode(fullPathSegs, variationChangeSet?.removed)??{})
    ];
  },
  [hasVaried, variationChangeSet]);

  const [custom, setCustom] = useState('');
  const optionNames = useMemo(()=>options.map(o=>typeof o === 'string' ? o : o.name), options);
  const nonOptions = useMemo(()=>difference(selectedList, optionNames),[selectedList, optionNames]);
  const toggle = (bindVal: string) => () => {
    if (Array.isArray(selectedList) && selectedList.includes(bindVal)) {
      removeItem(bindVal);
    } else {
      handleInsert(bindVal);
    }
  };

  const othersModified = nonOptions.filter(option=>varied.includes(option)).length > 0;

  const toggleNA = () => {
    if (!Array.isArray(selectedList)) {
      handleUpdate([],true);
    } else {
      if (selectedList.length > 0) {
        handleClear();
      } else {
        handleRemove();
      }
    }
  };

  const NAMode = Array.isArray(selectedList) && selectedList.length === 0;

  // Having the flash on the entire background looks too awkward, this is the pattern usually seen
  //  style={justUpdated ? { backgroundColor: 'lightgrey' } : {}}
  return <div>
    <div>
      <TextClickCheck
        disabled={readOnly}
        inline={true}
        onSelected={toggleNA}
        label='None applicable'
        type={'checkbox'}
        checked={NAMode}
        onFocus={handleAwarenessFocus}
        onBlur={handleAwarenessBlur}
      />
    </div>
    <div className={clsJn(!noColumns && 'd-flex flex-wrap')}>{options.map((opt) => {
      const name = typeof opt === 'string' ? opt : opt.name;
      const label = typeof opt === 'string' ? opt : opt.label;
      const clickHandle = toggle(name);
      return <div style={{ minWidth: `${tileWidth}px` }} key={name}>
        <TextClickCheck
          disabled={readOnly}
          onSelected={clickHandle}
          inline={true}
          name={name}
          label={label}
          type={'checkbox'}
          checked={!!selectedList?.includes(name)}
          onFocus={handleAwarenessFocus}
          onBlur={handleAwarenessBlur}
          markVaried={varied && varied.includes(name)}
        />
      </div>;
    })}
    </div>
    {beforeOthersNodes}
    {allowOthers && !readOnly && <div className='mt-2'>
      {otherTitle && <div><span className={clsJn('fs-5', othersModified && 'varied-control')}>{otherTitle}</span></div>}
      <div className={clsJn('my-2', !noColumns && 'd-flex flex-wrap')}>{nonOptions.map((text, idx) => {
        return <div key={text} className='d-flex align-items-center py-1 px-2 border border-light border-1' style={{ minWidth: `${tileWidth-2}px` }}>
          <span className={clsJn(varied && varied.includes(text) && 'varied-control')}>{text}</span>
          <div className='ms-auto'><CollectionRemoveButton onRemove={()=>removeItem(text)} removable={true} key={text} /></div>
        </div>;
      })}</div>
      <div className='d-flex' style={{ width: '300px' }}>
        <Form.Control
          className='flex-grow-1 flex-shrink-1'
          value={custom}
          onChange={e=>setCustom(e.target.value)}
          onFocus={handleAwarenessFocus}
          onBlur={handleAwarenessBlur}
        ></Form.Control>
        <Button
          title='Add other entries that are not in the list above'
          className='flex-grow-0 flex-shrink-0'
          variant='light'
          onClick={()=>{custom && handleInsert(custom);setCustom('');}}
        >
          {otherAddText}
        </Button>
      </div>
    </div>}
  </div>;
}
