import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export function TooltipWhenRequired(props: {children: JSX.Element[]|JSX.Element|string , title: string, required: boolean}) {
  if (props.required && props.title) {
    return <OverlayTrigger trigger={'click'} rootClose={true} overlay={<Tooltip id={'tooltip-disabled'}>{props.title}</Tooltip>}>
      <span className={'d-inline-block cursor-pointer'}>
        {props.children}
      </span>
    </OverlayTrigger>;
  }

  return <>{props.children}</>;
}
