import { WizardStepPage, WizardStepPageProps } from '@property-folders/components/dragged-components/Wizard/WizardStepPage';
import { useCallback, useState } from 'react';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { SaleAddress, SaleTitle } from '@property-folders/contract';
import { useFormMeta } from '@property-folders/components/hooks/useFormMeta';
import { VendorCollectionWithImport } from '@property-folders/components/dragged-components/form/VendorCollectionWithImport';
import { useIsSailisOutage } from '@property-folders/web/src/redux/useIsSailisOutage';

export type VendorWizardSetPageProps = WizardStepPageProps & {
  contractMode?: boolean;
  autoAddFirst?: boolean;
  topContent?: JSX.Element
};

export function VendorWizardStepPage(props: Partial<VendorWizardSetPageProps>) {
  const { value: saleAddrs } = useLightweightTransaction<SaleAddress[]>({ myPath: 'saleAddrs' });
  const { value: saleTitles } = useLightweightTransaction<SaleTitle[]>({ myPath: 'saleTitles' });
  const [showImportModal, setShowImportModal] = useState(false);

  const formMeta = useFormMeta();
  const onHide = useCallback(() => setShowImportModal(false), []);

  const headerActions: {[x:string]: any} = {};

  headerActions.vendorImport = {
    label: 'Order',
    tooltip: 'Order vendor details from Land Services SA',
    disabled: useIsSailisOutage() || saleAddrs?.length === 0 || saleTitles?.length === 0 || !saleAddrs?.some(a => a.gnaf),
    isPrimary: false,
    onClick: () => setShowImportModal(true)
  };

  // key is required on the collection because if the top content goes from present to not present,
  // it will treat it as a new component and re-render it

  return <>
    <WizardStepPage
      name='vendor'
      label='Vendor'
      icon='person'
      {...props}
      defaultHeaderActions={headerActions}
    >
      {props.topContent}
      <VendorCollectionWithImport
        key='vendorcollection'
        autoAddFirst={props.autoAddFirst}
        show={showImportModal}
        onHide={onHide}
        entityId={formMeta?.entity?.id}
      />
    </WizardStepPage>
  </>;
}
