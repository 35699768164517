import { EditorInfo } from '@property-folders/common/subscription-forms';
import { useEffect } from 'react';

export function WrappedFrameElement({
  element,
  editInfo,
  onOpen,
  iframe
}: {
  element: HTMLDivElement,
  editInfo: EditorInfo,
  onOpen: (editInfo: EditorInfo) => void,
  iframe: HTMLIFrameElement | null
}) {
  useEffect(() => {
    const handler = () => {
      onOpen(editInfo);
    };

    const keyDownHandler = (ev: KeyboardEvent) => {
      if (['Tab', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Escape'].includes(ev.key)) {
        return;
      }

      onOpen(editInfo);
    };

    if (iframe?.contentWindow) {
      const elLinked = iframe.contentWindow.document.createElement('span');
      elLinked.dataset.subFormsLinkedIndicator = 'true';
      elLinked.textContent = '(linked)';
      elLinked.title = `This field is linked to the property folder.
Changing this field will automatically update
other documents in the property folder.`;
      elLinked.style.position = 'absolute';
      elLinked.style.right = '-74px';
      element.after(elLinked);
    }

    element.addEventListener('click', handler);
    element.addEventListener('keydown', keyDownHandler);
    editInfo.editor !== 'none' && element.classList.add('pflinked');
    if (element.parentElement) {
      element.parentElement.classList.add('pflinked-container');
    }

    return () => {
      const nextSibling = element.nextElementSibling;
      if (nextSibling && 'dataset' in (nextSibling as HTMLElement)) {
        if ((nextSibling as HTMLElement).dataset.subFormsLinkedIndicator) {
          nextSibling.remove();
        }
      }
      element.removeEventListener('click', handler);
      element.removeEventListener('keydown', keyDownHandler);
      element.classList.remove('pflinked');
      if (element.parentElement) {
        element.parentElement.classList.remove('pflinked-container');
      }
    };
  }, []);
  return <></>;
}
