import { FieldPosition, PendingRemoteSigningSessionPage } from '@property-folders/contract';
import { renderText } from '@property-folders/common/util/image';
import { Image } from 'react-bootstrap';
import React from 'react';
import { asPercentage } from './common';

export function Witness({ page: { width: pageWidth, height: pageHeight }, location, value }: { page: PendingRemoteSigningSessionPage, location: FieldPosition, value?: string }) {
  const asImage = value && value.length > 0
    ? renderText({
      text: value,
      font: {
        name: 'Helvetica',
        size: 30
      },
      // this is just to adjust how freaking massive the text would appear otherwise
      padding: {
        left: 4,
        top: 40,
        right: 525,
        bottom: 10
      }
    })
    : undefined;

  return <div
    style={{
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'end',
      left: asPercentage(location.x, pageWidth),
      bottom: asPercentage(location.y, pageHeight),
      width: asPercentage(location.width, pageWidth),
      height: asPercentage(location.height, pageHeight)
    }}>
    <Image src={asImage} className={'w-100'}/>
  </div>;
}
