import { useEffect, useMemo } from 'react';
import { Predicate } from '@property-folders/common/predicate';
import { useTransactionField } from '@property-folders/components/hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { canonicalisers } from '@property-folders/common/util/formatting';
import { WrField } from '../CommonComponentWrappers';
import { isoLocalDateFormatter, parseInt2 } from '@property-folders/common/util/formatting';
import { ContractSettlementType } from '@property-folders/contract';
import { usePrevious } from 'react-use';
import { defaultConditionalDays, defaultSettlementDays } from '@property-folders/common/data-and-text/constants';

export const ContractSettlement = (props: TransactionConsumerProps & { radioEnable?: boolean, offerMode?: boolean, saaMode?: boolean }): JSX.Element => {
  const { value: settlement, fullPath, handleUpdate: updateSettlementDraft } = useTransactionField<ContractSettlementType>(props);
  const previous = usePrevious(settlement);

  const settlementDuration = settlement?.onContractDays || defaultSettlementDays;
  const dateSuggestion = useMemo(()=>{
    let rval;
    if (Predicate.isNotNullish(settlementDuration)) {
      const canoni = canonicalisers.days(settlementDuration);
      if (canoni.valid) {
        const days = parseInt2(canoni.canonical);
        const d = new Date();
        d.setDate(d.getDate()+days);
        rval = isoLocalDateFormatter(d);
      }
    }
    return rval;
  }, [settlementDuration]);

  const exclusiveSelector = (newTrue: 'onContract' | 'onDate' | 'onCondition') => {
    if (previous?.onDate && newTrue === 'onDate') return;
    if (previous?.onCondition && newTrue === 'onCondition') return;
    if (previous?.onContract && newTrue === 'onContract') return;
    updateSettlementDraft?.(draft => {

      delete draft.onContract;
      delete draft.onDate;
      delete draft.onCondition;
      switch (newTrue) {
        case 'onCondition': {
          draft.onCondition = true;
          return;
        }
        case 'onDate': {
          draft.onDate = true;
          return;
        }
        case 'onContract': {
          draft.onContract = true;
          return;
        }
      }
    });
  };

  useEffect(() => {
    if (!props.radioEnable) return;
    if (settlement?.onContract && previous?.onContract !== settlement.onContract) {
      exclusiveSelector('onContract');
    } else if (settlement?.onDate && previous?.onDate !== settlement.onDate) {
      exclusiveSelector('onDate');
    } else if (settlement?.onCondition && previous?.onCondition !== settlement.onCondition) {
      exclusiveSelector('onCondition');
    }
  }, [props.radioEnable, previous, settlement]);

  const onContractLabel = (settlement?.onContractDays ? `${settlement.onContractDays} ` : '') + 'Days after signing the contract';
  const onConditionLabel = (settlement?.onConditionDays ? `${settlement.onConditionDays} ` : '') + 'Business Days after the date on which all the Special Conditions and any conditional Additional Conditions are satisfied or waived.';

  return <div className="w-100">
    {props.offerMode
      ? <div className='lead'>What is your preferred date for settlement?</div>
      : <div className='lead'>When {props.saaMode?'should':'will'} Settlement occur?</div>}
    <div className='mt-3'>
      {/* Date is no longer specified as enabled by default. Given the contract now is able to
        specify days since contract signing, this should really only be used in cases such as
        auction. If you are setting up an auction, it'd make sense to set it up at SAA time too.
        so we'll keep the settlement date in the SAA
       */}
      <WrField.CheckRadio
        radioType={props.radioEnable ? 'radio' : 'checkbox'}
        options={{ 'true': 'Set Date' }}
        valueType='boolean'
        inline={true}
        parentPath={fullPath}
        name='onDate'
        myPath='onDate'
      />
    </div>
    {settlement?.onDate && <div style={{ width: '300px' }}><WrField.Control
      label='Settlement date'
      type='date'
      dateFromToday={true}
      name='date'
      parentPath={fullPath}
      myPath='date'
      useCanonical={true}
      key={'settlementSetDate'}
      setDefaultValue={dateSuggestion}
      defaultValueLabel={dateSuggestion ? settlementDuration + ' days from now' : undefined}
      alwaysShowDefaultSetter={true}
    /></div>}

    <div className='mt-3'>
      <WrField.CheckRadio
        radioType={props.radioEnable ? 'radio' : 'checkbox'}
        options={{ 'true': onContractLabel }}
        valueType='boolean'
        inline={true}
        parentPath={fullPath}
        name='onContract'
        myPath='onContract'
      />
    </div>
    {settlement?.onContract && <div style={{ width: '300px' }}>
      <WrField.Control
        label='Days'
        parentPath={fullPath}
        name='onContractDays'
        myPath='onContractDays'
        key={'settlementSetContractDays'}
        setDefaultValue={`${defaultSettlementDays}`}
        defaultValueLabel={`${defaultSettlementDays} days`}
      />
    </div>}

    <div className="mt-3">
      <WrField.CheckRadio
        radioType={props.radioEnable ? 'radio' : 'checkbox'}
        options={{ 'true': onConditionLabel }}
        valueType='boolean'
        inline={true}
        parentPath={fullPath}
        name='onCondition'
        myPath='onCondition'
      />
    </div>
    {settlement?.onCondition && <div style={{ width: '300px' }}>
      <WrField.Control
        label='Business Days'
        parentPath={fullPath}
        name='onConditionDays'
        myPath='onConditionDays'
        key={'settlementSetConditionDays'}
        setDefaultValue={`${defaultConditionalDays}`}
        defaultValueLabel={`${defaultConditionalDays} days`}
      />
    </div>}
  </div>;
};
