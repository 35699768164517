import { useTransactionField } from '../../hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { auctionOnSiteOptions } from '@property-folders/common/data-and-text/constants';
import '../Form.scss';
import { WrField } from './CommonComponentWrappers';
import { AddressSelector } from './AddressSelector';
import { saleMethodOptions } from '@property-folders/contract';
import { usePropertyGnafLocation } from '../../hooks/usePropertyGnafLocation';

export const SaleMethodInput = ({  ...restProps }: TransactionConsumerProps): JSX.Element => {
  const { value: transRoot } = useTransactionField({ parentPath: restProps.parentPath, myPath: '' });
  const saleMap = transRoot?.sale;
  const auctionMap = transRoot?.auction;
  const gnafId = usePropertyGnafLocation();

  return (
    <div className="d-flex w-100 gapped-row">
      <div className='w-100 subsection scrollspy-target' data-focus-path="subsection-sale-method">
        <div className="d-flex w-100 flex-wrap">
          <div className='flex-grow-0' style={{ minWidth: '190px' }}>
            <WrField.Select
              name='saleMethod'
              myPath='sale.saleMethod'
              label='Method of sale'
              options={saleMethodOptions}
              guidanceNoteId='saleMethodNote'
            />
          </div>
          {saleMap?.saleMethod === 'other' && <div className='flex-grow-1'>
            <WrField.Control name='saleMethodOther' myPath='sale.saleMethodOther' label="Method of sale (other)" />
          </div>}
        </div>
        {saleMap?.saleMethod === 'auction' && <>
          {!auctionMap?.timeTbc && <div className="d-flex w-100 flex-wrap gapped-row">
            <div style={{ minWidth: '190px' }}>
              <WrField.Control type='date' dateFromToday={true} useCanonical={true} myPath='auction.propDate' name='propDate' label='Proposed date'/>
            </div>

            <div style={{ minWidth: '190px' }}>
              <WrField.Control type='time' useCanonical={true} myPath='auction.propTime' name='propTime' label='Proposed time' />
            </div>
          </div>}
          <div className='mt-2'>
            <WrField.BoolCheck name='timeTbc' myPath='auction.timeTbc' label='Date and Time to be confirmed' />
          </div>

          <div className="d-flex w-100 flex-wrap gapped-row">
            <div className='flex-grow-0' style={{ minWidth: '190px' }}>
              <WrField.Select name='onSite' myPath='auction.onSite' label='Auction shall be' valueType='boolean' options={auctionOnSiteOptions} />
            </div>
            {typeof auctionMap?.onSite === 'boolean' && !auctionMap?.onSite && <div className='flex-grow-1'>
              <AddressSelector myPath='auction.location' label='Auction address' gnafCentre={gnafId} />
            </div>}
          </div>
        </>}
      </div>
    </div>
  );
};
