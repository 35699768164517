import { $createTextNode, TextNode } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import { mergeRegister } from '@lexical/utils';
import { $createReplacementTokenNode, ReplacementTokenNode } from './ReplacementTokenNode';
import invariant from '../shared/src/invariant';
import { isValidReplacementToken } from '@property-folders/common/util/process-template';

export function ReplacementTokenPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    invariant(editor.hasNodes([ReplacementTokenNode]),
      'ReplacementTokenPlugin: ReplacementTokenNode not registered on editor');

    return mergeRegister(
      // replace @@blah TextNodes with ReplacementTokenNodes
      editor.registerNodeTransform(TextNode, node => {
        if (!node.isSimpleText()) {
          return;
        }

        const text = node.getTextContent();
        const match = findReplacementToken(text);
        if (!match) {
          return;
        }
        let targetNode: TextNode;
        if (match.start === 0) {
          [targetNode] = node.splitText(
            match.end
          );
        } else {
          [, targetNode] = node.splitText(
            match.start,
            match.end
          );
        }
        const replacementTokenNode = $createReplacementTokenNode(match.token);
        targetNode.replace(replacementTokenNode);
      }),
      // replace not- @@blah ReplacementTokenNodes with TextNodes
      editor.registerNodeTransform(ReplacementTokenNode, node => {
        const text = node.getTextContent();
        if (isReplacementToken(text)) {
          return;
        }
        node.replace($createTextNode(text));
      })
    );
  }, [editor]);

  return null;
}

interface ReplacementTokenMatch { start: number, end: number, token: string }
function findReplacementToken(text: string): ReplacementTokenMatch | undefined {
  const match = text.match(/@@\w+/);
  if (match?.index == null) {
    return undefined;
  }

  const token = match[0];
  return isReplacementToken(token) ? {
    start: match.index,
    end: match.index + token.length,
    token
  } : undefined;
}

function isReplacementToken(text: string): boolean {
  return isValidReplacementToken(text);
}
