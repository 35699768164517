import { useLightweightTransaction } from '../../hooks/useTransactionField';
import { BenefitItem, DisclosureOfBenefitsType } from '@property-folders/contract';
import { useCurrentEntity } from '../../hooks/useEntity';
import React, { useMemo } from 'react';
import Select, { createFilter } from 'react-select';
import { DisclosureOfBenefitsTemplate } from '@property-folders/contract/yjs-schema/entity-settings';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import { v4 } from 'uuid';
import { byManyAspects, byMapperFn } from '@property-folders/common/util/sortComparison';

export function DisclosureOfBenefitTemplateSelector({ parentPath }: { parentPath: string }) {
  const { value: benefits } = useLightweightTransaction<DisclosureOfBenefitsType>({ parentPath });
  const { updateDraft: updateBenefits } = useYdocBinder<DisclosureOfBenefitsType>({ path: parentPath });
  const entitySettings = useCurrentEntity();
  const { options, selected } = useMemo(() => {
    const defaultTemplateId = entitySettings?.dobDefaultTemplateId;
    const selectedTemplateId = benefits?.template?.id;
    const result = [...(entitySettings?.dobTemplates || [])]
      .sort(byManyAspects([
        byMapperFn(x => defaultTemplateId && x.id === defaultTemplateId ? 1 : 0, 'desc'),
        (a, b) => a.name.localeCompare(b.name)
      ]));

    return {
      options: result,
      selected: selectedTemplateId
        ? result.find(o => o.id === selectedTemplateId)
        : undefined
    };
  }, [entitySettings, benefits?.template?.id]);

  if (!benefits?.enable) return <></>;
  return <div>
    <div className='lead mt-3'>
      <span>Disclosure of Benefits Template</span>
    </div>
    <Select<DisclosureOfBenefitsTemplate>
      options={options}
      value={selected}
      placeholder={'Select a template...'}
      isSearchable
      isClearable
      isOptionSelected={(option, values) => {
        return values.some(value => value.id === option.id);
      }}
      filterOption={createFilter({
        trim: true,
        ignoreCase: true,
        matchFrom: 'any',
        ignoreAccents: true,
        stringify: option => option.data.name
      })}
      onChange={value => {
        if (value) {
          updateBenefits?.(draft => {
            draft.template = {
              id: value.id,
              name: value.name
            };
            draft.benefits = value.items.map<BenefitItem>(item => ({
              id: v4(),
              itemDesc: item.nature,
              itemSource: item.source,
              itemCost: item.amount,
              itemRecipient: item.recipient
            }));
          });
        } else {
          updateBenefits?.(draft => {
            delete draft.template;
            delete draft.benefits;
          });
        }
      }}
      formatOptionLabel={(data, meta) => data.name}
      getOptionLabel={option => option.name}
      getOptionValue={option => option.name}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          background: 'var(--bs-body-bg)',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'rgb(var(--bs-secondary-rgb))',
          borderRadius: 0,
          height: '100%',
          fontSize: '1rem'
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 10
        })
      }}
    />
  </div>;
}
