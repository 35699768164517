import { Button, Card } from 'react-bootstrap';
import { Icon } from '../Icon';
import './ExternalPropertyUpdatesCard.scss';
import clsJn from '@property-folders/common/util/classNameJoin';

export interface ExternalPropertyUpdatesCardProps {
  show: boolean;
  className?: string;
  onReviewClick: () => void;
}

export function ExternalPropertyUpdatesCard({ show, className, onReviewClick }: ExternalPropertyUpdatesCardProps) {
  if (!show) {
    return <></>;
  }

  return <Card className={clsJn(className, 'external-property-updates-card')}>
    <Card.Body className='d-flex'>
      <Icon name='menu_open' style={{ transform: 'rotateY(180deg)' }} />
      <div className='w-100 ms-2 d-flex flex-column'>
        <p className='w-100'>
          <strong>Updates pending.</strong><br />
          External updates have been received.
        </p>
        <Button variant='outline-dark ms-auto' onClick={onReviewClick} style={{ color: 'black' }}>Review</Button>
      </div>
    </Card.Body>
  </Card>;
}
