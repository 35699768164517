import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap';
import { HtmlClause, MarkdownClause } from '@property-folders/contract/rest/clauses';
import { EditorMode, RichTextEditor, RichTextEditorComponent } from '../RichTextEditor';
import { useState, CSSProperties } from 'react';
import { MultiSelectForm } from '../SelectForm';
import FormCheck from 'react-bootstrap/FormCheck';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { Clause, PartialClause } from '@property-folders/common/types/Clause';
import { AsyncButton } from '../AsyncButton';

interface Props {
  show: boolean;
  clause: Clause & { migrate?: boolean; };
  onHide: () => void;
  onEdit: (clause: Clause) => void | Promise<void>;
  entityUuid: string
  propertyFoldersEnabled: boolean;
}

export function EditClauseModal(props: Props) {
  const { show, onHide, onEdit } = props;
  const [clause, setClause] = useState<Clause | null>(props.clause ?? null);
  const { newPropertyFolders } = useFeatureFlags();
  const [migrating, setMigrating] = useState(props.clause.migrate ?? false);
  const isMarkdown = clause && 'markdownClause' in clause && clause.markdownClause != null;

  const [validations, setValidations] = useState({
    title: true,
    clause: true,
    formCodes: true
  });

  if (!clause) {
    return null;
  }

  const updateClauseProperty = <K extends typeof clause, T extends keyof K>(key: T, val: K[T]) => {
    setClause(c => {
      if (c === null) {
        return c;
      }

      if (key === 'formCodes' && Array.isArray(val)) {
        c.formCodesRequired = c.formCodesRequired.filter(fcr => !val.includes(fcr));
      } else if (key === 'formCodesRequired' && Array.isArray(val)) {
        c.formCodes = c.formCodes.filter(fc => !val.includes(fc));
      }

      const newClause = { ...c, [key]: val };
      validate(key as string, newClause as PartialClause);

      return newClause;
    });

    // was getting a few weird flash backs when migrating, this seems to have resolved it
    (props.clause as K)[key] = val;
  };

  const validate = (type?: string, actualClause?: PartialClause) => {
    if (!actualClause) {
      if (!clause) {
        return;
      }

      actualClause = clause as PartialClause;
    }

    let newValidations = {
      title: validations.title,
      clause: validations.clause,
      formCodes: validations.formCodes,
      allForms: true
    };

    const actualValidation: { [key: string]: boolean } = {
      title: actualClause.title.length > 0,
      clause: isMarkdown && 'markdownClause' in actualClause
        ? actualClause.markdownClause.length > 0
        : (actualClause as HtmlClause).htmlClause.length > 0,
      formCodes: actualClause.formCodes.length > 0 || actualClause.formCodesRequired.length > 0 || actualClause.allForms,
      allForms: true
    };

    if (type && actualValidation[type] != null) {
      // @ts-ignore
      newValidations[type] = actualValidation[type];
    } else {
      // @ts-ignore
      newValidations = actualValidation;
    }

    setValidations(newValidations);

    for (const validation of Object.keys(newValidations)) {
      // @ts-ignore
      if (!newValidations[validation]) {
        return false;
      }
    }

    return true;
  };

  const editorStyles: CSSProperties = { maxHeight: '50vh', overflow: 'auto' };
  const topLevelItem = 1;

  return <Modal
    onHide={onHide}
    show={show}
    size='xl'
  >
    <Modal.Header>
      <h3>Edit Clause</h3>
      {!isMarkdown && !migrating &&
        <Button variant='outline-secondary' onClick={() => setMigrating(true)}>Migrate</Button>}
    </Modal.Header>

    <Modal.Body>
      <Form.Group className='mb-3'>
        <div className='d-flex w-100'>
          <Form.Control
            type='text'
            value={`${topLevelItem}.`}
            disabled={true}
            className='w-auto'
            style={{ maxWidth: 32 }}
            title='When clauses are imported into a document, they will be part of a numbered list. The top-level number will be the position of the clause in the list.'
          />
          <FloatingLabel label='Title' className='w-100'>
            <Form.Control
              type='text'
              placeholder='Enter the name of the clause...'
              value={clause?.title}
              onChange={e => updateClauseProperty('title', (e.target?.value ?? ''))}
              className='w-100'
            />
          </FloatingLabel>
        </div>
        {!validations.title && <p className='text-danger'>*Title must be set</p>}
      </Form.Group>

      {migrating && 'htmlClause' in clause &&
        <RichTextEditorComponent
          key={'migrating-' + clause?.clauseId}
          value={clause?.htmlClause as string}
          inputMode={EditorMode.HTML}
          outputMode={EditorMode.MARKDOWN}
          namespace='new-clause-dialog'
          onUpdate={value => {
            updateClauseProperty<MarkdownClause, keyof MarkdownClause>('markdownClause', value);
            updateClauseProperty<HtmlClause, keyof HtmlClause>('htmlClause', '');
            setMigrating(false);
          }}
          contentEditableStyle={editorStyles}
        />
      }

      {!migrating && isMarkdown &&
        <RichTextEditor
          key={'markdown' + clause?.clauseId}
          value={clause?.markdownClause as string}
          outputMode={EditorMode.MARKDOWN}
          namespace='new-clause-dialog'
          onUpdate={value => updateClauseProperty<MarkdownClause, keyof MarkdownClause>('markdownClause', value)}
          contentEditableStyle={editorStyles}
          style={{ counterReset: `topLevelItem ${topLevelItem}` }}
        />
      }

      {!migrating && !isMarkdown && 'htmlClause' in clause &&
        <RichTextEditor
          key={'html' + clause?.clauseId}
          value={clause?.htmlClause as string}
          outputMode={EditorMode.HTML}
          namespace='new-clause-dialog'
          onUpdate={value => updateClauseProperty<HtmlClause, keyof HtmlClause>('htmlClause', value)}
          contentEditableStyle={editorStyles}
        />
      }
      {!validations.clause && <p className='text-danger'>*Clause text must be set</p>}

      <FormCheck
        type='checkbox'
        label='Is editable?'
        checked={clause?.editable}
        id='editable'
        onChange={e => {
          updateClauseProperty('editable', !clause?.editable);
        }}
        className='mt-2'
      />

      <FormCheck
        type='checkbox'
        label='Available in all forms'
        checked={clause?.allForms}
        id={`${clause?.clauseId}-allForms`}
        onChange={e => {
          updateClauseProperty('allForms', !clause?.allForms);
        }}
        className='mt-2'
      />

      {!clause?.allForms && <>
        <MultiSelectForm
          key={'available-in' + props.clause?.clauseId}
          label='Available in'
          className='mt-2'
          value={clause?.formCodes ?? []}
          onChange={newForms => updateClauseProperty('formCodes', newForms)}
          entityUuid={props.entityUuid}
          subscriptionForms={true}
          propertyForms={false}
          withClauses={true}
        />

        {newPropertyFolders && <MultiSelectForm
          key={'required-in' + props.clause?.clauseId}
          label='Required in'
          className='mt-2'
          value={clause?.formCodesRequired ?? []}
          onChange={newForms => updateClauseProperty('formCodesRequired', newForms)}
          subscriptionForms={true}
          entityUuid={props.entityUuid}
          propertyForms={false}
          withClauses={true}
        />}
      </>}
      {!validations.formCodes && <p className='text-danger'>*At least one form must be selected</p>}
    </Modal.Body>
    <Modal.Footer>
      <Button
        variant='light'
        className='ms-auto'
        onClick={onHide}
      >Cancel</Button>
      <AsyncButton
        className='ms-2'
        onClick={async () => {
          await onEdit(clause);
        }}
      >Save</AsyncButton>
    </Modal.Footer>
  </Modal>;
}
