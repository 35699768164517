import { Remarkable } from 'remarkable';
import { useEffect, useState } from 'react';
import '../dragged-components/Numbering.scss';

interface MarkdownRendererInlineProps {
  markdown: string;
}

interface MarkdownRendererUrlProps {
  url: string;
}

export function MarkdownRenderer(props: MarkdownRendererInlineProps | MarkdownRendererUrlProps) {
  const remarkable = new Remarkable();
  const [markdown, setMarkdown] = useState<string>();

  useEffect(() => {
    if ('url' in props) {
      fetch(props.url).then(r => {
        r.text().then(text => {
          setMarkdown(remarkable.render(text));
        });
      });
    } else {
      setMarkdown(remarkable.render(props.markdown));
    }
  }, [props]);

  return markdown ? <div className='help-countable' dangerouslySetInnerHTML={{ __html: markdown }}></div> : <></>;
}
