import './MarkServedModal.scss';
import { Alert, Button, Modal } from 'react-bootstrap';
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { ContentType, ManifestType } from '@property-folders/contract';
import { FormTypes, PropertyFormYjsDal } from '@property-folders/common/yjs-schema/property/form';
import { useDropzone } from 'react-dropzone';
import clsJn from '@property-folders/common/util/classNameJoin';
import { processPdf } from '@property-folders/common/util/pdf/pdf-process';
import { FileStorage, FileType, StorageItemSyncStatus } from '@property-folders/common/offline/fileStorage';
import { uuidv4 } from 'lib0/random';
import * as Y from 'yjs';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { AnyAction, Store } from 'redux';
import { useStore } from 'react-redux';
import { FileSync } from '@property-folders/common/offline/fileSync';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';

export function MarkServedModal({
  formCode,
  formId,
  propertyId,
  recipientId,
  onClose
}: {
  formCode: string,
  formId: string,
  propertyId: string,
  recipientId: string,
  onClose: () => void
}) {
  const recipientType = 'purchaser';
  const label = FormTypes[formCode].label;
  const { ydoc, transactionRootKey, transactionMetaRootKey } = useContext(YjsDocContext);
  const [show, setShow] = useState(true);

  const uploadInput = useRef<HTMLInputElement | null>(null);
  const [pdf, setPdf] = useState<{filename:string, data:Uint8Array|ArrayBuffer}|undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState('');
  const [alterFile, setAlterFile] = useState(false);
  const store = useStore();
  const { instance: fileSync } = useContext(FileSyncContext);

  useEffect(() => {
    const recipient = new PropertyFormYjsDal(ydoc, transactionRootKey, transactionMetaRootKey).getServeRecipient(formCode, formId, recipientId);
    if (recipient?.manuallyServed) {
      setAlterFile(true);
    }
  }, [!!ydoc]);

  const handleDrop = async (acceptedFiles: File[] | FileList) => {
    const file = Array.isArray(acceptedFiles)
      ? acceptedFiles.at(0)
      : acceptedFiles.item(0);
    if (!file) {
      setErrorMessage('Failed to attach file. Please make sure PDF is specified');
      return;
    }
    try {
      const buf = await file?.arrayBuffer();
      const result = await processPdf(buf);
      if (!result?.pdf || result?.isEncrypted) {
        throw new Error('Could not attach PDF');
      }

      setErrorMessage('');
      setPdf({
        filename: file?.name || 'document.pdf',
        data: result.pdf
      });
    } catch (err: unknown) {
      console.error(err);
      setErrorMessage(`Failed to attach ${file?.name || 'file'}. Please make sure PDF is not encrypted and try again`);
    }
  };

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    await handleDrop(event.target.files);
  };

  const { getRootProps, getInputProps, isDragAccept  } = useDropzone({
    onDrop: handleDrop,
    noClick: false,
    multiple: false,
    accept: { [ContentType.Pdf]: [] }
  });

  const handleCancel = () => {
    setShow(false);
    onClose();
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setPdf(undefined);
  };

  const handleChooseClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    uploadInput.current?.click();
  };

  const handleMarkServed = async () => {
    if (!ydoc) return;

    try {
      if (alterFile) {
        if (!pdf) {
          return;
        }
        await alterServedCopy({ ydoc, file: pdf.data, propertyId, formId, formCode, recipientId, store, fileSync, dataRootKey: transactionRootKey, metaRootKey: transactionMetaRootKey });
      } else {
        await markServed({ ydoc, file: pdf?.data, propertyId, formId, formCode, recipientId, store, fileSync, dataRootKey: transactionRootKey, metaRootKey: transactionMetaRootKey });
      }
      setShow(false);
      onClose();
    } catch (err: unknown) {
      console.error(err);
      if (alterFile) {
        setErrorMessage('There was a problem updating the served file. Please try again.');
      } else {
        setErrorMessage('There was a problem marking as served. Please try again.');
      }
    }
  };

  return <Modal className='mark-served-modal' show={show} onHide={handleCancel}>
    <Modal.Header closeButton>
      {alterFile
        ? <Modal.Title>Update Signed Copy</Modal.Title>
        : <Modal.Title>Mark Served</Modal.Title>}
    </Modal.Header>
    <Modal.Body>
      <div>
        {alterFile
          ? <div className="mb-3">
          Update the signed copy served to {recipientType}.
          </div>
          : <div className="mb-3">
          Record that {label} has been served to this {recipientType}.
          </div>}
        <div>
          <input {...getInputProps()} ref={uploadInput} className={'d-none'} accept={'.pdf'} onChange={handleUpload} />

          <div style={{ height: '140px' }} {...getRootProps()} className={clsJn('drop-target mb-3', isDragAccept && 'drop-accept', pdf && 'is-uploaded' )} tabIndex={-1}>
            {pdf
              ? <div className={'d-flex text-black'}>
                <Icon name='check_circle' icoClass='me-1' style={{ color: 'green' }}/>
                {pdf.filename}
                <span className="material-symbols-outlined cursor-pointer ms-2" onClick={handleDeleteClick}>delete</span>
              </div>
              : <>
                <div className={'mb-2'}>Drag and drop the signed PDF</div>
                <div className={'mb-2'}>or</div>
                <Button variant={'primary'} className={'mr-3'} onClick={handleChooseClick}>Choose PDF</Button>
              </>}
          </div>

          {errorMessage && <Alert variant='danger' dismissible onClose={()=>setErrorMessage('')}>{errorMessage}</Alert>}
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant='outline-secondary' onClick={handleCancel}>Cancel</Button>
      {alterFile
        ? <AsyncButton disabled={!pdf} onClick={handleMarkServed}>Apply Update</AsyncButton>
        : <AsyncButton onClick={handleMarkServed}>Mark Served</AsyncButton>}
    </Modal.Footer>
  </Modal>;
}

async function markServed({
  ydoc,
  file,
  propertyId,
  formCode,
  formId,
  recipientId,
  store,
  fileSync,
  dataRootKey,
  metaRootKey
}: {
  ydoc: Y.Doc,
  file?: Uint8Array | ArrayBuffer,
  propertyId: string,
  formCode: string,
  formId: string,
  recipientId: string,
  store: Store<unknown,AnyAction>,
  fileSync: FileSync
  dataRootKey: string
  metaRootKey: string
}) {
  const id = uuidv4();
  if (file) {
    await FileStorage.write(
      id,
      FileType.PropertyFile,
      ContentType.Pdf,
      new Blob([file], { type: ContentType.Pdf }),
      StorageItemSyncStatus.PendingUpload,
      {
        propertyFile: {
          propertyId,
          formId,
          formCode
        }
      },
      { store, ydoc },
      {
        manifestType: ManifestType.None
      },
      undefined
    );
    FileSync.triggerSync(fileSync);
  }

  new PropertyFormYjsDal(ydoc, dataRootKey, metaRootKey).markServed(
    formCode,
    formId,
    recipientId,
    file ? { id, contentType: ContentType.Pdf } : undefined
  );
}

async function alterServedCopy({
  ydoc,
  file,
  propertyId,
  formCode,
  formId,
  recipientId,
  store,
  fileSync,
  dataRootKey,
  metaRootKey
}: {
  ydoc: Y.Doc,
  file: Uint8Array | ArrayBuffer,
  propertyId: string,
  formCode: string,
  formId: string,
  recipientId: string,
  store: Store<unknown,AnyAction>,
  fileSync: FileSync
  dataRootKey: string
  metaRootKey: string
}) {
  const id = uuidv4();
  await FileStorage.write(
    id,
    FileType.PropertyFile,
    ContentType.Pdf,
    new Blob([file], { type: ContentType.Pdf }),
    StorageItemSyncStatus.PendingUpload,
    {
      propertyFile: {
        propertyId,
        formId,
        formCode
      }
    },
    { store, ydoc },
    {
      manifestType: ManifestType.None
    },
    undefined
  );

  FileSync.triggerSync(fileSync);

  new PropertyFormYjsDal(ydoc, dataRootKey, metaRootKey).alterServedCopy(
    formCode,
    formId,
    recipientId,
    { id, contentType: ContentType.Pdf }
  );
}
