import { cloneElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { InstanceCardBase } from './InstanceCardBase';
import { SigningProgressBar } from './SigningProgressBar';
import { OfferContractSummary, OfferState } from '@property-folders/contract/property/OfferContractState';
import { friendlyDateFormatter, localDate } from '@property-folders/common/util/formatting';
import { Predicate } from '@property-folders/common/predicate';
import { SplitIfManyButton } from './SplitIfManyButton';
import { CardSectionRows } from './CardSectionRows';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { ExtraFormCode, FormCode, FormCodeUnion, FormInstance, FormSigningState, MaterialisedPropertyData, META_APPEND, PropertyRootKey, SigningStates, TransactionMetaData } from '@property-folders/contract/yjs-schema/property';
import { compareFormInstances } from '@property-folders/common/util/compareFormInstances';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { useReactRouterData } from '../hooks/useReactRouterHooks';
import { RouterData } from '@property-folders/web/src/App';
import { applyMigrationsV2_1 } from '@property-folders/common/yjs-schema';
import { useRef, useState } from 'react';
import { Icon } from './Icon';
import { FormUtil } from '@property-folders/common/util/form';
import { bind } from 'immer-yjs';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { useStore } from 'react-redux';
import { VoidSigningModal } from '../display/VoidSigningModal';

function isValidDate(d: Date) {
  return d instanceof Date && isFinite(d.getTime());
}

export function archiveToggleMigrationFactory(formCode: string) {
  const formFam = FormTypes[formCode].formFamily;
  if (!formFam) {
    return () => false;
  }
  return (draft: TransactionMetaData) => {
    const draftFormState = draft.formStates?.[formFam];
    if (!draftFormState) return;

    draftFormState.archived = !draftFormState.archived;

  };
}

function IndividualContractProgressBar({ instance }:{ instance?: OfferContractSummary }) {
  const total = (instance?.purchasers?.length??0) + (instance?.vendors?.length??0);
  const signed = [...(instance?.purchasers??[]), ...(instance?.vendors??[])].filter(p=>p.signedDate&&isValidDate(p.signedDate)).length;
  const signerCompletionValid = total && typeof signed === 'number';

  switch (instance?.state) {
    case OfferState.Terminated:
      return <SigningProgressBar
        completion={ 1}
        variant='danger'
        text='Terminated'
      />;
    case OfferState.Declined:
      return <SigningProgressBar
        completion={ 1}
        variant='danger'
        text='Declined'
      />;
    case OfferState.Withdrawn:
      return <SigningProgressBar
        completion={ 1}
        variant='danger'
        text='Withdrawn'
      />;
    case OfferState.Draft:
      return <SigningProgressBar
        completion={0}
        variant='success'
        text={'Draft'}
      />;
    case OfferState.Incomplete:
      return <SigningProgressBar
        completion={ signerCompletionValid ? signed/total : 0}
        variant='success'
        text={'Signing' + (signerCompletionValid ? ` ${signed} of ${total}` : '')}
      />;
    case OfferState.Signed:
      return <SigningProgressBar
        completion={1}
        variant='success'
        text={'Signed'}
      />;
    case OfferState.Submitted:
      return <SigningProgressBar
        completion={1}
        variant='info'
        text={'Offered'}
      />;
    default:
      return <SigningProgressBar
        completion={1}
        variant='danger'
        text='Unknown'
      />;
  }
}

export function OfferContractCard (props:{
  instance?: OfferContractSummary
  onRelatedClick?: ()=>void,
  onCreateForm?: (formCode: FormCodeUnion)=>void,
  onShowAssertTerminatedModal: ()=>void,
  onCloneSublineageForm: ()=>void,
  onForceFocus: (id: string, forceMe: boolean) => void
  forceFocus?: boolean
}): JSX.Element {
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const store = useStore();
  const { transId, ydoc } = useReactRouterData<RouterData>();
  const { instance } = props;
  const [showLabelModal, setShowLabelModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [label, setLabel] = useState<string>('');
  const textRef = useRef<HTMLInputElement>();

  const navigate = useNavigate();

  function getLatestInstance () {
    const formStates = (ydoc.getMap(instance?.lineageRootKey+META_APPEND).toJSON() as TransactionMetaData).formStates;
    const formState = formStates?.[FormCode.RSC_ContractOfSale];
    const lineageInstances = formState?.instances||[] as FormInstance[];
    return [...lineageInstances].sort(compareFormInstances)[0];
  }

  function handleViewYdoc () {
    const latestInstance = getLatestInstance();
    if (!latestInstance) return;
    navigate(LinkBuilder.documentPath(
      { id: transId, nicetext: instance?.headline },
      { id: latestInstance.id, nicetext: FormTypes[latestInstance.formCode].label },
      false)
    );
  }

  function handleViewPurchaserOffers () {
    const firstPurchaserName = instance?.purchasers?.[0]?.name;
    if (!instance?.prospectivePurchaserId || !firstPurchaserName) return;
    navigate(LinkBuilder.offerPurchaserPath({ id: transId, nicetext: instance?.headline }, { id: instance?.prospectivePurchaserId, nicetext: firstPurchaserName }));
  }
  function handleViewPurchaser () {
    const firstPurchaserName = instance?.purchasers?.[0]?.name;
    if (!instance?.prospectivePurchaserId || !firstPurchaserName) return;
    navigate(LinkBuilder.prospectivePurchaserPath({ id: transId, nicetext: instance?.headline }, { id: instance?.prospectivePurchaserId, name: firstPurchaserName }));
  }

  function handleVendorToSign () {
    const latestInstance = getLatestInstance();
    const firstPurchaserName = instance?.purchasers?.[0]?.name;
    if (!latestInstance.id || !firstPurchaserName) return;
    navigate(LinkBuilder.vendorToSignPath({ id: transId, nicetext: instance?.headline }, { id: latestInstance.id, nicetext: firstPurchaserName }));
  }

  const stateButtons = function() {
    switch (instance?.state) {
      case OfferState.Signed:
        return [
          <Button variant='outline-secondary' onClick={e=>{e.stopPropagation();props.onCreateForm?.(ExtraFormCode.SCV_ContractOfSaleVariation);}}>Create Variation</Button>,
          <Button variant='outline-secondary' onClick={e=>{e.stopPropagation();props.onCreateForm?.(ExtraFormCode.SCT_ContractOfSaleTermination);}}>Create Termination</Button>,
          <Button variant='outline-secondary' onClick={e=>{e.stopPropagation();props.onShowAssertTerminatedModal?.();}}>Mark as Terminated</Button>
        ].filter(Predicate.isTruthy);
      case OfferState.Submitted:
        return [
          <Button variant='outline-secondary' onClick={handleVendorToSign}>Vendor to Sign</Button>,
          <Button variant='outline-secondary' onClick={e=>{e.stopPropagation();props.onCloneSublineageForm();}}>Create new Contract</Button>
        ].filter(Predicate.isTruthy);
      case OfferState.Terminated:
      case OfferState.Declined:
        return [
          <Button variant='outline-secondary' onClick={e=>{e.stopPropagation();props.onCloneSublineageForm();}}>Create new Contract</Button>
        ];
      default: return [];
    }
  }();

  function handleAddLabel (label?:string) {
    applyMigrationsV2_1({
      typeName: 'Property',
      doc: ydoc,
      docKey: instance?.lineageRootKey + META_APPEND,
      migrations: [
        {
          name: 'Add Label',
          fn: (draft: TransactionMetaData) => {
            if (!draft.formStates?.[FormCode.RSC_ContractOfSale]) return;
            draft.formStates[FormCode.RSC_ContractOfSale].label = label;
          }
        }
      ]
    });
    setShowLabelModal(false);
  }

  function handleRemoveLabel () {
    applyMigrationsV2_1({
      typeName: 'Property',
      doc: ydoc,
      docKey: instance?.lineageRootKey + META_APPEND,
      migrations: [
        {
          name: 'Remove Label',
          fn: (draft: TransactionMetaData) => {
            if (!draft.formStates?.[FormCode.RSC_ContractOfSale]) return;
            draft.formStates[FormCode.RSC_ContractOfSale].label = undefined;
          }
        }
      ]
    });
  }

  function toggleArchive () {
    const meta = ydoc.getMap<TransactionMetaData>(instance?.lineageRootKey + META_APPEND);
    const data = ydoc.getMap(instance?.lineageRootKey||PropertyRootKey.Data); // Well it should always have a sublineage being a contract, but in case anyone gets any ideas that they should copy paste this code, this is the correct fallback in case of a non-sublineage
    const metaBinder = bind<TransactionMetaData>(meta);
    const dataBinder = bind<MaterialisedPropertyData>(data);
    const formState = (meta.toJSON() as TransactionMetaData).formStates?.[FormCode.RSC_ContractOfSale];
    if (!formState) return;
    const latestInstance = (formState?.instances||[] as FormInstance[])?.sort(compareFormInstances)[0];
    if (!formState.archived && SigningStates.has(latestInstance.signing?.state || FormSigningState.None)) {
      // We haven't yet moved to archived, so we're testing it is not yet archived
      FormUtil.transitionSigningState({
        store,
        formCode: latestInstance.formCode,
        formId: latestInstance.id,
        metaBinder,
        dataBinder,
        sessionInfo
      }, {
        to: FormSigningState.None
      });
    }
    applyMigrationsV2_1({
      typeName: 'Property',
      doc: ydoc,
      docKey: instance?.lineageRootKey + META_APPEND,
      migrations: [
        {
          name: `${formState.archived?'Unarchive':'Archive'} Contract`,
          fn: archiveToggleMigrationFactory(FormCode.RSC_ContractOfSale)
        }
      ]
    });

    setShowArchiveModal(false);
  }

  return <>
    <InstanceCardBase
      title="Contract of Sale"
      iconAfter={instance?.archived && 'archive-indicator'}
      onOpen={handleViewYdoc}
      clickable={true}
      footerButtons={<>
        {!([OfferState.Draft, OfferState.Incomplete, OfferState.Submitted].includes(instance?.state)) && <Button variant='outline-secondary' onClick={e=>{e.stopPropagation();props.onRelatedClick?.();}}>Related</Button>}
        <SplitIfManyButton onForceFocus={(forceMe)=>props.onForceFocus(instance?.lineageRootKey, forceMe)}>
          <Button variant='outline-secondary' onClick={e=>{e.stopPropagation();handleViewYdoc();}}>View</Button>
          {!!instance?.prospectivePurchaserId && <Button variant='outline-secondary' onClick={handleViewPurchaser}>View Prospective Purchaser</Button>}
          {!!instance?.prospectivePurchaserId && <Button variant='outline-secondary' onClick={handleViewPurchaserOffers}>View Related Offers</Button>}
          {stateButtons.map((stateButton, idx) => cloneElement(stateButton, { key: idx }))}
          {!!instance?.label && <Button variant='outline-secondary' onClick={handleRemoveLabel}>Remove Label</Button>}
          {!instance?.label && <Button variant='outline-secondary' onClick={()=>setShowLabelModal(true)}>Add Label</Button>}
          <Button variant='outline-secondary' onClick={() => instance?.state === OfferState.Incomplete && !instance.archived ? setShowArchiveModal(true) : toggleArchive()}>{instance?.archived ? 'Unarchive' : 'Archive'}</Button>
        </SplitIfManyButton>
      </>}
      forceFocus={props.forceFocus}
      bottom={instance?.label && <div className={'mt-auto'}>
        <Alert variant='secondary' className='m-0 px-4' style={{ borderBottom: 0, textAlign: 'center' }}>
          {instance?.label}
        </Alert>
      </div>}
    >
      <IndividualContractProgressBar instance={instance} />
      <div className='section-area'>
        {instance?.stateTime && <CardSectionRows
          rows={[['As at', friendlyDateFormatter(instance.stateTime)]]}>
        </CardSectionRows>}
        <CardSectionRows
          title='Purchasers'
          rows={instance?.purchasers?.map(party=>[
            party.name,
            `${localDate(party.signedDate) ?? '-'}`
          ])??[]}
        />
        <CardSectionRows
          title='Vendors'
          rows={instance?.vendors?.map(party=>[
            party.name,
            `${localDate(party.signedDate) ?? '-'}`
          ])??[]}
        />
        <CardSectionRows
          title='Form 1'
          rows={[instance?.form1Served
            ? ['Served', localDate(instance.form1Served)||'-']
            : [<><div className={''}>Not Served</div><Icon name='warning' icoClass='ms-2 me-1 warning-text'/></>, '']]??[]
          }
        />
      </div>
    </InstanceCardBase>

    <Modal show={showLabelModal} onHide={()=>setShowLabelModal(false)} backdrop="static" onEntered={()=>textRef?.current?.focus()}>
      <Modal.Header closeButton>
        <Modal.Title>Add Label</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          autoFocus
          ref={textRef}
          maxLength={20}
          onChange={e=> setLabel(e.target.value)}
          onKeyPress={event => {
            if (event.key === 'Enter') {
              handleAddLabel(label);
            }
          }} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={()=>setShowLabelModal(false)}>Cancel</Button>
        <Button variant="primary" onClick={()=>handleAddLabel(label)}>OK</Button>
      </Modal.Footer>
    </Modal>
    <VoidSigningModal
      show={showArchiveModal}
      onHide={()=>setShowArchiveModal(false)}
      title='Warning'
      bodyText={<p>
        This Contract has a signing session in progress.<br/>
        Archiving this contract will void the signing session.
      </p>}
      formInstance={getLatestInstance}
      ydocForceKey={instance?.lineageRootKey+META_APPEND}
      onConfirm={toggleArchive}
      confirmButtonText='Void Signing Session and Archive'
    />
    <Modal  backdrop="static">
      <Modal.Header>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={()=>setShowArchiveModal(false)}>Cancel</Button>
        <Button variant="primary" onClick={toggleArchive}></Button>
      </Modal.Footer>
    </Modal>
  </>;
}
