import { useContext, useEffect } from 'react';
import { FileStorage, FileType, IFileRelatedData } from '@property-folders/common/offline/fileStorage';
import { useLiveQuery } from 'dexie-react-hooks';
import { ContentType } from '@property-folders/contract';
import { FileSync } from '@property-folders/common/offline/fileSync';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';

export function useFileRef(fileId?: string, fileType?: FileType, contentType?: ContentType, relatedData?: IFileRelatedData) {
  const { instance: fileSync } = useContext(FileSyncContext);
  // generally this won't do anything.
  // if there is no logo loaded, then make an attempt here to do so.
  useEffect(() => {
    if (!(fileId && contentType && fileType && relatedData)) return;
    if (!fileSync) return;

    (async () => {
      try {

        const result = await FileStorage.read(fileId);

        if (result) {
          return;
        }

        await FileStorage.queueDownload(
          fileId,
          fileType,
          contentType,
          {  }
        );

        FileSync.triggerSync(fileSync);
      } catch (err: unknown) {
        console.error(err);
      }
    })();
  }, [fileId, fileSync]);

  return useLiveQuery(async () => {
    const data = await FileStorage.readFileOnly(fileId);
    if (!data?.size) return null;
    if (contentType !== data.type as ContentType) return null;

    return await data;
  }, [fileId]);
}
