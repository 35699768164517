import { boolYesNoOpts } from '@property-folders/common/data-and-text/constants';
import { WrField } from '../CommonComponentWrappers';
import './ContractSpecialConditions.scss';

export const TemplateContractSpecialConditionsPurchaserSale = (): JSX.Element => {

  return <div className="w-100">
    <div className="subsection">

      <div className="w-100 mt-2">
        <WrField.CheckRadio
          label="Will the Vendor consider offers where the Sale is subject to the sale of the Purchaser’s property?"
          radioType={'checkbox'}
          options={boolYesNoOpts}
          valueType='boolean'
          inline={true}
          name='purchaserSalePermitted'
          myPath='contractSpecial.purchaserSalePermitted'
        />
      </div>
    </div>
  </div>;
};
