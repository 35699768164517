import { Badge } from 'react-bootstrap';
import { missingWetPdfWarning } from './MissingWetPdfWarning';

export interface Props {
  form: {
    signed: boolean;
    waitingSign: boolean;
    declined: boolean;
    waitingConfigure: any;
    exists: any;
    wetSignedPdfNotUploaded?: boolean;
    signingProgress?: (number|undefined)[];
    configuringOrder: boolean;
    ordered: boolean;
    cancelled: boolean;
    terminated: boolean;
  }
}

export function FormListBadge({ form }: Props) {
  if (form.terminated) {
    return <Badge bg='white' text='danger'>terminated</Badge>;
  } else if (form.signed) {
    return <Badge
      bg='white'
      title={form.wetSignedPdfNotUploaded ? missingWetPdfWarning : ''}
      text={form.wetSignedPdfNotUploaded ? 'warning' : 'success'}
      className='d-flex align-items-center'
    >signed</Badge>;
  } else if (form.declined) {
    return <Badge bg='white' text='danger'>declined</Badge>;
  } else if (form.cancelled) {
    return <Badge bg='white' text='danger'>cancelled</Badge>;
  } else if (form.waitingSign) {
    return <Badge
      bg='white'
      className='d-flex align-items-center'
      text='secondary'
    >
      signing{form.signingProgress ? ` ${form.signingProgress[0]} of ${form.signingProgress[1]}` : ''}
    </Badge>;
  } else if (form.waitingConfigure || form.configuringOrder) {
    return <Badge bg='white' text='secondary'>configuring</Badge>;
  } else if (form.ordered) {
    return <Badge bg='white' text='secondary'>preparing</Badge>;
  } else if (form.exists) {
    return <Badge bg='white' text='secondary'>draft</Badge>;
  } else {
    return <></>;
  }
}
