import { useCurrentEntity } from './useEntity';
import { useContext } from 'react';
import { YjsDocContext } from '../context/YjsDocContext';
import { useImmerYjs } from './useImmerYjs';
import { MaterialisedPropertyData } from '@property-folders/contract';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';

export function usePrimarySalesperson() {
  const { ydoc } = useContext(YjsDocContext);
  const { bindState: bindMetaState } = useImmerYjs<MaterialisedPropertyData>(ydoc, PropertyRootKey.Data);
  const { data } = bindMetaState<MaterialisedPropertyData>(meta => meta || {});
  const entity = useCurrentEntity();
  const primaryAgent = (data?.agent ?? []).length > 0 ? data?.agent?.[0]?.salesp?.[0] : undefined;

  return primaryAgent
    ? (entity?.salespeople ?? []).find(s => s.id === primaryAgent.linkedSalespersonId)
    : null;
}
