import { Predicate } from '@property-folders/common/predicate';

/** finds tokens to mark a range of text with Bold or Italic. Child must be string
 *
 * Example
 * '*-*This text is bold*-*, this text is normal, /-/this text is italic/-/
 *
 * Bold + italic is not yet tested.
 */
export function FormatBI({ children: str }: {children: string}) {
  if (str.includes('/-/') || str.includes('*-*')) {
    const rval = str.split('/-/').map((s,idx) => idx%2 === 1
      ? <span className='fst-italic'>{s}</span>
      : s.split('*-*').map((s,idx)=>idx%2===1
        ? <span className='fw-bold'>{s}</span>
        : s
      )
    ).flat().filter(Predicate.isTruthy);

    return <>{rval}</>;
  }
  return <>{str}</>;
}