import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import React, { useContext, useMemo, useState } from 'react';
import { PostJobReturnBody } from '@property-folders/contract/rest/property';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { MaterialisedPropertyData, TransactionMetaData } from '@property-folders/contract';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { FormUtil } from '@property-folders/common/util/form';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { useMediaQuery } from 'react-responsive';
import { BP_MINIMA } from '@property-folders/common/data-and-text/bootstrapBreakpoints';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';
import { FormContext } from '@property-folders/components/context/FormContext';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';

const genericError = 'There was a problem returning to preparer. Please try again.';

export function ReturnToPreparerModal({
  onClose
}: {
  onClose: () => void
}) {

  const { ydoc: yDoc, docName: transId, transactionMetaRootKey } = useContext(YjsDocContext);
  const { formId, formName: formCode } = useContext(FormContext);
  const { value: transRoot } = useLightweightTransaction<MaterialisedPropertyData>({ 'myPath': '' });
  const { bindState: metaBindState } = useImmerYjs<TransactionMetaData>(yDoc, transactionMetaRootKey);
  const { data: meta } = metaBindState<TransactionMetaData>(m => m);
  const form  = useMemo(() => {
    return FormUtil.getFormState(formCode, formId, meta);
  }, [meta]);
  const narrowMode = useMediaQuery({ maxWidth: BP_MINIMA.sm });
  const headlineVal = generateHeadlineFromMaterialisedData(transRoot, narrowMode);
  const [show, setShow] = useState(true);
  const [returnMessage, setReturnMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleCancel = () => {
    setShow(false);
    onClose();
  };

  const returnToPreparerHandler = async () => {
    if (!form?.order?.job?.id) return;

    setErrorMessage('');
    const body: PostJobReturnBody = {
      formId,
      formCode,
      message: returnMessage
    };
    try {
      const response = await fetch(LinkBuilder.restApi(`/properties/${transId}/jobs/${form.order.job.id}/returns`), {
        method: 'POST',
        body: JSON.stringify(body)
      });

      if (!response.ok) {
        setErrorMessage(genericError);
        return;
      }

      handleCancel();
    } catch (err: unknown) {
      console.error(err);
      setErrorMessage(genericError);
    }
  };

  const hasJobId = !!form?.order?.job?.id;
  return <Modal show={show} size='lg' onHide={handleCancel}>
    <Modal.Header closeButton>
      <div className='d-flex flex-column'>
        <Modal.Title>Return {FormTypes[formCode].shortLabel || FormTypes[formCode].label} to Eckermann Property Forms for amendments</Modal.Title>
        <span>{headlineVal}</span>
      </div>
    </Modal.Header>
    <Modal.Body>
      {errorMessage && <Alert variant='danger' dismissible onClose={()=>setErrorMessage('')}>{errorMessage}</Alert>}
      <Form.Label
        htmlFor='description_of_changes'
        className='fw-bold'
      >Description of changes required</Form.Label>
      <Form.Control
        as='textarea'
        rows={6}
        name='description_of_changes'
        placeholder='Please describe the changes that are required. Provide as much detail as possible.'
        type='text'
        onChange={e => setReturnMessage(e.target.value)}
      />
    </Modal.Body>
    <Modal.Footer>
      <Button variant='outline-secondary' onClick={onClose}>Cancel</Button>
      <AsyncButton
        disabled={!hasJobId}
        title={hasJobId
          ? 'Return to preparer'
          : 'Could not determine preparer job reference to return'
        }
        onClick={returnToPreparerHandler}
      >Return</AsyncButton>
    </Modal.Footer>
  </Modal>;
}
