import React, { useEffect, useMemo, useState } from 'react';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { MaterialisedPropertyData, TransactionMetaData } from '@property-folders/contract';
import { useMediaQuery } from 'react-responsive';
import { BP_MINIMA } from '@property-folders/common/data-and-text/bootstrapBreakpoints';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import * as Y from 'yjs';
import { ContentTitler } from '@property-folders/components/dragged-components/ContentTitler';
import { FormTypes, PropertyFormYjsDal } from '@property-folders/common/yjs-schema/property/form';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { Spinner } from 'react-bootstrap';

export function ThirdPartyPreparingOrder({
  yDoc,
  formId,
  formCode,
  transId
}: {
  yDoc: Y.Doc,
  transId: string,
  formId: string,
  formCode: string
}) {
  const { value: transRoot, transactionMetaRootKey } = useLightweightTransaction<MaterialisedPropertyData>({ 'myPath': '' });
  const narrowMode = useMediaQuery({ maxWidth: BP_MINIMA.sm });
  const headlineVal = generateHeadlineFromMaterialisedData(transRoot, narrowMode);
  const label = FormTypes[formCode].label;
  const contentTitle = `Order ${label}`;
  const breadcrumbs = useMemo(() => [
    { label: 'Properties', href: '/properties/' },
    {
      label: headlineVal || 'Property Overview',
      href: `/properties/${LinkBuilder.seoFriendlySlug(transId, headlineVal)}`
    },
    { label: contentTitle }
  ], [headlineVal]);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const dal = new PropertyFormYjsDal(yDoc, PropertyRootKey.Data, PropertyRootKey.Meta);

  const {
    bindState: metaBindState
  } = useImmerYjs<TransactionMetaData>(yDoc, transactionMetaRootKey);
  const { data: meta } = metaBindState<TransactionMetaData>(m => m);
  const form = meta
    ? PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, meta)
    : undefined;
  const src =  useMemo(() => form?.order?.job?.id
    ? `/legacy/form1_dashboard.php?page=view&jobId=${form.order.job.id}&searches=${form?.order.info.waitForSearches??false}`
    : undefined, [form?.order?.job?.id]);

  dal.setSearchesOrdered({ formCode, formId });

  useEffect(() => {
    const messageHandler = (e: MessageEvent<string | any>) => {
      if (!e.data) return;
      const { data } = e;

      if (typeof data === 'string' && data === 'epf_iframe_loaded') {
        setIframeLoaded(true);
      }
    };

    window.addEventListener('message', messageHandler);
    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, []);

  return <div className='third-party-preparing-order' style={{ height: 'calc(100vh - 162px)' }}>
    <ContentTitler title={contentTitle} breadcrumbs={breadcrumbs}>
      {src && <iframe src={src} style={{ border: 'none', width: '100%', display: iframeLoaded ? undefined : 'none' }} />}
      {src && !iframeLoaded && <div className='w-100 h-100 d-flex flex-row justify-content-center align-items-center'>
        <Spinner
          animation='border'
          style={{
            width: '6rem',
            height: '6rem',
            marginInline: 'auto'
          }}/>
      </div>}
      {!src && meta && <span>Missing job id</span>}
    </ContentTitler>
  </div>;
}
