import { Card } from 'react-bootstrap';
import { FallbackProps } from '@property-folders/components/telemetry/ErrorBoundary';

export function PropertyCardFallback (props: FallbackProps) {
  return <Card className='property-card shadow'>
    <Card.Body>
      <p className='text-center'>Error loading property summary.</p>
      <p className='text-center'><a href='#' onClick={props.resetErrorBoundary}>Retry?</a></p>
    </Card.Body>
  </Card>;
}

export function GenericCardFallback (props: FallbackProps & {cardTitle: string}) {
  return <Card className='property-card not-property-card shadow'>
    <Card.Body>
      <p className='text-center'>Error loading {props.cardTitle} card.</p>
      <p className='text-center'><a href='#' onClick={props.resetErrorBoundary}>Retry?</a></p>
    </Card.Body>
  </Card>;
}
