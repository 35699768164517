import { useRef, useState } from 'react';
import { EmailPropertyAuditDetail } from '@property-folders/contract/rest/property';

export type Props = Pick<EmailPropertyAuditDetail, 'to' | 'toName' | 'from' | 'subject' | 'body'>;

export function Email({
  to,
  toName,
  body,
  subject,
  from
}: Props) {
  const [iframeHeight, setIframeHeight] = useState<string|number>('100%');
  const [overflow, setOverflow] = useState('hidden');
  const iframeRef = useRef<HTMLIFrameElement>(null);
  return <>
    <div className='bg-light mb-3'>
      <div><strong>To:</strong> {toName ? `${toName} <` : ''}{to}{toName ? '>' : ''}</div>
      <div><strong>From:</strong> {from}</div>
      <div><strong>Subject:</strong> {subject}</div>
    </div>
    <iframe
      ref={iframeRef}
      srcDoc={body}
      style={{
        height: iframeHeight,
        width: '100%',
        overflow // hidden initially to prevent scroll bars if they aren't needed
      }}
      onLoad={() => {
        const frameHeight = iframeRef.current?.contentWindow?.document.body.scrollHeight;
        if (frameHeight) {
          setIframeHeight(frameHeight);
          setOverflow('auto');
        }
      }}
    ></iframe>
  </>;
}
