
import { Icon } from '../Icon';
import './ExternalProposedChanges.scss';
import clsJn from '@property-folders/common/util/classNameJoin';
import { Form } from 'react-bootstrap';
import { HumanizedValueToken } from '@property-folders/contract/rest/property';
import { rawTextToHtmlParagraphs } from '@property-folders/common/util/formatting';
import React, { useEffect, useState } from 'react';
import { ProposedUpdate } from '@property-folders/common/types/ExternalPropertyUpdates';

interface Props {
  id: string;
  updates: ProposedUpdate[]
  setUpdates: (update: ProposedUpdate[]) => void;
}

type AllToggleState = 'none' | 'accept' | 'ignore';

function IdentifyingText({ text }: { text?: string }) {
  return text
    ? <span className={'ps-2 identifying-text'}>{text}</span>
    : <></>;
}

function NotSet() {
  return <del className={'ps-2 w-100 wrap-text audit-value'}><p>{'<Not Set>'}</p></del>;
}

export function Desc({ desc, mode }: { desc?: HumanizedValueToken, mode: 'from' | 'to' }) {
  switch (desc?.type) {
    case 'list':
      return <div className={'d-flex flex-column w-100 audit-list'}>
        <IdentifyingText text={desc.identifyingText} />
        <ul>
          {desc.items.map((item, idx) => (
            <li key={idx}><Desc desc={item} mode={mode} /></li>
          ))}
        </ul>
      </div>;

    case 'group':
      return <div className={'d-flex flex-column w-100 audit-group'}>
        <IdentifyingText text={desc.identifyingText} />
        {desc.items.map((item, idx) => (
          <Desc key={idx} desc={item} mode={mode} />
        ))}
      </div>;

    case 'text':
      return <span className={'p-2 audit-text'}>{desc.text}</span>;

    case 'value':
      return (() => {
        switch (desc.mode) {
          case 'del':
            return <del className={'ps-2 w-100 wrap-text audit-value'}>{rawTextToHtmlParagraphs(desc.text)}</del>;
          case 'ins':
            return <ins className={'ps-2 w-100 wrap-text audit-value'}>{rawTextToHtmlParagraphs(desc.text)}</ins>;
          default:
            return <span className={'ps-2 w-100 wrap-text audit-value'}>{rawTextToHtmlParagraphs(desc.text)}</span>;
        }
      })();

    case 'change':
      if (desc.from.length && desc.to.length) {
        return <div className={'d-flex flex-column w-100 audit-change-from-to'}>
          {mode === 'from' && <div className={'d-flex flex-row w-100 audit-change-from'}>
            {desc.from.map((item, idx) => (
              <Desc key={idx} desc={item} mode={mode} />
            ))}
          </div>}

          {mode === 'to' && <div className={'d-flex flex-row w-100 audit-change-to'}>
            {desc.to.map((item, idx) => (
              <Desc key={idx} desc={item} mode={mode} />
            ))}
          </div>}
        </div>;
      }

      if (mode === 'from') {
        return <div className={'d-flex flex-row audit-change-from'}>
          {desc.from && desc.from.length > 0
            ? desc.from.map((item, idx) => (
              <Desc key={idx} desc={item} mode={mode} />
            ))
            : <NotSet />
          }
        </div>;
      }

      return mode === 'to'
        ? <div className={'d-flex flex-row audit-change-to'}>
          {desc.to.map((item, idx) => (
            <Desc key={idx} desc={item} mode={mode} />
          ))}
        </div>
        : <NotSet />;

    default:
      return <NotSet />;
  }
}

export function ExternalProposedChanges(props: Props) {
  const { updates, id, setUpdates } = props;
  const [allToggleState, setAllToggleState] = useState<AllToggleState>('none');

  useEffect(() => {
    const boolToToggleState = (bool: boolean) => bool ? 'accept' : 'ignore';

    let toggleState: AllToggleState | null = null;
    if (!updates || updates.length === 0) {
      setAllToggleState('none');
      return;
    }

    for (const update of updates) {
      if (toggleState === null) {
        toggleState = boolToToggleState(update.acceptChange);
      } else {
        if (toggleState === boolToToggleState(update.acceptChange)) {
          continue;
        } else {
          toggleState = 'none';
          break;
        }
      }
    }

    setAllToggleState(toggleState ? toggleState : 'none');
  }, [updates]);

  const toggle = (idx: number, value: boolean) => {
    const newUpdates = [...updates];
    newUpdates[idx].acceptChange = value;
    setUpdates(newUpdates);
  };

  const toggleAll = (acceptChanges: boolean) => {
    setUpdates(updates.map(u => ({ ...u, acceptChange: acceptChanges })));
  };

  return <table className='external-proposed-changes w-100'>
    <thead>
      <tr className='d-none d-sm-table-row'>
        <th>Field</th>
        <th>From</th>
        <th></th>
        <th>To</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      {updates.map((u, idx, all) => {
        const surroundClasses = clsJn(
          'surround',
          idx === 0 ? 'surround-first' : '',
          idx === all.length - 1 ? 'surround-last' : ''
        );

        return <tr>
          <td className={surroundClasses}><strong className='d-inline d-sm-none align-top'>Field: </strong>{u.prettyFieldName}</td>
          <td className={surroundClasses}><strong className='d-inline d-sm-none'>From: </strong><Desc desc={u.desc}
            mode='from' /></td>
          <td className='arrow text-center'><Icon name='arrow_right_alt' /></td>
          <td className={surroundClasses}><strong className='d-inline d-sm-none'>To: </strong><Desc desc={u.desc}
            mode='to' /></td>
          <td className={surroundClasses}>
            <strong className='d-inline d-sm-none'>Action: </strong>
            <Form.Check
              type='radio'
              inline
              name={`radio-${id}-${idx}`}
              id={`radio-${id}-${idx}-accept`}
              label='Accept'
              checked={u.acceptChange}
              onChange={() => toggle(idx, true)}
            />

            <Form.Check
              type='radio'
              inline
              name={`radio-${id}-${idx}`}
              id={`radio-${id}-${idx}-ignore`}
              label='Ignore'
              checked={!u.acceptChange}
              onChange={() => toggle(idx, false)}
            />
          </td>
        </tr>;
      })}

      <tr>
        <td className='d-none d-sm-table-cell'></td>
        <td className='d-none d-sm-table-cell'></td>
        <td className='d-none d-sm-table-cell'></td>
        <td className='d-none d-sm-table-cell'></td>
        <td>
          <strong className='d-inline d-sm-none'>Action: </strong>
          <Form.Check
            type='radio'
            inline
            name='radio-accept-all'
            id='radio-accept-all'
            label='Accept all'
            checked={allToggleState === 'accept'}
            onChange={() => toggleAll(true)}
          />

          <Form.Check
            type='radio'
            inline
            name='radio-ignore-all'
            id='radio-ignore-all'
            label='Ignore all'
            checked={allToggleState === 'ignore'}
            onChange={() => toggleAll(false)}
          />
        </td>
      </tr>
    </tbody>
  </table>;
}
