import { useForm } from '@property-folders/components/hooks/useForm';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { TransactionMetaData } from '@property-folders/contract';

export function useFormMeta() {
  const { ydoc, transactionMetaRootKey } = useForm();
  const {
    bindState: metaBindState,
    binder: metaBinder
  } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const { data: meta } = metaBindState<TransactionMetaData>(m => m);

  return meta;
}
