import { useMemo } from 'react';
import PdfWorker from '@property-folders/components/form-gen-util/pdf/pdf-worker?worker';
import { CoverSheet, PdfWorkerDocumentDefinition } from '@property-folders/common/util/pdf/pdf-worker-types';
import { EntityBrandFormConfig } from '@property-folders/contract/yjs-schema/entity-settings';
import { uuidv4 } from 'lib0/random';
import { Annexure } from '@property-folders/contract';
import { SingleBlobFileProvider } from '@property-folders/common/util/SingleBlobFileProvider';
import { FileStorage } from '@property-folders/common/offline/fileStorage';

export type GeneratePdfResponse = ArrayBuffer;
const inflightRequests: {
  [key: string]: {
    resolve: (buffer: ArrayBuffer | Uint8Array) => void;
    reject: (err: any) => void;
    type: 'stitchPdf' | 'generateCoverPage' | 'generateAnnexure' | 'generateDocument'
    purpose?: string;
  }
} = {};

export type PdfWorker = ReturnType<typeof usePdfWorker>;

const isUint8ArrayBuffer = (b: ArrayBuffer | Uint8Array): b is Uint8Array => 'buffer' in b;

export function usePdfWorker() {
  const pdfWorker: Worker = useMemo(
    () => {
      const pf = new PdfWorker();
      pf.addEventListener('message', ev => {
        const { requestId, blob } = ev.data ?? {};
        if (!requestId) {
          console.warn('PDF worker sent invalid message', ev.data);
          return;
        }

        if (!inflightRequests[requestId]) {
          //PDF worker responded to unknown request, probably cancelled request
          return;
        }

        const { resolve, reject } = inflightRequests[requestId];

        if (!blob) {
          reject(new Error('PDF worker responded without blob'));
          return;
        }

        resolve(isUint8ArrayBuffer(blob) ? blob.buffer : blob);
      });

      return pf;
    },
    []
  );

  if (!window.Worker) {
    // supported by all browsers since ie10... I think we'll be okay
    // https://caniuse.com/webworkers
    throw new Error('Web workers unavailable');
  }

  return {
    stitchPdf: (opts: {pdfs: (Uint8Array | ArrayBuffer)[]}) => {
      const requestId = uuidv4();
      const bufferArray = opts.pdfs.map(t => isUint8ArrayBuffer(t) ? t.buffer : t);

      return new Promise<ArrayBuffer>((resolve, reject) => {
        pdfWorker.postMessage(
          {
            type: 'stitchPdf',
            pdfs: bufferArray,
            requestId
          },
          bufferArray
        );

        inflightRequests[requestId] = {
          resolve,
          reject,
          type: 'stitchPdf'
        };
      });
    },

    generateCoverPage: (opts: {
      formConfig: EntityBrandFormConfig,
      headline: string;
      agentName: string;
      documentLabel: string;
    }) => {
      const requestId = uuidv4();

      return new Promise<ArrayBuffer>((resolve, reject) => {
        pdfWorker.postMessage({
          type: 'generateCoverPage',
          requestId,
          ...opts
        });

        inflightRequests[requestId] = {
          resolve,
          reject,
          type: 'generateCoverPage'
        };
      });
    },

    generateAnnexure: async ({ annexure, brand, coversheet }: {
      annexure: Annexure,
      brand: EntityBrandFormConfig,
      coversheet: CoverSheet
    }) => {
      const requestId = uuidv4();
      const file = await FileStorage.read(annexure.id);
      const buffer = await (new SingleBlobFileProvider(file?.data || new Blob()))?.getFile();
      if (typeof buffer === 'undefined') {
        throw new Error('Unable to get buffer from annexure');
      }

      return new Promise<ArrayBuffer>((resolve, reject) => {
        pdfWorker.postMessage({
          buffer,
          annexure,
          brand,
          coversheet,
          type: 'generateAnnexure',
          requestId
        }, [buffer.buffer]);

        inflightRequests[requestId] = {
          resolve,
          reject,
          type: 'generateAnnexure'
        };
      });
    },

    generatePdf: (opts: Exclude<PdfWorkerDocumentDefinition, 'type'>, purpose?: string) => {
      const requestId = uuidv4();

      return new Promise<ArrayBuffer>((resolve, reject) => {
        if (purpose) {
          for (const key of Object.keys(inflightRequests)) {
            if (inflightRequests[key].type !== 'generateDocument') {
              continue;
            }

            if (inflightRequests[key].purpose !== purpose) {
              continue;
            }

            inflightRequests[key].reject(new Error('Aborted'));
            delete inflightRequests[key];
          }
        }

        pdfWorker.postMessage({
          ...opts,
          requestId,
          type: 'generateDocument'
        });
        inflightRequests[requestId] = {
          resolve,
          reject,
          type: 'generateDocument',
          purpose
        };
      });
    }
  };
}
