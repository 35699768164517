import { useMemo } from 'react';
import { WizardStepPage, WizardStepPageProps } from '@property-folders/components/dragged-components/Wizard/WizardStepPage';
import { useForm } from '../../hooks/useForm';
import { useLightweightTransaction, useTransactionField } from '../../hooks/useTransactionField';
import { AuthorityParty, FormCode, ProspectivePurchaserParty } from '@property-folders/contract';
import { useState } from 'react';
import { PurchaserCollection } from '../PurchaserCollection';
import { v4 } from 'uuid';
import { PropertyRootKey, ProspectivePurchaserGroup } from '@property-folders/contract/yjs-schema/property';

const exampleProspectivePurchasers: ProspectivePurchaserParty[][] = [
  [{
    id: v4(),
    address: '8 Orkland St',
    email: 'test@test.com',
    fullLegalName: 'Gendry Jornma',
    phone: '0400000000',
    address_parts: {}
  },
  {
    id: v4(),
    address: '8 Orkland St',
    email: 'test@test.com',
    fullLegalName: 'Mariana Mariachi',
    phone: '0400000000',
    address_parts: {}
  },
  {
    id: v4(),
    address: '8 Orkland St',
    email: 'test@test.com',
    fullLegalName: 'Mariana Mariachi',
    phone: '0400000000',
    address_parts: {}
  },
  {
    id: v4(),
    address: '8 Orkland St',
    email: 'test@test.com',
    fullLegalName: 'Mariana Mariachi',
    phone: '0400000000',
    address_parts: {}
  },
  {
    id: v4(),
    address: '8 Orkland St',
    email: 'test@test.com',
    fullLegalName: 'Mariana Mariachi',
    phone: '0400000000',
    address_parts: {}
  },
  {
    id: v4(),
    address: '8 Orkland St',
    email: 'test@test.com',
    fullLegalName: 'Mariana Mariachi',
    phone: '0400000000',
    address_parts: {}
  }],
  [{
    id: v4(),
    address: '8 Orkland St',
    email: 'test@test.com',
    fullLegalName: 'Arron Jones ',
    phone: '0400000000',
    address_parts: {}
  },
  {
    id: v4(),
    address: '8 Orkland St',
    email: 'test@test.com',
    fullLegalName: 'Barry Scorns',
    phone: '0400000000',
    address_parts: {}
  }],
  [{
    id: v4(),
    address: '8 Orkland St',
    email: 'test@test.com',
    fullLegalName: 'Mariana Mariachi',
    phone: '0400000000',
    address_parts: {}
  }],
  [{
    id: v4(),
    address: '8 Orkland St',
    email: 'test@test.com',
    fullLegalName: 'Mariana Mariachi',
    phone: '0400000000',
    address_parts: {}
  }],
  [{
    id: v4(),
    address: '8 Orkland St',
    email: 'test@test.com',
    fullLegalName: 'Yiorgio Tsketsi',
    phone: '0400000000',
    address_parts: {}
  }],
  [{
    id: v4(),
    address: '8 Orkland St',
    email: 'hobos@test.com',
    fullLegalName: 'Mariana Mariachi',
    phone: '0400000000',
    address_parts: {}
  }],
  [{
    id: v4(),
    address: '8 Orkland St',
    email: 'test@test.com',
    fullLegalName: 'Norbert Zaire',
    phone: '0400000000',
    address_parts: {}
  }],
  [{
    id: v4(),
    address: '8 Orkland St',
    email: 'test@test.com',
    fullLegalName: 'Mariana Mariachi',
    phone: '0400000000',
    address_parts: {}
  }]
];

export function PurchaserWizardStepPage(props: Partial<WizardStepPageProps>) {
  const parentPath = '';
  const myPath = 'purchasers';
  const { readOnly } = useTransactionField<AuthorityParty[]>({ myPath, parentPath });
  const { formFamilyCode } = useForm();
  const [showImportModal, setShowImportModal] = useState(false);

  const { value: prospectivePurchasersT } = useLightweightTransaction<ProspectivePurchaserGroup[]>({
    ydocForceKey: PropertyRootKey.Meta,
    myPath: 'offerManagement.prospectivePurchasers'
  });
  const prospectivePurchasers = useMemo(()=>{

    //return exampleProspectivePurchasers;
    return prospectivePurchasersT?.map(ppp=>ppp.purchaserParties)??[];
  }, [prospectivePurchasersT]);
  const headerActions: {[x:string]: any} = {};
  const importModalEnable = formFamilyCode === FormCode.RSC_ContractOfSale && !readOnly && prospectivePurchasers?.length;
  if (importModalEnable) {
    headerActions.purchaserImport = {
      label: 'Import',
      tooltip: 'Replace Purchasers with an existing Purchaser',
      isPrimary: true,
      onClick: ()=>setShowImportModal(true)
    };
  }

  return <WizardStepPage
    name="purchaser"
    label="Purchaser"
    icon="shopping_cart"
    {...props}
    defaultHeaderActions={headerActions}
  >
    <PurchaserCollection
      prospectivePurchasers={prospectivePurchasers??[]}
      onHideImportModal={()=>setShowImportModal(false)}
      showImportModal={showImportModal}
      parentPath={parentPath}
      myPath={myPath}
      primaryPurchaserIdPath={['primaryPurchaser']}
    />
  </WizardStepPage>;
}
