import { Modal } from 'react-bootstrap';
import { AsyncButton } from '../AsyncButton';

type Props = {
  migrateForm: () => Promise<any>;
  changes: string[];
};

export function MigrateFormModal({ migrateForm, changes }: Props) {
  return <Modal show={true}>
    <Modal.Header><h3>Updated Form</h3></Modal.Header>
    <Modal.Body>
      <h4>Important Notice</h4>
      <p>This form is now updated and has changed, click Migrate to migrate it to the new current version.</p>
      <p>It is important that you check the form as there may be changes to legal terms and may be more data fields.
        Print, check, and understand the form before using it.</p>

      <h4>Changes:</h4>
      {changes.map(c => <div dangerouslySetInnerHTML={{ __html: c }}></div>)}
    </Modal.Body>
    <Modal.Footer>
      <AsyncButton onClick={migrateForm}>Migrate</AsyncButton>
    </Modal.Footer>
  </Modal>;
}
