
export function CardSectionRows(props: {
  title?: string;
  rows: [leftContent: string|JSX.Element, rightContent: string|JSX.Element][] | undefined;
  maxRows?: number;
  className: string;
}) {
  const displayRows = props.maxRows ? props.rows?.slice(0, props.maxRows) : props.rows;
  return <div className={`card-section ${props.className}`}>
    {props.title&&<div className={'fw-bold'}>{props.title}</div>}
    {displayRows?.map(([left, right], index) => <div key={index} className='d-flex'>
      <div className='d-flex align-items-center overflow-hidden'>{left}</div>
      <div className="ms-auto">{right}</div>
    </div>)}
    {props.maxRows && ((props.rows?.length??0) > (displayRows?.length??0)) && (<div className={'mt-1'}>and {(props.rows?.length??0) - (displayRows?.length??0)} more</div>)}
  </div>;
}
