import { CustomFieldDetails, DraggableType } from './types';
import { CustomFieldType, MaterialisedPropertyData } from '@property-folders/contract';
import React, { useEffect, useState } from 'react';
import { CustomFieldMeta, customFieldMetas } from '@property-folders/contract/property/meta';
import { CustomFieldPartyInfo } from './CustomField';
import { getTextFieldText } from '@property-folders/common/util/pdf';

export interface FormattedFieldTextProps {
  details: CustomFieldDetails,
  party?: CustomFieldPartyInfo,
  property?: MaterialisedPropertyData,
  onEdit?: (text: string) => void,
  style: React.CSSProperties,
}

export function FormattedFieldText({
  details,
  party,
  property,
  onEdit,
  style
}: FormattedFieldTextProps) {
  const editable = details.mode === DraggableType.Existing && (details.type === CustomFieldType.text || details.type === CustomFieldType.remoteText);
  const meta = customFieldMetas[details.type];
  const text = getFieldText(details, party, property);
  return editable && onEdit
    ? <EditableFormattedFieldText initialText={text} onEdit={onEdit} meta={meta} style={style} />
    : <ReadOnlyFormattedFieldText text={text} style={style} meta={meta} />;
}

function ReadOnlyFormattedFieldText({
  meta,
  style,
  text
}: {
  meta: CustomFieldMeta,
  style: React.CSSProperties,
  text: string,
}) {
  return <div
    className='overflow-hidden'
    style={style}
  >{text}</div>;
}

function EditableFormattedFieldText({
  initialText,
  meta,
  onEdit,
  style
}: {
  initialText: string,
  meta: CustomFieldMeta,
  onEdit: (text: string) => void,
  style: React.CSSProperties,
}) {
  const [text] = useState(initialText);
  const [newText, setNewText] = useState(text);

  useEffect(() => {
    if (!onEdit) return;
    if (text === newText) return;

    const handle = setTimeout(() => {
      onEdit(newText);
    }, 200);

    return () => {
      clearTimeout(handle);
    };
  }, [text, newText, onEdit]);

  return <div
    className='overflow-hidden'
    style={{
      ...style,
      height: '100%',
      width: '100%'
    }}
    suppressContentEditableWarning={true}
    contentEditable={true}
    onInput={e => {
      setNewText((e.target as HTMLElement).innerText);
    }}
    onKeyDown={e => e.stopPropagation()}
  >{text}</div>;
}

export function getFieldText(details: CustomFieldDetails, party: CustomFieldPartyInfo | undefined, property: MaterialisedPropertyData | undefined): string {
  return getTextFieldText(
    details.mode === DraggableType.Existing
      ? details
      : details.type,
    party?.snapshot,
    property
  ) || customFieldMetas[details.type].title;
}
