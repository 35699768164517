import { gstWithholdOpts } from '@property-folders/common/data-and-text/constants';
import { WrField } from '../../dragged-components/form/CommonComponentWrappers';
import { ShowGuidanceNotesButton } from '../../dragged-components/guidance/ShowGuidanceNotesButton';

export function GstWithholding() {
  return <div className='subsection scrollspy-target' data-focus-path="subsection-gst-with">
    <div className='fs-4 d-flex align-items-center'>GST withholding <ShowGuidanceNotesButton noteId='gst'/></div>
    <div className="w-100 mt-2">
      <WrField.CheckRadio
        label='Is GST withholding at Settlement applicable?'
        radioType='checkbox'
        options={gstWithholdOpts}
        valueType='boolean'
        inline={true}
        name='gstWithholdEnable'
        myPath='gstWithholdEnable'
      />
    </div>
  </div>;
}