import { useId, useRef } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Icon } from './Icon';
import clsJn from '@property-folders/common/util/classNameJoin';
import { Typeahead } from 'react-bootstrap-typeahead';
import './SearchBar.scss';

interface Props {
  onSearch: (term: string) => void;
  autocompleteValues?: string[];
  autoFocus?: boolean;
  className?: string;
  defaultValue?: string
  doClearAfterSelect?: boolean;
  isDropdown?: boolean
  open?: boolean;
  placeholder?: string;
  constrainWidth?: boolean;
}

export function SearchBar({ autoFocus, autocompleteValues, className, defaultValue, doClearAfterSelect, isDropdown, open, placeholder, onSearch, constrainWidth = true }: Props) {
  const typeaheadRef = useRef<Typeahead>(null);

  return autocompleteValues ?
    <Typeahead
      id={useId()}
      autoFocus={autoFocus}
      ref={typeaheadRef}
      placeholder={placeholder ?? 'Search'}
      className={clsJn('embed-icon-left searchbar-constrain', isDropdown && 'searchbar-dropdown')}
      options={autocompleteValues || []}
      onInputChange={(s) => onSearch(s)}
      onChange={(selected) => {
        onSearch(selected?.[0] as string);
        doClearAfterSelect && typeaheadRef.current?.clear();
      }}
      size='lg'
      open={open}
      inputProps={{
        className: 'border-secondary'
      }}
    >
      <div className={'rbt-aux embed-icon'} style={{ left: 7, right: 'unset', display: 'flex' }}>
        <Icon name='search' variant='outlined' icoClass='embed-icon'></Icon>
      </div>
    </Typeahead>
    :
    <InputGroup
      size='lg'
      className={clsJn('embed-icon-wrapper', { 'searchbar-constrain': constrainWidth }, className)}
    >
      <Form.Control
        placeholder={placeholder ?? 'Search'}
        onChange={e => {
          onSearch(e.target.value ?? '');
        }}
        defaultValue={defaultValue}
        className='border border-secondary embed-icon-target'
      ></Form.Control>
      <Icon name='search' variant='outlined' icoClass='embed-icon'></Icon>
    </InputGroup>;
}
