import { useLiveQuery } from 'dexie-react-hooks';
import {
  PropertyCardProps
} from '@property-folders/components/dragged-components/property/PropertyCard';
import { InfinitePropertiesList } from '@property-folders/components/dragged-components/property/InfinitePropertiesList';
import { Predicate } from '@property-folders/common/predicate';
import { ISearchScoredOfflineProperty, OfflineProperties } from '@property-folders/common/offline/offlineProperties';
import { useNoop } from '@property-folders/components/hooks/useNoop';
import { byCreatedDateDesc, byManyAspects, byScoreDesc } from '@property-folders/common/util/sortComparison';

export function OfflinePropertiesList(props: {
  searchTerm: string;
  showStarred: boolean;
  starredProperties: Set<string>;
}) {
  const data = useLiveQuery(() => {
    return OfflineProperties.search(props.searchTerm);
  }, [props.searchTerm]);
  const sortFunc = props.searchTerm
    ? byManyAspects<ISearchScoredOfflineProperty>( [byScoreDesc, byCreatedDateDesc])
    : byCreatedDateDesc;
  const properties = (data || [])
    .filter(p => Predicate.isNotNull(p?.id))
    .filter(p => !p?.meta?.parentId)
    .filter(p => !props.showStarred || props.starredProperties.has(p.id))
    .filter(p => !p.meta?.archived)
    .sort(sortFunc)
    .map(p => PropertyCardProps.fromOfflineData(p, props.starredProperties));

  const noop = useNoop();
  // note: hasNextPage, isFetching, etc. are all false to avoid a flash
  // of loading indicators as the data is retrieved from indexed db
  return <InfinitePropertiesList
    items={properties}
    hasNextPage={false}
    isFetching={false}
    isFetchingNextPage={false}
    fetchNextPage={noop}
    onPropertyArchiveSet={noop}
  ></InfinitePropertiesList>;
}
