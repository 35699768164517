import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export function GenericConfirmDialog({
  onContinue,
  onCancel,
  title,
  message,
  cancelLabel,
  okLabel
}: {
  onContinue: () => void,
  onCancel: () => void,
  title: string,
  message: string | JSX.Element,
  cancelLabel?: string,
  okLabel?: string
}) {
  return <Modal size='lg' show={true} onHide={onCancel}>
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>{message}</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant='outline-secondary' onClick={onCancel}>{cancelLabel||'Cancel'}</Button>
      <Button variant='primary' onClick={onContinue}>{okLabel||'OK'}</Button>
    </Modal.Footer>
  </Modal>;
}
