import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { LinkButton } from '../LinkButton';
import { FallbackProps } from '@property-folders/components/telemetry/ErrorBoundary';

export function WholePageWithLogoApology(props: FallbackProps) {
  return <div className='d-flex align-items-center flex-column'>
    <div style={{ maxWidth: '500px' }} className='mx-2'>
      <div className=''>
        <img id="logo-large" alt="reaforms" src={LinkBuilder.propertiesImages('reaforms_logo_strap.svg')} className="d-inline-block my-4" style={{ width: '150px' }}></img>
      </div>
      <ApologyText {...props} />
    </div>
  </div>;
}
export function WholePageApology(props: FallbackProps & {children?: JSX.Element}) {
  return <div className='d-flex align-items-center flex-column'>
    <div style={{ maxWidth: '500px' }} className='mt-3 px-2 px-sm-4 py-4 bg-white'>
      {props.children ?? <ApologyText {...props} />}
    </div>
  </div>;
}

export function ApologyText(props: FallbackProps) {
  return <div>
    <div className='fs-3'>Something went wrong.</div>
    <div className='mt-2' >
  Our engineers will get this issue resolved as soon as possible.
    </div>
    <div className='mt-2' >

    </div>
    <div className='mt-2'>
      <span>Please try this </span>
      <LinkButton onClick={props.resetErrorBoundary}>reload</LinkButton>
      <span>, or try refreshing your browser.</span>
    </div>
    <div className='mt-2' >
  If this problem still persists, please contact our friendly support team:
    </div>
    <div className='mt-2' ><a href='mailto:support@reaforms.com.au'>support@reaforms.com.au</a></div>
  </div>;
}
