import { NOT_SET_STR } from '@property-folders/common/data-and-text/constants';
import { minimumAdvertisingPrice } from '@property-folders/common/util/defaults';
import { canonicalisers, friendlyMinimalDateFormatter, friendlyMinimalTimeFormatter } from '@property-folders/common/util/formatting';
import { MaterialisedPropertyData, SaleMethod, saleMethodOptions } from '@property-folders/contract';
import { useVariation } from '../../hooks/useVariation';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { buildAllSigningTimelines, getExpiryDescriptorTimeRanges } from '@property-folders/common/util/dataExtract';
import { Predicate } from '@property-folders/common/predicate';

export function SidebarParticulars({ transRoot, saaVariationSnapshot }: {
  transRoot: MaterialisedPropertyData | undefined
  saaVariationSnapshot: ReturnType<typeof useVariation> | undefined
}) {
  const timelines = buildAllSigningTimelines(saaVariationSnapshot?.snapshotHistory);
  const ranges = timelines ? getExpiryDescriptorTimeRanges(timelines).filter(Predicate.isNotNullish) : [];
  let chosenRangeIndex = -1;

  const duration = canonicalisers.days(transRoot?.agency?.duration).canonical;
  if (ranges.length) {
    const now = new Date();
    for (const rangeIstr in ranges) {
      const rangeI = parseInt(rangeIstr);
      const range = ranges[rangeI];
      if (now.getTime() < range.end.getTime()) {
        chosenRangeIndex = rangeI;
        break;
      }
    }
    if (chosenRangeIndex === -1) chosenRangeIndex = ranges.length-1;

    const fileId = ranges[chosenRangeIndex].instFile;
    if (fileId) {
      const snap = saaVariationSnapshot?.snapshotHistory?.data?.[fileId];
      if (snap) transRoot = snap;
    }
  }

  const agreedSaleOpts = transRoot?.sale;
  const rangeDefaults = minimumAdvertisingPrice(agreedSaleOpts);
  const advertStr = canonicalisers.aud(agreedSaleOpts?.advertPrc ?? rangeDefaults.lowerDefault).display;
  const advertUpperStr = canonicalisers.aud(agreedSaleOpts?.advertPrcUpper?? rangeDefaults.upperDefault).display;
  const advertisedString = agreedSaleOpts?.advertRange === 'range'
    ? `${advertStr || NOT_SET_STR} - ${advertUpperStr || NOT_SET_STR}`
    : agreedSaleOpts?.advertRange === 'set'
      ? advertStr || NOT_SET_STR
      : agreedSaleOpts?.advertRange === 'noprice'
        ? 'Advertised without price'
        : NOT_SET_STR;

  const auc = transRoot?.auction;
  const saleMethodString = transRoot?.sale?.saleMethod === 'other'
    ? transRoot?.sale?.saleMethodOther
    : transRoot?.sale?.saleMethod && transRoot?.sale?.saleMethod in saleMethodOptions
      ? transRoot.sale.saleMethod === SaleMethod.Auction
        ? 'Auction'
        + (auc?.onSite == null ? '' : auc?.onSite ? ', on site' : ', not on site')
        + (
          auc?.timeTbc ? ', tbc' : auc?.propDate && auc?.propTime
            ? (`, ${friendlyMinimalTimeFormatter(auc?.propTime)} ${friendlyMinimalDateFormatter(auc?.propDate)}`)
            : auc?.propDate
              ?`, ${friendlyMinimalDateFormatter(auc?.propDate)}`
              :''
        )
        : saleMethodOptions[transRoot?.sale?.saleMethod]
      : transRoot?.sale?.saleMethod;

  let saaExpiry = canonicalisers.days(transRoot?.agency?.duration).display;
  let saaExpiryTitle = 'Agency Duration';

  if (ranges.length && chosenRangeIndex !== -1) {
    (()=>{

      if (!transRoot?.agency) return;

      // To be clear, Expiry date means the first day the agreement is invalid, not the last day it is
      // valid, in contrast to the legislation
      const expiryDate = ranges[chosenRangeIndex].end;
      const startDate = ranges[chosenRangeIndex].start;
      const now = new Date();
      const daysRemaining = Math.ceil((expiryDate.getTime() - now.getTime()) / 86400000);

      saaExpiry = startDate.getTime() > now.getTime()
        ? `Starts ${friendlyMinimalDateFormatter(startDate)} (${duration} days)`
        : daysRemaining === 1
          ? 'Expires Tonight'
          : daysRemaining <= 0
            ? 'Expired!'
            : `${daysRemaining} days remaining`;
      saaExpiryTitle = 'Agency Duration';
      const followingRangeFile = ranges[chosenRangeIndex+1]?.instFile;
      if (followingRangeFile) {
        const followSnap = saaVariationSnapshot?.snapshotHistory?.data[followingRangeFile];
        if (followSnap?.agency?.duration) {
          saaExpiry = `${saaExpiry}, +${followSnap?.agency?.duration} days`;
        }
      }
    })();
  }

  return <Card className="p-3 m-2">
    <div className='fw-bold'>PARTICULARS</div>
    <div className='summary-card-body'>
      <div>
        <div className='fw-bold mb-1'>Agency</div>
        <Container>
          <Row>
            <Col xs={6}>Method of Sale</Col>
            <Col xs={6}>{saleMethodString || NOT_SET_STR}</Col>
          </Row>
          <Row>
            <Col xs={6}>{saaExpiryTitle}</Col>
            <Col xs={6}>{saaExpiry || NOT_SET_STR}</Col>
          </Row>
          <Row>
            <Col xs={6}>Advertised price</Col>
            <Col xs={6}>{advertisedString || NOT_SET_STR}</Col>
          </Row>
        </Container>
      </div>

    </div>
  </Card>;
}