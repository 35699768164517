import { FormInstance, TransactionMetaData } from '@property-folders/contract';
import { UpdateFn } from 'immer-yjs/src/immer-yjs';
import { useEffect } from 'react';
import { formCompleted } from '@property-folders/common/util/form/formCompleted';

export function useUpdateInstanceModifiedOnLoad(instance: FormInstance | undefined, formId: string, familyCode: string, updateMeta: ((fn: UpdateFn<TransactionMetaData>) => void) | undefined) {
  return useEffect(() => {
    if (!(updateMeta && instance && !formCompleted(instance))) {
      console.warn('update conditions not met', !!updateMeta, instance);
      return;
    }
    updateMeta(state => {
      const instance = (state?.formStates?.[familyCode]?.instances || []).filter(i => i.id === formId)[0];
      if (instance) {
        instance.modified = Date.now();
      } else {
        console.warn(`update ${familyCode} ${formId} last modified cannot be done`);
      }
    });
  }, [!!instance, formId, familyCode, updateMeta]);
}
