import { EmptyPropertyCard } from '@property-folders/components/dragged-components/property/EmptyPropertyCard';
import {
  DefaultPropertyCardDimensions,
  IPropertyCardDataProps,
  PropertyCardDimensions,
  MemoPropertyCard, OnPropertyArchiveSetHandler
} from '@property-folders/components/dragged-components/property/PropertyCard';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import './PropertyCard.scss';
import { Predicate } from '@property-folders/common/predicate';
import { useSize } from '@property-folders/components/hooks/useSize';
import { BP_MINIMA } from '@property-folders/common/data-and-text/bootstrapBreakpoints';
import { useMediaQuery } from 'react-responsive';
import clsJn from '@property-folders/common/util/classNameJoin';

export type InfinitePropertiesListProps = {
  hasNextPage: boolean | undefined;
  isFetching: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
  items: IPropertyCardDataProps[];
  onPropertyArchiveSet: OnPropertyArchiveSetHandler;
};

export function InfinitePropertiesList(
  {
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    items,
    onPropertyArchiveSet
  }: InfinitePropertiesListProps
) {
  // This system of force focussing a card exists soley to prevent dropdowns from a card becoming
  // casulties of Stacking Contexts, making z-indicies non-global. fwiw I did also try removing
  // property row focus indicies completely, but (at least when I tried it) it didn't work.
  // See https://philipwalton.com/articles/what-no-one-told-you-about-z-index/
  const [forceFocusId, setForceFocusId] = useState<string|null>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const size = useSize(parentRef);
  const width = size.width;
  const isBigSpace = useMediaQuery({ minWidth: BP_MINIMA.sm });
  const dim = isBigSpace
    ? DefaultPropertyCardDimensions
    : new PropertyCardDimensions({
      width,
      baseWidthIncludesMargins: true
    });
  const isLoading = isFetching || isFetchingNextPage;
  const statusMessage = isLoading
    ? undefined
    : items.length
      ? 'No more matches found'
      : 'No matches found';
  const [{ rows, cols }, setDim] = useState({ rows: 0, cols: 0 });
  const count = hasNextPage
    ? rows + 1
    : rows;
  const rowVirtualizer = useVirtualizer({
    count,
    getScrollElement: () => parentRef.current,
    estimateSize: useCallback(() => {
      return dim.totalHeight;
    }, [dim]),
    // if you set overscan too low, the virtualiser misbehaves after a back nav
    overscan: 2
  });

  useEffect(() => {
    if (!width) {
      return setDim({ rows: 0, cols: 0 });
    }

    // if the space doesn't technically support at least 1 column, force it to 1.
    // we have a small-screen breakpoint in place anyway
    const cols = Math.max(Math.floor(width / dim.totalWidth), 1);
    const rows = Math.ceil(items.length / cols);
    setDim({ cols, rows });
  }, [width, items.length]);

  useEffect(() => {
    const lastItem = rowVirtualizer.getVirtualItems().slice(-1)[0];

    if (!lastItem) {
      return;
    }

    if (
      lastItem.index >= rows - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  }, [
    hasNextPage,
    fetchNextPage,
    rows,
    isFetchingNextPage,
    rowVirtualizer.getVirtualItems()
  ]);

  const onForceFocus = useCallback((propertyId: string, forceMe: boolean) => {
    setForceFocusId(ffi => forceMe
      ? propertyId
      : ffi === propertyId
        ? null
        : ffi);
  }, [onPropertyArchiveSet]);

  return (
    <div ref={parentRef}
      style={{
        overflow: 'auto',
        width: '100%',
        height: '100%'
        // padding: dim.marginPx
      }}
    >
      <div
        className={'d-flex flex-column'}
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative'
          // marginBottom: `${itemGap * rows}px`
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow) => {
          const idxFirstColInRow = virtualRow.index * cols;
          const allInRowData = items.slice(idxFirstColInRow, idxFirstColInRow + cols);
          const rowForceHover = forceFocusId != null ? allInRowData.map(p=>p.id).includes(forceFocusId) : null;
          const allInRow = allInRowData.map((item,idx) => {
            if (item) {
              return <MemoPropertyCard
                key={idx}
                onPropertyArchiveSet={onPropertyArchiveSet}
                onForceFocus={onForceFocus}
                forceFocus={forceFocusId ? forceFocusId === item.id : undefined}
                {...item}
              />;
            }
            if (isLoading) {
              return <EmptyPropertyCard key={idx}></EmptyPropertyCard>;
            }
            return undefined;
          })
            .filter(Predicate.isNotNull);
          // to make scrollbars look ok, the container div above has its height set to the total height of all rows.
          // because not all rows are rendered, the rendered rows aren't "pushed down"
          // into the area that's visible to the user.
          // so, on the rendered rows, some special positioning/translation styling is required
          return (
            <div
              key={virtualRow.index}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                // transform: `translateY(${virtualRow.start + itemGap*virtualRow.index}px)`,
                transform: `translateY(${virtualRow.start}px)`,
                display: 'flex',
                flexDirection: 'row'
                // gap: dim.gapPx
              }}
              className={clsJn({
                'property-card-row': true,
                [`row-index-${virtualRow.index}`]: true,
                'hover-force': rowForceHover === true,
                'hover-force-off': rowForceHover === false
              })}
            >
              {allInRow}
            </div>
          );
        })}
      </div>
      { statusMessage && <div style={{ margin: dim.marginPx }}>{statusMessage}</div> }
    </div>
  );
}
