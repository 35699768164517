import { EditorListChildProps } from '../form/CollectionEditor';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { MarketingTemplateItem, MarketingTemplateItemState, MarketingTemplateSectionType } from '@property-folders/contract/yjs-schema/entity-settings';
import { useTransactionField } from '../../hooks/useTransactionField';
import clsJn from '@property-folders/common/util/classNameJoin';
import { WrField } from '../form/CommonComponentWrappers';
import { CollectionRemoveButton } from '../form/CollectionRemoveButton';
import React, { useEffect } from 'react';
import { Icon } from '../Icon';
import { useYdocBinder } from '../../hooks/useYdocBinder';

export function MarketingLineItem({ type, removable, editMode, parentLocked, ...restProps }: EditorListChildProps & TransactionConsumerProps
  & {
  type: MarketingTemplateSectionType,
  editMode?: boolean,
  parentLocked?: boolean
}) {
  const { value, fullPath, handleRemove } = useTransactionField(restProps);
  const { updateDraft: updateItem } = useYdocBinder<MarketingTemplateItem>({ path: fullPath });
  const { locked, defaultState } = value || {};
  const disabled = !editMode && ((type === MarketingTemplateSectionType.individual && locked) || parentLocked);
  const isPackage = type === MarketingTemplateSectionType.package;

  const defaultStateOptions = [
    { name: MarketingTemplateItemState.unchecked, label: 'Unchecked' },
    { name: MarketingTemplateItemState.checked, label: 'Checked' },
    { name: MarketingTemplateItemState.required, label: 'Required' }
  ];

  useEffect(()=>{
    if (!editMode || !updateItem || !value) return;
    updateItem(draft => {
      if (!draft) return;
      draft.enabled = (defaultState || MarketingTemplateItemState.unchecked) !== MarketingTemplateItemState.unchecked;
    });
  }, [defaultState]);

  const defaultStateCheckbox = <WrField.Select
    name="defaultState"
    optionRender={option => {
      return <div>
        <Icon
          name={option.name === MarketingTemplateItemState.unchecked ? 'check_box_outline_blank' : 'check_box'}
          variant='outlined'
          icoClass={clsJn('me-1 fs-4 mb-0 default-checkbox', option.name === MarketingTemplateItemState.required && 'required-checkbox')}>
        </Icon>
        {option.label}
      </div>;
    }}
    inputRender={(inputProps) => {
      return <Icon
        name={[MarketingTemplateItemState.checked, MarketingTemplateItemState.required].includes(defaultState) ? 'check_box' : 'check_box_outline_blank'}
        variant='outlined'
        onClick={(e)=>{
          inputProps.onClick?.(e);
        }}
        icoClass={clsJn('me-0 fs-4 cursor-pointer default-checkbox', defaultState === MarketingTemplateItemState.required && 'required-checkbox')}
        title={'Item default'}>
      </Icon>;
    }}
    options={defaultStateOptions}
    parentPath={fullPath}
    myPath='defaultState'
    canClear={false}
    tight={true}
  />;

  return (
    <div className={clsJn('w-100 align-items-start d-flex marketing-row', !isPackage && locked && 'locked-row', editMode && 'edit-mode')}>
      <div className={clsJn('flex-grow-1 marketing-row-inner', isPackage && 'package')}>
        <div className="mx-0 gapped-row my-1 d-flex me-2" style={{ columnGap: '0.5rem', rowGap: '0.2rem' }}>
          <div className={'d-flex gap-2 ps-0 pt-1 flex-grow-0'}>
            {!isPackage && editMode && defaultStateCheckbox}
            {!isPackage && !editMode && <WrField.BoolCheck disabled={defaultState === MarketingTemplateItemState.required} name="enabled" parentPath={fullPath} myPath="enabled" inline={false}/>}
          </div>

          <div className={'d-flex align-items-center gap-2 ps-0 flex-grow-1 flex-wrap'}>
            <WrField.Control disabled={disabled} parentPath={fullPath} myPath={'description'} name={'description'} containerClassName={clsJn('flex-grow-1', isPackage && 'ms-4')} minWidth={'180px'}/>
            {!isPackage && <div className={'ps-0'} style={{ flexBasis: '100px' }}>
              <WrField.Control disabled={disabled} parentPath={fullPath} myPath={'price'} name={'price'} minWidth={'90px'}/>
            </div>}
          </div>
        </div>
      </div>

      <div className='delete-div d-flex align-items-center' title={'Lock item content'} style={{ height: '38px' }}>
        {!isPackage && editMode && <WrField.BoolCheck
          disabled={disabled}
          containerClass={'d-flex align-items-center me-1'}
          iconUnchecked={'lock_open'}
          iconChecked={'lock'}
          name='locked'
          parentPath={fullPath}
          myPath='locked'
        />}
        <CollectionRemoveButton removable={removable && (editMode || (!disabled && defaultState !== MarketingTemplateItemState.required))} className={'remove-button-children'} onRemove={handleRemove} />
      </div>
    </div>
  );
}
