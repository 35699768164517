import { EditorInfo, SubscriptionFormCode } from '@property-folders/common/subscription-forms';
import * as Y from 'yjs';
import React, { useCallback, useState } from 'react';
import useDetectSticky from '../../../hooks/useDetectSticky';
import { materialisePropertyData, materialisePropertyMetadata } from '@property-folders/common/yjs-schema/property';
import { Button, Modal } from 'react-bootstrap';
import { VendorWizardStepPage } from '../../../dragged-components/Wizard/VendorWizardStepPage';
import { PurchaserWizardStepPage } from '../../../dragged-components/Wizard/PurchaserWizardStepPage';
import { ContractSettlementWizardStepPage } from '../../../dragged-components/Wizard/ContractSettlementWizardStepPage';
import { PropertyWizardStepPage } from '../../../dragged-components/Wizard/PropertyWizardStepPage';
import { AgentWizardStepPage } from '../../../dragged-components/Wizard/AgentWizardStepPage';
import { clsJn as classNames } from '@property-folders/common/util/classNameJoin';
import { applyChangesetToFrame } from './EditSubscriptionForm';

export function EditingModalContext({
  editInfo,
  onComplete,
  frameWindow,
  ydoc,
  offsetStyle,
  formCode
}: {
  editInfo: EditorInfo
  onComplete: (changedNames: string[]) => void,
  frameWindow: Window | undefined | null,
  ydoc: Y.Doc,
  offsetStyle: React.CSSProperties,
  formCode: SubscriptionFormCode
}) {

  const [visible, setVisible] = useState(!!frameWindow);
  const [footerIsStuck, footerRef] = useDetectSticky(undefined, undefined, true);
  if (!frameWindow) {
    onComplete([]);
  }

  const applyChanges = useCallback(() => {
    const changes = ydoc
      ? editInfo.getChanges(materialisePropertyData(ydoc), materialisePropertyMetadata(ydoc))
      : [];

    const changedNames = applyChangesetToFrame(changes, frameWindow, formCode);

    onComplete(changedNames ?? []);
    setVisible(false);
  }, [frameWindow, ydoc]);

  if (!editInfo || editInfo.editor === 'none') {
    return <></>;
  }

  return <Modal show={visible} size={'lg'} style={offsetStyle} onHide={applyChanges}>
    <Modal.Body className="p-0">{(() => {
      switch (editInfo.editor) {
        case 'vendor':
          return <VendorWizardStepPage embedded={true}/>;
        case 'purchaser':
          return <PurchaserWizardStepPage embedded={true}/>;
        case 'contract_settlement':
          return <ContractSettlementWizardStepPage embedded={true}/>;
        case 'property':
          return <PropertyWizardStepPage embedded={true}/>;
        case 'agent':
          return <AgentWizardStepPage embedded={true}/>;
        case 'agent_or_representative':
          return <AgentWizardStepPage embedded={true} preferEditAuthRep={true}/>;
        default:
          return <></>;
      }
    })()}</Modal.Body>
    <Modal.Footer
      ref={footerRef}
      className={classNames({
        'stuck': footerIsStuck,
        'bg-white': true,
        'sticky-footer-shadow': footerIsStuck
      })}
      style={{
        position: 'sticky',
        bottom: '-1px',
        zIndex: '5'
      }}
    >
      <Button onClick={applyChanges}>Ok</Button>
    </Modal.Footer>
  </Modal>;
}
