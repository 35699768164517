import { useNavigate } from 'react-router-dom';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { MouseEvent } from 'react';
import { useLightweightTransaction } from '../hooks/useTransactionField';
import { OfferMeta } from '@property-folders/contract';
import { PropertyRootKey, PurchaserProcessAction } from '@property-folders/contract/yjs-schema/property';
import { CardSectionRows } from './CardSectionRows';
import { sortBy } from 'lodash';

export const PurchaserManagementCardBody = ({ propertyId, headline }: {
  propertyId: string,
  headline: string
}) => {
  const { value: offerManagement } = useLightweightTransaction<OfferMeta>({ ydocForceKey: PropertyRootKey.Meta, myPath: 'offerManagement' });
  const navigate = useNavigate();
  const doNavigate = (e: MouseEvent<HTMLDivElement>, url: string) => {
    navigate(url);
    e.stopPropagation();
    e.preventDefault();
  };

  const purchasers = sortBy(
    offerManagement?.prospectivePurchasers
      ?.filter(p => p.meta?.registration.find(r => r.action === PurchaserProcessAction.registered || r.action === PurchaserProcessAction.invited)),
    r=>r.purchaserParties?.[0]?.fullLegalName
  );

  const rows = purchasers?.map(o => {
    const ppName = o.purchaserParties?.find(p => p.id === o.primaryPurchaser)?.fullLegalName || '';
    return [
      <div onClick={(e) => doNavigate(e,`${LinkBuilder.prospectivePurchaserPath({ id: propertyId, nicetext: headline }, { id: o.id, name: ppName })}`)} className={'font-underline'}>
        {ppName}
      </div>,''
    ];
  });

  return (
    <div className='instance-body pseudoform-body d-flex flex-column' style={{ flex: '1 1 auto' }}>
      <CardSectionRows rows={rows} maxRows={14} />
    </div>
  );
};
