import { Button, Modal, Table } from 'react-bootstrap';
import { useRef, ChangeEvent, useState } from 'react';
import { PurchaserPortalSharedDocument } from '@property-folders/contract';
import { AsyncButton, SpinnerButton } from '../../AsyncButton';
import { mapDocumentTypeToFriendlyName } from '@property-folders/common/util/form/purchaser-portal/mapDocumentTypeToFriendlyName';

interface Props {
  visible: boolean;
  onClose: () => void;
  documents: (Partial<PurchaserPortalSharedDocument> & Pick<PurchaserPortalSharedDocument, 'documentType'>)[];
  onUpload: (documentType: PurchaserPortalSharedDocument['documentType'], file: File) => Promise<boolean>;
  onDelete: (documentId: string) => Promise<boolean>;
}

export function UploadDocumentModal(props: Props) {
  const uploadInput = useRef<HTMLInputElement | null>(null);
  const onUploadClick = () => uploadInput?.current?.click();
  const [isUploading, setIsUploading] = useState(false);

  const onUpload = async (documentType: PurchaserPortalSharedDocument['documentType'], changeEvent: ChangeEvent<HTMLInputElement> | undefined) => {
    setIsUploading(true);
    if (changeEvent == null || !changeEvent.target.files || changeEvent.target.files.length === 0) {
      setIsUploading(false);
      return;
    }

    await props.onUpload(documentType, changeEvent.target.files[0]);
    setIsUploading(false);
  };

  return <Modal show={props.visible} onHide={props.onClose} dialogClassName='upload-document-modal' size='lg'>
    <Modal.Header closeButton className=''>
      <h3>Documents</h3>
    </Modal.Header>

    <Modal.Body>
      <Table hover>
        <thead>
          <tr>
            <th>Name</th>
            <th style={{ width: '140px' }}>Action</th>
          </tr>
        </thead>

        <tbody>
          {props.documents.map(document => (
            <tr key={document.documentType}>
              <input ref={uploadInput} className='d-none' type='file' accept='.pdf' onChange={ev => {
                onUpload(document.documentType, ev);
              }} />
              <td className='align-middle'>{mapDocumentTypeToFriendlyName(document.documentType)}</td>
              <td style={{ width: '150px' }} className='align-middle'>
                {document.url
                  ? <>
                    <Button
                      variant='outline-secondary'
                      onClick={() => window.open(document.url, '_blank')}
                      className='me-1'
                    >View</Button>
                    <AsyncButton
                      variant='outline-secondary'
                      onClick={async () => props.onDelete(document.documentId as string)}
                      inline={true}
                    >Delete</AsyncButton>
                  </>
                  : <SpinnerButton
                    variant='primary'
                    onClick={onUploadClick}
                    disabled={isUploading}
                    processing={isUploading}
                  >Upload</SpinnerButton>
                }
              </td>
            </tr>)
          )}
        </tbody>
      </Table>
    </Modal.Body>
  </Modal>;
}
