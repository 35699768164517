import { SigningApi } from '@property-folders/common/client-api/signing';
import { mergePaths, normalisePathToStr } from '@property-folders/common/util/pathHandling';
import { AgentSessionInfoResult, FormCodeUnion, FormInstance, FormSigningState, MaterialisedPropertyData, SignedStates, SigningParty, TransactionMetaData } from '@property-folders/contract';
import { PathType } from '@property-folders/contract/yjs-schema/model';
import { WrField } from '../../dragged-components/form/CommonComponentWrappers';
import { Alert, Button, FloatingLabel, Form, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap';
import { Predicate } from '@property-folders/common/predicate';
import { canonicalisers, getTimeString } from '@property-folders/common/util/formatting';
import { Icon } from '../../dragged-components/Icon';
import { useContext, useMemo, useState } from 'react';
import { getEmailEventText } from '../../dragged-components/signing/PartySessionCard';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { YjsDocContext } from '../../context/YjsDocContext';
import { applyMigrationsV2_1 } from '@property-folders/common/yjs-schema';
import { FormTypes, PropertyFormYjsDal } from '@property-folders/common/yjs-schema/property/form';
import { ShowGuidanceNotesButton } from '../../dragged-components/guidance/ShowGuidanceNotesButton';
import { getProxyWithOriginalName } from '@property-folders/common/util/dataExtract';
import { FileStorage } from '@property-folders/common/offline/fileStorage';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { usePdfWorker } from '../../hooks/usePdfWorker';

function someContactAvailable (party?: SigningParty) {
  const proxyMode = Predicate.proxyNotSelf(party?.proxyAuthority);
  return !!(proxyMode ? (party?.proxyEmail || party?.proxyPhone) : (party?.snapshot?.phone || party?.snapshot?.email));
}

function delayActive (party: SigningParty|null|undefined) {
  return party?.distributionState?.type === 'delayed' && someContactAvailable(party);
}

function markedManually(party: SigningParty|null|undefined) {
  return party?.distributionState?.type === 'manual';
}

function awaitingConfirmation(party: SigningParty|null|undefined) {
  return party?.distributionState?.type === 'manualWaiting';
}

function downloadObjectUrl(objectUrl: string, fileName: string) {
  const linkElement = document.createElement('a');
  linkElement.href = objectUrl;
  linkElement.setAttribute('download', fileName);
  document.body.appendChild(linkElement);
  linkElement.click();
  linkElement.parentNode?.removeChild(linkElement);
}

function partyStatusLine(party: SigningParty, sendingNow?: boolean) {
  if (['sms', 'email', 'manual'].includes(party.distributionState?.type)) {
    return <div className='d-flex align-items-center fw-bold'>
      <Icon name='check_circle' icoClass='me-1' style={{ color: 'green' }} />
    Distributed
    </div>;
  }

  if (!someContactAvailable(party) && awaitingConfirmation(party)) {
    return <div className='d-flex align-items-center fw-bold'>
      <Icon name='schedule' icoClass='me-1' />
      Confirmation Required
    </div>;
  }

  if (!someContactAvailable(party)) {
    return <div className='d-flex align-items-center fw-bold'>
      <Icon name='warning' icoClass='me-1' style={{ color: 'orange' }} />
      Distribution Required
    </div>;
  }
  if (sendingNow && delayActive(party)) {
    return <div className='d-flex align-items-center fw-bold'>
      <Icon name='pending' icoClass='me-1' />
    Pending
    </div>;
  }
  if (delayActive(party)) {
    return <div className='d-flex align-items-center fw-bold'>
      <Icon name='schedule' icoClass='me-1' />
      Distribution Delayed
    </div>;
  }

}

function partyEmailDelivery(party: SigningParty, sessionInfo: AgentSessionInfoResult | undefined) {
  if (party.distributionState?.type !== 'email') return;
  return getEmailEventText(
    party.distributionState.lastDistributionEmailEvent,
    getTimeString(party.distributionState.lastDistributionEmailEventTimestamp,sessionInfo?.timeZone, 'at'),
    'distrib'
  );
}

function markPartyDistributed(
  ydoc: Y.document,
  metaRootKey: string,
  formCode: FormCodeUnion,
  formId: string,
  partyId: string,
  stateTransition: ({
  date: string,
  newState: 'manual'
  }|{newState: 'manualWaiting'})

) {
  const { newState } = stateTransition;

  applyMigrationsV2_1({
    doc: ydoc,
    docKey: metaRootKey,
    typeName: 'Property',
    migrations: [{
      name: 'Mark party as manually distributed',
      fn: (draft: TransactionMetaData)=>{
        const instance = PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, draft);
        const party = instance?.signing?.parties?.find(p=>p.id === partyId);
        if (!party) return false;
        if (!party.distributionState) {
          party.distributionState = { type: newState, declaredDate: date };
          return;
        }
        if (newState === 'manualWaiting' && party.distributionState.type === 'delayed') {
          party.distributionState.type = newState;
          return;
        }
        if (newState === 'manual' && party.distributionState.type === 'manualWaiting') {
          party.distributionState.type = newState;
          party.distributionState.declaredDate = stateTransition.date;
          return;
        }
        return false;
      }
    }]
  });
}

function PartyStatusPanel({ party, sessionInfo, sendingNow }: {
  party: SigningParty,
  sendingNow?: boolean | SigningParty
  sessionInfo: AgentSessionInfoResult | undefined
}) {
  const status = useMemo(()=>partyStatusLine(party, (sendingNow && typeof sendingNow === 'object') ? sendingNow.id === party.id : (sendingNow??false)), [
    party.distributionState?.type,
    sendingNow
  ]);

  const emailStatus = partyEmailDelivery(party, sessionInfo);
  const dType = party.distributionState?.type;
  const proxyMode = Predicate.proxyNotSelf(party.proxyAuthority);
  const method = dType === 'email'
    ? 'Method: Email Link'
    : dType === 'sms'
      ? 'Method: SMS Link'
      : dType === 'manual'
        ? 'Method: Marked by agent'
        : null;
  const delayMethod = !delayActive(party)
    ? null
    : (proxyMode
      ? (party.proxyEmail
        ? 'Method: Email Link'
        : 'Method: SMS Link'
      )
      : party.snapshot?.email
        ? 'Method: Email Link'
        : 'Method: SMS Link'
    );

  return <div className='flex-grow-1 mb-2' style={{ width: '250px' }}>
    {status}
    {!someContactAvailable(party) && !markedManually(party) && !awaitingConfirmation(party) && <div>No email address provided</div>}
    {!someContactAvailable(party) && awaitingConfirmation(party) && <div>Awaiting manual distribution by Agent</div>}
    {method && <div>{method}</div>}
    {delayMethod && <div key='Delay method'>{delayMethod}</div>}
    {delayActive(party) && <div>Delayed until 11pm</div>}
    {emailStatus && <div>{emailStatus}</div>}
  </div>;
}

function PartyRow({ party, sessionInfo, sendingNow, onMarkPartyManuallyDistributed, onResendToParty, onSendToParty, onPartyToDownload, downloadAvailable, onRedownload }: {
  party: SigningParty,
  sessionInfo: AgentSessionInfoResult | undefined,
  sendingNow?: boolean | SigningParty;
  onMarkPartyManuallyDistributed: (party: SigningParty) => void,
  onPartyToDownload: (party: SigningParty) => void
  onRedownload: () => void
  onResendToParty: (party: SigningParty) => void
  onSendToParty: (party: SigningParty) => void,
  downloadAvailable: boolean
}) {
  const signingParty = party;
  const proxyMode = Predicate.proxyNotSelf(signingParty?.proxyAuthority);
  const signerName = proxyMode ? signingParty.proxyName : signingParty?.snapshot?.name;
  const onBehalfText = proxyMode ? `On behalf of ${signingParty?.snapshot?.name}` : '';
  const signerEmail = proxyMode ? signingParty.proxyEmail : signingParty?.snapshot?.email;
  const signerPhone = proxyMode ? signingParty.proxyPhone : signingParty?.snapshot?.phone;

  return <div className='d-flex mt-3'>
    <div className='flex-shrink-1 flex-grow-1'>
      <div className='d-flex flex-wrap' >
        <div className='flex-grow-1 mb-2' style={{ width: '250px' }}>
          <div className='fw-bold'>{signerName}</div>
          {onBehalfText && <div className=''>{onBehalfText}</div>}
          <div className='text-truncate'>{signerEmail}</div>
          <div>{canonicalisers.phone(signerPhone||'').display}</div>
        </div>
        <PartyStatusPanel party={signingParty} sessionInfo={sessionInfo} sendingNow={sendingNow} />

      </div>
    </div>

    <div className='flex-shrink-0 flex-grow-0 mb-2 signing-party-button-stack' style={{ width: '110px' }}>
      {downloadAvailable && ((!someContactAvailable(party) && !markedManually(party) && !awaitingConfirmation(party))) && <Button variant='outline-secondary' onClick={()=>onPartyToDownload(party)}>Download</Button>}
      {downloadAvailable && ((!someContactAvailable(party) && markedManually(party))) && <Button variant='outline-secondary' onClick={()=>onRedownload()}>Re-download</Button>}
      {((!someContactAvailable(party) && awaitingConfirmation(party))) && <Button variant='outline-secondary' onClick={()=>onMarkPartyManuallyDistributed(party)}>Confirm</Button>}
      {(someContactAvailable(party) && delayActive(party)) && <Button variant='outline-secondary' onClick={()=>onSendToParty(party)}>Send</Button>}
      {(['email', 'sms'].includes(party.distributionState?.type)) && <Button variant='outline-secondary' onClick={()=>onResendToParty(party)}>Resend</Button>}
    </div>
  </div>;
}

export function SigningSessionDistribution(props: {
  instance: FormInstance
  instancePath: PathType
  parties: SigningParty[]
  propertyData?: MaterialisedPropertyData
}) {
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const { ydoc, transactionMetaRootKey, docName } = useContext(YjsDocContext);
  const [markingParty, setMarkingParty] = useState<null|SigningParty>(null);
  const [downloadingParty, _setDownloadingParty] = useState<null|SigningParty>(null);
  const [downloadError, setDownloadError] = useState<string>('');
  const setDownloadingParty = (party: null|SigningParty) => {
    if (!party) setDownloadError('');
    _setDownloadingParty(party);
  };

  const [markDate, setMarkDate] = useState<string>('');
  const [resendingParty, setResendingParty] = useState<null|SigningParty>(null);
  const [sendingParty, setSendingParty] = useState<null|SigningParty>(null);
  const [showSendNow, setShowSendNow] = useState<boolean>(false);
  // Faking the sending status a bit. If the user refreshes the page, the delayed status will return
  const [sendingNow, setSendingNow] = useState<boolean|SigningParty>(false);

  const [sendError, setSendError] = useState<boolean>(false);
  const { instance, instancePath, parties } = props;

  function handleMarkPartyManuallyDistributed(partyId: string|undefined, date: string|undefined) {
    if (!(partyId && date && canonicalisers.date(date??'').valid)) return;
    markPartyDistributed(
      ydoc,
      transactionMetaRootKey,
      instance.formCode,
      instance.id,
      partyId,
      {
        date,
        newState: 'manual'
      }
    );
  }
  function promptToMark(party: SigningParty) {
    setMarkingParty(party);
  }
  function handleResend(partyId: string|undefined) {
    const signingSessionId = instance.signing?.session?.id;
    if (!(docName && signingSessionId && partyId)) return;
    SigningApi.resendCompletedLink(docName, signingSessionId, partyId);
  }

  const downloadAvailable = !Array.isArray(instance.signing?.session?.completedFile) || instance.signing?.session?.completedFile.length > 0;

  const pdfWorker = usePdfWorker();
  async function composeCompletedFilesAndDownload() {
    if (!instance.signing?.session?.completedFile) return false;
    const completedFiles = Array.isArray(instance.signing?.session?.completedFile) ? instance.signing?.session?.completedFile : [instance.signing?.session?.completedFile];
    let pdfData;
    if (completedFiles.length > 1) {
      const allPdfBytes = (await Promise.all(completedFiles.map(async fileRef => {
        const file = await FileStorage.read(fileRef.id);

        if (!file?.data) return null;
        return await file.data.arrayBuffer();
      }))).filter(Predicate.isNotNullish);

      pdfData = new Blob([await pdfWorker.stitchPdf({ pdfs: allPdfBytes })]);
    } else {
      const file = await FileStorage.read(completedFiles[0].id);
      if (!file?.data) return false;
      pdfData = file.data;
    }
    if (!pdfData) return false;
    const url = URL.createObjectURL(pdfData);
    const headline = generateHeadlineFromMaterialisedData(props.propertyData);
    downloadObjectUrl(url, `Completed ${FormTypes[instance.formCode].label}${headline ? ' for '+headline : ''}.pdf`);
    return true;
  }

  return <>
    {!SignedStates.has(instance?.signing?.state) && <div className='d-flex flex-row'><WrField.BoolCheck
      name='delayFullAutoDistribute'
      label={'Automatically distribute the fully signed agreement to the parties once completed'}
      parentPath={normalisePathToStr(mergePaths(instancePath, 'signing'))}
      myPath='delayFullAutoDistribute'
      bindToMetaKey={true}
      inverter={true}
      nullishIsFalse={true}
    />
    <span style={{ marginLeft: '-0.5rem' }}><ShowGuidanceNotesButton noteId={'distributionDelay'} /></span>
    </div>}

    {SignedStates.has(instance?.signing?.state) && instance?.signing?.state !== FormSigningState.Signed && <div>
      Signing is currently executing
    </div>}

    {instance.signing.session?.delayAcknowledged && !instance.signing.session?.delayFulfilled
    && (typeof sendingNow !== 'boolean' || !sendingNow) && instance?.signing?.state === FormSigningState.Signed
    && instance.signing?.parties?.some(party=>party.distributionState?.type === 'delayed' && someContactAvailable(party))
    && (<Alert variant={sendError ? 'warning' : 'info'}>
      {!sendError && <>
        <p>Distribution of this document to the parties has been delayed.</p>
        <p>If not distributed beforehand, the document will be automatically shared with the parties at 11pm (Adelaide time) today.</p>
      </>}
      {sendError && <>
        <p>There was an error in sending the distribution now. Please try again. If it still does not work, please contact support@reaforms.com.au</p>

      </>}
      <div className='d-flex w-100'>
        <Button className='ms-auto' onClick={()=>setShowSendNow(true)}>Send Now</Button>
      </div>
    </Alert>)}

    {instance?.signing?.state === FormSigningState.Signed && parties.map(p=>{
      return <PartyRow
        party={p}
        sessionInfo={sessionInfo}
        key={p.id}
        sendingNow={sendingNow}
        downloadAvailable={downloadAvailable}
        onMarkPartyManuallyDistributed={promptToMark}
        onPartyToDownload={(party: SigningParty) => setDownloadingParty(party)}
        onResendToParty={(party: SigningParty) => setResendingParty(party)}
        onSendToParty={(party: SigningParty) => setSendingParty(party)}
        onRedownload={composeCompletedFilesAndDownload}
      />;
    })}

    <Modal show={!!markingParty} onHide={()=>setMarkingParty(null)}>
      <ModalHeader closeButton><ModalTitle>Confirm {getProxyWithOriginalName(markingParty)} as distributed</ModalTitle></ModalHeader>
      <ModalBody>
        <p>
            Please enter the date the fully signed document was distributed to {getProxyWithOriginalName(markingParty)}.
        </p>
        <div className='d-flex'>
          <FloatingLabel label="Date of distribution" style={{ width: '220px' }}>
            <Form.Control type='date' onChange={event=>setMarkDate(event.target.value)}/>
          </FloatingLabel>
        </div>
      </ModalBody>
      <ModalFooter  className='d-flex'>
        <Button variant='outline-secondary' onClick={()=>setMarkingParty(null)}>Cancel</Button>
        <Button
          variant='primary'
          disabled={!canonicalisers.date(markDate).valid}
          onClick={()=>{
            handleMarkPartyManuallyDistributed(markingParty?.id, markDate);
            setMarkingParty(null);
          }}>Confirm</Button>
      </ModalFooter>
    </Modal>

    <Modal show={!!downloadingParty} onHide={()=>setDownloadingParty(null)}>
      <ModalHeader closeButton><ModalTitle>Download for distribution</ModalTitle></ModalHeader>
      <ModalBody>
        <p>
            You are downloading the completed agreement to manually give to {getProxyWithOriginalName(downloadingParty)}. After you
            have given the completed agreement to {getProxyWithOriginalName(downloadingParty)}, you can Confirm their receipt of the
            agreement.
        </p>
      </ModalBody>
      <ModalFooter  className='d-flex'>
        <Button variant='outline-secondary' onClick={()=>setDownloadingParty(null)}>Cancel</Button>
        <Button
          variant='primary'
          onClick={()=>{
            if (!downloadingParty) return;
            composeCompletedFilesAndDownload().then(success=>{
              if (!success) {
                setDownloadError('Error preparing file for download');
                return;
              }
              markPartyDistributed(
                ydoc,
                transactionMetaRootKey,
                instance.formCode,
                instance.id,
                downloadingParty.id,
                { newState: 'manualWaiting' }
              );
              setDownloadError('');
              setDownloadingParty(null);
            });

          }}>Download</Button>
      </ModalFooter>
    </Modal>

    <Modal show={!!resendingParty} onHide={()=>setResendingParty(null)}>
      <ModalHeader closeButton><ModalTitle>Resend completed document</ModalTitle></ModalHeader>
      <ModalBody>
        <p>
            Do you wish to send the {FormTypes[instance.formCode].label} to {resendingParty?.snapshot?.name} again?
        </p>
      </ModalBody>
      <ModalFooter  className='d-flex'>
        <Button variant='outline-secondary' onClick={()=>setResendingParty(null)}>Cancel</Button>
        <Button variant='primary' onClick={()=>{handleResend(resendingParty?.id);setResendingParty(null);}}>Resend</Button>
      </ModalFooter>
    </Modal>

    <Modal show={showSendNow} onHide={()=>setShowSendNow(false)}>
      <ModalHeader closeButton><ModalTitle>Send all delayed documents</ModalTitle></ModalHeader>
      <ModalBody>
        <p>
        Do you wish to send the {FormTypes[instance.formCode].label} to the parties now?
        </p>
      </ModalBody>
      <ModalFooter  className='d-flex'>
        <Button variant='outline-secondary' onClick={()=>setShowSendNow(false)}>Cancel</Button>
        <Button variant='primary' onClick={()=>{
          setSendError(false);
          SigningApi.sendDelayedDistributions(props.propertyData?.id, instance.signing?.session?.id).catch(()=>{
            setSendingNow(false);
            setSendError(true);
          });
          setSendingNow(true);
          setShowSendNow(false);
        }}>Send</Button>
      </ModalFooter>
    </Modal>

    <Modal show={!!sendingParty} onHide={()=>setSendingParty(null)}>
      <ModalHeader closeButton><ModalTitle>Send delayed document to party</ModalTitle></ModalHeader>
      <ModalBody>
        <p>
        Do you wish to send the {FormTypes[instance.formCode].label} to {sendingParty && getProxyWithOriginalName(sendingParty)} now?
        </p>
      </ModalBody>
      <ModalFooter  className='d-flex'>
        <Button variant='outline-secondary' onClick={()=>setSendingParty(null)}>Cancel</Button>
        <Button variant='primary' onClick={()=>{
          setSendError(false);
          SigningApi.sendDelayedDistributionToParty(props.propertyData?.id, instance.signing?.session?.id, sendingParty?.id).catch(()=>{
            setSendingNow(false);
            setSendError(true);
          });
          setSendingNow(sendingParty??false);
          setSendingParty(null);
        }}>Send</Button>
      </ModalFooter>
    </Modal>
  </>;
}