import { ProgressBar } from 'react-bootstrap';
import React from 'react';

type ProgressBarVariants = 'success' | 'info' | 'warning' | 'danger' | undefined;

export function SigningProgressBar ({ text, completion, variant, title }:{text: string, completion: number, variant: ProgressBarVariants, title?: string}) {
  return <div className='position-relative mb-2' title={title}>
    <div className='w-100'>
      <ProgressBar variant={variant} style={{ height: '1.5rem' }} now={Math.round(100*completion)} />
    </div>
    <div className='position-absolute d-flex align-items-center justify-content-center' style={{ top: 0, bottom: 0, left: 0, right: 0 }}>
      <div>
        {text}
      </div>
    </div>
  </div>;
}
