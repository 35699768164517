import { Predicate } from '@property-folders/common/predicate';
import {
  FeeCalcMechanism,
  FeeEnables,
  FixedFeePayableWhen,
  ProfessionalFeeType,
  UpliftType
} from '@property-folders/contract';
import { useLightweightTransaction } from '../../hooks/useTransactionField';
import {
  fixedFeePayableOpts,
  professionalCommissionFeeModeOpts,
  professionalFixedFeeModeOpts,
  quadStateUpliftEnableOpts
} from '@property-folders/common/data-and-text/constants';
import { WrField } from './CommonComponentWrappers';
import './ManualFeesSection.scss';
import { SlidingScale } from '../../display/form/SlidingScale';
import './ProfessionalFeeSection.scss';
import { SetHeaderActionsFn } from '../Wizard/WizardStepPage';
import { useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { PathString } from '@property-folders/contract/yjs-schema/model';
import { mergePathsAsStr } from '@property-folders/common/util/pathHandling';
import { QuadStateDualBoolCheckboxes } from '../../display/form/QuadStateRadioCheckboxes';
import { quadStateEnableOrStrike, quadStateEnableOrStrikeRules } from '@property-folders/common/util/quadStateFilter';

export type ProfessionalFeeSubsectionProps = {
  setHeaderActions?: SetHeaderActionsFn
};

const modeEnabledTypes = [FeeCalcMechanism.Set, FeeCalcMechanism.Range];

const RenderProfessionalFeeCustomiseMenu = (props: {upliftPath: PathString, feesPath: PathString}) => {
  const { feesPath, upliftPath } = props;
  const { value: feeEnables } = useLightweightTransaction<ProfessionalFeeType['enabledModes']|undefined>({ parentPath: feesPath, myPath: 'enabledModes' });
  const forceDefaults = feeEnables == null;
  const feeVisiblePath= mergePathsAsStr(feesPath, 'enabledModes.fixedFee');
  const commisVisiblePath = mergePathsAsStr(feesPath, 'enabledModes.commis');

  const notNonePaths = Object.entries(feeEnables??{}).filter(([key,val]) => val.none ? null : key).filter(Predicate.isTruthy).map(([key]) => key);
  const remainingNotNone = notNonePaths.length === 1 && feeEnables[notNonePaths[0]];
  const onlyOneSelectionRemains = remainingNotNone && Object.values(remainingNotNone).filter(Predicate.isTruthy).length === 1;

  return <Dropdown autoClose="outside" className='professional-fee-custom-menu'>
    <Dropdown.Toggle variant='light'>
      Display
    </Dropdown.Toggle>

    <Dropdown.Menu>
      <Dropdown.Header>Commission</Dropdown.Header>
      <div className="checkbox-container">
        <WrField.BoolCheck
          name={'c-set'}
          label={'Set'}
          parentPath={commisVisiblePath}
          myPath='set'
          overrideChecked={forceDefaults?true:undefined}
          disabled={onlyOneSelectionRemains && feeEnables?.commis?.set}
        />
        <WrField.BoolCheck
          name={'c-range'}
          label={'Range'}
          parentPath={commisVisiblePath}
          myPath='range'
          overrideChecked={forceDefaults?true:undefined}
          disabled={onlyOneSelectionRemains && feeEnables?.commis?.range}
        />
        <WrField.BoolCheck
          name={'c-discount'}
          label={'Discounted'}
          parentPath={commisVisiblePath}
          myPath='discount'
          overrideChecked={forceDefaults?false:undefined}
          disabled={onlyOneSelectionRemains && feeEnables?.commis?.discount}
        />
        <WrField.BoolCheck
          name={'c-scale'}
          label={'Scale'}
          parentPath={commisVisiblePath}
          myPath='scale'
          overrideChecked={forceDefaults?false:undefined}
          disabled={onlyOneSelectionRemains && feeEnables?.commis?.scale}
        />
        <WrField.BoolCheck
          name={'c-none'}
          label={'None'}
          parentPath={commisVisiblePath}
          myPath='none'
          overrideChecked={forceDefaults?false:undefined}
          disabled={feeEnables?.fixedFee?.none && !feeEnables?.commis?.none}
        />
      </div>
      <Dropdown.Header>Fixed Fee</Dropdown.Header>
      <div className="checkbox-container">
        <WrField.BoolCheck
          name={'f-set'}
          label={'Set'}
          parentPath={feeVisiblePath}
          myPath='set'
          overrideChecked={forceDefaults?true:undefined}
          disabled={onlyOneSelectionRemains && feeEnables?.fixedFee?.set}
        />
        <WrField.BoolCheck
          name={'f-range'}
          label={'Range'}
          parentPath={feeVisiblePath}
          myPath='range'
          overrideChecked={forceDefaults?true:undefined}
          disabled={onlyOneSelectionRemains && feeEnables?.fixedFee?.range}
        />
        <WrField.BoolCheck
          name={'f-discount'}
          label={'Discounted'}
          parentPath={feeVisiblePath}
          myPath='discount'
          overrideChecked={forceDefaults?false:undefined}
          disabled={onlyOneSelectionRemains && feeEnables?.fixedFee?.discount}
        />
        <WrField.BoolCheck
          name={'f-scale'}
          label={'Scale'}
          parentPath={feeVisiblePath}
          myPath='scale'
          overrideChecked={forceDefaults?false:undefined}
          disabled={onlyOneSelectionRemains && feeEnables?.fixedFee?.scale}
        />
        <WrField.BoolCheck
          name={'f-none'}
          label={'None'}
          parentPath={feeVisiblePath}
          myPath='none'
          overrideChecked={forceDefaults?false:undefined}
          disabled={feeEnables?.commis?.none && !feeEnables?.fixedFee?.none}
        />
      </div>
      <Dropdown.Header>Uplift</Dropdown.Header>
      <div className="checkbox-container">
        <WrField.BoolCheck name='showUplift' parentPath={upliftPath} myPath='displaySuppressed' inverter={true} label='Commission' nullishIsFalse={true}></WrField.BoolCheck>
      </div>
    </Dropdown.Menu>
  </Dropdown>
  ;
};

function filterProvidedOptions(enables: FeeEnables, options: {[key in FeeCalcMechanism]?: any}) {

  if (enables && Object.values(enables).every(e=>!e)) return options; // Show everything if they've turned everything including none off
  if (enables?.none) return {};

  let enabled = enables;
  if (!enables) enabled = { set: true, range: true };

  const filtered = Object.entries(options??{}).filter(([keyStr, _value]) => {
    const key = parseInt(keyStr);
    switch (key) {
      case FeeCalcMechanism.None: return false;
      case FeeCalcMechanism.Set: return enabled.set;
      case FeeCalcMechanism.Range: return enabled.range;
      case FeeCalcMechanism.Discounted: return enabled.discount;
      case FeeCalcMechanism.Scale: return enabled.scale;
      default: return false;
    }
  });
  return Object.assign({}, ...filtered.map(([k,v]) => ({ [k]: v })));
}

function ProfessionalFeeSubSection () {
  const { value: profess, fullPath } = useLightweightTransaction<ProfessionalFeeType>({ myPath: 'professFee' });

  const aFixedFeeModeIsSelected = !(!profess?.fixedMode || [FeeCalcMechanism.None, FeeCalcMechanism.Error].includes(profess.fixedMode));
  const commisItemsFiltered = filterProvidedOptions(profess?.enabledModes?.commis, professionalCommissionFeeModeOpts);
  const fixedItemFiltered = filterProvidedOptions(profess?.enabledModes?.fixedFee, professionalFixedFeeModeOpts);

  const fixedFeeSlidingScale = profess?.fixedMode === FeeCalcMechanism.Scale;

  return <div className='subsection scrollspy-target professional-fee-section' data-focus-path="subsection-profess-fee">
    <blockquote className={'ms-2 me-2'}>All professional fee dollar amounts, and percentages included below, include GST. Any sale prices included in any sliding scale or commission uplifts, are based on a sale price including GST, if applicable.</blockquote>
    <div className="w-100 d-flex flex-wrap">
      {!profess?.enabledModes?.commis?.none && <div className="mt-2 pseudo-col">
        <WrField.CheckRadio
          label="Commission"
          titleGuidanceNoteKey="calculationProfessionalFee"
          radioType="checkbox"
          options={commisItemsFiltered}
          valueType="int"
          inline={false}
          parentPath={fullPath}
          name="commissionMode"
          myPath="commissionMode"
        />
        {[FeeCalcMechanism.Range, FeeCalcMechanism.Set, FeeCalcMechanism.Discounted].includes(profess?.commissionMode)
          && <div className="d-flex flex-wrap mt-2 ms-2">
            <WrField.Control
              className="flex-grow-1"
              label={profess?.commissionMode === FeeCalcMechanism.Range ? 'Minimum %' : profess?.commissionMode === FeeCalcMechanism.Discounted ? 'Normal %' : 'Commission %'}
              parentPath={fullPath}
              name="commis"
              myPath="commis"
              style={{ width: '140px' }}
              hasAsterisks
            />
            {profess?.commissionMode === FeeCalcMechanism.Range &&
              <WrField.Control
                className="flex-grow-1"
                label={'Maximum %'}
                parentPath={fullPath}
                name="commisUpper"
                myPath="commisUpper"
                style={{ width: '140px' }}
                hasAsterisks
              />}
            {/* As tempting as it is to roll up this into the existing 2 variables, we want set to
            be able to be selected, and then discounted, and set becomes the "Normal" rate, and.
            putting a discount in a variable called "upper" would be a bit weird. Already tried
            making discount the normal one, but that just looks more sus then, because you then need
            to re-fill out the normal rate with the one that just became the discount. If you're
            editing in front of the client, you want the theatre of taking what's usually an
            "iron-clad" single rate and showing "it's just for you". Definitely easier and neater
            to do this with a new field, than trying to do a group function and adding complexity
            plus potential render weirdness
            */}
            {profess?.commissionMode === FeeCalcMechanism.Discounted &&
              <WrField.Control
                className="flex-grow-1"
                label={'Discounted %'}
                parentPath={fullPath}
                name="commisDiscount"
                myPath="commisDiscount"
                style={{ width: '140px' }}
                hasAsterisks
              />}
          </div>
        }
        {profess?.commissionMode === FeeCalcMechanism.Scale && <div>
          <div className="mt-2 ms-2">
            <SlidingScale
              parentPath={fullPath}
              myPath="commisScale"
              amountValuePlaceholder="%"
            />
          </div>
        </div>}
      </div>}
      {!profess?.enabledModes?.fixedFee?.none && <div className="mt-2 pseudo-col">
        <WrField.CheckRadio
          label="Fixed Fee"
          titleGuidanceNoteKey="calculationProfessionalFee"
          radioType="checkbox"
          options={fixedItemFiltered}

          valueType="int"
          inline={false}
          parentPath={fullPath}
          name="fixedMode"
          myPath="fixedMode"
        />
        {[FeeCalcMechanism.Range, FeeCalcMechanism.Set, FeeCalcMechanism.Discounted].includes(profess?.fixedMode)
          && <div className="d-flex flex-wrap mt-2 ms-2">
            <WrField.Control
              className="flex-grow-1"
              label={profess?.fixedMode === FeeCalcMechanism.Range ? 'Minimum Fee' : profess?.fixedMode === FeeCalcMechanism.Discounted ? 'Normal Fee' : 'Fixed Fee Amount'}
              parentPath={fullPath}
              name="fixed"
              myPath="fixed"
              style={{ width: '140px' }}
              hasAsterisks
            />
            {profess?.fixedMode === FeeCalcMechanism.Range &&
              <WrField.Control
                className="flex-grow-1"
                label={'Maximum Fee'}
                parentPath={fullPath}
                name="fixedUpper"
                myPath="fixedUpper"
                style={{ width: '140px' }}
                hasAsterisks
              />}
            {/* Use of extra field as above */}
            {profess?.fixedMode === FeeCalcMechanism.Discounted &&
              <WrField.Control
                className="flex-grow-1"
                label={'Discounted Fee'}
                parentPath={fullPath}
                name="fixedDiscount"
                myPath="fixedDiscount"
                style={{ width: '140px' }}
                hasAsterisks
              />}
          </div>
        }
        {profess?.fixedMode === FeeCalcMechanism.Scale && <div>
          <div className="mt-2 ms-2">
            <SlidingScale
              parentPath={fullPath}
              myPath="fixedScale"
              amountValuePlaceholder="$"
            />
          </div>
        </div>}
        {aFixedFeeModeIsSelected && <div className="mt-2" style={{ flex: '0 1 300px' }}>
          <WrField.CheckRadio
            label="When is Fixed Fee payable?"
            radioType="checkbox"
            options={fixedFeePayableOpts}
            valueType="int"
            inline={false}
            parentPath={fullPath}
            name="fixedPayableWhen"
            myPath="fixedPayableWhen"
            disabled={fixedFeeSlidingScale}
            overrideValue={fixedFeeSlidingScale ? FixedFeePayableWhen.SaleEffected.toString() : null}
          />
        </div>}

      </div>}
      <UpliftSection/>
    </div>

  </div>;

}

const upliftFieldStyles = { flex: '1 1 240px' };

function UpliftSection() {
  const { value: uplift, fullPath } = useLightweightTransaction<UpliftType>({ myPath: 'uplift' });
  const { value: professionalFee } = useLightweightTransaction<ProfessionalFeeType>({ myPath: 'professFee' });
  const mode = modeEnabledTypes.includes(professionalFee?.commissionMode) && modeEnabledTypes.includes(professionalFee?.fixedMode);
  const { enable, displaySuppressed = false } = uplift??{};

  return <>
    {!displaySuppressed && Predicate.isNotNullish(mode)
      ? <div className='subsection scrollspy-target pseudo-col uplift-section' data-focus-path="subsection-uplift">
        <div className='lead'>Commission Uplift</div>
        <div className="w-100">

          <div className={'ms-2 flex-grow-0'}>
            <QuadStateDualBoolCheckboxes
              parentPath={'uplift'}
              yesNoBoolRelPath={'strike'}
              unknownBoolRelPath={'enable'}
              options={quadStateUpliftEnableOpts}
              label=''
              setUnknownRules={quadStateEnableOrStrikeRules}
              valueFilter={quadStateEnableOrStrike}
            />

          </div>

        </div>
        {enable && <div className='d-flex flex-wrap mt-2 ms-2'>
          <div className="flex-grow-1 mt-2" style={upliftFieldStyles}>
            <WrField.Control
              key="thresh"
              label="Sale price threshold for uplift"
              parentPath={fullPath}
              myPath="thresh"
              name="thresh"
            />
          </div>
          <div className="flex-grow-1 mt-2" style={upliftFieldStyles}>
            <WrField.Control
              key="portion"
              label="Percentage of amount over threshold"
              parentPath={fullPath}
              myPath="portion"
              name="portion"
            />
          </div>
        </div>}
      </div>
      : <></>}
  </>;

}

export function ProfessionalFeeSection(props: ProfessionalFeeSubsectionProps) {

  useEffect(() => {
    props.setHeaderActions?.(() => {

      return {
        actionsRenderMode: 'customRender',
        childRenderer: RenderProfessionalFeeCustomiseMenu,
        childProps: { upliftPath: 'uplift', feesPath: 'professFee' }
      };

    });
  }, []);

  return <>
    <ProfessionalFeeSubSection/>

  </>;
}
