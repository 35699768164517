import { useState } from 'react';
import clsJn from '@property-folders/common/util/classNameJoin';
import './CopyToClipboard.scss';
import { Icon } from './Icon';

export class ClipboardMock extends EventTarget {
  writeText = () => Promise.resolve();
  readText = () => Promise.resolve('');
  write = () => Promise.resolve();
  read = () => Promise.resolve( []);
}
const canVibrate = ('vibrate' in navigator);

export function CopyToClipboard({ text, copyText, copyCallback, className }: {text: string, copyText?: string, copyCallback?: ()=>void, className?: string }) {
  const clipboard: Clipboard = 'clipboard' in navigator
    ? navigator.clipboard as Clipboard
    : new ClipboardMock();
  const [hasClicked, setHasClicked] = useState(false);

  const handleClick = () => {
    copyCallback ? copyCallback() : clipboard.writeText(copyText||'');
    setHasClicked(true);
    canVibrate && navigator.vibrate(200);
    setTimeout(()=> setHasClicked(false), 2000);
  };

  return <div className={clsJn('copy-to-clipboard position-relative cursor-pointer font-underline', className)} onClick={handleClick}>
    <Icon name={'check_circle'} icoClass={clsJn(!hasClicked && 'fade')} style={{ color: '#090', left: '-26px', position: 'absolute', fontSize: '22px' }}/>
    <span>{text}</span>
  </div>;
}
