import React from 'react';

export function RemoteCheckbox({
  label,
  labelStyle,
  onEdit,
  onAttrChange,
  checked
}: {
  label?: string,
  labelStyle?: React.CSSProperties,
  checkStyle: React.CSSProperties,
  onEdit?: (text: string) => void,
  onAttrChange?: (name: string, raw: any) => void,
  checked?: boolean
}) {
  return <div className='d-flex flex-row w-100 h-100'>
    <div className='h-100 position-relative' style={{ aspectRatio: 1 }}>
      <input
        type='checkbox'
        className='w-100 h-100 position-absolute'
        style={{ top: 0, left: 0, cursor: 'move' }}
        checked={Boolean(checked)}
      />
      {/*bug in firefox: it won't recognise the check input as draggable / emit drag events whatever you try.*/}
      {/*so the fix is to overlay it with an invisible div that can receive and bubble the drag events up to the dnd library*/}
      <div
        className='w-100 h-100'
        style={{ position: 'absolute', top: 0, left: 0 }}
        onClick={onAttrChange
          ? e => onAttrChange('on', !checked)
          : undefined}
      />
    </div>
    {label && <span style={{ ...labelStyle, marginLeft: '0.2em', maxWidth: '1px' }} className='flex-grow-1 flex-shrink-1'>{label}</span>}
  </div>;
}

export function RemoteRadio({
  label,
  labelStyle,
  onEdit,
  onAttrChange,
  checked
}: {
  label?: string,
  labelStyle: React.CSSProperties,
  radioStyle: React.CSSProperties,
  onEdit?: (text: string) => void,
  onAttrChange?: (name: string, raw: any) => void,
  checked?: boolean
}) {
  return <div className='d-flex flex-row w-100 h-100'>
    <div className='h-100 position-relative' style={{ aspectRatio: 1 }}>
      <input
        type='radio'
        className='w-100 h-100'
        style={{ cursor: 'move', position: 'absolute', top: 0, left: 0 }}
        checked={Boolean(checked)}
      />
      {/*bug in firefox: it won't recognise the radio input as draggable / emit drag events whatever you try.*/}
      {/*so the fix is to overlay it with an invisible div that can receive and bubble the drag events up to the dnd library*/}
      <div
        className='w-100 h-100'
        style={{ position: 'absolute', top: 0, left: 0 }}
        onClick={onAttrChange
          ? e => onAttrChange('on', !checked)
          : undefined}
      />
    </div>
    {label && <span style={{ ...labelStyle, marginLeft: '0.2em', maxWidth: '1px' }} className='flex-grow-1 flex-shrink-1'>{label}</span>}
  </div>;
}

function EditableLabel({ label, onEdit, style }: {
  label: string,
  onEdit: (text: string) => void,
  style?: React.CSSProperties
}) {
  return <div
    className='h-100 flex-grow-1'
  >
    <div
      style={{
        ...style,
        height: '100%',
        overflow: 'visible',
        position: label.trim() ? undefined : 'absolute'
      }}
      onKeyDown={e => {
        e.stopPropagation();
      }}>
      <input
        type='text'
        placeholder='Label'
        className='p-0 m-0 border-0 w-100'
        style={{ minWidth: '4em', outline: '1px solid black' }}
        value={label}
        onChange={e => {
          onEdit(e.currentTarget.value);
        }}
      />
    </div>
  </div>;
}
