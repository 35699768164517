import React, { useContext, useEffect, useState } from 'react';
import { canonicalisers } from '@property-folders/common/util/formatting';
import { PropertyFormYjsDal } from '@property-folders/common/yjs-schema/property/form';
import { Button, Col, Container, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';

type InputChangeEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

export function UpdateServeViaLinkPurchaserModal({
  formCode,
  formId,
  recipientId,
  onClose
}: {
  formCode: string,
  formId: string,
  recipientId: string,
  onClose: () => void
}) {
  const { ydoc, transactionRootKey, transactionMetaRootKey } = useContext(YjsDocContext);
  const [show, setShow] = useState(true);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [emailValid, setEmailValid] = useState(false);

  const handleEmailChange = (e: InputChangeEvent) => {
    const value = e.target.value;
    setEmail(value);
    if (value) {
      setEmailValid(!!canonicalisers.email(value).valid);
    } else {
      setEmailValid(false);
    }
  };

  const handleCancel = () => {
    setShow(false);
    onClose();
  };

  const handleApply = () => {
    try {
      if (!ydoc) return;

      new PropertyFormYjsDal(ydoc, transactionRootKey, transactionMetaRootKey)
        .updateServeRecipientEmail(formCode, formId, recipientId, email);
    } finally {
      setShow(false);
      onClose();
    }
  };

  useEffect(() => {
    if (!ydoc) return;

    const recipient = new PropertyFormYjsDal(ydoc, transactionRootKey, transactionMetaRootKey).getServeRecipient(formCode, formId, recipientId);
    if (!recipient) return;

    if (!recipient.email) {
      handleCancel();
      return;
    }
    setName(recipient.name);
    setAddress(recipient.address);
    setEmail(recipient.email);
    setEmailValid(!!canonicalisers.email(recipient.email).valid);
    setLoaded(true);
  }, [!!ydoc, formCode, formId, recipientId]);

  if (!loaded) {
    return <></>;
  }

  return <Modal show={show} onHide={handleCancel}>
    <Modal.Header closeButton><Modal.Title>Serve to Purchaser</Modal.Title></Modal.Header>
    <Modal.Body>
      <Container>
        <Row>
          <Col>
            <FloatingLabel label="Name">
              <Form.Control
                disabled={true}
                type="text"
                value={name}
                readOnly
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col>
            <FloatingLabel label="Postal Address">
              <Form.Control
                disabled={true}
                type="text"
                value={address}
                readOnly
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col>
            <FloatingLabel label="Email">
              <Form.Control
                placeholder=""
                type="text"
                autoComplete="email"
                onChange={handleEmailChange}
                isInvalid={!!email && !emailValid}
                defaultValue={email}
              />
              <Form.Control.Feedback type={'invalid'}>Must be valid email address.</Form.Control.Feedback>
            </FloatingLabel>
          </Col>
        </Row>
      </Container>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="outline-secondary" onClick={handleCancel}>Cancel</Button>
      <Button disabled={!emailValid} onClick={handleApply}>Apply</Button>
    </Modal.Footer>
  </Modal>;
}
