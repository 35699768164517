import * as Y from 'yjs';
import React, { useCallback, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { WizardStepPage } from '../../../dragged-components/Wizard/WizardStepPage';
import { AnnexuresSection } from '../../../dragged-components/form/AnnexuresSection';

export function AnnexureModal({
  onComplete,
  formCode,
  formId,
  yDoc,
  offsetStyle,
  transactionMetaRootKey
}: {
  onComplete: () => void,
  formCode: string,
  formId: string,
  yDoc: Y.Doc,
  offsetStyle: React.CSSProperties
  transactionMetaRootKey: string
}) {
  const [visible, setVisible] = useState(true);
  const close = useCallback(() => {
    setVisible(false);
    onComplete();
  }, []);

  return <Modal show={visible} size={'lg'} style={offsetStyle} onHide={close}>
    <Modal.Body className="p-0">
      <WizardStepPage
        name="annexures"
        label="Annexures"
        icon="attach_file"
        embedded={true}
      >
        <AnnexuresSection
          formCode={formCode}
          formId={formId}
          ydoc={yDoc}
          transactionMetaRootKey={transactionMetaRootKey}
        />
      </WizardStepPage>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={close}>Ok</Button>
    </Modal.Footer>
  </Modal>;
}
