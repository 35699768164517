import { useRef, useState, useEffect } from 'react';

// https://github.com/yairEO/react-hooks/blob/master/hooks/useDetectSticky.js
/**
 * detects when a (CSS) sticky element changes "sticky" state
 * @param {object} ref optional react ref. if not provided, a new one will be used instead.
 * @param {object} observerSettings Observer's settings object
 * @param {boolean} bottom set true if observing sticky state at the bottom of the screen
 * @return {[boolean, RefObject, function]}
 */
const useDetectSticky = (ref = undefined, observerSettings = { threshold: [1] }, bottom = false) => {
  const [isSticky, setIsSticky] = useState(false);
  const newRef = useRef();
  ref ||= newRef;

  useEffect(()=>{
    if (!ref.current) return;

    const cachedRef = ref.current,
      observer = new IntersectionObserver(
        ([e]) => {
          if (bottom) {
            setIsSticky(e.intersectionRatio < 1 && e.boundingClientRect.bottom !== e.intersectionRect.bottom);
          } else {
            setIsSticky(e.intersectionRatio < 1 && e.boundingClientRect.top !== e.intersectionRect.top);
          }
        },
        observerSettings
      );

    observer.observe(cachedRef);

    // unmount
    return () => {
      observer.unobserve(cachedRef);
    };
  }, [ref.current]);

  return [isSticky, ref, setIsSticky];
};

export default useDetectSticky;
