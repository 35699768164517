import { boolYesNoOpts } from '@property-folders/common/data-and-text/constants';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { Predicate } from '@property-folders/common/predicate';
import { WrField } from '@property-folders/components/dragged-components/form/CommonComponentWrappers';
import { ContractPriceType, gstFreeReasonOpts, MaterialisedPropertyData } from '@property-folders/contract';

export const ContractGst = (props: {templateContractMode?: boolean} & TransactionConsumerProps): JSX.Element => {
  const { value: property } = useLightweightTransaction<MaterialisedPropertyData>({ myPath: '' });
  const { value: contractPrice, fullPath } = useLightweightTransaction<ContractPriceType>(props);

  return (
    <div className="w-100">
      <div className='subsection scrollspy-target' data-focus-path="subsection-is-taxed">
        <div className='fs-4'>GST Conditions of sale</div>
        <div className='d-flex w-100 mt-2'>
          <WrField.CheckRadio
            label='Is the Vendor registered, or required to be registered, for GST?'
            radioType={props.templateContractMode ? 'radio' : 'checkbox'}
            options={boolYesNoOpts}
            valueType='boolean'
            inline={true}
            name='vendorGst'
            myPath='vendorGst'
          />
        </div>
        {Predicate.boolFalse(property?.vendorGst) && <div className='d-flex w-100 mt-2'>GST does not apply.</div>}
        {property?.vendorGst && <>
          <div className='d-flex w-100 mt-2'>
            <WrField.CheckRadio
              label={'Is the sale the supply of an existing \'residential premises\', which is input taxed?'}
              titleGuidanceNoteKey='inputTaxedPremises'
              radioType='checkbox'
              options={boolYesNoOpts}
              valueType='boolean'
              inline={true}
              parentPath={fullPath}
              name='inputTaxed'
              myPath='inputTaxed'
            />
          </div>
          {contractPrice?.inputTaxed && <div className='d-flex w-100 mt-2'>GST does not apply.</div>}
          {Predicate.boolFalse(contractPrice?.inputTaxed) && <>
            <div className='d-flex w-100 mt-2'>
              <WrField.CheckRadio
                label={'Is the sale a taxable supply?'}
                radioType='checkbox'
                options={boolYesNoOpts}
                valueType='boolean'
                inline={true}
                parentPath={fullPath}
                name='taxableSupply'
                myPath='taxableSupply'
              />
            </div>
            {contractPrice?.taxableSupply && <>
              <div className='d-flex w-100 mt-2'>
                <WrField.CheckRadio
                  label='Is the margin scheme to be applied?'
                  radioType='checkbox'
                  options={boolYesNoOpts}
                  valueType='boolean'
                  inline={true}
                  parentPath={fullPath}
                  name='marginApplied'
                  myPath='marginApplied'
                />
              </div>
              {Predicate.boolFalse(contractPrice?.marginApplied) && <div className='d-flex w-100 mt-2'>
                GST payable will be shown as a separate amount in the contract
              </div>}
              {contractPrice?.marginApplied && <>
                <div className='d-flex w-100 mt-2'>
                  <WrField.CheckRadio
                    label='Is GST included in the Purchase Price?'
                    radioType='checkbox'
                    options={boolYesNoOpts}
                    valueType='boolean'
                    inline={true}
                    parentPath={fullPath}
                    name='gstIncluded'
                    myPath='gstIncluded'
                  />
                </div>
                {Predicate.boolFalse(contractPrice?.gstIncluded) && <div className='d-flex w-100 mt-2'>
                  GST payable will be shown as a separate amount in the contract
                </div>}
              </>}
            </>}
            {Predicate.boolFalse(contractPrice?.taxableSupply) && <div className='d-flex w-100 mt-2'>
              <WrField.CheckRadio
                label='The sale is GST-free as it is:'
                radioType='checkbox'
                options={gstFreeReasonOpts}
                name='gstFreeReason'
                myPath='gstFreeReason'
                parentPath={fullPath}
                inline={false}
              />
            </div>}
          </>}
        </>}

        <div className='d-flex w-100 mt-2'>
          The Agent is not qualified to provide advice, and the Vendor and Purchaser must obtain
          their own independent professional advice, in relation to the appropriate GST treatment.
        </div>
      </div>
    </div>
  );
};
