import * as Y from 'yjs';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';

/**
 * detect changes in any of the specified keys
 * @param doc the ydoc
 * @param rootKeys the ydoc's root properties to bind to
 * @param pathFilters list of regex to decide when a change event is relevant
 * @param debug true = print some info to console
 */
export function useYDocObserve(doc: Y.Doc, rootKeys: string[], pathFilters?: RegExp[], debug?: boolean) {
  const [buster, setBuster] = useState('');

  useEffect(() => {
    const rootHandler = (key: string, evts: Y.YEvent<any>[], tran: Y.Transaction) => {
      if (!pathFilters?.length) {
        setBuster(v4());
        return;
      }
      for (const evt of evts) {
        const paths = getChangedPaths(evt);
        if (debug) {
          console.log('paths changed on', key);
        }
        for (const path of paths) {
          for (const filter of pathFilters) {
            if (filter.test(path)) {
              setBuster(v4());
              return;
            }
          }
          if (debug) {
            console.log('path miss', path);
          }
        }
      }
    };
    const maps = rootKeys.map(key => ({
      handler: (evts: Y.YEvent<any>[], tran: Y.Transaction) => {
        rootHandler(key, evts, tran);
      },
      map: doc.getMap(key)
    }));
    for (const { map, handler } of maps) {
      map.observeDeep(handler);
    }
    return () => {
      for (const { map, handler } of maps) {
        map.unobserveDeep(handler);
      }
    };
  }, [
    doc,
    rootKeys.join(';'),
    pathFilters?.map(pf => pf.toString())?.join(';')
  ]);

  return buster;
}

function getChangedPaths(evt: Y.YEvent<any>): string[] {
  const prefix = evt.path.map(el => {
    switch (typeof el) {
      case 'number':
        return `[${el}]`;
      default:
        return el;
    }
  }).join('.');
  return [...evt.keys.keys()].map(key => prefix
    ? `${prefix}.${key}`
    : key
  );
}
