import { memo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Icon } from './Icon';
import './Expander.scss';

interface CollapsibleProps {
  children: JSX.Element
  collapsed: boolean;
}

interface Props {
  children: (props: {
    ExpanderButton: () => JSX.Element,
    Collapsible: (props: CollapsibleProps) => JSX.Element;
    collapsed: boolean
  }) => JSX.Element
  collapsed?: boolean;
  onToggle?: (collapsed: boolean) => void;
}

const CollapsibleComponent = (props: CollapsibleProps) => props.collapsed ? <></> : props.children;
const Collapsible = memo(CollapsibleComponent);

export const Expander = memo((props: Props): JSX.Element => {
  const [collapsed, setCollapsed] = useState(props.collapsed ?? false);
  const toggleCollapse = () => {
    setCollapsed(state => {
      const newState = !state;

      if (typeof props.onToggle === 'function') {
        props.onToggle(newState);
      }

      return newState;
    });
  };

  const ExpanderButton = () => <Button variant='light' onClick={toggleCollapse} className='expander-button'>
    {collapsed && <><Icon name='expand_more' pack='material-symbols'/></>}
    {!collapsed && <><Icon name='expand_less' pack='material-symbols'/></>}
  </Button>;

  return props.children({
    ExpanderButton,
    collapsed,
    Collapsible,
    setCollapsed
  });
});
