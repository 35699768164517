import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { WrField } from '../../dragged-components/form/CommonComponentWrappers';
import { fullPathJoin, useLightweightTransaction, useTransactionField } from '../../hooks/useTransactionField';
import { CollectionEditor, EditorListChildProps } from '../../dragged-components/form/CollectionEditor';
import { canonicalisers } from '@property-folders/common/util/formatting';
import { CollectionRemoveButton } from '../../dragged-components/form/CollectionRemoveButton';
import { useContext } from 'react';
import { FormUserInteractionContext } from '../../context/FormUserInteractionContext';
import { mergePaths } from '@property-folders/common/util/pathHandling';
import { useSpecificErrorsForPath } from '../../hooks/useFocusErrorList';
import './SlidingScale.scss';
import { Button } from 'react-bootstrap';

const limitStyle = { width: '130px' };
const amountStyle = { width: '83px' };
function SlidingScaleItem(props: TransactionConsumerProps & EditorListChildProps & {amountValuePlaceholder: string, amountTextEnd?: boolean}) {
  const { value: collection } = useLightweightTransaction({ parentPath: props.parentPath });
  const { handleRemove, fullPath } = useTransactionField(props);
  const shouldShowFullValidationError = useContext(FormUserInteractionContext).userShouldSeeAllValidation;
  const validationResult = useSpecificErrorsForPath(mergePaths(fullPath, 'limit'));

  // This is only for showing an error, if it is visible, and it is in error, it should be showing.
  // We shouldn't need to check mode because this entire component shouldn't render then
  const showErrorText = shouldShowFullValidationError && !validationResult?.valid && validationResult?.errors.some(e=>e === 'scaleLimitNotGreaterThanPrev');

  const isLastElement =props.myIndex === props.collectionLength-1;

  const removeButton = isLastElement
    ? <Button variant='outline-secondary' onClick={()=>props.onInsert?.(false)} className='add'>+</Button>
    : <CollectionRemoveButton removable={(props.myIndex !== 0 && props.removable)??false} onRemove={handleRemove} className={props.myIndex === 0 ? 'opacity-0' : undefined}/>;
  const rowContent = () => {
    if (props.myIndex === 0) {
      // Initial row
      return <>
        <div className='text-part'>Up to&nbsp;</div>
        <div className='field-part'>
          <WrField.Control
            key='rowLimit'
            name="limit"
            parentPath={fullPath}
            myPath="limit"
            valuePlaceholder='$'
            style={limitStyle}
          />
          <WrField.Control
            key='rowAmount'
            name="amount"
            parentPath={fullPath}
            myPath="amount"
            valuePlaceholder={props.amountValuePlaceholder??'$'}
            style={amountStyle}
            textEnd={props.amountTextEnd}
          />
        </div>
      </>;
    } else if (isLastElement) {
      const previousItem = collection[(props.myIndex??1)-1];
      return <>
        <div className='text-part'>From {canonicalisers.aud((previousItem?.limit??0)+0.01).display} and above&nbsp;</div>
        <div className='field-part'>
          <WrField.Control
            key='rowAmount'
            name="amount"
            parentPath={fullPath}
            myPath="amount"
            valuePlaceholder={props.amountValuePlaceholder??'$'}
            style={amountStyle}
            textEnd={props.amountTextEnd}
          />
        </div>
      </>;
    } else {
      const previousItem = collection[(props.myIndex??1)-1];
      return <>
        <div className='text-part last-item'>
          <span>From&nbsp;</span>
          {canonicalisers.aud((previousItem?.limit??0)+0.01).display}
          <span>&nbsp;up to&nbsp;</span>
        </div>
        <div className='field-part last-item'>
          <WrField.Control
            key='rowLimit'
            name="limit"
            parentPath={fullPath}
            myPath="limit"
            valuePlaceholder='$'
            style={limitStyle}
          />
          <WrField.Control
            key='rowAmount'
            name="amount"
            parentPath={fullPath}
            myPath="amount"
            valuePlaceholder={props.amountValuePlaceholder??'$'}
            style={amountStyle}
            textEnd={props.amountTextEnd}
          />
        </div>
      </>;
    }
  };
  return <div className='d-flex flex-column w-100'>
    <div className='d-flex'>
      <div className='row-data w-100'>
        {rowContent()}
      </div>
      <div className='button-container'>
        {removeButton}
      </div>
    </div>
    {showErrorText && <div className='d-flex w-100 align-items-end justify-content-end d-block invalid-feedback'>Sale price limit must be greater than previous<div style={{ width: '3em' }}></div></div>}

  </div>
  ;
}

export function SlidingScale(props: TransactionConsumerProps & {amountValuePlaceholder: string, amountTextEnd?: boolean}) {
  const fullPath = fullPathJoin(props);
  return <div className='sliding-scale'><CollectionEditor
    parentPath={fullPath}
    myPath='levels'
    childItemRenderer={SlidingScaleItem}
    autoAddFirst={2}
    allowAdd={true}
    childProps={{ amountValuePlaceholder: props.amountValuePlaceholder, amountTextEnd: props.amountTextEnd }}
    passAddToChildInstead={true}
  />
  </div>;

}
