import { Icon } from '../../dragged-components/Icon';
import { Spinner } from 'react-bootstrap';

export function DocumentLoading({ loadFailed, lightMode }: {loadFailed?: boolean; lightMode?: boolean}) {
  const background = lightMode ? undefined : '#2A2A2E';
  const color = lightMode ? undefined : '#FFFFFF';

  return <div
    className="w-100 h-100 d-flex flex-column justify-content-center align-items-center"
    style={{ background, color }}
  >
    <div>
      {loadFailed
        ? <Icon name='warning' style={{ fontSize: '5rem' }}  />
        : <Spinner animation={'border'} style={{ width: '6rem', height: '6rem' }} />
      }
    </div>
  </div>;
}
