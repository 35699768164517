import React, { Children, ReactElement } from 'react';
import { Predicate } from '@property-folders/common/predicate';
import { ButtonGroup, ButtonProps, Dropdown } from 'react-bootstrap';
import { AsyncDropdownItem } from './AsyncDropdownItem';

export type SplitButtonChild =
  | ReactElement<ButtonProps>
  | ReactElement<ButtonProps>[]
  | null
  | undefined
  | boolean;
export type SplitButtonChildren =
  | SplitButtonChild
  | Iterable<SplitButtonChild>
  | false | null | undefined;

export function SplitIfManyButton({ children, onForceFocus }: { children: SplitButtonChildren, onForceFocus?: (forceMe: boolean) => void }) {
  const asArray = Children
    .toArray(children)
    .filter(Predicate.isNotNull) as ReactElement<ButtonProps>[];

  if (asArray.length === 0) {
    return <></>;
  }

  if (asArray.length === 1) {
    return asArray[0];
  }

  return <Dropdown as={ButtonGroup} onToggle={(nextShow) => onForceFocus?.(nextShow)}>
    {asArray[0]}
    <Dropdown.Toggle split variant={asArray[0].props.variant}/>
    <Dropdown.Menu>
      {asArray.slice(1).map((item, index) => (
        <AsyncDropdownItem {...item.props} key={`i${index}`}/>
      ))}
    </Dropdown.Menu>
  </Dropdown>;
}
