import { Badge, Card } from 'react-bootstrap';
import FormCheck from 'react-bootstrap/FormCheck';
import './ClauseImportCard.scss';
import { Clause } from '@property-folders/common/types/Clause';
import clsJn from '@property-folders/common/util/classNameJoin';

export interface Props {
  clause: Clause,
  checked: boolean;
  onChange: (clause: Clause) => void;
  showMigratable?: boolean;
}

export function ClauseImportCard(props: Props) {
  const { clause, checked, onChange, showMigratable= true } = props;
  const needsMigration = !clause.markdownClause;

  if (!showMigratable && needsMigration) {
    return <></>;
  }

  return <Card
    key={clause.clauseId}
    className='clause-import-card'
  >
    <Card.Header>
      <FormCheck
        key={clause.clauseId}
        id={String(clause.clauseId)}
      >
        <FormCheck.Input
          disabled={needsMigration}
          onChange={() => onChange(clause)}
          checked={checked}
        />
        <FormCheck.Label>
          <h5>{clause.title}</h5>
          <div className='badges'>
            {needsMigration && <Badge
              pill={true}
              bg='dark'
              title='This clause was written for subscription forms only. It needs to be migrated to work with property folder documents as well.'
              className='migration-required'
            >Migration Required</Badge>}
            <Badge pill={true} className={clsJn({ reaforms: clause.isGlobal, agency: !clause.isGlobal }, 'owner')}>{clause.isGlobal ? 'reaforms' : 'Agency'}</Badge>
          </div>
        </FormCheck.Label>
      </FormCheck>
    </Card.Header>
  </Card>;
}
