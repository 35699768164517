import { Card } from 'react-bootstrap';
import './InstanceCardBase.scss';

import clsJn from '@property-folders/common/util/classNameJoin';
import { Icon } from './Icon';
import React from 'react';

/**
 *
 * @param props.noHover Overrides clickable
 * @returns
 */
export const InstanceCardBase = (props:{
  onOpen?: ()=>void
  title: string
  mini?: boolean
  cardClass?: string
  isProcess?: boolean
  iconBefore?: string
  iconAfter?: string
  clickable?: boolean
  noHover?: boolean
  footerButtons?: JSX.Element|JSX.Element[]
  children?: JSX.Element|JSX.Element[]|string
  forceFocus?: boolean
  bottom?: JSX.Element|JSX.Element[] | undefined
}) => {
  const { title, cardClass, mini, clickable: clickableProp, noHover, onOpen, isProcess, iconBefore, iconAfter, children, footerButtons } = props;
  const clickable = !noHover && clickableProp;
  return <Card
    className={clsJn(
      'border-0 shadow instance-card-base',
      cardClass,
      mini && 'mini',
      clickable && !noHover && 'clickable',
      !noHover && 'hoverable',
      isProcess && 'process',
      props.forceFocus ? 'hoverable-force' : 'hoverable-force-off'
    )}
  >
    <Card.Header onClick={()=>clickable && !noHover && onOpen?.()}>
      <div className={clsJn('thick-title')}>
        {title}{iconAfter && <Icon name='archive' icoClass='archive-indicator' title={'Archived'}></Icon>}
      </div>
    </Card.Header>
    <Card.Body
      className='d-flex flex-column'
      onClick={()=>clickable && !noHover && onOpen?.()}
    >

      <div className='instance-body pseudoform-body d-flex flex-column' style={{ flex: '1 1 auto' }}>
        {children}
      </div>
    </Card.Body>
    {props.bottom}
    <Card.Footer className='d-flex justify-content-end'>
      <div className='footer-buttons d-flex'>
        {footerButtons}
      </div>
    </Card.Footer>
  </Card>;
};
