import { Alert, Button, Col, Container, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { Icon } from './Icon';
import {
  API_CHARGE_PRICE_CENTS,
  API_CHARGE_PRICE_STR,
  LegacyApi,
  Payment,
  Searches,
  StoredCard
} from '@property-folders/common/client-api/legacyApi';
import { CardLogo, CardType, getCardType } from './CardLogo';
import { cardValidator } from '@property-folders/common/util/cc-validation';
import './PaymentModal.scss';
import { clsJn as classNames } from '@property-folders/common/util/classNameJoin';

export interface LineItem {
  description: string;
  price: string;
  priceCents: number;
}

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
  searches: Searches | undefined;
  entityId: string | number;
  onPayment: (token: string) => void;
  formUrl?: string;
}

const yearStart = parseInt((new Date).getFullYear().toString().slice(2, 4));
const yearEnd = yearStart + 10;

function calculateEwaySurcharge(cardType: CardType, priceCents: number) {
  const percentageTimes10 = cardType === CardType.AMEX ? 18 : 19;
  const flatFeeCents = cardType === CardType.AMEX ? 28 : 30;
  const variableChargeCents = Math.round(priceCents * percentageTimes10) / 1000;

  return flatFeeCents + variableChargeCents;
}

export function PaymentModal(props: Props) {
  const [storedPayment, setStoredPayment] = useState<StoredCard | false | null>(null);
  const [cardNumber, setCardNumber] = useState('');
  const [cardType, setCardType] = useState<CardType>(CardType.UNKNOWN);

  const [cardName, setCardName] = useState('');
  const [flagEmptyName, setFlagEmptyName] = useState(false);

  const [cardCvn, setCardCvn] = useState('');
  const [cardExpiryMonth, setCardExpiryMonth] = useState('');
  const [cardExpiryYear, setCardExpiryYear] = useState('');
  const [errorText, setErrorText] = useState<string | null>(null);
  const [makingPayment, setMakingPayment] = useState(false);

  const [ccValid, setCcValid] = useState(false);
  const [flagCcInvalid, setFlagCcInvalid] = useState(false);

  const [cvnValid, setCvnValid] = useState(false);
  const [flagCvnInvalid, setFlagCvnInvalid] = useState(false);

  const [monthValid, setMonthValid] = useState(false);
  const [flagMonthInvalid, setFlagMonthInvalid] = useState(false);

  const [yearValid, setYearValid] = useState(false);
  const [flagYearInvalid, setFlagYearInvalid] = useState(false);

  const [expiryValid, setExpiryValid] = useState(false);
  const [flagExpiryInvalid, setFlagExpiryInvalid] = useState(false);

  const lineItems: LineItem[] = (props.searches ?? []).map(s => ({
    description: s.type === 'proprietor' ? `Proprietor Search (${s.address})` : `Title Search (${s.title} ${s.address})`,
    price: LegacyApi.paymentTypeToPrice(s.type).formatted,
    priceCents: LegacyApi.paymentTypeToPrice(s.type).cents
  })) ?? [];
  lineItems.push(
    ...(props.searches ?? [])
      .filter(s => s.type === 'title_pf')
      .map(s => ({
        description: `Land Services API Charge (${s.title})`,
        price: API_CHARGE_PRICE_STR,
        priceCents: API_CHARGE_PRICE_CENTS
      }))
  );
  const totalPrice = lineItems.reduce((ac, cur) => {
    if (!cur) {
      return ac;
    }

    return ac + Number(cur.priceCents);
  }, 0);

  const ewaySurchargeCents = Math.round(calculateEwaySurcharge(cardType, totalPrice));
  lineItems.push({
    description: 'Eway Processing Fee',
    price: (ewaySurchargeCents / 100).toFixed(2),
    priceCents: ewaySurchargeCents
  });

  function validateMonthYear(month: string, year: string) {
    if (!(month && year)) {
      return;
    }
    const { isValid } = cardValidator.expirationDate(`${month}/${year}`); // Checking 10 years in the future is the job
    // of the year field
    setExpiryValid(isValid);
    if (!isValid) {
      setFlagExpiryInvalid(true);
    }
  }

  useEffect(() => {
    if (!props.show) {
      setStoredPayment(null);
      setFlagCcInvalid(false);
      setFlagCvnInvalid(false);
      setFlagEmptyName(false);
      setFlagExpiryInvalid(false);
      setFlagMonthInvalid(false);
      setFlagYearInvalid(false);
      return;
    }

    LegacyApi.checkForStoredPayment(String(props.entityId)).then(card => {
      if (!card.available) {
        setStoredPayment(false);
        return;
      }

      setStoredPayment(card);
    });
  }, [props.show]);

  const makePayment = () => {
    setErrorText(null);

    if (!storedPayment && !cardNumber) {
      setErrorText('Please enter all required details');
      return;
    }

    if (!props.searches) {
      setErrorText('An error occurred, please try again');
      return;
    }

    const payment: Payment = {
      type: 'mixed',
      searches: props.searches,
      formUrl: props.formUrl || window.location.href
    };

    if (cardNumber) {
      payment.card = {
        name: cardName,
        cvn: cardCvn,
        number: cardNumber,
        expiry_month: cardExpiryMonth,
        expiry_year: cardExpiryYear,
        cardType: getCardType(cardNumber)
      };
    }

    setMakingPayment(true);
    LegacyApi.makePayment(String(props.entityId), payment).then(token => {
      props.onPayment(token);
      props.setShow(false);
    }).catch(e => {
      setErrorText('An error occurred trying to process your payment');
    }).finally(() => {
      setMakingPayment(false);
    });
  };

  const noExpiryInvalidMessage = !(
    (!monthValid && flagMonthInvalid)
    || (!yearValid && flagYearInvalid)
    || (!expiryValid && flagExpiryInvalid)
  );

  let modalBody = <>
    <Container className='px-4 mb-3'>
      {storedPayment !== null && !!storedPayment && <Row className='mt-4'>
        <div>

          <div className='d-flex align-items-center'>
            <CardLogo cardType={storedPayment.card.cardType} style={{ fontSize: '32px' }} />
            <h5 className='m-0 ms-2'>Stored Credit Card Details</h5>
          </div>

          <p>
            <strong>{storedPayment.card.name}</strong><br />
            xxxx xxxx xxxx {storedPayment.card.lastFour}<br />
            {storedPayment.card.expiry}
          </p>
        </div>
      </Row>}

      {storedPayment !== null && !storedPayment && <>
        <Row>
          <Col xs={12} lg={6}>
            <Container fluid className='mb-3 mb-lg-0'>
              <Form>
                <Row><h5>Credit Card Details</h5></Row>
                <Row>
                  <Col>
                    <FloatingLabel label='Name on card' className='flex-grow-1 common-label'>
                      <Form.Control
                        placeholder=''
                        type='text'
                        autoComplete='name'
                        isInvalid={flagEmptyName && !cardName}
                        onChange={(e) => {
                          setFlagEmptyName(false);
                          setCardName(e.target.value);
                        }}
                        onBlur={() => setFlagEmptyName(true)}
                      />
                      <div className='invalid-feedback'>Please enter the cardholder name</div>
                    </FloatingLabel>
                  </Col>
                </Row>

                <Row className='mt-2'>
                  <Col>
                    <FloatingLabel label='Card Number' className='flex-grow-1 common-label'>
                      <Form.Control
                        placeholder=''
                        type='text'
                        autoComplete='cc-number'
                        isInvalid={!ccValid && flagCcInvalid}
                        onChange={(e) => {
                          setFlagCcInvalid(false);
                          let cursorPosition = e.target.selectionStart;
                          let settingCursorPosition = false;
                          let modNewVal = e.target.value;
                          const matchingDeletedSpace = modNewVal.match(/[0-9]{5,8}/)?.[0];
                          if (matchingDeletedSpace) {
                            const startPos = modNewVal.indexOf(matchingDeletedSpace);
                            if (cardNumber[startPos + 4] === ' ') {
                              settingCursorPosition = true;
                              modNewVal = modNewVal.slice(0, startPos + 3) + modNewVal.slice(startPos + 4);
                              cursorPosition--;
                            }
                          }
                          const numbersOnly = modNewVal.replace(/\D/g, '');
                          let output = '';
                          for (let i = 0; i < 4; i++) {
                            const start = i * 4;
                            const testSegment = numbersOnly.slice(start, start + 4);
                            if (testSegment) {
                              if (i > 0) {
                                output += ' ';
                              }
                              output += testSegment;
                            }
                          }

                          e.target.value = output;
                          const { isPotentiallyValid, isValid } = cardValidator.number(numbersOnly);
                          setCcValid(isValid);
                          if (!isPotentiallyValid) {
                            setFlagCcInvalid(true);
                          }
                          setCardType(getCardType(output));
                          setCardNumber(output);
                          if (settingCursorPosition) e.target.setSelectionRange(cursorPosition, cursorPosition);
                        }}
                        onBlur={(e) => {
                          if (!ccValid) {
                            setFlagCcInvalid(true);
                          }
                        }}
                      />
                      <div className='invalid-feedback'>Please enter a valid Credit Card Number</div>
                    </FloatingLabel>
                  </Col>
                </Row>

                <Row className='mt-2'>
                  <Col>
                    <FloatingLabel label='Expiry Month' className='flex-grow-1 common-label'>
                      <Form.Control
                        placeholder=''
                        type='number'
                        minLength={2}
                        maxLength={2}
                        min={1}
                        max={12}
                        autoComplete='cc-exp-month'
                        isInvalid={(flagMonthInvalid && !monthValid) || (flagExpiryInvalid && !expiryValid)}
                        onChange={(e) => {
                          setFlagMonthInvalid(false);
                          setFlagExpiryInvalid(false);
                          let mutableValue = e.target.value.replace(/\D/g, '');

                          if (mutableValue.length > 2) {
                            mutableValue = parseInt(mutableValue).toString().slice(0, 2);
                          }
                          if (mutableValue) e.target.value = mutableValue;
                          setCardExpiryMonth(mutableValue);
                          const { isValid } = cardValidator.expirationMonth(mutableValue);
                          setMonthValid(isValid);
                        }}
                        onBlur={(e) => {
                          let mutableValue = e.target.value.replace(/\D/g, '');

                          const { isValid } = cardValidator.expirationMonth(mutableValue);
                          setMonthValid(isValid);
                          if (parseInt(mutableValue) === 0) {
                            mutableValue = '';
                            e.target.value = mutableValue;
                            setFlagMonthInvalid(true);

                          } else if (mutableValue) {
                            mutableValue = mutableValue.padStart(2, '0');
                            e.target.value = mutableValue;
                          }
                          setCardExpiryMonth(mutableValue);
                          if (!isValid) {
                            setFlagMonthInvalid(true);
                          }
                          validateMonthYear(mutableValue, cardExpiryYear);

                        }}
                      />

                    </FloatingLabel>
                  </Col>

                  <Col>
                    <FloatingLabel label='Expiry Year' className='flex-grow-1 common-label'>
                      <Form.Control
                        placeholder=''
                        type='number'
                        minLength={2}
                        maxLength={2}
                        min={yearStart}
                        max={yearEnd} // it's what amazon does, it's gotta be a safe bet.
                        autoComplete='cc-exp-year'
                        isInvalid={(flagYearInvalid && !yearValid) || (flagExpiryInvalid && !expiryValid)}
                        onChange={(e) => {
                          setFlagYearInvalid(false);
                          setFlagExpiryInvalid(false);
                          let mutableValue = e.target.value.replace(/\D/g, '');

                          if (mutableValue.length > 2) {
                            mutableValue = parseInt(mutableValue).toString().slice(0, 2);
                          }
                          if (mutableValue) e.target.value = mutableValue;
                          setCardExpiryYear(mutableValue);
                          const { isValid } = cardValidator.expirationYear(mutableValue, 10);
                          setYearValid(isValid);
                        }}
                        onBlur={(e) => {
                          let mutableValue = e.target.value.replace(/\D/g, '');
                          /* enforce range and confuse users
                          if (parseInt(mutableValue) < yearStart) {
                            mutableValue = yearStart.toString();
                          }
                          if (parseInt(mutableValue) > yearEnd) {
                            mutableValue = yearEnd.toString();
                          }
                          */
                          const { isValid } = cardValidator.expirationYear(mutableValue, 10);
                          setYearValid(isValid);
                          if (parseInt(mutableValue) === 0) {
                            mutableValue = '';
                            setFlagYearInvalid(true);
                          } else if (mutableValue) {
                            mutableValue = mutableValue.padStart(2, '0');
                          }
                          e.target.value = mutableValue;
                          setCardExpiryYear(mutableValue);
                          if (!isValid) {
                            setFlagYearInvalid(true);
                          }
                          validateMonthYear(cardExpiryMonth, mutableValue);

                        }}
                      />

                    </FloatingLabel>

                  </Col>
                </Row>

                <Row>
                  <div className={classNames({ 'invalid-feedback': true, valid: noExpiryInvalidMessage })}>{
                    (!monthValid && flagMonthInvalid)
                      ? 'Please enter a valid month'
                      : (!yearValid && flagYearInvalid)
                        ? 'Please enter a year up to 10 years in future'
                        : (!expiryValid && flagExpiryInvalid)
                          ? 'Please ensure your date is this month or in future'
                          : 'Placeholder'
                  }</div>
                </Row>

                <Row className='mt-2'>
                  <Col>
                    <FloatingLabel label='CVV' className='flex-grow-1 common-label'>
                      <Form.Control
                        placeholder=''
                        type='number'
                        minLength={2}
                        maxLength={4}
                        autoComplete='cc-csc'
                        isInvalid={flagCvnInvalid && !cvnValid}
                        onChange={(e) => {
                          setFlagCvnInvalid(false);
                          const mutableValue = e.target.value.replace(/\D/g, '');
                          if (mutableValue.length !== 3) {
                            setCvnValid(false);
                          } else {
                            setCvnValid(true);
                          }
                          setCardCvn(mutableValue);
                        }}
                        onBlur={(e) => {
                          if (!cvnValid) {
                            setFlagCvnInvalid(true);
                          }
                        }}
                      />
                      <div className='invalid-feedback'>Please enter the 3 digit CVV on the back of the card</div>
                    </FloatingLabel>
                  </Col>
                </Row>
              </Form>
            </Container>
          </Col>
          <Col xs={12} lg={6}>
            <Container>
              <Row>
                <Col>
                  <h5>Register Credit Card</h5>
                  <p>Your business can register a credit card for future searches.</p>
                  <p>The <strong>Main User</strong> registers a card in:</p>
                  <p><strong>Settings {'>'} Edit Profile | Title Purchases</strong></p>
                  <p>This is for business wide use.</p>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </>}

      <Row><h5>Purchases</h5></Row>
      {(lineItems ?? []).map(li => <Row>
        <Col>
          <div className='d-flex'>
            <span>{li.description}</span>
            <span className='ms-auto'>${li.price}</span>
          </div>
        </Col>
      </Row>)}

    </Container>
  </>;

  if (storedPayment === null) {
    modalBody = <div>Loading...</div>;
  }

  return <Modal
    size='lg'
    show={props.show}
    onHide={() => {
      props.setShow(false);
    }}
    centered={true}
  >
    <Modal.Header>
      <h4>Payment Details</h4>
    </Modal.Header>
    <Modal.Body className='PaymentsModal'>
      {errorText && <Alert variant='danger' className='mt-3'>{errorText}</Alert>}

      {modalBody}

      <div className='d-flex'>
        <Button
          onClick={makePayment}
          disabled={storedPayment === null || makingPayment || (storedPayment === false && (!ccValid || !cardName || !monthValid || !yearValid || !expiryValid || !cvnValid))}
          className='mt-3 ms-auto'
        >
          Pay Now <Icon name='check' pack='material-symbols' />
        </Button>
      </div>
    </Modal.Body>
  </Modal>;
}
