import { DetailedHTMLProps, PropsWithChildren, HTMLAttributes } from 'react';
import clsJn from '@property-folders/common/util/classNameJoin';
import { w3cFontSelection } from '@property-folders/common/util/w3cFontSelection';

export enum TagColor {
  FFADAD = '#FFADAD',
  FFD6A5 = '#FFD6A5',
  FDFFB6 = '#FDFFB6',
  CAFFBF = '#CAFFBF',
  '9BF6FF' = '#9BF6FF',
  A0C4FF = '#A0C4FF',
  BDB2FF = '#BDB2FF',
  FFC6FF = '#FFC6FF',
  DRAFT = '#868e96',
  SIGNED = '#54c45e',
  ADOBE_RED = '#ff0000',
  DAVYS_GREY = '#605f5e',
  CERULEAN = '#247BA0',
  ROYAL_BLUE = '#0A2463',
  TIFFANY_BLUE = '#46237A'
}

export interface TagProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  background: TagColor;
  outline?: boolean;
}

export function Tag({ background, outline, children, ...props }: PropsWithChildren<TagProps>) {
  return <div
    {...props}
    className={clsJn('badge d-flex align-items-center justify-content-center py-2 px-3', props.className)}
    style={{
      backgroundColor: !outline ? background : undefined,
      color: !outline ? w3cFontSelection(background) : background,
      border: !outline ? undefined : `1px solid ${background}`,
      ...props.style
    }}
  >
    {children}
  </div>;
}
