import { createContext, ReactNode, RefObject } from 'react';
import { useSize } from '../hooks/useSize';

export const SideNavMenuSizingContext = createContext<DOMRectReadOnly>(new DOMRectReadOnly(0, 0, 0, 0));

export function SetupSideNavMenuSizingContext({
  sideNavRef,
  children
}: {
  sideNavRef: RefObject<HTMLElement>,
  children: ReactNode
}) {
  const sizing = useSize(sideNavRef);

  return (
    <SideNavMenuSizingContext.Provider value={sizing}>
      {children}
    </SideNavMenuSizingContext.Provider>
  );
}
