import { useCallback, useRef, useState } from 'react';
import { Suggestion } from '../form/Suggestor';
import {
  IAddressSearchResponse, IAddressSearchResponseParts,
  IPropertySearchSuggestions
} from '@property-folders/contract/rest/address-search';
import { PropertySearchApi } from '@property-folders/common/client-api/propertySearchApi';
import { Typeahead } from '../Typeahead';
import { composeFullAddressFromParts } from '@property-folders/common/util/formatting/string-composites';
import { stringifySaleAddress } from '@property-folders/common/util/stringifySaleAddress';

export interface BareAddressSelectorProps {
  id: string;
  label: string;
  gnafCentre?: string;
  international?: boolean;
  countries?: ('au'|'nz')[]
  autoFocus?: boolean;
  containerClassName?: string;
  onAddressSelect: (value: string, parts?: IAddressSearchResponseParts, gnaf?: string, userInput?: boolean) => void;
  className?: string;
  tabIndex?: number;
  // when true, pass the selected address through onAddressSelect, even if the details callback will be called.
  // this is in case the details response takes a long time and the user clicks away.
  selectIntermediateAddressLine?: boolean;
  placeholder?: string;
  defaultValue?: string;
  formatter?: (originalFullAddress: string, parts?: IAddressSearchResponseParts) => string
}

function defaultFormatter(original: string, streetAddr_parts?: IAddressSearchResponseParts) {
  return streetAddr_parts
    ? stringifySaleAddress({ streetAddr_parts }) || original
    : original;
}

export function BareAddressSelector(props: BareAddressSelectorProps) {
  const {
    gnafCentre,
    international,
    autoFocus,
    label,
    onAddressSelect,
    id,
    className,
    tabIndex,
    selectIntermediateAddressLine,
    placeholder,
    containerClassName,
    defaultValue,
    formatter: rawFormatter,
    countries
  } = props;

  const [suggestions, setSuggestions] = useState([] as Suggestion[]);
  const [isSearching, setIsSearching] = useState(0);
  const activeSearch = useRef<{
    abort: () => void,
    response: Promise<IPropertySearchSuggestions | undefined>
  }>();

  const [valueOverride, setValueOverride] = useState<string|null>(defaultValue || null);

  const searchFunc = useCallback((query: string) => {
    activeSearch.current?.abort();
    if (!query || query.trim() === '') {
      return;
    }

    setIsSearching(s => s + 1);
    activeSearch.current = PropertySearchApi.getSuggestions({
      searchTerm: query,
      gnafCentre,
      international,
      countries
    });
    activeSearch.current.response.then((suggestions) => {
      setSuggestions(suggestions?.Predictions.slice(0, 10).map(s => ({
        label: s.AddressParts ? composeFullAddressFromParts(s.AddressParts) : s.FullAddress,
        ...s
      })) ?? []);
    }).catch(e => {
      console.error(e);
    }).finally(() => {
      setIsSearching(s => s - 1);
    });
  }, [international, gnafCentre]);

  function handleSuggestionSelect([suggestion]: [IAddressSearchResponse]) {
    const formatter = rawFormatter || defaultFormatter;
    if (suggestion.AddressParts) {
      const addressStr = formatter(suggestion.FullAddress, suggestion.AddressParts);
      setValueOverride(addressStr);
      onAddressSelect(addressStr, suggestion.AddressParts, suggestion.GNAF || undefined);
      return;
    }

    if (suggestion.DetailCallback) {
      if (selectIntermediateAddressLine) {
        onAddressSelect(suggestion.FullAddress, undefined, suggestion.GNAF || undefined);
      }
      PropertySearchApi.getDetail(suggestion.DetailCallback.EndpointParams).response
        .then((response) => {
          const parts = response?.Predictions?.at(0)?.AddressParts;
          const addressStr = formatter(suggestion.FullAddress, parts);
          setValueOverride(addressStr);
          onAddressSelect(addressStr, parts, suggestion.GNAF || undefined);
        });
      return;
    }

    onAddressSelect(suggestion.FullAddress, undefined, suggestion.GNAF || undefined);
  }

  return <Typeahead
    debounceMs={0}
    label={label}
    isLoading={isSearching > 0}
    options={suggestions}
    autoFocus={autoFocus}
    onSearch={searchFunc}
    promptText='Type address to search'
    searchText='Searching for address'
    onSuggestSelect={handleSuggestionSelect}
    onChange={(e) => { onAddressSelect(e.target.value, undefined, undefined, true); }}
    id={id ?? 'address-selector'}
    className={className}
    valid={true}
    tabIndex={tabIndex}
    value={valueOverride}
    placeholder={placeholder}
    containerClassName={containerClassName}
  />;
}
