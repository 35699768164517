import React, { useMemo, useState } from 'react';
import Select, { Options } from 'react-select';
import { Predicate } from '@property-folders/common/predicate';
import { useBreakpointValue } from '../hooks/useBreakpointValue';

type Option = { value: string, label: string };

export interface Props {
  setShowArchived?: (show: boolean) => void;
  setShowStarred?: (show: boolean) => void;
  setCurrentViewOption?: (opt: Option) => void;
  archived?: boolean;
  favourites?: boolean;
}

export function SearchType(props: Props) {
  const {
    archived,
    favourites,
    setShowArchived,
    setShowStarred,
    setCurrentViewOption
  } = props;

  const width = useBreakpointValue<string | number>({ lg: 170 }, '100%');
  const outerClass = useBreakpointValue({ lg: '' }, 'w-100');

  const options: Options<Option> = useMemo(() => {
    return [
      { value: 'all', label: 'Active' },
      typeof favourites === 'undefined' || favourites ? { value: 'favourites', label: 'Favourites' } : undefined,
      typeof archived === 'undefined' || archived ? { value: 'archived', label: 'Archived' } : undefined
    ].filter(Predicate.isNotNull);
  }, [favourites, archived]) ;

  const [selected, setSelected] = useState<Option>(options[0]);

  return <Select
    className={outerClass}
    options={options}
    isClearable={false}
    isSearchable={false}
    styles={{
      container: (baseStyles) => ({
        ...baseStyles,
        marginLeft: width === '100%' ? '0 !important' : undefined,
        height: 42.25 // height of input
      }),
      control: (baseStyles, state) => ({
        ...baseStyles,
        background: 'var(--bs-body-bg)',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'rgb(var(--bs-secondary-rgb))',
        borderRadius: 0,
        height: '100%',
        fontSize: '1.25rem',
        width
      })
    }}
    onChange={c => {
      if (c == null) {
        return;
      }

      setSelected(c);
      switch (c.value) {
        case 'favourites':
          setShowStarred?.(true);
          setShowArchived?.(false);
          break;

        case 'archived':
          setShowStarred?.(false);
          setShowArchived?.(true);
          break;

        case 'all':
        default:
          setShowStarred?.(false);
          setShowArchived?.(false);
          break;
      }
      setCurrentViewOption?.(c);
    }}
    value={selected}
  />;
}
