import { useEffect } from 'react';
import { MaterialisedPropertyData } from '@property-folders/contract';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { boolApplicableOpts, boolKnownOpts, chattelsOptionsContract } from '@property-folders/common/data-and-text/constants';
import {
  calculateFinalPrice2
} from '@property-folders/common/util/pdfgen/sections/contract-price';
import { WrField } from '../CommonComponentWrappers';
import { Exclusions } from '../SubSections/Exclusions';
import { Inclusions } from '../SubSections/Inclusions';
import { TenancySubsection } from '../TenancySubsection';
import { useYdocBinder } from '@property-folders/components/hooks/useYdocBinder';
import { parseInt2 } from '@property-folders/common/util/formatting';
import { ShowGuidanceNotesButton } from '../../guidance/ShowGuidanceNotesButton';
import { vendorWorksFieldPlaceholder } from '@property-folders/common/util/formatting/constants';
import { PoolCompliance } from '../SubSections/PoolCompliance';
import { GstWithholding } from '../../../display/form/GstWithholding';

interface Props {
  purchaserMode?: boolean;
}

export const ContractSchedule = ({ purchaserMode }: Props): JSX.Element => {
  const { value: transRoot } = useLightweightTransaction<MaterialisedPropertyData>({});
  const { updateDraft } = useYdocBinder<MaterialisedPropertyData>({ path: '' });
  const { finalPrice } = calculateFinalPrice2(transRoot?.vendorGst, transRoot?.contractPrice);

  useEffect(()=>{
    if (updateDraft && finalPrice) {
      updateDraft(draft => {
        draft.cgtEnable = parseInt2(finalPrice) >= 750000;
      });
    }
    // updateDraft is a different value every render, so we need to just determine if it exists so we can use it
  }, [finalPrice, !!updateDraft]);

  return <div className="w-100">
    <Inclusions myPath="chattels" inclusions={chattelsOptionsContract} purchaserMode={purchaserMode} contractMode={true} />

    <Exclusions myPath="exclusions"/>
    {!purchaserMode && <div className='subsection scrollspy-target' data-focus-path="subsection-encroach">
      <div className='fs-4'>Encroachments and fences not on boundaries  <ShowGuidanceNotesButton noteId='knownEncroachments'/></div>
      <div className="w-100 mt-2">
        <WrField.CheckRadio
          label='Are there any encroachments and fences not on boundaries?'
          radioType='checkbox'
          options={boolKnownOpts}
          valueType='boolean'
          inline={true}
          name='encroachEnable'
          myPath='encroachEnable'
        />
      </div>
      {transRoot?.encroachEnable && <div className='w-100 mt-2'>
        <WrField.Control textArea={{ rows: 3 }} name='encroach' myPath='encroach' label='Known encroachments' maxLength={1000}/>
      </div>}
    </div>}

    {!purchaserMode && <div className='subsection scrollspy-target' data-focus-path="subsection-noti-works">
      <div className='fs-4'>Alterations and improvements erected without consent <ShowGuidanceNotesButton noteId='alterationsWithoutConsent'/></div>
      <div className="w-100 mt-2">
        <WrField.CheckRadio
          label='Are there any alterations and improvements erected without consent?'
          radioType='checkbox'
          options={boolKnownOpts}
          valueType='boolean'
          inline={true}
          name='notiWorksEnable'
          myPath='notiWorksEnable'
        />
      </div>
      {transRoot?.notiWorksEnable && <div className='w-100 mt-2'>
        <WrField.Control textArea={{ rows: 3 }} name='notiWorks' myPath='notiWorks' label='Alterations' maxLength={1000}/>
      </div>}
    </div>}

    {!purchaserMode && <div className='subsection scrollspy-target' data-focus-path="subsection-vendor-works">
      <div className='fs-4'>Vendor’s Works</div>
      <div className="w-100 mt-2">
        <WrField.CheckRadio
          label='Are there any works to be carried out by Vendor prior to settlement?'
          radioType='checkbox'
          options={boolApplicableOpts}
          valueType='boolean'
          inline={true}
          name='vendorWorksEnable'
          myPath='vendorWorksEnable'
        />
      </div>
      {transRoot?.vendorWorksEnable && <div className='w-100 mt-2'>
        <WrField.Control textArea={{ rows: 3 }} name='vendorWorks' myPath='vendorWorks' label={vendorWorksFieldPlaceholder} maxLength={1000}/>
      </div>}
    </div>}

    <PoolCompliance contractMode={true}/>

    {!purchaserMode && <div className='subsection scrollspy-target' data-focus-path='subsection-tenancy'>
      <TenancySubsection saleContractMode={true}/>
    </div>}

    <GstWithholding />

    {!purchaserMode
      && <div className='subsection scrollspy-target' data-focus-path="subsection-title-matters">
        <div className='fs-4'>Permitted Encumbrances</div>
        <div>(Only complete if a Form 1 has not been served on the Purchaser before full execution of this Contract, and
          a cooling-off period is not applicable.)
        </div>
        <div>Detail all Encumbrances (existing or intended to be created) not to be discharged prior to Settlement.
        </div>
        <WrField.Control textArea={{ rows: 3 }} name='mattersAffectTitle' myPath='contractSchedule.noForm1NoCoolMatters'
          label='Details of Permitted Encumbrances' maxLength={1000}/>
      </div>}

  </div>;
};
