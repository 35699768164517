import { CSSProperties, useEffect, useMemo } from 'react';
import { useDragLayer, XYCoord } from 'react-dnd';
import { CustomField, CustomFieldPartyInfo } from './CustomField';
import { MaterialisedPropertyData } from '@property-folders/contract';
import { clamp } from 'lodash';

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%'
};

function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null
): CSSProperties {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none'
    };
  }

  const { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
    opacity: '0.6'
  };
}

export function DragLayer({ parties, property, dragToScrollContainer, onDragStart }: {
  parties: CustomFieldPartyInfo[],
  property: MaterialisedPropertyData,
  dragToScrollContainer?: HTMLElement | null,
  onDragStart?: () => void
}) {
  const { isDragging, item, initialOffset, currentOffset, contentScale } =
    useDragLayer((monitor) => {
      const item = monitor.getItem();
      return ({
        item,
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
        contentScale: item != null && 'contentScale' in item && typeof item.contentScale === 'number' ? item.contentScale : 1
      });
    });

  // determine scroll threshold intersection, and how much to scroll by each time tick
  const { vert, hor } = useMemo<{ vert?: number, hor?: number }>(() => {
    if (!isDragging) return {};
    if (!currentOffset) return {};
    if (!dragToScrollContainer) return {};
    const { y, height, x, width } = dragToScrollContainer.getBoundingClientRect();
    const threshold = 50;
    return {
      vert: selectScrollThresholdSpeed(currentOffset.y, {
        threshold: y + threshold,
        scaler: 10
      }, {
        threshold: y + height - threshold,
        scaler: 10
      }),
      hor: selectScrollThresholdSpeed(currentOffset.x, {
        threshold: x + threshold,
        scaler: 25
      }, {
        threshold: x + width - threshold,
        scaler: 10
      })
    };
  }, [isDragging, dragToScrollContainer, currentOffset]);

  useEffect(() => {
    if (!isDragging) return;
    if (!dragToScrollContainer) return;
    if (!(vert || hor)) return;

    const el = dragToScrollContainer;
    const handle = setInterval(() => {
      el.scroll(
        clamp(hor ? el.scrollLeft + hor : el.scrollLeft, 0, el.scrollWidth),
        clamp(vert ? el.scrollTop + vert : el.scrollTop, 0, el.scrollHeight),
      );
    }, 10);

    return () => {
      clearInterval(handle);
    };
  }, [isDragging, dragToScrollContainer, vert, hor]);

  useEffect(() => {
    if (isDragging) {
      onDragStart?.();
    }
  }, [isDragging]);

  if (!isDragging) {
    return null;
  }

  return (
    <div style={layerStyles}>
      <div
        style={getItemStyles(initialOffset, currentOffset)}
      >
        <CustomField
          details={item}
          parties={parties}
          dragging={true}
          contentScale={contentScale}
          property={property}
        />
      </div>
    </div>
  );
}

function selectScrollThresholdSpeed(position: number, start: {threshold: number, scaler: number }, end: {threshold: number, scaler: number}) {
  if (position < start.threshold) {
    const diff = start.threshold - position;
    return -1 * Math.ceil(Math.abs(diff / start.scaler));
  }
  if (position > end.threshold) {
    const diff = position - end.threshold;
    return Math.ceil(Math.abs(diff / end.scaler));
  }

  return undefined;
}
