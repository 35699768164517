import React, { forwardRef } from 'react';
import { asPercentage, FieldProps } from './common';
import { useBreakpointValue } from '../../../hooks/useBreakpointValue';
import { SigningSessionFieldType, SigningSessionSubType } from '@property-folders/contract';
import { StackedFieldImage } from './StackedFieldImage';
import clsJn from '@property-folders/common/util/classNameJoin';
import { Button } from 'react-bootstrap';

export const SignInitialsField = forwardRef<HTMLDivElement, FieldProps>((props, ref) => {
  const {
    type,
    subtype,
    name,
    timestamp,
    timestampPosition,
    timeZone,
    location,
    minCompositeSignatureDimensions,
    page: {
      width: pageWidth,
      height: pageHeight
    },
    text,
    image,
    fillField,
    pixelsPerInch
  } = props;
  const buttonFontSize = useBreakpointValue({ base: '8px', sm: '12px', md: '14px', lg: '18px', xl: '24px' }, '8px');
  const textFontSize = useBreakpointValue({ base: '10px', sm: '12px', md: '18px' }, '10px');

  const isFilled = !!(text || image);
  const clearField = isFilled
    ? props.clearField
    : undefined;
  const clearable = !!clearField;

  const renderImage = () => {
    if (!image) {
      return <></>;
    }
    switch (subtype) {
      case SigningSessionSubType.RenderInfoInline:
        return <StackedFieldImage
          image={image}
          clearable={clearable}
          name={name}
          timestamp={timestamp}
          timeZone={timeZone}
          dimensions={minCompositeSignatureDimensions || location}
          pixelsPerInch={pixelsPerInch}
          timestampPosition={timestampPosition}
        />;
      default:
        return <img
          src={image}
          className={clsJn({
            'h-100': true,
            'w-100': true,
            'clearable-field': clearable
          })}
          style={{
            objectFit: 'contain',
            objectPosition: 'left bottom'
          }}
        />;
    }
  };

  return <div
    ref={ref}
    onClick={clearField}
    style={{
      position: 'absolute',
      cursor: clearField ? 'pointer' : 'default',
      left: asPercentage(location.x, pageWidth),
      bottom: asPercentage(location.y, pageHeight),
      width: asPercentage(location.width, pageWidth),
      height: asPercentage(location.height, pageHeight)
    }}>
    {fillField && !isFilled
      ? (
        <Button
          className={'d-flex justify-content-center align-items-center w-100 h-100'}
          variant={'primary'}
          onClick={fillField}
          style={{ fontSize: buttonFontSize }}
        >{type === SigningSessionFieldType.Initials ? 'Initials here' : 'Sign here'}</Button>
      )
      : <></>}
    {text && <div className={'h-100 w-100 d-flex flex-column justify-content-end'}><p style={{ fontSize: textFontSize, marginBottom: 0, bottom: 0 }}>
      {text}
    </p></div>}
    {renderImage()}
  </div>;
});
