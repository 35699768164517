import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { WrField } from './CommonComponentWrappers';
import { boolYesNoOpts } from '@property-folders/common/data-and-text/constants';
import '../Form.scss';

export const AgentAuthorityInput = (props: TransactionConsumerProps): JSX.Element => {
  return (
    <div className="d-flex w-100 gapped-row">
      <div className='flex-grow-1'>
        <WrField.CheckRadio
          label='Is the Agent authorised to accept offers on behalf of the Vendor?'
          radioType='checkbox'
          options={boolYesNoOpts}
          valueType='boolean'
          inline={true}
          name='agentAuthority'
          myPath='agentAuthority'
        />
      </div>
    </div>
  );
};
