import Select from 'react-select';
import clsJn from '@property-folders/common/util/classNameJoin';
import { CSSProperties, useEffect, useState } from 'react';
import { FormsApi } from '@property-folders/common/client-api/formsApi';
import { Chevroned } from './Chevroned';
import { Variant } from 'react-bootstrap/types';

type SelectOption = { value: string | 'All'; label: React.ReactNode; };

export interface FormCategorySelectProps {
  className?: string;
  variant?: Variant
  onChange: (category: string) => void;
  includeAll?: boolean;
  value: string;
  style?: CSSProperties | undefined;
}

export function FormCategorySelect(props: FormCategorySelectProps) {
  const {
    className,
    variant = 'secondary',
    onChange,
    includeAll = true,
    value,
    style
  } = props;

  const getValue = (options: SelectOption[]): SelectOption | SelectOption[] | undefined => {
    // const optionsFlat = options.map(o => o.options).flat();
    if (typeof value === 'string') {
      return options.find(opt => opt.value === value);
    } else {
      return undefined;
    }
  };

  const [val, setVal] = useState<SelectOption | SelectOption[] | undefined>(undefined);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setVal(getValue(options));
  }, [value, isLoading]);

  useEffect(() => {
    FormsApi.listCategoriesForAgent().then(categories => {
      const multiProduct = new Set<number>(categories.results.map(r => r.productId)).size > 1;
      const seenProducts = new Set<number>();
      const newOptions: SelectOption[] = [
        { label: 'All', value: 'All' }
      ];
      for (const module of categories.results) {
        if (!module.hasForms) continue;
        if (!seenProducts.has(module.productId) && multiProduct) {
          newOptions.push({
            label: module.fullProductName,
            value: `product.${module.productId}`
          });
          seenProducts.add(module.productId);
        }
        const labelItems = multiProduct
          ? [module.stateName].concat(module.chain.map(c => c.moduleName))
          : module.chain.map(c => c.moduleName);
        newOptions.push({
          label: <Chevroned items={labelItems} />,
          value: String(module.moduleId)
        });
      }
      setOptions(newOptions);
      setIsLoading(false);
    });
  }, []);

  return <Select
    className={clsJn('select-form', className)}
    isMulti={false}
    options={options}
    isClearable={false}
    value={isLoading ? { value: '', label: 'Loading...' } : val}
    isLoading={isLoading}
    onChange={newValue => {
      if (newValue == null) {
        return;
      }

      onChange(newValue.value);
    }}
    styles={{
      control: (baseStyles, state) => ({
        ...baseStyles,
        background: 'var(--bs-body-bg)',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: `rgb(var(--bs-${variant}-rgb))`,
        borderRadius: 0,
        height: '100%',
        fontSize: '1.25rem'
      }),
      container: (baseStyles, state) => ({
        ...baseStyles,
        ...style
      })
    }}
  />;
}
