import { Button } from 'react-bootstrap';
import { DisplayDate } from '../DisplayDate';
import { ExternalReviewUpdate } from '@property-folders/common/types/ExternalPropertyUpdates';
import { useNavigate } from 'react-router-dom';

export function ExternalReviewCardHeader({ currentUpdate, timezone }: {currentUpdate: ExternalReviewUpdate, timezone: string}) {
  const navigate = useNavigate();
  const chatLink = currentUpdate.chatLink; // typescript was not smart enough to do the type calculation without an explicit variable

  return <div className='w-100 d-flex flex-row'>
    <div>
      <h4>{currentUpdate.name}</h4>
      <span>{currentUpdate.position}</span>
      <span className='badge mt-1 px-2' style={{ backgroundColor: '#e8ecef', color: 'black' }}>via Eckermann Property Forms</span>
    </div>
    <div className='ms-auto'>
      <span><DisplayDate date={currentUpdate.time} timezone={timezone} /></span>
      <span className='badge ms-3 px-2' style={{ backgroundColor: '#e8ecef', color: 'black' }}>{currentUpdate.status}</span>
      {chatLink && (
        <Button
          className='d-block ms-auto mt-3 px-5'
          variant='outline-secondary'
          onClick={() => navigate(chatLink)}
        >
          Chat
        </Button>
      )}
    </div>
  </div>;
}
