import {
  SignerProxyType,
  SigningParty,
  SigningPartyMessageConfig,
  SigningPartySnapshot, SigningPartySource,
  SigningPartyType,
  SigningPartyVerificationConfig,
  SigningPartyVerificationType
} from '@property-folders/contract';
import {
  applySplitPartySignerType
} from '@property-folders/common/yjs-schema/property/validation/field-group-functions/splitPartySignerType';
import { canonicalisers } from '@property-folders/common/util/formatting';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { Predicate } from '@property-folders/common/predicate';

export type EditPartyState = {
  party: {
    type: SigningPartyType;
    source: SigningPartySource;
    typeHostParty?: string | number;
    typeHostComposite?: string;
    proxyAuthority: SignerProxyType;
    proxyEmail?: string;
    proxyPhone?: string;
    proxyName?: string;
    message?: SigningPartyMessageConfig;
    verification?: SigningPartyVerificationConfig;
    verificationDefaultCleared?: boolean
    snapshot: SigningPartySnapshot;
  }
  validationParts: {
    email: boolean;
    requiresEmail: boolean;
    phone: boolean;
    requiresPhone: boolean;
    expectedSigningType: string;
    allowCounterPartSigning: boolean;
  }
  isValid: boolean,
  form: {
    originalEmail: string;
    dependsOnEmail: boolean;
    showEmailWarning: boolean;
    originalPhone: string;
    dependsOnPhone: boolean;
    showPhoneWarning: boolean;
    invalidSigningType: boolean;
  }
};
type EditPartyStateParty = EditPartyState['party'];

function copyParty(party: SigningParty): EditPartyStateParty {
  if (!party.snapshot) {
    throw new Error('Unexpected lack of signing party snapshot info');
  }
  const preCopyResult: EditPartyStateParty = {
    // note: gotta involve splitPartySignerType somehow
    type: party.type,
    source: party.source,
    typeHostParty: party.typeHostParty,
    typeHostComposite: party.typeHostComposite,
    proxyAuthority: party.proxyAuthority,
    proxyEmail: party.proxyEmail,
    proxyPhone: party.proxyPhone,
    proxyName: party.proxyName,
    message: party.message,
    verification: party.verification,
    snapshot: party.snapshot
  };

  return JSON.parse(JSON.stringify(preCopyResult));
}

function applyDraftStateValidation(draft: EditPartyState) {
  const proxyMode = Predicate.proxyNotSelf(draft.party.proxyAuthority);
  const email = (proxyMode ? draft.party.proxyEmail : draft.party.snapshot.email) ?? '';
  const phone = (proxyMode ? draft.party.proxyPhone : draft.party.snapshot.phone) ?? '';
  const { valid: emailValid } = canonicalisers.email(email);
  const { valid: phoneValid, canonical: canonPhone } = canonicalisers.phone(phone);
  if (proxyMode) {
    draft.party.proxyPhone = canonPhone.toString();
  } else {
    draft.party.snapshot.phone = canonPhone.toString();
  }

  draft.validationParts = {
    ...draft.validationParts,
    email: !!emailValid,
    requiresEmail: draft.party.type === SigningPartyType.SignOnline,
    phone: !!phoneValid,
    requiresPhone: draft.party.type === SigningPartyType.SignOnlineSms || draft.party.verification?.type === SigningPartyVerificationType.Sms
  };

  if (!draft.validationParts.allowCounterPartSigning) {
    const expectPaper = String(draft.validationParts.expectedSigningType?.split('_')?.[0]) === String(SigningPartyType.SignWet);
    const actualPaper = String(draft.party.typeHostComposite?.split('_')?.[0]) === String(SigningPartyType.SignWet);

    if (expectPaper && !actualPaper || !expectPaper && actualPaper) {
      draft.form.invalidSigningType = true;
      draft.isValid = false;
      return draft;
    }
  }

  if (draft.validationParts.requiresEmail && !draft.validationParts.email) {
    draft.isValid = false;
    return draft;
  }

  if (draft.validationParts.requiresPhone && !draft.validationParts.phone) {
    draft.isValid = false;
    return draft;
  }

  draft.isValid = true;

  return draft;
}

export function getInitialEditPartyState(party: SigningParty, formCode: string): EditPartyState {
  const proxyMode = Predicate.proxyNotSelf(party.proxyAuthority);
  const email = (proxyMode ? party.proxyEmail : party.snapshot?.email) ?? '';
  const phone = (proxyMode ? party.proxyPhone : party.snapshot?.phone) ?? '';
  const formType = FormTypes[formCode];
  return applyDraftStateValidation({
    party: copyParty(party),
    validationParts: {
      email: true,
      requiresEmail: true,
      phone: true,
      requiresPhone: true,
      expectedSigningType: party.typeHostComposite ?? String(SigningPartyType.SignOnline),
      allowCounterPartSigning: !formType.disableCounterpartSigning
    },
    isValid: true,
    form: {
      originalEmail: email || '',
      dependsOnEmail: !!formType.usesPartyEmail,
      showEmailWarning: false,
      originalPhone: phone || '',
      dependsOnPhone: !!formType.usesPartyPhone,
      showPhoneWarning: false,
      invalidSigningType: false
    }
  });
}

export type EditPartyAction =
  | { type: 'set-snapshot-email', value?: string }
  | { type: 'set-snapshot-phone', value?: string }
  | { type: 'set-signing-method', value: string }
  | { type: 'set-message', value?: SigningPartyMessageConfig }
  | { type: 'set-verification', value?: SigningPartyVerificationConfig, userInitiatedDelete?: boolean };

export function editPartyStateReducer(draft: EditPartyState, action: EditPartyAction) {
  // apply
  const proxyMode = Predicate.proxyNotSelf(draft.party.proxyAuthority);
  switch (action.type) {
    case 'set-snapshot-email':
      if (proxyMode) draft.party.proxyEmail = action.value ? action.value : '';
      else draft.party.snapshot.email = action.value ? action.value : '';
      draft.form.showEmailWarning = draft.form.dependsOnEmail && draft.form.originalEmail !== draft.party.snapshot.email;
      break;
    case 'set-snapshot-phone':
      if (proxyMode) draft.party.proxyPhone = action.value ? action.value : '';
      else draft.party.snapshot.phone = action.value ? action.value : '';
      draft.form.showPhoneWarning = draft.form.dependsOnPhone && draft.form.originalPhone !== draft.party.snapshot.phone;
      break;
    case 'set-signing-method':
      draft.party.typeHostComposite = action.value;
      applySplitPartySignerType(draft.party, { noProxyChange: true });
      break;
    case 'set-message':
      draft.party.message = action.value;
      break;
    case 'set-verification':
      draft.party.verification = action.value;
      if (action.userInitiatedDelete) draft.party.verificationDefaultCleared = true;
      break;
  }

  applyDraftStateValidation(draft);
}
