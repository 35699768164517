import { useTransactionField } from '../hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { EditorMode, RichTextEditor } from './RichTextEditor';
import { memo } from 'react';
import { generateStandardFieldWrapping } from './form/CommonComponentWrappers';

type LexicalRichTextEditorProps = TransactionConsumerProps
  & { disabled?: boolean; className?: string; counterStart?: number; outputMode: EditorMode, inputMode: EditorMode };

export const LexicalRichTextEditorComponent = (props: LexicalRichTextEditorProps) => {
  const {
    value: text,
    handleUpdate: textUpdate,
    handleAwarenessFocus,
    handleAwarenessBlur,
    errorKeys,
    fullPath
  } = useTransactionField({
    parentPath: props.parentPath,
    myPath: props.myPath
  });

  return <div className='w-100' style={{ counterReset: `topLevelItem ${props.counterStart ?? ''}` }}>
    {generateStandardFieldWrapping(
      undefined,
      errorKeys,
      true,
      fullPath,
      <RichTextEditor
        key={fullPath}
        namespace={fullPath}
        onUpdate={update => {
          textUpdate(update);
        }}
        value={text}
        onFocus={e => handleAwarenessFocus({ nativeEvent: e, ...e } as any)}
        onBlur={e => handleAwarenessBlur({ nativeEvent: e, ...e } as any)}
        disabled={props.disabled}
        className={props.className}
        inputMode={props.inputMode}
        outputMode={props.outputMode}
        contentEditableStyle={{ overflow: 'auto', maxHeight: '50vh' }}
      />
    )}
  </div>;
};

export const LexicalRichTextEditor = memo(LexicalRichTextEditorComponent);
