import React, { useRef, useState, useCallback, ChangeEvent, useMemo } from 'react';
import './MultiLineAddressSelector.scss';
import { PropertySearchApi } from '@property-folders/common/client-api/propertySearchApi';
import { useTransactionField } from '../../hooks/useTransactionField';
import clsJn from '@property-folders/common/util/classNameJoin';
import { Suggestion } from './Suggestor';
import { AsyncTypeahead, Hint } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { composeStreetAddressFromParts, composeSubStatePostFromParts } from '@property-folders/common/util/formatting/string-composites';
import { ISuburbSearchSuggestion, ISuburbSearchSuggestions, IAddressSearchResponse, IPropertySearchSuggestions } from '@property-folders/contract/rest/address-search';
import { usePropertyGnafLocation } from '../../hooks/usePropertyGnafLocation';
import { Icon } from '../Icon';

interface MultiLineAddressSelectorProps {
  myPath: [string, string];
  parentPath: string;
  onUpdate?: (el: any) => void;
  onAddrSelect?: (suggestion: Suggestion) => void;
  onSuburbSelect?: (suggestion: Suggestion) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  primaryConnected?: boolean;
  onPrimaryDisconnect?: () => void;
  duplicate?: boolean;
  autoFocus?: boolean;
  fromLssa?: boolean;
}

export const MultiLineAddressSelector = ({ onAddrSelect, onSuburbSelect, onUpdate, primaryConnected, onPrimaryDisconnect, duplicate, onChange, autoFocus, fromLssa, ...restProps }: MultiLineAddressSelectorProps): JSX.Element => {
  const primaryField = useTransactionField({ parentPath: restProps.parentPath, myPath: restProps.myPath[0] });
  const secondaryField = useTransactionField({ parentPath: restProps.parentPath, myPath: restProps.myPath[1] });
  const gnafId = usePropertyGnafLocation();

  const [addrSuggestions, setAddrSuggestions] = useState([] as Suggestion[]);
  const [subSuggestions, setSubSuggestions] = useState([] as Suggestion[]);
  const [isAddrSearching, setIsAddrSearching] = useState(0);
  const [isSubSearching, setIsSubSearching] = useState(0);
  const [addrHasFocus, setAddrHasFocus] = useState(false);
  const streetFieldRef = useRef<HTMLInputElement>(null);
  const subFieldRef = useRef<HTMLInputElement>(null);
  const justUpdated = primaryField.justUpdated || secondaryField.justUpdated;
  const valid = primaryField.valid && secondaryField.valid;

  const activeAddrSearch = useRef<{abort: ()=>void, response: Promise<IPropertySearchSuggestions | ISuburbSearchSuggestions | undefined>}>();
  const activeSubSearch = useRef<{abort: ()=>void, response: Promise<IPropertySearchSuggestions | ISuburbSearchSuggestions | undefined>}>();
  const hasIntegrationResult = useMemo(()=> addrSuggestions?.some(a => a.IntegrationListingId), [addrSuggestions]);

  const subSearch = useCallback((query: any) => {
    activeSubSearch.current?.abort();
    if (!query || query.trim() === '') {
      setSubSuggestions([]);
      return;
    }

    setIsSubSearching(s => s + 1);

    activeSubSearch.current = PropertySearchApi.getSuburbSuggestions(query, true);
    activeSubSearch.current.response.then((suggestions: any) => {
      setSubSuggestions(suggestions?.Predictions.slice(0, 10).map((s: ISuburbSearchSuggestion) => ({ label: `${s.Name} ${s.State} ${s.Postcode}`, ...s })) ?? []);
    }).catch(e => {
      console.warn('Error getting suburb suggestions', e);
    }).finally(()=>{
      setIsSubSearching(s => s - 1);
    });
  }, []);

  const addrSearch = useCallback((query: string) => {
    activeAddrSearch.current?.abort();
    if (!query || query.trim() === '') {
      return;
    }

    setIsAddrSearching(s => s + 1);

    activeAddrSearch.current = PropertySearchApi.getSuggestions({
      searchTerm: query,
      limitToSa: true,
      gnafCentre: gnafId
    });
    activeAddrSearch.current.response.then((suggestions: any) => {
      setAddrSuggestions(suggestions?.Predictions.slice(0, 10).map((s: IAddressSearchResponse) => ({ label: composeStreetAddressFromParts(s.AddressParts), key: s.FullAddress, ...s })) ?? []);
    }).catch(e => {
      console.warn('Error getting address suggestions', e);
    }).finally(()=>{
      setIsAddrSearching(s => s - 1);
    });
  }, [gnafId]);

  const handleAddrSelect = (selection: Option[]) => {
    if (!Array.isArray(selection) || selection.length !== 1) {
      return;
    }
    const suggestion = selection[0] as Suggestion;

    if (onAddrSelect) {
      onAddrSelect(suggestion);
      return;
    }

    if (suggestion.AddressParts === undefined) {
      return;
    }
    const parts = suggestion.AddressParts;

    primaryField.handleUpdate(composeStreetAddressFromParts(parts), true);
    secondaryField.handleUpdate(composeSubStatePostFromParts(parts), true);
    onUpdate?.(suggestion.FullAddress);
  };

  const handleSuburbSelect = (selection: Option[]) => {
    if (!Array.isArray(selection) || selection.length !== 1) {
      return;
    }
    const suggestion = selection[0] as Suggestion;

    if (onSuburbSelect) {
      onSuburbSelect(suggestion);
      return;
    }

    secondaryField.handleUpdate(`${suggestion.Name}, ${suggestion.State} ${suggestion.Postcode}`, true);
    onUpdate?.(suggestion.FullAddress);
  };

  const suburbFieldDisabled = !primaryField?.value || primaryField.value.trim() === '' || primaryConnected;

  return <div className='referencing-container d-flex position-relative'>
    <AsyncTypeahead
      disabled={primaryField.readOnly}
      id={primaryField.fullPath}
      delay={200}
      useCache={false}
      isLoading={isAddrSearching > 0}
      className={clsJn('form-control two-line-addr address-selector', valid && !duplicate ? '' : 'is-invalid', primaryField.readOnly && 'disabled', restProps.fromLssa && 'is-valid', (primaryField.hasVaried || secondaryField.hasVaried) && 'varied-control')}
      options={addrSuggestions || []}
      onSearch={addrSearch}
      onChange={handleAddrSelect}
      filterBy={() => true}
      highlightOnlyResult={true}
      renderMenuItemChildren={(option: Option) => {
        const vendor = option?.IntegrationContacts?.find(c => c?.contact?.contactClasses?.some(cc => cc.name==='Vendor'))?.contact;
        let icon, tooltip, pack = 'material-icons', variant = 'filled';
        if (vendor && option.GNAF) {
          icon = 'circle';
          tooltip = 'This record has a valid address and vendor details';
        } else if (vendor || option.GNAF) {
          icon = 'clock_loader_40';
          pack = 'material-symbols';
          variant = 'outlined';
          tooltip = vendor ? 'This record has vendor details, but does not have a valid address' : 'This record has a valid address but no vendor details';
        } else {
          icon = 'circle';
          variant = 'outlined';
          tooltip = 'This record does not have a valid address or vendor details';
        }
        return <div className={'d-flex'}>
          {hasIntegrationResult && <div style={{ width: '17px' }}>{option.IntegrationListingId && <Icon pack={pack} title={tooltip} name={icon} variant={variant} icoClass={'agentbox-icon'} />}</div>}
          <div className={'flex-grow-1'}>
            <div className='fw-bold'>{composeStreetAddressFromParts(option.AddressParts)}</div>
            <div className={'d-flex w-100'}>
              <div className='combo-field-subtext'>{composeSubStatePostFromParts(option.AddressParts)}</div>
              {option.IntegrationListingId && vendor && <div className={'ms-auto combo-field-subtext'}>{`${vendor?.firstName} ${vendor?.lastName}`}</div>}
            </div>
          </div>
        </div>;
      }}
      placeholder='Street Address'
      renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
        const {
          onChange: autoOnChange,
          onBlur: autoOnBlur,
          onFocus: autoOnFocus,
          value: autoValue,
          className: selectedClassName,
          autoComplete: _autoComplete,
          ...autocompleteProps
        } = inputProps;

        const renderedValue = autoValue || primaryField.value || '';
        return <Hint><input
          ref={(node) => {
            inputRef(node);
            referenceElementRef(node);
            streetFieldRef.current = node;
          }}
          autoFocus={autoFocus}
          className={clsJn('pseudo-form-control border-0 first-row-size-ref', selectedClassName)}
          style={justUpdated ? { backgroundColor: 'lightgrey' } : {}}
          value={renderedValue}
          autoComplete='new-password' // hack to disable autocomplete
          spellCheck={false}
          onChange={e => {
            autoOnChange?.(e);
            const nativeEvt = e.nativeEvent as InputEvent;
            const isDropInsert = nativeEvt.inputType === 'insertFromDrop';
            const value = isDropInsert ? nativeEvt.data : e.target?.value;
            const text = value ?? '';

            primaryField.handleUpdate(text);
            onPrimaryDisconnect?.();
            secondaryField.handleUpdate('', true);
            onChange?.(e);
          }}
          onBlur={e => {
            autoOnBlur?.(e);
            primaryField.handleAwarenessBlur(e);
            primaryField.handleUpdate(e.target?.value, true);
            setAddrHasFocus(false);
          }}
          onKeyUp={e => {
            if (e.nativeEvent.code === 'Enter') {
              subFieldRef.current?.focus();
              e.preventDefault();
            }
          }}
          onFocus={e=>{
            autoOnFocus?.(e);
            primaryField.handleAwarenessFocus(e);
            setAddrHasFocus(true);
          }}
          maxLength={300}
          {...autocompleteProps}
        /></Hint>;
      }}
    />
    <div className='second-row-absolute' onClick={()=>{suburbFieldDisabled && !primaryField.readOnly && streetFieldRef.current?.focus();}}>
      <AsyncTypeahead
        key={primaryField.value} // rerenders component when an address auto complete selection is picked
        id={secondaryField.fullPath}
        delay={500}
        useCache={false}
        isLoading={isSubSearching > 0}
        className='w-100'
        options={subSuggestions || []}
        onSearch={subSearch}
        onChange={handleSuburbSelect}
        placeholder={suburbFieldDisabled || isAddrSearching || (addrSuggestions.length > 0 && addrHasFocus) ? '' : 'Suburb, State, Postcode'}
        disabled={secondaryField.readOnly||suburbFieldDisabled}
        highlightOnlyResult={true}
        renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
          const {
            onChange: autoOnChange,
            onBlur: autoOnBlur,
            onFocus: autoOnFocus,
            value: autoValue,
            className: selectedClassName,
            autoComplete: _autoComplete,
            ...autocompleteProps
          } = inputProps;

          const renderedValue = autoValue || secondaryField.value || '';
          return <input
            ref={(node) => {
              inputRef(node);
              referenceElementRef(node);
              subFieldRef.current = node;
            }}
            value={renderedValue}
            autoComplete='new-password' // hack to disable autocomplete
            spellCheck={false}
            onChange={e => {
              autoOnChange?.(e);

              const nativeEvt = e.nativeEvent as InputEvent;
              const isDropInsert = nativeEvt.inputType === 'insertFromDrop';
              const text = isDropInsert ? nativeEvt.data : e.target?.value;
              secondaryField.handleUpdate(text, isDropInsert, nativeEvt.data ? 'insert' : undefined);
            }}
            onBlur={e => {
              autoOnBlur?.(e);
              secondaryField.handleAwarenessBlur(e);
              secondaryField.handleUpdate(e.target?.value, true);
            }}
            onFocus={e=>{
              autoOnFocus?.(e);
              secondaryField.handleAwarenessFocus(e);
            }}
            maxLength={300}
            {...autocompleteProps}
          />;
        }}
      />
      {suburbFieldDisabled && !primaryField.readOnly && <div className='w-100 h-100 second-row-click-mask' onClick={()=>{streetFieldRef.current?.focus();}}></div>}
    </div>

  </div>;
};
