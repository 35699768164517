import { Button, Modal } from 'react-bootstrap';

import { Clause } from '@property-folders/common/types/Clause';
import { AsyncButton } from '../AsyncButton';

interface Props {
  show: boolean;
  clause: Clause | null;
  onHide: () => void;
  onDelete: (clause: Clause) => void | Promise<void>;
}

export function DeleteClauseModal(props: Props) {
  const { show, onHide, onDelete, clause } = props;

  if (!clause) {
    return null;
  }

  return <Modal
    onHide={onHide}
    show={show}
  >
    <Modal.Header>
      <h3>Delete Clause</h3>
    </Modal.Header>

    <Modal.Body>
      <div>
        Are you sure you wish to delete the clause titled <em>{clause.title}</em>?
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button
        variant='light'
        className='ms-auto'
        onClick={onHide}
      >Cancel</Button>
      <AsyncButton
        variant='danger'
        className='ms-2'
        onClick={async () => {
          await onDelete(clause);
        }}
      >Delete</AsyncButton>
    </Modal.Footer>
  </Modal>;
}
