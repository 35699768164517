import { useEffect, useState } from 'react';

export function WrappedPlainElement({ element, onChange }: {
  element: Element,
  onChange: (targetNames: string[]) => void
}) {
  const asInput = element as (HTMLInputElement | HTMLTextAreaElement);
  const [value, setValue] = useState('');

  useEffect(() => {
    if (element.classList.contains('custom-select')) {
      const change = () => onChange([asInput.name]);
      element.addEventListener('change', change);
      return () => element.removeEventListener('change', change);
    } else {
      const focus = () => setValue(asInput.value);
      element.addEventListener('focus', focus);
      return () => element.removeEventListener('focus', focus);
    }
  }, []);

  useEffect(() => {
    if (!element.classList.contains('custom-select')) {
      const blur = () => {
        if (asInput.value === value) return;
        onChange([asInput.name]);
      };
      element.addEventListener('blur', blur);
      return () => element.removeEventListener('blur', blur);
    }
  }, [value]);

  return <></>;
}
