import { WizardStepPage, WizardStepPageProps } from '@property-folders/components/dragged-components/Wizard/WizardStepPage';
import { AddressTitlePair } from '@property-folders/components/dragged-components/form/AddressTitlePair';

export function PropertyWizardStepPage(props: Partial<WizardStepPageProps>) {
  return <WizardStepPage
    name="property"
    label="Property"
    icon="home"
    pdfFocus='subsection-property'
    {...props}
  >
    <AddressTitlePair autoAddFirst={false} />
  </WizardStepPage>;
}
