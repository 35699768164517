import { useMemo, useContext } from 'react';
import { LineageContext } from './useVariation';
import { isVariationPresent } from '@property-folders/common/util/variations/isVariationPresent';

export const useMultiWatchVariation = (pathWatchList: string[][], extraVariationCallback?: ()=>boolean) => {
  const { variationsMode: variationsModeContext, changeSet, expandAll } = useContext(LineageContext);
  const variationsMode = variationsModeContext && pathWatchList && pathWatchList.length > 0;
  return useMemo(()=>{
    if (!variationsModeContext || !changeSet) {
      return { variationsMode, hasVaried: false };
    }

    return { variationsMode, expandAll, hasVaried: isVariationPresent(pathWatchList, changeSet) || extraVariationCallback?.() || false };
  }, [variationsModeContext, expandAll, changeSet, pathWatchList]);
};
