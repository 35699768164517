import { FormCodeUnion } from '@property-folders/contract';
import { useVariation } from '../../hooks/useVariation';
import { isVariationPresent } from '@property-folders/common/util/variations/isVariationPresent';
import { propertyFolder } from '@property-folders/contract/yjs-schema/model/field';
import { PathSegments } from '@property-folders/contract/yjs-schema/model';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { handleNewForm } from '@property-folders/common/util/handleNewForm';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { Y } from '@syncedstore/core';
import { FileSync } from '@property-folders/common/offline/fileSync';
import { ExtraFormCode, FormCode, FormSigningState, META_APPEND, PropertyRootKey, MaterialisedPropertyData, TransactionMetaData } from '@property-folders/contract/yjs-schema/property';
import { useContext } from 'react';
import { YjsDocContext } from '../../context/YjsDocContext';
import { FileSyncContext } from '../../context/fileSyncContext';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { buildSigningTimelines } from '@property-folders/common/util/dataExtract';
import { AGENCY_AGREEMENT_EXPIRY_REMINDER_DAYS } from '@property-folders/common/data-and-text/constants';

interface FormCreateActions {
  pickNavigate: boolean,
  createAction: ()=>void,
  navigateAction: ()=>void,
  targetFormCode: FormCodeUnion
}

function anySignedUnterminatedContracts (ydoc: Y.Doc) {
  const meta = ydoc.getMap(PropertyRootKey.Meta).toJSON() as TransactionMetaData;
  return (meta.sublineageRoots??[]).some(subline => {
    const sublinMeta = ydoc.getMap(subline+META_APPEND).toJSON() as TransactionMetaData;

    const fstate = sublinMeta.formStates?.[FormCode.RSC_ContractOfSale];
    if (!fstate || fstate.terminationConfirmed) return false;
    return (fstate.instances??[]).some(inst=>inst.signing?.state === FormSigningState.Signed);
  });
}

export function ShowIfVariationRequired({ formCode, pathWatchList, children }: {
  formCode: FormCodeUnion,
  pathWatchList: PathSegments[],
  children: React.ReactNode | ((actions: FormCreateActions) => React.ReactNode)
}) {
  formCode = FormTypes[formCode].formFamily;
  const navigate = useNavigate();
  const { ydoc, transactionRootKey, transactionMetaRootKey } = useContext(YjsDocContext);
  if (!ydoc) return null;
  const { instance: fileSync } = useContext(FileSyncContext);
  const { changeSet, snapshotHistory } = useVariation(
    false, {
      formCode: formCode,
      transactionRules: propertyFolder
    }
  );
  const family = FormTypes[formCode].formFamily;
  const latestExpiry = family === FormCode.RSAA_SalesAgencyAgreement && buildSigningTimelines(snapshotHistory)?.latestExpiry;

  const expiryWarn = latestExpiry && new Date(latestExpiry);
  if (expiryWarn) expiryWarn.setDate(expiryWarn.getDate()-AGENCY_AGREEMENT_EXPIRY_REMINDER_DAYS);
  const withinExpiry = expiryWarn && expiryWarn < new Date();
  const signedContracts = anySignedUnterminatedContracts(ydoc);
  const noContractsBlocking = withinExpiry && family === FormCode.RSAA_SalesAgencyAgreement && !signedContracts;
  const showExpiry = (noContractsBlocking && withinExpiry);
  const show = isVariationPresent(pathWatchList, changeSet) || showExpiry;

  const variationCode = showExpiry ? ExtraFormCode.CRSSA_SalesAgencyAgreementSubsequent : Object.entries(FormTypes).find(([_fc, ft]) => ft.isVariation && ft.formFamily === family)?.[0] as FormCodeUnion | undefined;

  if (!show) return null;
  if (typeof children === 'function' && variationCode && ydoc && fileSync) {
    children = children(createOrGotoVariationFactory(
      ydoc,
      fileSync,
      transactionRootKey,
      transactionMetaRootKey,
      navigate,
      variationCode
    ));
  } else if (typeof children === 'function') {
    return <div>Error!</div>;
  }
  return Array.isArray(children)
    ? <>{children}</>
    : children;
}

const navigateToFormFactory = (navigate: NavigateFunction, ydoc: Y.Doc) => (instanceId: string, formCode: FormCodeUnion) => {
  const property = ydoc.getMap<MaterialisedPropertyData>(PropertyRootKey.Data).toJSON() as MaterialisedPropertyData;

  const headline = generateHeadlineFromMaterialisedData(property);
  const formName = FormTypes[formCode]?.label;
  // Subscription forms don't yet support variations?
  navigate(LinkBuilder.documentPath({ id: property.id, nicetext: headline }, { id: instanceId, nicetext: formName }, false));
};

const createFormFactory = (ydoc: Y.Doc, fileSync: FileSync, dataRootKey:string = PropertyRootKey.Data, metaRootKey:string = PropertyRootKey.Meta, navigate: NavigateFunction) => (formCode: FormCodeUnion) => {
  const newFormPromise = handleNewForm(ydoc, formCode, fileSync, undefined, dataRootKey, metaRootKey);
  newFormPromise.then(newForm=>{
    if (!newForm) {
      return;
    }
    navigateToFormFactory(navigate, ydoc)(newForm.formId, formCode);
  });
};

export function createOrGotoVariationFactory(
  ydoc: Y.Doc,
  fileSync: FileSync,
  dataRootKey:string = PropertyRootKey.Data,
  metaRootKey:string = PropertyRootKey.Meta,
  navigate: NavigateFunction,
  formCode: FormCodeUnion
): FormCreateActions {
  const family = FormTypes[formCode].formFamily;
  const instances = (ydoc.getMap<TransactionMetaData>(metaRootKey).toJSON() as TransactionMetaData).formStates?.[family].instances;
  const existingVariationOrSubsequent = (instances??[]).filter(inst => {
    const type = FormTypes[inst.formCode];
    return (type?.isVariation || inst.formCode === ExtraFormCode.CRSSA_SalesAgencyAgreementSubsequent) && inst.signing?.state !== FormSigningState.Signed;
  })[0];
  return {
    createAction: ()=>createFormFactory(ydoc, fileSync, dataRootKey, metaRootKey, navigate)(formCode),
    navigateAction: ()=>navigateToFormFactory(navigate, ydoc)(existingVariationOrSubsequent.id, formCode),
    pickNavigate: !!existingVariationOrSubsequent,
    targetFormCode: formCode
  };
}