import React, { useCallback, useContext, useMemo, useState } from 'react';
import { WrField } from '../CommonComponentWrappers';
import { quadStateYesNoUnknownOpts } from '@property-folders/common/util/pdfgen/constants';
import { UploadedDocumentsSelector } from '../../UploadedDocumentsSelector';
import {
  FileRef,
  FormCode,
  Maybe,
  PortionOfLandDwellingDetails,
  twelveMonthsAfterContractDate,
  UploadType
} from '@property-folders/contract';
import { useLightweightTransaction, useTransactionField } from '../../../hooks/useTransactionField';
import { FormContext } from '../../../context/FormContext';
import { QuadStateDualBoolCheckboxes } from '../../../display/form/QuadStateRadioCheckboxes';
import {
  canonicalisers,
  composeErrorPathClassName,
  friendlyDateFormatter,
  friendlyTimeFormatter
} from '@property-folders/common/util/formatting';
import { YjsDocContext } from '../../../context/YjsDocContext';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { Dwelling } from '@property-folders/common/util/dwelling';
import { quadStateBoolText } from '@property-folders/common/util/quadStateFilter';
import { WrappedFetch } from '@property-folders/common/client-api/wrappedFetch';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { AsyncButton } from '../../AsyncButton';
import { Accordion, Alert } from 'react-bootstrap';
import { Predicate } from '@property-folders/common/predicate';
import { Icon } from '../../Icon';

export function DwellingDetails({
  editable = true,
  startExpanded = false
}: {
  editable?: boolean,
  startExpanded?: boolean
}) {
  const { formName: formCode } = useContext(FormContext);
  const { docName, transactionRootKey } = useContext(YjsDocContext);
  const { value: dwelling, handleUpdate: updateDwelling } = useTransactionField<Maybe<PortionOfLandDwellingDetails>>({
    parentPath: 'dwelling'
  });
  const {
    value: vendorPlan,
    handleUpdate: updateVendorPlan,
    handleRemove: removeVendorPlan,
    fullPath: vendorPlanPath
  } = useTransactionField<Maybe<FileRef>>({
    parentPath: 'dwelling',
    myPath: 'vendorPlan'
  });
  const {
    value: vendorSpecification,
    handleUpdate: updateVendorSpecification,
    handleRemove: removeVendorSpecification,
    fullPath: vendorSpecificationPath
  } = useTransactionField<Maybe<FileRef>>({
    parentPath: 'dwelling',
    myPath: 'vendorSpecification'
  });

  const selectedVendorPlans = useMemo<FileRef[]>(() => {
    return vendorPlan
      ? [{ ...vendorPlan }]
      : [];
  }, [vendorPlan?.id]);
  const handleChangeVendorPlans = useCallback((refs: FileRef[]) => {
    const ref = refs.at(0);
    if (ref) {
      updateVendorPlan(ref, true);
    } else {
      removeVendorPlan();
    }
  }, [vendorPlan?.id]);

  const selectedVendorSpecifications = useMemo<FileRef[]>(() => {
    return vendorSpecification
      ? [{ ...vendorSpecification }]
      : [];
  }, [vendorSpecification?.id]);
  const handleChangeVendorSpecifications = useCallback((refs: FileRef[]) => {
    const ref = refs.at(0);
    if (ref) {
      updateVendorSpecification(ref, true);
    } else {
      removeVendorSpecification();
    }
  }, [vendorSpecification?.id]);

  const show = useMemo(() => Dwelling.sectionVisibility(dwelling),
    [JSON.stringify(dwelling)]);
  const [expanded, setExpanded] = useState(!!startExpanded);
  const summary = useMemo(() => {
    const missing: boolean[] = [
      typeof dwelling?.vacantLand === 'undefined',
      typeof dwelling?.demolitionRequired === 'undefined',
      show.purchaserWillBuildQuestion && typeof dwelling?.purchaserWillBuild === 'undefined',
      show.purchaserBuildInfo && !dwelling?.purchaserBuilderName,
      show.purchaserBuildInfo && !(dwelling?.buildContractDue12 || dwelling?.buildContractDueDate),
      show.vendorWillBuildQuestion && typeof dwelling?.vendorWillBuild === 'undefined',
      show.vendorBuildInfo && typeof dwelling?.vendorHasPlanningConsent === 'undefined',
      show.vendorBuildInfo && typeof dwelling?.vendorHasDevelopmentApproval === 'undefined',
      show.vendorBuildInfo && !dwelling?.vendorPlan,
      show.vendorBuildInfo && !dwelling?.vendorSpecification
    ];

    if (missing.some(Boolean)) {
      return <span className='text-danger'>Missing information</span>;
    }

    const pills = [
      dwelling?.vacantLand ? 'Vacant' : 'Non-Vacant',
      dwelling?.demolitionRequired ? 'Demolition' : undefined,
      show.purchaserWillBuildQuestion && dwelling?.purchaserWillBuild ? 'Purchaser Build' : undefined,
      show.vendorWillBuildQuestion && dwelling?.vendorWillBuild ? 'Vendor Build' : undefined
    ].filter(Predicate.isTruthy);

    return <span>
      {pills.join(', ')}
    </span>;
  }, [expanded]);

  return <div className='mt-3'>
    <Accordion defaultActiveKey={startExpanded ? '0' : undefined} flush onSelect={e => {
      setExpanded(e != null);
    }}>
      <Accordion.Item eventKey='0'>
        <Accordion.Header>
          <div className='w-100 pe-4 d-flex align-items-baseline justify-content-between gap-2 h-100'>
            <div className={'fs-4'}>
              Dwelling Details
            </div>
            {!expanded && <div
              className={'overflow-hidden text-overflow-ellipsis d-flex flex-row gap-1'}
              style={{ lineHeight: '1.5rem' }}>
              {summary}
            </div>}
          </div>
        </Accordion.Header>
        <Accordion.Body>
          {!editable && <div className='d-flex flex-column'>
            <div>Vendor providing vacant land at settlement: <b>{quadStateBoolText(dwelling?.vacantLand, dwelling?._vacantLand)}</b></div>
            <div>Demolition required: <b>{quadStateBoolText(dwelling?.demolitionRequired, dwelling?._demolitionRequired)}</b></div>
            {show.purchaserWillBuildQuestion && <div>Purchaser will enter building contract: <b>{quadStateBoolText(dwelling?.purchaserWillBuild, dwelling?._purchaserWillBuild)}</b></div>}
            {show.purchaserBuildInfo && <>
              <div>Purchaser builder name: <b>{dwelling?.purchaserBuilderName}</b></div>
              <div>Purchaser building contract due: <b>{dueDateText(dwelling?.buildContractDue12, dwelling?.buildContractDueDate, '12 months after contract date')}</b></div>
            </>}
            {show.vendorWillBuildQuestion && <div>Vendor will build a dwelling prior to settlement: <b>{quadStateBoolText(dwelling?.purchaserWillBuild, dwelling?._purchaserWillBuild)}</b></div>}
            {show.vendorBuildInfo && <>
              <div>Planning consent obtained: <b>{quadStateBoolText(dwelling?.vendorHasPlanningConsent, dwelling?._vendorHasPlanningConsent)}</b></div>
              <div>Development approval obtained: <b>{quadStateBoolText(dwelling?.vendorHasDevelopmentApproval, dwelling?._vendorHasDevelopmentApproval)}</b></div>
              <div>Dwelling plans uploaded: <b>{dwelling?.vendorPlan ? 'yes' : 'no'}</b></div>
              <div>Dwelling specifications uploaded: <b>{dwelling?.vendorSpecification ? 'yes' : 'no'}</b></div>
            </>}
          </div>}
          {editable && <>
            <div className='d-flex w-100 flex-wrap mt-2'>
              <div className='flex-grow-1'>
                <QuadStateDualBoolCheckboxes
                  parentPath='dwelling'
                  yesNoBoolRelPath='vacantLand'
                  unknownBoolRelPath='_vacantLand'
                  label='Is the Vendor providing vacant land to the Purchaser at Settlement?'
                  options={quadStateYesNoUnknownOpts}
                  inline={true}
                />
              </div>
            </div>
            <div className='d-flex w-100 flex-wrap mt-2'>
              <div className='flex-grow-1'>
                <QuadStateDualBoolCheckboxes
                  parentPath='dwelling'
                  yesNoBoolRelPath='demolitionRequired'
                  unknownBoolRelPath='_demolitionRequired'
                  label='Is there demolition required?'
                  options={quadStateYesNoUnknownOpts}
                  inline={true}
                />
              </div>
            </div>
            {show.purchaserWillBuildQuestion && <div className='d-flex w-100 flex-wrap mt-2'>
              <div className='flex-grow-1'>
                <QuadStateDualBoolCheckboxes
                  parentPath='dwelling'
                  yesNoBoolRelPath='purchaserWillBuild'
                  unknownBoolRelPath='_purchaserWillBuild'
                  label='Is the Purchaser required to enter into a Building Contract with a particular Builder prior to Settlement?'
                  options={quadStateYesNoUnknownOpts}
                  inline={true}
                />
              </div>
            </div>}
            {show.purchaserBuildInfo && <div className='d-flex w-100 flex-wrap mt-2'>
              <div className='flex-grow-1'>
                <WrField.Control
                  name='purchaserBuilderName'
                  myPath='purchaserBuilderName'
                  parentPath='dwelling'
                  label='Who is the builder?'
                />
                <div className='d-flex w-100 flex-wrap mt-2'>
                  <WrField.CheckRadio
                    parentPath='dwelling'
                    name='buildContractDue12'
                    myPath='buildContractDue12'
                    label='What date does the Purchaser need to enter into the Building Contract by?'
                    radioType='checkbox'
                    valueType='boolean'
                    options={twelveMonthsAfterContractDate}
                  />
                </div>
                {!dwelling?.buildContractDue12 && <div className='d-flex w-100 flex-wrap mt-2'>
                  <div className='flex-grow-1'>
                    <WrField.Control
                      parentPath='dwelling'
                      name='buildContractDueDate'
                      myPath='buildContractDueDate'
                      label='Building Contract due date'
                      type='date'
                      dateFromToday={true}
                      useCanonical={true}
                    />
                  </div>
                </div>}
              </div>
            </div>}
            {show.vendorWillBuildQuestion && <div className='d-flex w-100 flex-wrap mt-2'>
              <div className='flex-grow-1'>
                <QuadStateDualBoolCheckboxes
                  parentPath='dwelling'
                  yesNoBoolRelPath='vendorWillBuild'
                  unknownBoolRelPath='_vendorWillBuild'
                  label='Is the Vendor building a new dwelling on the land for the Purchaser prior to Settlement?'
                  options={quadStateYesNoUnknownOpts}
                  inline={true}
                />
              </div>
            </div>}
            {show.vendorBuildInfo && <>
              <div className='d-flex w-100 flex-wrap mt-2'>
                <div className='flex-grow-1'>
                  <QuadStateDualBoolCheckboxes
                    parentPath='dwelling'
                    yesNoBoolRelPath='vendorHasPlanningConsent'
                    unknownBoolRelPath='_vendorHasPlanningConsent'
                    label='Has Planning Consent been obtained?'
                    options={quadStateYesNoUnknownOpts}
                    inline={true}
                  />
                </div>
              </div>
              <div className='d-flex w-100 flex-wrap mt-2'>
                <div className='flex-grow-1'>
                  <QuadStateDualBoolCheckboxes
                    parentPath='dwelling'
                    yesNoBoolRelPath='vendorHasDevelopmentApproval'
                    unknownBoolRelPath='_vendorHasDevelopmentApproval'
                    label='Has Development Approval been obtained?'
                    options={quadStateYesNoUnknownOpts}
                    inline={true}
                  />
                </div>
              </div>
              <div className='d-flex w-100 flex-wrap flex-row gap-2 mt-2'>
                <div className='flex-grow-1'>
                  <UploadedDocumentsSelector
                    onSelect={handleChangeVendorPlans}
                    selectedFileRefs={selectedVendorPlans}
                    uploadTypeFilter={UploadType.DwellingPlan}
                    label={'Upload a copy of the Dwelling Plans'}
                    overlayLabel={'Drop Dwelling Plans here'}
                    inputClassName={composeErrorPathClassName(vendorPlanPath, undefined)}
                  />
                </div>
                <div className='flex-grow-1'>
                  <UploadedDocumentsSelector
                    onSelect={handleChangeVendorSpecifications}
                    selectedFileRefs={selectedVendorSpecifications}
                    uploadTypeFilter={UploadType.DwellingSpecification}
                    label={'Upload a copy of the  Dwelling Specifications'}
                    overlayLabel={'Drop Dwelling Specifications here'}
                    inputClassName={composeErrorPathClassName(vendorSpecificationPath, undefined)}
                  />
                </div>
              </div>
            </>}
          </>}
          <div className='d-flex w-100 flex-wrap flex-row mt-4'>
            <DwellingRequestQuote/>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  </div>;
}

export function dueDateText(due12: boolean | undefined, dueDate: string | undefined, due12Text: string): string {
  if (due12) return due12Text;
  if (!dueDate) return 'unknown';
  return canonicalisers.date(dueDate).display;
}

export function DwellingRequestQuote() {
  const { docName: propertyId, transactionRootKey } = useContext(YjsDocContext);
  const { formName: formCode } = useContext(FormContext);
  const { value: dwelling } = useLightweightTransaction<PortionOfLandDwellingDetails>({ parentPath: 'dwelling' });
  const family = FormTypes[formCode]?.formFamily;
  const immediate = family === FormCode.RSC_ContractOfSale || (family === FormCode.RSAA_SalesAgencyAgreement && formCode !== FormCode.RSAA_SalesAgencyAgreement);
  // cases:
  // * on SAA/newTrans - quote not requested
  //    * checkbox to request quote after execution
  // * on RSC - quote not requested - SAA not executed
  //    * checkbox to request quote after execution
  // * on RSC - quote not requested - SAA executed
  //    * button to request quote now
  // * on RSC - quote requested
  //    * "Request for cost estimate and turnaround time sent to EL on [date] at [time]"

  if (!dwelling?.el?.requested && (!dwelling || Dwelling.isSimple(dwelling))) {
    return <Alert variant={'info'} className='d-flex flex-row gap-2 align-items-center'>
      <Icon name={'info'} />
      <span>
        According to the answers provided above,
        no Additional Condition is required in the Contract in relation to
        any dwelling associated with the Property.
      </span>
    </Alert>;
  }

  return <div>
    <Alert variant={'info'} className='d-flex flex-row gap-2 align-items-center'>
      <Icon name={'info'} />
      <span>
        According to the answers provided above,
        an Additional Condition may be required for the Contract to
        appropriately deal with the dwelling details associated with the Property.
      </span>
    </Alert>
    {dwelling?.el?.requested
      ? <p>
        Request for cost estimate and turnaround time sent to Eckermann Lawyers
        on {friendlyDateFormatter(dwelling.el.requested)} at {friendlyTimeFormatter(dwelling.el.requested)}
      </p>
      : <>
        <p>
          Eckermann Lawyers can provide a cost estimate and turnaround time for providing an appropriate Additional
          Condition for the Contract
        </p>
        {immediate
          ? <AsyncButton onClick={async () => {
            const qs = LinkBuilder.buildQueryString({
              sublineageId: transactionRootKey
            });
            try {
              await WrappedFetch.bare(LinkBuilder.restApi(`/properties/${propertyId}/el/request-quote?${qs}`), { method: 'POST' });
            } catch (err) {
              console.error(err);
            }
          }}>Request now</AsyncButton>
          : <WrField.BoolCheck
            parentPath={'dwelling'}
            myPath={'el.queued'}
            name={'el.queued'}
            label={'Request a quote from Eckermann Lawyers automatically once the Agency Agreement is executed'}
          />
        }
        <p className='d-flex flex-column'>

          <small>This will share Property, Vendor, and Agency details from this transaction with Eckermann
            Lawyers</small>
        </p>
        <p>
          Otherwise, we recommend the Vendor engage the services of a solicitor to
          prepare an appropriate Additional Condition on their behalf.
        </p>
      </>}

  </div>;
}
