import { useEffect } from 'react';

interface Props {
  to: string;
}

export function WindowRedirect({ to }: Props) {
  useEffect(() => {
    window.location.href = to;
  });

  return <></>;
}
