import './Field.scss';
import { forwardRef } from 'react';
import { FieldProps } from './common';
import { SigningSessionFieldType } from '@property-folders/contract';
import { SignInitialsField } from './SignInitialsField';
import { TextField } from './TextField';
import { RadioField } from './RadioField';
import { CheckField } from './CheckField';

export const Field = forwardRef<HTMLDivElement, FieldProps>((props, ref) => {
  switch (props.type) {
    case SigningSessionFieldType.Signature:
    case SigningSessionFieldType.Initials:
      return <SignInitialsField ref={ref} {...props} />;
    case SigningSessionFieldType.Text:
      return <TextField ref={ref} {...props} />;
    case SigningSessionFieldType.Radio:
      return <RadioField ref={ref} {...props} />;
    case SigningSessionFieldType.Check:
      return <CheckField ref={ref} {...props} />;
  }
});
