import { ElementTransformer } from '~/components/lexical/lexical-markdown/src';
import {
  $isParagraphNode,
  ParagraphNode
} from 'lexical';

export const INDENT: ElementTransformer = {
  dependencies: [ParagraphNode],
  export: (node, exportChildren) => {
    if (!$isParagraphNode(node) || node.getIndent() === 0) {
      return null;
    }

    // some md readers trim the lines, so we've got to make it untrimmable
    return '|' + ' '.repeat(4 * node.getIndent()) + exportChildren(node);
  },
  regExp: /^\|(\s{4})+/,
  replace: (node, _children, match, _isImport) => {
    if (match.length > 0) {
      const indent = Math.floor(match[0].length / 4);
      node.setIndent(indent);
    }
  },
  type: 'element'
};
