import { useCallback, useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { AuditApi } from '@property-folders/common/client-api/audit';
import { GetSigningSessionHistoryResult, PropertyAuditMetadata } from '@property-folders/contract/rest/property';
import { Predicate } from '@property-folders/common/predicate';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '../../display/errors/modals';
import clsJn from '@property-folders/common/util/classNameJoin';

function LabeledEnvelopeColumn({ label, value, fullWidth }: {label: string, value: string, fullWidth?: boolean}) {
  return <div className={clsJn({
    'col-lg-6': !fullWidth,
    'col-lg-12': !!fullWidth,
    'd-flex': true,
    'flex-column': true,
    'mb-2': true
  })}>
    <div className={'fw-bold'}>{label}</div>
    <div>{value}</div>
  </div>;
}

function LabeledHistoryValue({ label, value, width }: {label: string, value: string, width: 2|6 }) {
  return <div className={`col-lg-${width} d-flex flex-column`}>
    <div className={'d-block d-lg-none fw-bold'}>{label}</div>
    <div>{value}</div>
  </div>;
}

function LabeledHistoryUser({ name, meta, width }: { name: string, meta?: PropertyAuditMetadata, width: 2|6 }) {
  const metaItems = [
    meta?.proxyOnBehalfOf ? `On behalf of ${meta.proxyOnBehalfOf}` : '',
    meta?.phone,
    meta?.email,
    meta?.ip,
    meta?.hostedBy,
    meta?.smsVerified ? 'SMS verified' : undefined
  ].filter(Predicate.isTruthy);

  return <div className={`col-lg-${width} d-flex flex-column`}>
    <div className={'d-block d-lg-none fw-bold'}>User</div>
    <div>
      <div>{name}</div>
      {!!metaItems.length && <ul className={'wrap-text list-unstyled fs-6'}>
        {metaItems.map((item, idx) => (<li key={idx}>{item}</li>))}
      </ul>}
    </div>
  </div>;
}

function LabeledHistoryActivity({ data, width }: { data?: PropertyAuditMetadata & { activity: string }, width: 2|6 }) {
  const items = [
    data?.activity,
    data?.wetSignedDate,
    data?.copyAttached !== undefined ? data?.copyAttached ? 'Copy attached' : 'Copy not attached' : undefined
  ].filter(Predicate.isTruthy);

  return <div className={`col-lg-${width} d-flex flex-column`}>
    <div className={'d-block d-lg-none fw-bold'}>Activity</div>
    <div>
      {!!items.length && <ul className={'wrap-text list-unstyled fs-6'}>
        {items.map((item, idx) => (<li key={idx}>{item}</li>))}
      </ul>}
    </div>
  </div>;
}

function RenderData({ data }: {data: GetSigningSessionHistoryResult}) {
  return <div className={'container'}>
    <div className={'row mb-3'}>
      <div className={'col'}>
        <h4>Details</h4>
      </div>
    </div>
    <div className={'row mb-3'}>
      <LabeledEnvelopeColumn label={'Subject'} value={data.envelope.subject} fullWidth={true} />
      <LabeledEnvelopeColumn label={'Document'} value={data.envelope.document} />
      <LabeledEnvelopeColumn label={'Signing Session ID'} value={data.envelope.id} />
      <LabeledEnvelopeColumn label={'Initiator'} value={data.envelope.initiator} />
      <LabeledEnvelopeColumn label={'Parties'} value={data.envelope.recipients} />
      <LabeledEnvelopeColumn label={'Time Zone'} value={data.envelope.timezone} />
      <LabeledEnvelopeColumn label={'Created Date'} value={data.envelope.createdAt} />
      <LabeledEnvelopeColumn label={'Status'} value={data.envelope.status} />
      <LabeledEnvelopeColumn label={'Status Date'} value={data.envelope.statusAt} />
    </div>
    <div className={'row mb-3'}>
      <div className={'col'}>
        <h4>History</h4>
      </div>
    </div>
    <div className={'row d-none d-lg-flex py-3'}>
      <div className={'col-2 fw-bold'}>Time</div>
      <div className={'col-3 fw-bold'}>User</div>
      <div className={'col-2 fw-bold'}>Action</div>
      <div className={'col-5 fw-bold'}>Activity</div>
    </div>
    {data.items.length === 0 && (
      <div className={'row py-3 border-top'}>
        <div className={'col'}>
          <h5>No items to display.</h5>
          <small>There can be a delay before records are available.</small>
        </div>
      </div>
    )}
    {data.items.length !== 0 && data.items.map((item, idx) => (
      <div key={idx} className={'row py-3 border-top'}>
        <LabeledHistoryValue label={'Time'} value={item.time} width={2} />
        <LabeledHistoryUser name={item.user} meta={item.meta} width={3} />
        <LabeledHistoryValue label={'Action'} value={item.action} width={2} />
        <LabeledHistoryActivity label={'Activity'} data={{ activity: item.activity, ...item.meta }} width={5} />
      </div>
    ))}
  </div>;
}

interface HistoryModalProps { propertyId: string, signingSessionId: string, onClose: () => void }

export function HistoryModal(props: HistoryModalProps) {
  return <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} onClose={props.onClose} />}>
    <HistoryModalUnwrapped {...props} />
  </ErrorBoundary>;
}

function HistoryModalUnwrapped({ propertyId, signingSessionId, onClose }: HistoryModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [data, setData] = useState<GetSigningSessionHistoryResult | undefined>(undefined);
  const handleClose = useCallback(() => {
    setShow(false);
    onClose();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const request = AuditApi.getSigningSessionHistory(propertyId, signingSessionId);
    request.response
      .then(data => {
        setData(data);
      })
      .catch(console.error)
      .finally(() => setIsLoading(false));

    return () => request.abort();
  }, []);

  return <Modal show={show} onHide={handleClose} size={'xl'} fullscreen={'sm-down'}>
    <Modal.Header>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col'}>
            <h1>Signing History</h1>
          </div>
        </div>
      </div>
    </Modal.Header>
    <Modal.Body>
      {isLoading && <div className={'d-flex flex-row justify-content-center my-3'}>
        <Spinner animation={'border'} />
      </div>}
      {data && <RenderData data={data} />}
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={handleClose}>Close</Button>
    </Modal.Footer>
  </Modal>;
}
